<div class="offcanvas-body profile-offcanvas p-0">
  <div class="text-center p-4 border-bottom">
    <div class="mb-4">
      <img *ngIf="chatHeaderAvatar" [src]="chatHeaderAvatar" class="rounded-circle avatar-lg img-thumbnail" alt="">
      <div *ngIf="!chatHeaderAvatar" class=" profile-user rounded-circle avatar-lg img-thumbnail">
      <span class=" avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                          {{chatHeaderName.charAt(0)}}
                        </span>
      </div>
    </div>
    <h5 class="font-size-16 mb-1 text-truncate">{{chatHeaderName}}</h5>
    <!--    user status eg : Online-->

    <!--    <p class="text-muted text-truncate mb-1">-->
    <!--      <i class="ri-record-circle-fill font-size-10 me-1"-->
    <!--         [ngClass]=" {'text-success': chatHeaderStatus === 'online','text-warning': chatHeaderStatus === 'away'}"></i>-->
    <!--      {{chatHeaderStatus}}</p>-->
  </div>
  <!-- End profile user -->

  <!-- Start user-profile-desc -->
  <perfect-scrollbar class="p-4 user-profile-desc">
    <div class="text-muted">
      <!--    user description -->
      <!--      <p class="mb-4">If several languages coalesce, the grammar of the resulting language is more simple and regular-->
      <!--        than that of the individual.</p>-->
    </div>

    <div id="profile-user-accordion" class="custom-accordion">
      <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
        <ngb-panel cardClass="card shadow-none border mb-2" id="about">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>Text</h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <div>
              <p class="text-muted mb-1">Name</p>
              <h5 class="font-size-14">{{chatHeaderName}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">Extension</p>
              <h5 class="font-size-14">{{chatSelected.peerUserExtension}}</h5>
            </div>
          </ng-template>
        </ngb-panel>

        <!--        <ngb-panel cardClass="card shadow-none border mb-2" id="file">-->
        <!--          <ng-template ngbPanelTitle>-->
        <!--            <h5 class="font-size-14 m-0">-->
        <!--              <i class="ri-attachment-line me-2 align-middle d-inline-block"></i>Attached Files-->
        <!--            </h5>-->
        <!--          </ng-template>-->
        <!--          <ng-template ngbPanelContent>-->
        <!--            <div class="card p-2 border mb-2">-->
        <!--              <div class="d-flex align-items-center">-->
        <!--                <div class="avatar-sm me-3 ms-0">-->
        <!--                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">-->
        <!--                    <i class="ri-file-text-fill"></i>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="flex-grow-1">-->
        <!--                  <div class="text-start">-->
        <!--                    <h5 class="font-size-14 mb-1">admin_v1.0.zip</h5>-->
        <!--                    <p class="text-muted font-size-13 mb-0">12.5 MB</p>-->
        <!--                  </div>-->
        <!--                </div>-->

        <!--                <div class="ms-4 me-0">-->
        <!--                  <ul class="list-inline mb-0 font-size-18">-->
        <!--                    <li class="list-inline-item">-->
        <!--                      <a href="javascript: void(0);" class="text-muted px-1">-->
        <!--                        <i class="ri-download-2-line"></i>-->
        <!--                      </a>-->
        <!--                    </li>-->
        <!--                    <li class="list-inline-item dropdown" ngbDropdown>-->
        <!--                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"-->
        <!--                         role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--                        <i class="ri-more-fill"></i>-->
        <!--                      </a>-->
        <!--                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Action</a>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>-->
        <!--                        <div class="dropdown-divider"></div>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Delete</a>-->
        <!--                      </div>-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            &lt;!&ndash; end card &ndash;&gt;-->

        <!--            <div class="card p-2 border mb-2">-->
        <!--              <div class="d-flex align-items-center">-->
        <!--                <div class="avatar-sm me-3 ms-0">-->
        <!--                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">-->
        <!--                    <i class="ri-image-fill"></i>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="flex-grow-1">-->
        <!--                  <div class="text-start">-->
        <!--                    <h5 class="font-size-14 mb-1">Image-1.jpg</h5>-->
        <!--                    <p class="text-muted font-size-13 mb-0">4.2 MB</p>-->
        <!--                  </div>-->
        <!--                </div>-->

        <!--                <div class="ms-4 me-0">-->
        <!--                  <ul class="list-inline mb-0 font-size-18">-->
        <!--                    <li class="list-inline-item">-->
        <!--                      <a href="javascript: void(0);" class="text-muted px-1">-->
        <!--                        <i class="ri-download-2-line"></i>-->
        <!--                      </a>-->
        <!--                    </li>-->
        <!--                    <li class="list-inline-item dropdown" ngbDropdown>-->
        <!--                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);"-->
        <!--                         role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--                        <i class="ri-more-fill"></i>-->
        <!--                      </a>-->
        <!--                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Action</a>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>-->
        <!--                        <div class="dropdown-divider"></div>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Delete</a>-->
        <!--                      </div>-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            &lt;!&ndash; end card &ndash;&gt;-->

        <!--            <div class="card p-2 border mb-2">-->
        <!--              <div class="d-flex align-items-center">-->
        <!--                <div class="avatar-sm me-3 ms-0">-->
        <!--                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">-->
        <!--                    <i class="ri-image-fill"></i>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="flex-grow-1">-->
        <!--                  <div class="text-start">-->
        <!--                    <h5 class="font-size-14 mb-1">Image-2.jpg</h5>-->
        <!--                    <p class="text-muted font-size-13 mb-0">3.1 MB</p>-->
        <!--                  </div>-->
        <!--                </div>-->

        <!--                <div class="ms-4 me-0">-->
        <!--                  <ul class="list-inline mb-0 font-size-18">-->
        <!--                    <li class="list-inline-item">-->
        <!--                      <a href="javascript:void(0);" class="text-muted px-1">-->
        <!--                        <i class="ri-download-2-line"></i>-->
        <!--                      </a>-->
        <!--                    </li>-->
        <!--                    <li class="list-inline-item dropdown" ngbDropdown>-->
        <!--                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript: void(0);"-->
        <!--                         role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--                        <i class="ri-more-fill"></i>-->
        <!--                      </a>-->
        <!--                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Action</a>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>-->
        <!--                        <div class="dropdown-divider"></div>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Delete</a>-->
        <!--                      </div>-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            &lt;!&ndash; end card &ndash;&gt;-->

        <!--            <div class="card p-2 border mb-2">-->
        <!--              <div class="d-flex align-items-center">-->
        <!--                <div class="avatar-sm me-3 ms-0">-->
        <!--                  <div class="avatar-title bg-soft-primary text-primary rounded font-size-20">-->
        <!--                    <i class="ri-file-text-fill"></i>-->
        <!--                  </div>-->
        <!--                </div>-->
        <!--                <div class="flex-grow-1">-->
        <!--                  <div class="text-start">-->
        <!--                    <h5 class="font-size-14 mb-1">Landing-A.zip</h5>-->
        <!--                    <p class="text-muted font-size-13 mb-0">6.7 MB</p>-->
        <!--                  </div>-->
        <!--                </div>-->

        <!--                <div class="ms-4 me-0">-->
        <!--                  <ul class="list-inline mb-0 font-size-18">-->
        <!--                    <li class="list-inline-item">-->
        <!--                      <a href="javascript:void(0);" class="text-muted px-1">-->
        <!--                        <i class="ri-download-2-line"></i>-->
        <!--                      </a>-->
        <!--                    </li>-->
        <!--                    <li class="list-inline-item dropdown" ngbDropdown>-->
        <!--                      <a class="dropdown-toggle text-muted px-1" ngbDropdownToggle href="javascript:void(0);"-->
        <!--                         role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
        <!--                        <i class="ri-more-fill"></i>-->
        <!--                      </a>-->
        <!--                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Action</a>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Another action</a>-->
        <!--                        <div class="dropdown-divider"></div>-->
        <!--                        <a class="dropdown-item" href="javascript:void(0);">Delete</a>-->
        <!--                      </div>-->
        <!--                    </li>-->
        <!--                  </ul>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </div>-->
        <!--            &lt;!&ndash; end card &ndash;&gt;-->
        <!--          </ng-template>-->
        <!--        </ngb-panel>-->
      </ngb-accordion>
      <!-- End Attached Files card -->
    </div>
    <!-- end profile-user-accordion -->
  </perfect-scrollbar>
  <!-- end user-profile-desc -->

</div><!--end offcanvas-body-->
