<div class="offcanvas-body profile-offcanvas p-0" xmlns="http://www.w3.org/1999/html">
  <div class="text-center p-4 border-bottom">

    <div class="mb-4 profile-user">

      <mat-progress-spinner
        *ngIf="isAvatarLoading"
        [diameter]="24"
        [mode]="'indeterminate'"
      ></mat-progress-spinner>

      <div *ngIf="!isAvatarLoading">
        <img *ngIf="selectedGroup.srcImg" [src]="selectedGroup.srcImg" class="rounded-circle avatar-lg img-thumbnail"
             alt="">

        <div *ngIf="!selectedGroup.srcImg" class="rounded-circle avatar-lg img-thumbnail">
      <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                          {{(selectedGroup.groupName).charAt(0)}}
                        </span>
        </div>
      </div>

      <div *ngIf="isAdmin">
        <!--edit photo-->
        <input id="profile-img-file-input" class="profile-img-file-input d-none"
               (click)="compressAndUploadFile()">
        <label *ngIf="!isAvatarLoading" for="profile-img-file-input" class="profile-photo-edit avatar-xs">
        <span class="avatar-title rounded-circle bg-light text-body">
          <i class="ri-pencil-fill"></i>
        </span>
        </label>

        <!--delete photo-->
        <label *ngIf="selectedGroup.srcImg &&  !isAvatarLoading"
               (click)="fileDelete()"
               class="profile-photo-delete avatar-xs">
        <span class="avatar-title rounded-circle bg-light text-body">
          <i class="ri-delete-bin-line"></i>
        </span>
        </label>

      </div>
    </div>


    <!--    <h5 class="font-size-16 mb-1 text-truncate">{{selectedGroup.groupName}}</h5>-->
    <!--    <p class="text-muted text-truncate mb-1">-->

    <!--    <div>-->
    <h5 class="font-size-16 mb-1 text-truncate" id="group_name">{{selectedGroup.groupName}}
      <span></span>
      <span *ngIf="isAdmin" type="button" id="group_name_edit" class="pl-5"
            (click)="edit_GroupName()"><i class="ri-edit-fill ml-1 align-middle"></i></span>

    </h5>

    <div *ngIf="isAdmin" class="visually-hidden d-flex justify-content-between" id="edit-group-name">
      <input type="text" [(ngModel)]="editedGroupName" class="border:none form-control bg-soft-light border-light"
             maxlength="20"/>
      <div class="float-right">
        <button type="submit" id="receiverSave" (click)="changeGroupName()"
                class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
          <i class="ri-check-line ml-1 align-middle"></i>
        </button>
      </div>
    </div>


    <!--    <p class="text-muted text-truncate mb-1">-->
    <!--      No status for group till now-->
    <!--      <i class="ri-record-circle-fill font-size-10 me-1"-->
    <!--         [ngClass]=" {'text-success': chatHeaderStatus === 'online','text-warning': chatHeaderStatus === 'away'}"></i>-->
    <!--      {{chatHeaderStatus}}</p>-->
  </div>
  <!-- End profile user -->

  <!-- Start user-profile-desc -->
  <perfect-scrollbar id="scrollView" class="p-4 user-profile-desc" (psScrollY)="onScrollGetDisplayedItems()">
    <div class="text-muted">


      <div id="group_description">

        <p *ngIf="isAdmin"
           class="mb-4">{{selectedGroup?.description !== null && selectedGroup.description?.trim() !== '' ? selectedGroup.description : "Add Description"}}
          <span></span>
          <span type="button" id="group_description_edit" class="pl-5"
                (click)="edit_GroupDescription()"><i class="ri-edit-fill ml-1 align-middle"></i></span>
        </p>

        <p *ngIf="!isAdmin" class="mb-4">{{selectedGroup?.description}}</p>

      </div>

      <div *ngIf="isAdmin" class="visually-hidden d-flex justify-content-between mb-2" id="edit-group-description">
        <input type="text" [(ngModel)]="editedGroupDescription"
               class="border:none form-control bg-soft-light border-light"
               maxlength="40"/>
        <div class="float-right">
          <button type="submit" (click)="changeGroupDescription()"
                  class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
            <i class="ri-check-line ml-1 align-middle"></i>
          </button>
        </div>
      </div>

    </div>

    <div id="profile-user-accordion" class="custom-accordion">
      <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
        <ngb-panel cardClass="card shadow-none border mb-2" id="about">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-information-line me-2 align-middle d-inline-block"></i>Info</h5>
          </ng-template>
          <ng-template ngbPanelContent>

            <div>
              <p class="text-muted mb-1">Creation Date</p>
              <h5 class="font-size-14">{{selectedGroup.createdDate| date: 'yyyy-MM-dd'}}</h5>

            </div>


          </ng-template>
        </ngb-panel>

        <!--Members card-->
        <ngb-panel cardClass="card shadow-none border mb-2" id="members">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-group-line me-2 align-middle d-inline-block"></i>Members
            </h5>
          </ng-template>
          <ng-template ngbPanelContent>

            <div *ngIf="isAdmin" class="py-1">
              <a href="javascript:void(0);" (click)="openAddParticipantsModal(addMemberModal)">
                <div class="d-flex align-items-center">
                  <div class="chat-user-img me-3 ms-0">
                    <div class="avatar-xs">
                        <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                      <i class="ri-user-add-line"></i>
                        </span>
                    </div>
                  </div>
                  <div class="flex-grow-1 overflow-hidden">
                    <h5 class="text-truncate text-primary font-size-14 mb-0">
                      Add participants
                    </h5>
                  </div>
                </div>
              </a>
            </div>

            <div *ngFor="let groupMember of selectedGroupMembers; let first = first; let i = index" class="py-1">
              <div id="groupMember{{i}}">
                <div *ngIf="first && isAdmin" class="border-top py-1"></div>
                <div *ngIf="!first" class="border-top py-1"></div>
                <a href="javascript:void(0);">
                  <div class="d-flex align-items-center">
                    <div class="chat-user-img me-3 ms-0">
                      <div class="avatar-xs">
                        <span *ngIf="!groupMember.profileImgSrc"
                              class="avatar-title rounded-circle bg-soft-primary text-primary">
                          {{(groupMember.userName).charAt(0)}}
                        </span>
                        <img *ngIf="groupMember.profileImgSrc" [src]="groupMember.profileImgSrc" alt="user-img"
                             class="img-fluid rounded-circle w-100 h-100">
                      </div>
                    </div>
                    <div class="d-flex flex-grow-1 justify-content-between">
                      <h5
                        class="text-truncate font-size-14 mb-0">{{groupMember.userName}}{{groupMember.userId === defaultUser.id ? " (YOU)" : ""}}</h5>
                      <!--three dots -->
                      <div>
                        <span *ngIf="groupMember.admin"
                              class="badge bg-soft-primary text-primary rounded-pill">Admin</span>
                        <div class="dropdown float-end" ngbDropdown *ngIf="isAdmin">
                          <a class="dropdown-toggle text-muted" href="javascript:void(0);" role="button"
                             ngbDropdownToggle
                             data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="ri-more-2-fill"></i>
                          </a>
                          <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);"
                               *ngIf="groupMember.userId !== defaultUser.id"
                               (click)="deleteGroupMember(groupMember.userId)">
                              Remove member
                            </a>
                            <a class="dropdown-item" href="javascript:void(0);" *ngIf="!groupMember.admin"
                               (click)="promoteToAdmin(groupMember.userId)">
                              Promote to admin
                            </a>
                            <a class="dropdown-item" href="javascript:void(0);" *ngIf="groupMember.admin"
                               (click)="removeAdminPermission(groupMember.userId, informUser)">
                              Dismiss as admin
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>


          </ng-template>
        </ngb-panel>
        <!--end of Members card-->

      </ngb-accordion>
      <!-- End Attached Files card -->
    </div>
    <!-- end profile-user-accordion -->
    <!--        exit group-->
    <div>
      <button class="btn text-danger w-100 text-start" (click)="leaveGroup(informUser)">
        <i class="ri-user-unfollow-line me-2"></i>Leave group
      </button>
    </div>


    <!--        delete group-->
    <div *ngIf="isAdmin">
      <div class="border-top my-1"></div>
      <button class="btn text-danger w-100 text-start" (click)="deleteGroupByAdmin()">
        <i class="ri-delete-bin-line me-2"></i>Delete group
      </button>
    </div>
  </perfect-scrollbar>
  <!-- end user-profile-desc -->

</div><!--end offcanvas-body-->

<ng-template #informUser let-modal>
  <div class="modal-body">
    <div class="p-0 card-body">
      <div class="modal--modal-content">
        <div class="text-center p-1">
          <i class=" ri-information-fill " style="color: #EF476F;font-size: 100px"></i>
          <h5 class="text-wrap">You should assign another admin first!</h5>
          <div class="mt-3">
            <ul class="list-inline mb-1">
              <li class="list-inline-item px-2 me-2 ms-0">
                <div class="border-0 modal-footer">
                  <button (click)="modal.dismiss()" class="btn btn-secondary">Ok</button>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #addMemberModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Add Participants</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="filteredContacts.length > 0" class="p-2 card-body">
      <div data-simplebar="init" style="max-height: 200px;">
        <div class="simplebar-wrapper" style="margin: 0px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
              <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
                   style="height: auto; overflow: hidden scroll;">
                <div class="simplebar-content" style="padding: 0px;">

                  <div *ngFor="let item of groupedContacts; let index = index">
                    <div class="p-3 font-weight-bold text-primary">{{item.key}}</div>
                    <ul class="list-unstyled contact-list">
                      <li *ngFor="let contactItem of item.filteredContacts; let i = index">
                        <div class="form-check">
                          <input [id]="'memberCheck' + index + i" type="checkbox" class="form-check-input"
                                 (change)="checkMemberChanged($event , contactItem)" [checked]="contactItem.checked">
                          <label [for]="'memberCheck' + index + i"
                                 class="form-check-label">{{contactItem.displayName}}</label>
                        </div>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: auto; height: 1666px;"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
          <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
          <div class="simplebar-scrollbar" style="height: 25px; transform: translate3d(0px, 0px, 0px); display: block;">
          </div>
        </div>
      </div>
      <div class="border-0 modal-footer">
        <button class="btn btn-primary" (click)="addParticipants()">Add Participants</button>
      </div>
    </div>

    <div *ngIf="filteredContacts.length == 0" class="p-2 card-body">
      <div data-simplebar="init" style="max-height: 200px;">
        <div class="simplebar-wrapper" style="margin: 0px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
              <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
                   style="height: auto; overflow: hidden scroll;">
                <div class="simplebar-content" style="padding: 0px;">
                  <h6>There is No Contacts to add</h6>
                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: auto; height: 1666px;"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
          <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
          <div class="simplebar-scrollbar" style="height: 25px; transform: translate3d(0px, 0px, 0px); display: block;">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
