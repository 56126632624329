import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {EventService} from "../../../sip/services/event-service";
import {LocalStorageService} from "../../../sip/services/local-storage-service";
import {SIPService} from "../../../sip/services/sip-service";
import {UserCallManagementComponent} from "../../user-chat/user-call-management/user-call-management.component";
import {ManagedSession} from 'sip.js/lib/platform/web';
import {GeneralService} from "../../../services/general-service";
import {User} from "../../../model/user.model";


@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit {

  @ViewChild('DTMF') DTMF: ElementRef = null as any;
  componentRef: ComponentRef<any>;
  activeCalls: Array<ManagedSession> = null as any;

  phoneToDialInput: string = "";
  defaultUser: User = null;
  isSipConnected: boolean = false;
  enableCallButton: boolean = false;

  constructor(private sipService: SIPService, private localStorageService: LocalStorageService, private _changeDetectorRef: ChangeDetectorRef,
              private eventService: EventService, private rootViewContainer: ViewContainerRef, private generalService: GeneralService) {


  }

  ngOnInit(): void {
    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.generalService.isSipConnected$.subscribe((val) => {
      this.isSipConnected = val;
    });
    // this.initiateCallManagement();
  }

  handleKeyUp(event: KeyboardEvent) {
    if (!event.key) {
      return;
    }
    if (event.key == "Enter") {
      this.call();
    } else if (event.key.match(/[0-9]/)) {
      // this.digitHandler(parseInt(event.key), true);
      this.playTone(parseInt(event.key));
    } else if (event.key.match(/\*|#/)) {
      this.digitHandler(event.key, true);
    }
  }

  digitHandler(digit: any, fromInputText?: boolean | undefined) {

    // let activeList = this.sipService.listActiveCalls();
    // if (this.eventService.anyPendingTransfer()) {
    //   return;
    // }
    // if (activeList.length > 0 && fromInputText == true) {
    //   this.sipService.sendDTMFTone(digit, activeList[0].session.id);
    //   this.playTone(digit);
    //   return;
    // }
    // if (activeList.length <= 0 && fromInputText == undefined)
    //   this.phoneToDialInput = this.phoneToDialInput + digit;
    // else {
    //   this.sipService.sendDTMFTone(digit, activeList[0].session.id);
    //   this.phoneToDialInput = this.phoneToDialInput + digit;
    //   this.playTone(digit);
    // }

    this.playTone(digit);
    this.phoneToDialInput = this.phoneToDialInput + digit;


  }

  deleteDigit() {
    this.phoneToDialInput = this.phoneToDialInput.substring(0, this.phoneToDialInput.length - 1);
  }


  private playTone(digit: any) {
    this.DTMF.nativeElement.play();
  }

  isTransferPending() {
    return this.eventService.anyPendingTransfer();
  }

  isCallButtonEnabled() {
    if (this.phoneToDialInput === undefined || this.phoneToDialInput === null || this.phoneToDialInput.trim() === '' || this.phoneToDialInput.trim() === this.defaultUser.extension) {
      this.enableCallButton = false;
    } else if (!this.isSipConnected) {
      this.enableCallButton = false;
    } else {
      this.enableCallButton = this.isOngoingCall()
    }
    return this.enableCallButton
  }

  isOngoingCall() {
    try {
      return this.sipService.listActiveCalls().length <= 0 || this.eventService.anyPendingTransfer();
    } catch (e) {
      return false;
    }

  }

  call() {

    this.generalService.setDialerExtension(this.phoneToDialInput);
    this.generalService.openCallManagementComponent.emit(true);
    this.generalService.outgoingCallMethod.emit();


  }


  initiateCallManagementComponent() {
    this.componentRef = this.rootViewContainer.createComponent(UserCallManagementComponent);

    this.componentRef.instance.activeCalls = this.activeCalls;

    this.rootViewContainer.insert(this.componentRef.hostView).detectChanges();
    this.componentRef.instance.closeModal.subscribe(() => this.componentRef.destroy());


    console.log(typeof this.rootViewContainer.get(this.rootViewContainer.length - 1));
  }


}
