import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./guards/auth.route";

const routes: Routes = [
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard', children: [
          {
            path: '',
            loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
          }
        ]
      }
    ],
    data: {
      roles: ['USER']
    }
  },
  // Pages
  {
    path: 'pages', children: [
      // error
      {
        path: 'error',
        loadChildren: () => import('./pages/error/error.module').then(m => m.ErrorModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
