import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {SIPService} from "../../../sip/services/sip-service";
import {LocalStorageService} from "../../../sip/services/local-storage-service";
import {EventService} from "../../../sip/services/event-service";
import {MainMenuService} from "../../main-menu/main-menu.service";
import {Router} from "@angular/router";
import {NgbModal, NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder} from "@angular/forms";
import {ManagedSession} from 'sip.js/lib/platform/web';
import {CallEvent} from "../../../sip/lib/events/call-events";
import {CallState} from "../../../sip/lib/model/call-state";
import {CallHistoryModel} from "../../../sip/lib/model/call-history-model";
import {CallDirectionState} from "../../../sip/lib/model/call-direction-state";
import {v4 as uuid} from "uuid";
import {Session} from 'sip.js';
import {TimerFormat} from "../../../sip/lib/helper/timer-format";
import {UserCallManagementService} from "./user-call-management.service";
import {GeneralService} from "../../../services/general-service";
import {MessageContentTypeEnum} from "../../../model/message-type";
import {Message} from "../../../model/message.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "../../../model/user.model";
import {ChatItem} from "../../../model/chatItem.model";
import {MessageService} from "../../../services/message-service";
import {CallStatusEnum} from "../../../model/call-status-enum";
import {ChatService} from "../../../services/chat-service";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {UserService} from "../../../services/user-service";
import {Contact} from "../../../model/contact.model";
import {environment} from "../../../../environments/environment";
import {take} from "rxjs";


@Component({
  selector: 'user-call-management',
  templateUrl: './user-call-management.component.html',
  styleUrls: ['./user-call-management.component.scss']
})
export class UserCallManagementComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() messageElement: string;
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();


  chatHeaderName: any;
  chatHeaderAvatar: any;


  //TODO:Fadi check for the below to be used from service
  callStatusMessage: String;
  callInProgress: boolean = false;
  callMaps: Map<string, CallHistoryModel> = new Map<string, CallHistoryModel>();
  confMap: Map<string, boolean> = new Map<string, boolean>();
  activeCalls: Array<ManagedSession> = null as any;
  currentCall: CallHistoryModel = null;


  @ViewChild('outgoingRingTone') outgoingRingTone: ElementRef = null as any;
  @ViewChild('hangupTone') hangupTone: ElementRef = null as any;
  @ViewChild('dialingTone') dialingTone: ElementRef = null as any;
  @ViewChild('callWaiting') callWaiting: ElementRef = null as any;
  @ViewChild('wrongNumberTone') wrongNumberTone: ElementRef = null as any;
  @ViewChild('incomingRingTone') incomingRingTone: ElementRef = null as any;


  visibleIncomingCall: boolean;
  visibleReachingOrInprogressCall: boolean;
  visibleCallManagement: boolean;
  minimizeCall: boolean = false;
  message: Message = null;
  defaultUser: User = null;
  selectedChatId: string = null;
  selectedContactId: string = null;
  selectedItemType: SelectedItemEnum;
  dialerExtension: string = null;
  peerUserInCallName: string = null;
  peerUserInCallImgSrc: any = null;
  numberOfSubscription: number = 0;
  startingCall: boolean = false;


  constructor(private sipService: SIPService, private localStorageService: LocalStorageService, private eventService: EventService,
              private mainMenuService: MainMenuService, private generalService: GeneralService, private messageService: MessageService,
              private router: Router, private modalService: NgbModal, private offcanvasService: NgbOffcanvas, private userCallManagementService: UserCallManagementService,
              public formBuilder: FormBuilder, private _snackBar: MatSnackBar, private chatService: ChatService, private userService: UserService) {
  }

  ngOnInit(): void {

    this.generalService.dialerExtension$.subscribe((value) => {
      this.dialerExtension = value;
    });
    this.generalService.itemSelected$.subscribe((value) => {
      this.selectedItemType = value;
    });
    this.generalService.chatSelectedId$.subscribe((value) => {
      this.selectedChatId = value;
    });
    this.generalService.contactSelectedId$.subscribe((value) => {
      this.selectedContactId = value;
    });


    this.userCallManagementService.minimizeCall$.subscribe((value) => {
      this.minimizeCall = value;
    });
    this.userCallManagementService.setMinimizeCall(false);


    this.generalService.chatHeaderName$.subscribe((value) => {
      this.chatHeaderName = value;
    });
    this.generalService.chatHeaderAvatar$.subscribe((value) => {
      this.chatHeaderAvatar = value;
    });

    this.generalService.peerUserInCallName$.subscribe((value) => {
      if (value != null) {
        this.peerUserInCallName = value;
      }
    });
    this.generalService.peerUserInCallImgSrc$.subscribe((value) => {
      this.peerUserInCallImgSrc = value;
    });

    console.log('visibleIncomingCall ', this.visibleIncomingCall);
    console.log('visibleReachingOrInprogressCall ', this.visibleReachingOrInprogressCall);
    console.log('visibleCallManagement ', this.visibleCallManagement);


    this.createAndAddListeners();

    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.generalService.numberOfDialerCallSubscription$.subscribe((value) => {
      this.numberOfSubscription = value;
    });


    this.generalService.outgoingCallMethod.pipe(take(1)).subscribe(value => {
      if (this.numberOfSubscription < 1) {
        this.call();
        this.generalService.setNumberOfDialerCallSubscription(this.numberOfSubscription + 1);
      }

    });

  }

  createAndAddListeners() {
    this.sipService.getCallEvent().addEventListener(CallEvent.outgoingCall, (e) => {
      console.log('inside CallEvent.outgoingCall -- 1');
      this.visibleCallManagement = false;
      this.startingCall = false;
      this.visibleReachingOrInprogressCall = true;
      this.callInProgress = false;

      this.activeCalls = this.sipService.listSessions();
      let event: CustomEvent = e as CustomEvent;
      let callState: CallState = event.detail.callState;
      let sessionId: CallState = event.detail.sessionId;


      console.log('callState in OutgoingCall Event = ', callState);


      this.callMaps.set(event.detail.sessionId, this.getCallHistoryModel(sessionId));


      switch (callState) {
        case CallState.Trying:
          //trying the call, show trying to dial
          this.dialingTone.nativeElement.play();
          this.callStatusMessage = 'Calling ... ';

          break;
        case CallState.Progress:
          //pause all the rings and show ringing voice
          this.pauseRings();
          this.outgoingRingTone.nativeElement.play();
          this.callStatusMessage = 'Ringing ... ';

          break;
        case CallState.Reject:
          //pause all the rings and show ringing voice
          this.pauseRings();
          this.callStatusMessage = 'Hanging Up ... ';

          break;
        default:
          this.setCurrentCall(event.detail.sessionId);
          this.pauseRings();
          this.callInProgress = true;

          break;
      }

      console.log('leave CallEvent.outgoingCall -- 1');

    });


    this.sipService.getCallEvent().addEventListener(CallEvent.hangupCall, (e) => {
      console.log("inside CallEvent.hangupCall ");
      this.hangupTone.nativeElement.play();

      this.callStatusMessage = 'hanging Up... ';

      let event: CustomEvent = e as CustomEvent;
      this.activeCalls = this.sipService.listSessions();
      let sessionId: string = event.detail.sessionId;
      //get the call with the duration and save it in history
      let callHistoryModel: CallHistoryModel = this.callMaps.get(sessionId) as CallHistoryModel;
      if (callHistoryModel?.direction == CallDirectionState.Inbound && callHistoryModel?.duration == 0) {
        callHistoryModel.direction = CallDirectionState.Missed;
      }
      if (!callHistoryModel.number.includes("park+*")) {
        let callHistoryList: Array<CallHistoryModel> = this.localStorageService.getCallHistory();
        callHistoryList.unshift(callHistoryModel);
        this.localStorageService.saveCallHistory(callHistoryList);
      }
      //remove the session
      this.callMaps.delete(event.detail.sessionId);
      this.callInProgress = false;
      this.pauseRings();


      this.eventService.numberUnTransferClick(sessionId);
      this.removeCallFromConference(sessionId);

      console.log("leave CallEvent.hangupCall ");

      this.callStatusMessage = '';
      this.closeModal.emit(event);
      this.generalService.setDialerExtension(null);
      this.generalService.setNumberOfDialerCallSubscription(0);

    });


    this.sipService.getCallEvent().addEventListener(CallEvent.callAnswered, (e) => {
      console.log("inside CallEvent.callAnswered ");

      let event: CustomEvent = e as CustomEvent;
      this.activeCalls = this.sipService.listSessions();
      this.pauseRings();
      this.callInProgress = true;
      this.callStatusMessage = '';

      this.startTimer(event.detail.sessionId);
      this.setCurrentCall(event.detail.sessionId);
      console.log("leave CallEvent.callAnswered ");

    });


    this.sipService.getCallEvent().addEventListener(CallEvent.rejectedCall, (e) => {
      console.log("inside CallEvent.rejectedCall ");

      let event: CustomEvent = e as CustomEvent;
      let statusCode: number = event.detail.statusCode;
      this.callInProgress = false;
      this.pauseRings();
      this.callStatusMessage = 'Call Declined ... ';

      if (statusCode !== 487) {
        this.wrongNumberTone.nativeElement.play();
      }

      console.log("leave CallEvent.rejectedCall ");

      this.callStatusMessage = '';
      this.closeModal.emit(event);


    });

  }

  ngAfterViewInit() {

    if (this.visibleIncomingCall) {
      //debugger;
      if (this.sipService.listSessions().length > 1) {
        this.callWaiting.nativeElement.play();
        setTimeout(() => {
          this.callWaiting.nativeElement.play();
        }, 2000);
      } else {
        //otherwise ring
        this.incomingRingTone.nativeElement.play();
      }

    }
  }

  private holdAllSessions(sessionId: string) {
    let activeSessionList = this.sipService.listActiveCalls().filter(ms => (ms.session.id != sessionId && !ms.held));
    activeSessionList.forEach((ms) => {
      this.sipService.hold(ms.session.id);
    });
  }

  public hangup() {
    console.log('hangup method');
    this.startingCall=false;
    if (!this.message || !this.message.id) {
      this.messageService.callMessage$.subscribe({
        next: (responseMessage: Message) => {
          this.message = responseMessage;
        }
      });
    }
    if (this.message && this.message !== null && this.message.id !== null) {

      switch (this.message.callStatus) {
        case CallStatusEnum.ACCEPTED:
          this.updateCallStatus(CallStatusEnum.COMPLETED);
          break;
        case CallStatusEnum.TRYING:
          if (this.message.authorId === this.defaultUser.id) {
            this.updateCallStatus(CallStatusEnum.CANCELED);
          } else {
            this.updateCallStatus(CallStatusEnum.REJECTED);
          }
          break;
      }


    }
    // debugger;
    this.hangupTone.nativeElement.play();
    // setTimeout (() => {
    //   this.hangupTone.nativeElement.play();
    // }, 1000);
    if (this.activeCalls !== null && this.activeCalls.length !== 0) {
      this.sipService.hangup(this.activeCalls[0].session.id);
    }
    // this.hideAll();
    // this.modalService.dismissAll();

  }

  private updateCallStatus(callStatus: CallStatusEnum) {
    this.messageService.updateCallStatus(this.message.id, this.defaultUser.id, callStatus).subscribe(
      {
        next: () => {
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      }
    );
  }

  public answerIncomingCall() {
    this.holdAllSessions(this.activeCalls[0].session.id);
    this.sipService.accept(this.activeCalls[0].session.id);
    this.updateCallStatus(CallStatusEnum.ACCEPTED);
  }

  private pauseRings() {
    this.dialingTone.nativeElement.pause();
    this.outgoingRingTone.nativeElement.pause();
    this.incomingRingTone.nativeElement.pause();
  }


  private removeCallFromConference(sessionId: string) {
    if (this.confMap.has(sessionId)) {
      this.confMap.delete(sessionId);
    }
    let sessionIdList = [...this.confMap.keys()];
    if (sessionIdList.length == 1) {
      this.confMap.clear();
      this.sipService.cleanupMedia();
      this.sipService.assignStream(this.sipService.getSession(sessionIdList[0]));
    }
  }


  private startTimer(sessionId: string) {

    let timerInterval = setInterval(() => {
      let isActiveCall = this.activeCalls.filter(ms => ms.session.id === sessionId).length
      if (!this.callMaps.has(sessionId) || isActiveCall <= 0) {
        clearInterval(timerInterval);
        return;
      }
      let num = this.callMaps.get(sessionId)?.duration;
      this.callMaps.get(sessionId)!.duration = num! + 1;
    }, 1000)

  }


  private getCallHistoryModel(sessionId: string): CallHistoryModel {
    let session: Session = this.sipService.getSession(sessionId);
    return {
      id: uuid(),
      displayName: session.remoteIdentity.displayName,
      number: session.remoteIdentity.uri.user as string,
      date: new Date(),
      direction: CallDirectionState.Outbound,
      duration: 0
    };
  }


  call() {

    this.startingCall = true;
    if (this.dialerExtension && this.dialerExtension.trim() !== '') {
      this.handleCallToDialerExtension();
      return;
    } else if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.CHAT) {
      let selectedChatItem = {...this.chatService.getChatItem(this.selectedChatId)};
      this.handleCallAndSendMessageInPrivateChat(selectedChatItem);
    } else if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.CONTACT) {
      let selectedContact = {...this.userService.getContactByUserId(this.selectedContactId)};
      this.handleCallAndSendMessageToNewContact(selectedContact);
    }


  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  openSnackBarBottomRight(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        panelClass: [type + '-snackbar']
      }
    );
  }

  closeOrMinimize(event) {
    if (this.startingCall) {
      this.minimize();
    } else {
      this.close(event)
    }
  }

  close(event) {
    if (this.activeCalls !== null && this.activeCalls.length !== 0) {
      this.hangup();
    }
    this.hideAll();
    this.closeModal.emit(event);
  }

  ngOnDestroy(): void {
    this.sipService.getCallEvent().removeAllListeners('outgoingCall');
    this.sipService.getCallEvent().removeAllListeners('callAnswered');
    this.sipService.getCallEvent().removeAllListeners('hangupCall');
    this.sipService.getCallEvent().removeAllListeners('rejectedCall');

    this.generalService.setDialerExtension(null);
    this.generalService.setNumberOfDialerCallSubscription(0);


    this.userCallManagementService.setCurrentCall(null);
    this.userCallManagementService.setMinimizeCall(false);
    console.log('Good Bye',
      'outgoingCall ' + this.sipService.getCallEvent().eventListeners('outgoingCall').length,
      'callAnswered ' + this.sipService.getCallEvent().eventListeners('callAnswered').length,
      'hangupCall ' + this.sipService.getCallEvent().eventListeners('hangupCall').length,
      'rejectedCall ' + this.sipService.getCallEvent().eventListeners('rejectedCall').length,
    );


  }

  hideAll() {
    this.visibleCallManagement = false;
    this.startingCall = false;
    this.visibleReachingOrInprogressCall = false;
    this.visibleIncomingCall = false;
  }

  public formatTimer(num: any) {
    return TimerFormat.formatTimer(num);
  }

  setCurrentCall(sessionId) {
    let isActiveCall = this.activeCalls.filter(ms => ms.session.id === sessionId).length
    if (!this.callMaps.has(sessionId) || isActiveCall <= 0) {
      this.currentCall = null;
      this.userCallManagementService.setCurrentCall(this.currentCall);
      return;
    }
    this.currentCall = this.callMaps.get(sessionId);
    this.userCallManagementService.setCurrentCall(this.currentCall);
  }

  minimize() {
    this.userCallManagementService.setMinimizeCall(true);
  }

  private handleCallAndSendMessageInPrivateChat(selectedChatItem: ChatItem) {

    this.generalService.setPeerUserInCallName(selectedChatItem.peerUserName);
    this.generalService.setPeerUserInCallImgSrc(selectedChatItem.peerUserProfileImgSrc);

    this.message = {
      authorId: this.defaultUser.id,
      forward: false,
      roomId: selectedChatItem.chatId,
      messageContentType: MessageContentTypeEnum.AudioCall,
      body: environment.systemMessageProperties.prefix + MessageContentTypeEnum.AudioCall
    }

    this.messageService.createMessage(this.message).subscribe(
      {
        next: (responseMessage: Message) => {
          this.message = responseMessage;
          this.sipService.callOnlyIfNoActive(selectedChatItem.peerUserExtension);
          this.sipService.sendMessage(selectedChatItem.peerUserExtension,
            JSON.stringify({type: responseMessage.messageContentType, message: responseMessage})).then(() => {
            this.openSnackBarBottomRight('Success.', 'success');
            this.updateViewsAndBehaviorsForPrivateChat(this.message, selectedChatItem);
          });
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });

  }

  private handleCallAndSendMessageToNewContact(selectedContact: Contact) {

    this.generalService.setPeerUserInCallName(selectedContact.displayName);
    this.generalService.setPeerUserInCallImgSrc(selectedContact.profileImgSrc);

    this.message = {
      authorId: this.defaultUser.id,
      forward: false,
      roomId: null,
      messageContentType: MessageContentTypeEnum.AudioCall,
      unreadIDs: [selectedContact.id],
      body: environment.systemMessageProperties.prefix + MessageContentTypeEnum.AudioCall
    }

    this.messageService.createMessage(this.message).subscribe(
      {
        next: (responseMessage: Message) => {
          this.message = responseMessage;
          this.sipService.callOnlyIfNoActive(selectedContact.extension);
          this.sipService.sendMessage(selectedContact.extension,
            JSON.stringify({type: responseMessage.messageContentType, message: responseMessage})).then(() => {
            this.openSnackBarBottomRight('Success.', 'success');
            this.message.dateTime = responseMessage.dateTime;
            this.message.id = responseMessage.id;
            this.message.roomId = responseMessage.roomId;
            this.message.active = responseMessage.active;
            this.message.unreadIDs = responseMessage.unreadIDs;
            this.updateViewsAndBehaviorsForNewContact(this.message, selectedContact);

          });
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });

  }

  private updateViewsAndBehaviorsForNewContact(message: Message, selectedContact: Contact) {


    let tempChatItem: ChatItem = {
      chatId: message.roomId,
      lastMessageBody: message.body,
      lastMessageId: message.id,
      lastMessageTime: message.dateTime,
      peerUserExtension: selectedContact.extension,
      peerUserId: selectedContact.id,
      peerUserName: selectedContact.displayName,
      peerUserProfileImgId: selectedContact.profileImgId,
      peerUserProfileImgSrc: selectedContact.profileImgSrc,
      reachEndOfMessages: false,
      unreadMessageCount: 0,
    };
    //update selectedChatMessages
    if (message.unreadIDs[0] === this.selectedContactId) {
      this.messageService.setCurrentConversationMessages([message]);
    }
    this.messageService.addOneMessageToAllConversationMessages(message.roomId, message)

    //update selectedChatItem Behavior with new messages
    this.generalService.setChatSelectedId(message.roomId);
    this.generalService.setContactSelectedId(null);
    this.generalService.setItemSelected(SelectedItemEnum.CHAT);


    //set ChatList Behavior with new messages
    this.chatService.addOneItemToChatMap(tempChatItem);

    this.generalService.scrollToBottomAtChatConversation();

  }

  private updateViewsAndBehaviorsForPrivateChat(message: Message, selectedChatItem: ChatItem) {

    selectedChatItem.lastMessageId = message.id;
    selectedChatItem.lastMessageBody = message.body;
    selectedChatItem.lastMessageTime = message.dateTime;
    //update selectedChatMessages
    if (this.selectedChatId === message.roomId) {
      this.messageService.addOneMessageToCurrentConversationMessages(message);
    }
    this.chatService.handleAComingMessageForMap(selectedChatItem.chatId, message, true);

    this.generalService.scrollToBottomAtChatConversation();
  }

  private handleCallToDialerExtension() {

    let chatItem = {...this.chatService.getChatItemByExtension(this.dialerExtension)};
    let contact = {...this.userService.getContactByExtension(this.dialerExtension)};

    if (chatItem !== undefined && chatItem && Object.keys(chatItem).length !== 0) {
      this.handleCallAndSendMessageInPrivateChat(chatItem);
      return;
    } else if (contact !== undefined && contact && Object.keys(contact).length !== 0) {
      this.handleCallAndSendMessageToNewContact(contact);
      return;
    }


    //if didn't find a chat or contact
    this.generalService.setPeerUserInCallName(this.dialerExtension);
    this.generalService.setPeerUserInCallImgSrc(null);
    this.sipService.callOnlyIfNoActive(this.dialerExtension);


  }
}
