import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {properties} from "../../properties/urlProperties";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {Message} from "../model/message.model";
import {CallStatusEnum} from "../model/call-status-enum";
import {OAuthService} from "angular-oauth2-oidc";

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private _messageUrl: string = properties.deyalaSecureURL + '/message';
  private _currentConversationMessages: BehaviorSubject<Message[] | null> = new BehaviorSubject(null);
  private _callMessage: BehaviorSubject<Message | null> = new BehaviorSubject(null);
  private _allConversationMessages: BehaviorSubject<Map<string, Message[]> | null> = new BehaviorSubject<Map<string, Message[]> | null>(null);

  constructor(
    private _httpClient: HttpClient,
    private oAuthService: OAuthService
  ) {
  }


  createMessage(message: Message): Observable<any> {
    return this._httpClient.post(this._messageUrl + '/createMessage', message, {
      responseType: 'json'
    });
  }

  // abortUpload(attachmentName: string): Observable<any> {
  //   return this._httpClient.post(this._messageUrl + '/abortMultipartUpload',null,{
  //     params: {
  //       "attachmentName": attachmentName
  //     }
  //   });
  // }

  generateVideoCallUrl(message: Message): Observable<string> {
    return this._httpClient.post(this._messageUrl + '/generateVideoCallUrl', message, {
      responseType: 'text'
    });
  }

  get currentConversationMessages$(): Observable<Message[]> {
    return this._currentConversationMessages.asObservable();
  }

  get callMessage$(): Observable<Message> {
    return this._callMessage.asObservable();
  }

  setCallMessage(message: Message): void {
    this._callMessage.next(message);
  }

  setCurrentConversationMessages(messageList: Message[]): void {
    this._currentConversationMessages.next(messageList);
  }

  addToSelectedChatMessagesList(messageList: Message[]): void {
    this._currentConversationMessages.next(this._currentConversationMessages.getValue().concat(messageList));
  }

  getRoomMessagesHistory(chatId: string, skipMessages: number): Observable<any> {
    return this._httpClient.get(this._messageUrl + '/getMessagesHistory', {
      params: {
        'chatId': chatId,
        'skipMessages': skipMessages
      }
    }).pipe(
      tap(() => {
      })
    );
  }

  searchMessages(roomId: string, searchKeyword: string): Observable<any> {
    return this._httpClient.get(this._messageUrl + '/searchMessages', {
      params: {
        'roomId': roomId,
        'searchKeyword': searchKeyword
      }
    });
  }

//0  : today
//1  : yesterday
//2  : dayBefore


  updateCallStatus(messageId: string, userId: string, callStatus: CallStatusEnum): Observable<any> {
    let params = {
      'messageId': messageId,
      'userId': userId,
      'callStatus': callStatus
    };
    return this._httpClient.put(this._messageUrl + '/updateCallStatus', {}, {
      responseType: 'text',
      params: params
    });
  }

  addOneMessageToCurrentConversationMessages(message: Message) {

    let tempSelectedMessages: Message[] = [];
    if (this._currentConversationMessages.getValue() !== null && this._currentConversationMessages.getValue().length !== 0) {
      this._currentConversationMessages.getValue().forEach(value => {
        tempSelectedMessages.push({...value});
      });
    }


    tempSelectedMessages.unshift(message);
    this.setCurrentConversationMessages(tempSelectedMessages);
  }


  uploadOneMessageToCurrentConversationMessages(message: Message) {
    let tempSelectedMessages: Message[] = [];

    if (this._currentConversationMessages.getValue() !== null && this._currentConversationMessages.getValue().length !== 0) {
      this._currentConversationMessages.getValue().forEach(value => {
        tempSelectedMessages.push({...value});
      });
    }


    let index = tempSelectedMessages.findIndex(value => {
      return value.localUUId === message.localUUId
    });
    if (index < 0) {
      message.uploading = true;
      tempSelectedMessages.unshift(message);
    } else {
      tempSelectedMessages[index] = message;
      tempSelectedMessages[index].uploading = false;
    }
    this.setCurrentConversationMessages(tempSelectedMessages);
  }

  removeOneFakeMessageFromCurrentConversationMessages(message: Message) {
    // debugger;
    let tempSelectedMessages: Message[] = [];
    if (this._currentConversationMessages.getValue() !== null && this._currentConversationMessages.getValue().length !== 0) {
      tempSelectedMessages = this._currentConversationMessages.getValue().filter(item => item.localUUId !== message.localUUId);
      this.setCurrentConversationMessages(tempSelectedMessages);
    }
  }

  removeOneMessageFromCurrentConversationMessages(message: Message) {
    if (message === undefined || message === null) return;
    let tempSelectedMessages: Message[] = [...this._currentConversationMessages.getValue()];
    if (tempSelectedMessages !== undefined && tempSelectedMessages !== null && this._currentConversationMessages.getValue().length !== 0) {
      tempSelectedMessages = tempSelectedMessages.filter(item => item.id !== message.id);
      this.setCurrentConversationMessages(tempSelectedMessages);
    }
  }


  markChatMessagesAsRead(userId: string, roomId: string): Observable<any> {
    let params = {
      'userId': userId,
      'roomId': roomId
    };
    return this._httpClient.put(this._messageUrl + '/markChatAsRead', {}, {
      params: params
    });
  }

  downloadMessageAttachment(messageId: string, userId: string, contentS3Id: string): Observable<any> {
    const params = {
      'messageId': messageId,
      'userId': userId,
      'contentS3Id': contentS3Id
    };
    return this._httpClient.get(this._messageUrl + '/downloadMessageAttachment',
      {
        responseType: "blob",
        params: params
      });
  }

  getMessageAttachmentRequest(messageId: string, userId: string, contentS3Id: string) {
    return {
      url: this._messageUrl + '/downloadMessageAttachment',
      method: "get",
      headers: {
        "Authorization": "Bearer " + this.oAuthService.getAccessToken(),
      },
      params: {
        'messageId': messageId,
        'userId': userId,
        'contentS3Id': contentS3Id
      }
    }
  }


  get allConversationMessages$(): Observable<Map<string, Message[]>> {
    return this._allConversationMessages.asObservable();
  }

  setAllConversationMessages(messageMap: Map<string, Message[]>): void {
    this._allConversationMessages.next(messageMap);
  }

  addToAllConversationMessages(chatId: string, response: Message[]) {
    //TODO:Fadi check if needed to deepCopy
    let tempMap: Map<string, Message[]> = new Map(this._allConversationMessages.getValue());
    tempMap.set(chatId, response);
    this.setAllConversationMessages(tempMap);

  }

  addOneMessageToAllConversationMessages(chatId: string, message: Message) {

    let tempMap: Map<string, Message[]> = new Map(this._allConversationMessages.getValue());
    let newMessagesArray: Message[] = tempMap.get(chatId);
    if (newMessagesArray === undefined || newMessagesArray === null) {
      newMessagesArray = [message];
    } else {
      newMessagesArray.unshift(message);

    }
    tempMap.set(chatId, newMessagesArray);
    this.setAllConversationMessages(tempMap);
  }


  uploadOneMessageToAllConversationMessages(chatId: string, message: Message) {

    let tempMap: Map<string, Message[]> = new Map(this._allConversationMessages.getValue());
    let newMessagesArray: Message[] = tempMap.get(chatId);
    if (newMessagesArray === undefined || newMessagesArray === null) {
      newMessagesArray = [message];
    } else {
      let index = newMessagesArray.findIndex(value => {
        return value.localUUId === message.localUUId
      });


      if (index < 0) {
        message.uploading = true;
        newMessagesArray.unshift(message);
      } else {
        newMessagesArray[index] = message;
        newMessagesArray[index].uploading = false;
      }
    }
    tempMap.set(chatId, newMessagesArray);
    this.setAllConversationMessages(tempMap);
  }


  removeOneFakeMessageFromAllConversationMessages(chatId: string, message: Message) {

    let tempMap: Map<string, Message[]> = new Map(this._allConversationMessages.getValue());
    let newMessagesArray: Message[] = tempMap.get(chatId);
    if (newMessagesArray === undefined || newMessagesArray === null) {
      return;
    } else {
      newMessagesArray = newMessagesArray.filter(item => item.localUUId !== message.localUUId);
    }
    tempMap.set(chatId, newMessagesArray);
    this.setAllConversationMessages(tempMap);
  }

  removeOneMessageFromAllConversationMessages(message: Message) {
    if (message === undefined || message === null) return;
    let tempMap: Map<string, Message[]> = new Map(this._allConversationMessages.getValue());
    let newMessagesArray: Message[] = [...tempMap.get(message.roomId)];
    if (newMessagesArray === undefined || newMessagesArray === null) {
      return;
    } else {
      newMessagesArray = newMessagesArray.filter(item => item.id !== message.id);
    }
    tempMap.set(message.roomId, newMessagesArray);
    this.setAllConversationMessages(tempMap);
  }

  getConversationMessagesFromAllConversationMessages(chatId: string) {
    if (this._allConversationMessages.getValue()) {
      return this._allConversationMessages.getValue().get(chatId);
    } else {
      return null;
    }
  }

  isThereAnUploadingMessageByRoomId(chatId: string) {
    let tempMap: Map<string, Message[]> = new Map(this._allConversationMessages.getValue());
    let newMessagesArray: Message[] = tempMap.get(chatId);
    if (newMessagesArray === undefined || newMessagesArray === null) {
      return false;
    } else {
      let index = newMessagesArray.findIndex(value => {
        return value.uploading === true
      });
      return index >= 0;
    }

  }


  deleteMessage(messageId: string, userId: string, lastMessageBody: string, lastMessageId: string, lastMessageDateTime: string): Observable<any> {
    if (lastMessageBody === undefined) {
      lastMessageBody = null;
    }
    if (lastMessageId === undefined) {
      lastMessageId = null;
    }
    if (lastMessageDateTime === undefined) {
      lastMessageDateTime = null;
    }

    let params = {
      'messageId': messageId,
      'userId': userId,
      'lastMessageBody': lastMessageBody,
      'lastMessageId': lastMessageId,
      'lastMessageDateTime': lastMessageDateTime
    };
    return this._httpClient.put(this._messageUrl + '/deleteMessage', {}, {
      params: params
    });
  }

  getMessageFromAllConversationMessages(roomId: string, messageId: string) {

    if (messageId === null) return null;
    let messages: Message[] = this.getConversationMessagesFromAllConversationMessages(roomId);
    if (messages !== undefined && messages !== null && messages.length !== 0) {

      let index = messages.findIndex(value => {
        return value.id === messageId
      });
      if (index > -1) {
        return messages[index];
      }
    }

    return null
  }

  markAllMessagesAsRead(selectedChatItemId: string, currentUser: string) {
    let tempMap: Map<string, Message[]> = new Map(this._allConversationMessages.getValue());
    let messages = tempMap.get(selectedChatItemId);
    messages.forEach(value => {
      if (value.unreadIDs) {
        value.unreadIDs = value.unreadIDs.filter(value1 => {
          return value1 !== currentUser;
        });
      }
    });
    tempMap.set(selectedChatItemId, messages);
    this.setAllConversationMessages(tempMap);

  }

  getMessageById(messageId: string) {
    return this._httpClient.get(this._messageUrl + '/getMessageById',
      {
        params: {'messageId': messageId}
      });
  }


}
