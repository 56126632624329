<div>
  <div class="modal" *ngIf="visibleVideoCallManagement">
    <div class="modal-overlay" (click)="close()"></div>
    <div class="modal--modal-content">
      <div class="text-center p-4">
        <div class="d-flex justify-content-center mb-4">
          <img *ngIf="peerUserInVideoCallImgSrc" [src]="peerUserInVideoCallImgSrc"
               class="avatar-lg img-thumbnail rounded-circle" alt="">
          <div class="rounded-circle avatar-lg img-thumbnail" *ngIf="!peerUserInVideoCallImgSrc">
            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
              {{(peerUserInVideoCallName).charAt(0)}}
            </span>
          </div>
        </div>
        <h5 class="text-truncate">{{(peerUserInVideoCallName)}}</h5>
        <p class="text-muted">Start Video Call</p>
        <div class="mt-5">
          <ul class="list-inline mb-1">
            <li class="list-inline-item px-2 me-2 ms-0">
              <button type="button" class="btn btn-danger avatar-sm rounded-circle"
                      data-bs-dismiss="modal" (click)="close()">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-close-fill"></i>
                              </span>
              </button>
            </li>
            <li class="list-inline-item px-2">
              <button type="button" class="btn btn-success avatar-sm rounded-circle" (click)="openVideoManagement()">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-vidicon-fill"></i>
                              </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!--Center Outgoing call modal-->
  <div class="modal" *ngIf="visibleReachingOrInProgressVideoCall">
    <div class="modal--modal-content">
      <div class="text-center p-4">

        <!--      <div class="avatar-lg mx-auto mb-4">-->
        <!--        <img [src]="peerUserInCallImgSrc" alt="" class="img-thumbnail rounded-circle">-->
        <!--      </div>-->

        <div class="d-flex justify-content-center mb-4">
          <img *ngIf="peerUserInVideoCallImgSrc" [src]="peerUserInVideoCallImgSrc"
               class="avatar-lg img-thumbnail rounded-circle" alt="">
          <div class="rounded-circle avatar-lg img-thumbnail" *ngIf="!peerUserInVideoCallImgSrc">
            <span class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
              {{(peerUserInVideoCallName).charAt(0)}}
            </span>
          </div>
        </div>

        <h5 class="text-truncate">{{(peerUserInVideoCallName)}}</h5>
        <p class="text-muted">{{videoCallStatusMessage}}</p>


        <div class="mt-5">

          <ul class="list-inline mb-1">
            <li class="list-inline-item px-2 me-2 ms-0">
              <button type="button" class="btn btn-danger avatar-sm rounded-circle"
                      data-bs-dismiss="modal" (click)="hangup()">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-close-fill"></i>
                              </span>
              </button>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>

  <!--Center Incoming call modal-->
  <div class="modal" *ngIf="visibleIncomingVideoCall">
    <div class="modal--modal-content">

      <div class="text-center p-4">
        <div class="avatar-lg mx-auto mb-4">
          <img *ngIf="peerUserInVideoCallImgSrc" [src]="peerUserInVideoCallImgSrc" alt=""
               class="img-thumbnail rounded-circle">
          <div class="avatar-lg" *ngIf="!peerUserInVideoCallImgSrc">
            <span class=" avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
              {{(peerUserInVideoCallName).charAt(0)}}
            </span>
          </div>
        </div>


        <h5 class="text-truncate">{{(peerUserInVideoCallName)}}</h5>
        <p class="text-muted">Incoming Call.....</p>

        <div class="mt-5">

          <ul class="list-inline mb-1">

            <li class="list-inline-item px-2">
              <button type="button" class="btn btn-success avatar-sm rounded-circle"
                      (click)="answerIncomingCall()">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-vidicon-fill"></i>
                              </span>
              </button>
            </li>

            <li class="list-inline-item px-2 me-2 ms-0">
              <button type="button" class="btn btn-danger avatar-sm rounded-circle"
                      data-bs-dismiss="modal" (click)="hangup()">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-close-fill"></i>
                              </span>
              </button>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</div>


<audio #outgoingRingTone id="outgoingRingTone" src="assets/sounds/outgoing.mp3" loop></audio>
<audio #hangupTone id="hangupTone" src="assets/sounds/hangup.mp3"></audio>

<audio #dialingTone id="dialingTone" src="assets/sounds/dialing.mp3"></audio>

<audio #callWaiting id="call-waiting" src="assets/sounds/call-waiting.mp3"></audio>
<audio #wrongNumberTone id="wrongNumberTone" src="assets/sounds/wrongNumber.mp3"></audio>


<audio #incomingRingTone id="incomingRingTone" src="assets/sounds/incoming.mp3" loop></audio>
