import {EventEmitter, Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {Group} from "../model/group.model";
import {Chats} from "../chat/index/chat.model";
import {chat} from "../chat/index/data";
import {SelectedItemEnum} from "../model/selected-item-enum";
import {Message} from "../model/message.model";
import {ChatService} from "./chat-service";
import {UserService} from "./user-service";
import {SearchModeEnum} from "../model/search-mode-enum";

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  uploadAttachmentMethodCalled: EventEmitter<FileList> = new EventEmitter<FileList>();

  outgoingCallMethod: EventEmitter<null> = new EventEmitter<null>();
  private _numberOfDialerCallSubscription: BehaviorSubject<number | null> = new BehaviorSubject(null);

  openCallManagementComponent: EventEmitter<boolean> = new EventEmitter<boolean>();

  private _tabsLoading: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  private _sideCanvasLoading: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  private _messagesLoading: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  private _createGroupModalLoading: BehaviorSubject<boolean | null> = new BehaviorSubject(null);
  private _itemSelected: BehaviorSubject<SelectedItemEnum | null> = new BehaviorSubject(null);
  private _groupSelected: BehaviorSubject<Group | null> = new BehaviorSubject(null);
  // private _chatSelected: BehaviorSubject<ChatItem | null> = new BehaviorSubject(null);
  // private _contactSelected: BehaviorSubject<Contact | null> = new BehaviorSubject(null);
  private _isSipConnected: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  private _chat: BehaviorSubject<Chats[] | null> = new BehaviorSubject(null);
  private _chatHeaderName: BehaviorSubject<string | null> = new BehaviorSubject(null);
  // private _chatHeaderStatus: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _chatHeaderAvatar: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _clearChatInput: BehaviorSubject<any> = new BehaviorSubject(null);
  private _scrollToBottomChatConv: BehaviorSubject<any> = new BehaviorSubject(null);
  private _isInternetConnected: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  private _chatSelectedId: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _contactSelectedId: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _dialerExtension: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private sendingMessage: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  private _peerUserInCallExtension: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _peerUserInCallName: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _peerUserInCallImgSrc: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _replyMessage: BehaviorSubject<Message | null> = new BehaviorSubject(null);
  private _closeAttachmentCard: BehaviorSubject<any> = new BehaviorSubject(null);
  private _videoCallUrl: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _searchOption: BehaviorSubject<SearchModeEnum | null> = new BehaviorSubject(null);


  constructor(private _httpClient: HttpClient
    , private chatService: ChatService
    , private userService: UserService) {
  }

  get videoCallUrl$(): Observable<string> {
    return this._videoCallUrl.asObservable();
  }

  setVideoCallUrl(value: string): void {
    this._videoCallUrl.next(value);
  }

  get replyMessage$(): Observable<Message> {
    return this._replyMessage.asObservable();
  }

  setReplyMessage(value: Message): void {
    this._replyMessage.next(value);
  }

  get tabsLoading$(): Observable<boolean> {
    return this._tabsLoading.asObservable();
  }

  setTabsLoading(value: boolean): void {
    this._tabsLoading.next(value);
  }

  get messagesLoading$(): Observable<boolean> {
    return this._messagesLoading.asObservable();
  }

  setMessagesLoading(value: boolean): void {
    this._messagesLoading.next(value);
  }

  get createGroupModalLoading$(): Observable<boolean> {
    return this._createGroupModalLoading.asObservable();
  }

  setCreateGroupModalLoading(value: boolean): void {
    this._createGroupModalLoading.next(value);
  }

  get itemSelected$(): Observable<SelectedItemEnum> {
    return this._itemSelected.asObservable();
  }

  setItemSelected(value: SelectedItemEnum): void {
    this._itemSelected.next(value);
  }

  get isSipConnected$(): Observable<boolean> {
    return this._isSipConnected.asObservable();
  }

  setIsSipConnected(value: boolean): void {
    this._isSipConnected.next(value);
  }

  get sendingMessage$(): Observable<boolean> {
    return this.sendingMessage.asObservable();
  }

  setSendingMessage(value: boolean): void {
    this.sendingMessage.next(value);
  }

  get isInternetConnected$(): Observable<boolean> {
    return this._isInternetConnected.asObservable();
  }

  setIsInternetConnected(value: boolean): void {
    this._isInternetConnected.next(value);
  }

  get groupSelected$(): Observable<Group> {
    return this._groupSelected.asObservable();
  }

  setGroupSelected(value: Group): void {
    this._groupSelected.next(value);
  }

  // get chatSelected$(): Observable<ChatItem> {
  //   return this._chatSelected.asObservable();
  // }
  //
  // setChatSelected(value:ChatItem):void {
  //   this._chatSelected.next(value);
  // }

  // get contactSelected$(): Observable<Contact> {
  //   return this._contactSelected.asObservable();
  // }
  //
  // setContactSelected(value:Contact):void {
  //   this._contactSelected.next(value);
  // }


  get contactSelectedId$(): Observable<string> {
    return this._contactSelectedId.asObservable();
  }

  setContactSelectedId(value: string): void {
    this._contactSelectedId.next(value);
  }


  get chat$(): Observable<Chats[]> {
    return this._chat.asObservable();
  }

  setChat(): void {
    this._chat.next(chat);
  }


  get chatHeaderName$(): Observable<string> {
    return this._chatHeaderName.asObservable();
  }

  setChatHeaderName(value: string): void {
    this._chatHeaderName.next(value);
  }

  // get chatHeaderStatus$(): Observable<string> {
  //   return this._chatHeaderStatus.asObservable();
  // }

  // setChatHeaderStatus(value: string): void {
  //   this._chatHeaderStatus.next(value);
  // }

  get chatHeaderAvatar$(): Observable<any> {
    return this._chatHeaderAvatar.asObservable();
  }

  setChatHeaderAvatar(value: any): void {
    this._chatHeaderAvatar.next(value);
  }

  set domain(domain: string) {
    sessionStorage.setItem('domain', domain);
  }

  get domain(): string {
    return sessionStorage.getItem('domain') ?? '';
  }

  set extension(extension: string) {
    sessionStorage.setItem('extension', extension);
  }

  get extension(): string {
    return sessionStorage.getItem('extension') ?? '';
  }

  get clearChatInput$(): Observable<any> {
    return this._clearChatInput.asObservable();
  }

  clearChatInput(): void {
    this._clearChatInput.next(true);
  }

  get scrollToBottomAtChatConversation$(): Observable<any> {
    return this._scrollToBottomChatConv.asObservable();
  }

  scrollToBottomAtChatConversation(): void {
    this._scrollToBottomChatConv.next(true);
  }

  get sideCanvasLoading$(): Observable<boolean> {
    return this._sideCanvasLoading.asObservable();
  }

  setSideCanvasLoading(value: boolean): void {
    this._sideCanvasLoading.next(value);
  }


  // addOneMessageToSelectedChat(message: Message) {
  //   let tempChat:ChatItem=this.deepCopyChatItem();
  //   tempChat.messages.unshift(message);
  //   this.setChatSelected(tempChat);
  // }


  // addOneMessageToSelectedGroup(message: Message) {
  //   let tempGroup:Group=this.deepCopyGroup()
  //   tempGroup.messages.unshift(message);
  //   this.setGroupSelected(tempGroup);
  // }

  // private deepCopyChatItem() {
  //
  //   let item ={...this._chatSelected.getValue()};
  //
  //     let copyChatItem:ChatItem={
  //       chatId: item.chatId,
  //       lastMessageBody: item.lastMessageBody,
  //       lastMessageId: item.lastMessageId,
  //       lastMessageTime: item.lastMessageTime,
  //       messages: [],
  //       peerUserExtension: item.peerUserExtension,
  //       peerUserId: item.peerUserId,
  //       peerUserName: item.peerUserName,
  //       peerUserProfileImgId: item.peerUserProfileImgId,
  //       peerUserProfileImgSrc: item.peerUserProfileImgSrc,
  //       reachEndOfMessages: item.reachEndOfMessages,
  //       unreadMessageCount: item.unreadMessageCount
  //     };
  //
  //   if (item.messages !== undefined && item.messages !== null) {
  //     item.messages.forEach(value => {
  //       copyChatItem.messages.push({...value});
  //     });
  //   }
  //
  //   return copyChatItem;
  //
  //
  //
  // }

  // private deepCopyGroup() {
  //
  //   let item ={...this._groupSelected.getValue()};
  //
  //   let copyGroupItem:Group={
  //     id: item.id,
  //     groupName: item.groupName,
  //     groupImgId: item.groupImgId,
  //     srcImg: item.srcImg,
  //     unreadMessageCount: item.unreadMessageCount,
  //     createdDate: item.createdDate,
  //     createdBy: item.createdBy,
  //     admin:item.admin,
  //     description:item.description,
  //     lastMessageBody: item.lastMessageBody,
  //     lastMessageTime: item.lastMessageTime,
  //     lastMessageId: item.lastMessageId,
  //     members: item.members,
  //     messages: [],
  //     reachEndOfMessages: item.reachEndOfMessages
  //   };
  //
  //   if (item.messages !== undefined && item.messages !== null) {
  //     item.messages.forEach(value => {
  //       copyGroupItem.messages.push({...value});
  //     });
  //   }
  //
  //   return copyGroupItem;
  //
  // }


  get chatSelectedId$(): Observable<string> {
    return this._chatSelectedId.asObservable();
  }

  setChatSelectedId(value: string): void {
    this._chatSelectedId.next(value);
  }


  get dialerExtension$(): Observable<string> {
    return this._dialerExtension.asObservable();
  }

  setDialerExtension(value: string): void {
    this._dialerExtension.next(value);
  }


  get numberOfDialerCallSubscription$(): Observable<number> {
    return this._numberOfDialerCallSubscription.asObservable();
  }

  setNumberOfDialerCallSubscription(val: number) {
    this._numberOfDialerCallSubscription.next(val);

  }


  get peerUserInCallExtension$(): Observable<string> {
    return this._peerUserInCallExtension.asObservable();
  }

  setPeerUserInCallExtension(value: string): void {
    this._peerUserInCallExtension.next(value);
  }

  get peerUserInCallName$(): Observable<string> {
    return this._peerUserInCallName.asObservable();
  }

  setPeerUserInCallName(value: string): void {
    this._peerUserInCallName.next(value);
  }


  get peerUserInCallImgSrc$(): Observable<any> {
    return this._peerUserInCallImgSrc.asObservable();
  }

  setPeerUserInCallImgSrc(value: any): void {
    this._peerUserInCallImgSrc.next(value);
  }


  get closeAttachmentCard$(): Observable<any> {
    return this._closeAttachmentCard.asObservable();
  }

  closeAttachmentCard(): void {
    this._closeAttachmentCard.next(true);
  }

  get getSelectedGroupData() {
    return this._groupSelected.getValue();
  }

  setPeerUserInfo(selectedItemType: SelectedItemEnum, selectedId: string): void {
    if (selectedItemType === SelectedItemEnum.CHAT) {
      let tempChatItem = this.chatService.getChatItem(selectedId);
      this.setPeerUserInCallName(tempChatItem.peerUserName);
      this.setPeerUserInCallImgSrc(tempChatItem.peerUserProfileImgSrc);
    } else if (selectedItemType === SelectedItemEnum.CONTACT) {
      let tempContact = this.userService.getContactByUserId(selectedId)
      this.setPeerUserInCallName(tempContact.displayName);
      this.setPeerUserInCallImgSrc(tempContact.profileImgSrc);
    }
  }

  get searchMode$(): Observable<SearchModeEnum> {
    return this._searchOption.asObservable();
  }

  setSearchMode(value: SearchModeEnum): void {
    this._searchOption.next(value);
  }

}
