<div class="side-menu flex-lg-column me-lg-1">
  <!-- LOGO -->
  <div class="navbar-brand-box">
    <!--TODO Add Deyala logo-->
    <!--    <a routerLink="/" class="logo logo-dark">-->
    <!--        <span class="logo-sm">-->
    <!--          <img src="assets/images/logo.svg" alt="" height="30">-->
    <!--        </span>-->
    <!--    </a>-->

    <!--    <a routerLink="/" class="logo logo-light">-->
    <!--        <span class="logo-sm">-->
    <!--          <img src="assets/images/logo.svg" alt="" height="30">-->
    <!--        </span>-->
    <!--    </a>-->
  </div>
  <!-- end navbar-brand-box -->

  <!-- Start side-menu nav -->
  <div class="flex-lg-column my-auto">
    <ul class="nav nav-pills side-menu-nav justify-content-center" role="tablist">
      <li class="nav-item" ngbTooltip="Chats">
        <a class="nav-link active" id="pills-chat-tab" (click)="setActiveTab('Chats')" href="javascript: void(0);"
           [ngClass]="{'active': activetab === 'Chats'}">
          <i class="ri-message-3-line"></i>
        </a>
      </li>
      <li class="nav-item" ngbTooltip="Groups">
        <a class="nav-link pills-groups-tab" id="pills-groups-tab" (click)="setActiveTab('Groups')"
           href="javascript: void(0);"
           [ngClass]="{'active': activetab === 'Groups'}">
          <i class="ri-group-line"></i>
        </a>
      </li>
      <li class="nav-item" ngbTooltip="Contacts" placement="top">
        <a class="nav-link" id="pills-contacts-tab" (click)="setActiveTab('Contacts')" href="javascript: void(0);"
           [ngClass]="{'active': activetab === 'Contacts'}">
          <i class="ri-contacts-line"></i>
        </a>
      </li>
      <li class="nav-item" ngbTooltip="Dialer" placement="top">
        <a class="nav-link" id="pills-dialer-tab" (click)="setActiveTab('Dialer')" href="javascript: void(0);"
           [ngClass]="{'active': activetab === 'Dialer'}">
          <i class="ri-phone-line"></i>
        </a>
      </li>
      <li class="nav-item d-lg-block d-none" ngbTooltip="Settings">
        <a class="nav-link" id="pills-setting-tab" (click)="setActiveTab('Settings')" href="javascript: void(0);"
           [ngClass]="{'active': activetab === 'Settings'}">
          <i class="ri-settings-2-line"></i>
        </a>
      </li>

      <li *ngIf="defaultUser.type===RoleTypeEnum.ADMIN" class="nav-item d-lg-block d-none" ngbTooltip="New User">
        <a class="nav-link" id="pills-newUser-tab" (click)="setActiveTab('NewUser')" href="javascript: void(0);"
           [ngClass]="{'active': activetab === 'NewUser'}">
          <i class="ri-user-add-line"></i>
        </a>
      </li>


      <li class="nav-item dropdown profile-user-dropdown d-inline-block d-lg-none" ngbDropdown placement="top">
        <a class="nav-link dropdown-toggle" href="javascript: void(0);" role="button" data-toggle="dropdown"
           aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
          <mat-progress-spinner
            *ngIf="loadingPhoto"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
          <img *ngIf="!loadingPhoto" [src]="userImageUrl" alt="" class="profile-user rounded-circle">
        </a>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <a *ngIf="defaultUser.type===RoleTypeEnum.ADMIN" class="dropdown-item" href="javascript:void(0);" (click)="setActiveTab('NewUser')"
             [ngClass]="{'active': activetab === 'NewUser'}">New User
            <i class="ri-user-add-line float-end text-muted"></i></a>
          <a class="dropdown-item" href="javascript:void(0);" (click)="setActiveTab('Settings')"
             [ngClass]="{'active': activetab === 'Settings'}">Setting
            <i class="ri-settings-3-line float-end text-muted"></i></a>
          <a class="dropdown-item" href="javascript:void(0);" ngbTooltip="Light Mode">
            Theme
            <i class="ri-sun-line theme-mode-icon layout-mode-dark float-end" (click)="changeMode('dark')"></i>
            <i class="ri-sun-line theme-mode-icon layout-mode-light float-end" (click)="changeMode('light')"></i>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">Log out
            <i class="ri-logout-circle-r-line float-end text-muted"></i></a>
        </div>
      </li>
    </ul>
  </div>
  <!-- end side-menu nav -->

  <div class="flex-lg-column d-none d-lg-block">
    <ul class="nav side-menu-nav justify-content-center">

      <!--      <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left">-->
      <!--        <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle-->
      <!--           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
      <!--          <i class="ri-global-line"></i>-->
      <!--        </a>-->
      <!--        <div class="dropdown-menu" ngbDropdownMenu>-->
      <!--          <a class="dropdown-item" *ngFor="let item of listLang" (click)="setLanguage(item.lang)"-->
      <!--             [ngClass]="{'active': lang === item.lang}" href="javascript: void(0);">-->
      <!--            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span-->
      <!--            class="align-middle">{{item.text}}</span>-->
      <!--          </a>-->
      <!--        </div>-->
      <!--      </li>-->

      <li class="nav-item">
        <a class="nav-link" id="light-dark" href="javascript:void(0);" ngbTooltip="Light Mode">
          <i class="ri-sun-line theme-mode-icon layout-mode-dark" (click)="changeMode('dark')"></i>
          <i class="ri-sun-line theme-mode-icon layout-mode-light" (click)="changeMode('light')"></i>
        </a>
      </li>

      <li class="nav-item btn-group dropup profile-user-dropdown" ngbDropdown placement="top-left">
        <a class="nav-link dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <mat-progress-spinner
            class="m-auto"
            *ngIf="loadingPhoto"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
          <img *ngIf="!loadingPhoto" [src]="userImageUrl" alt="" class="profile-user rounded-circle">
        </a>
        <div class="dropdown-menu" ngbDropdownMenu>
          <a class="dropdown-item" href="javascript:void(0);" (click)="setActiveTab('Settings')"
             [ngClass]="{'active': activetab === 'Settings'}">Setting
            <i class="ri-settings-3-line float-end text-muted"></i></a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript: void(0);" (click)="logout()">Log out
            <i class="ri-logout-circle-r-line float-end text-muted"></i></a>
        </div>
      </li>
    </ul>
  </div>
  <!-- Side menu user -->
</div>
