import {Component, Input, OnDestroy} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnDestroy {
  @Input() deleteAction: (() => void) | undefined;
  @Input() question: string = "Are you sure to delete?";
  @Input() button: string = "Delete";

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnDestroy(): void {
    console.log("on destroy");
  }

  confirmDelete() {
    if (this.deleteAction) {
      this.deleteAction();
    }
    this.activeModal.dismiss();
  }

}
