<div>

  <div class="px-4 pt-4">
    <h4 class="mb-4">Chats</h4>
    <div class="search-box chat-search-box">
      <div class="input-group mb-3 rounded-3">
                <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                  <i class="ri-search-line search-icon font-size-18"></i>
                </span>
        <input type="text" class="form-control bg-light"
               placeholder="Search users"
               aria-label="Search users" aria-describedby="basic-addon1" (keyup)="contactSearch()"
               id="searchContact" autocomplete="off">
      </div>
    </div>
  </div> <!-- .p-4 -->

  <!-- Start user status -->
  <div class="px-4 pb-4" dir="ltr">
    <div>

      <ng-template carouselSlide>

        <div class="item">
          <a href="javascript:void(0);" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="../../../../assets/images/users/avatar-2.jpg" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">Patrick</h5>
          </a>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <a href="javascript:void(0);" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="../../../../assets/images/users/avatar-4.jpg" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">Doris</h5>
          </a>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <a href="javascript:void(0);" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="../../../../assets/images/users/avatar-5.jpg" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">Emily</h5>
          </a>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <a href="javascript:void(0);" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
              <img src="../../../../assets/images/users/avatar-6.jpg" alt="user-img" class="img-fluid rounded-circle">
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">Steve</h5>
          </a>
        </div>
      </ng-template>
      <ng-template carouselSlide>
        <div class="item">
          <a href="javascript:void(0);" class="user-status-box">
            <div class="avatar-xs mx-auto d-block chat-user-img online">
                      <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                        T
                      </span>
              <span class="user-status"></span>
            </div>

            <h5 class="font-size-13 text-truncate mt-3 mb-1">Teresa</h5>
          </a>
        </div>
      </ng-template>

    </div>

    <!-- end user status carousel -->
  </div>
  <!-- end user status -->

  <!-- Start chat-message-list -->
  <div class="px-2">
    <h5 class="mb-3 px-3 font-size-16">Recent</h5>

    <perfect-scrollbar id="scrollView" class="chat-message-list" (psScrollY)="onScrollGetDisplayedItems()">
      <ul class="list-unstyled chat-list chat-user-list">

        <li *ngFor="let key of chatMapKeys  ; let i = index " [ngClass]="{'typing': false}"
            appDragDropFileUpload (fileDropped)="fileDroppedToChatHandler($event,i)"
            (click)="showChat(i)">
          <a href="javascript:void(0);" id="chatItem{{chatMap.get(key).chatId}}">
            <div class="d-flex" [ngStyle]="{'opacity': (chatMap.get(key).isChatItemIsTemp) ? '50%' : '100%'}">
              <div class="chat-user-img online align-self-center me-3 ms-0" [ngClass]="{'online': 'online' === 'online',
                  'away': 'online' === 'away'}">
                <img *ngIf="chatMap.get(key).peerUserProfileImgSrc" [src]="chatMap.get(key).peerUserProfileImgSrc"
                     class="rounded-circle avatar-xs"
                     alt="">
                <div class="avatar-xs" *ngIf="!chatMap.get(key).peerUserProfileImgSrc">
                          <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                            {{(chatMap.get(key).peerUserName)?.charAt(0)}}
                          </span>
                </div>
                <span *ngIf="false" class="user-status"></span>
              </div>

              <div class="flex-grow-1 overflow-hidden">
                <h5
                  class="text-truncate font-size-15 mb-1">{{chatMap.get(key).peerUserName}}{{chatMap.get(key).peerUserId === defaultUser.id ? " (YOU)" : ""}}</h5>
                <p *ngIf="false" class="chat-user-message text-truncate mb-0">typing<span
                  class="animate-typing">
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                          </span></p>
                <p class="chat-user-message text-truncate mb-0">{{chatMap.get(key).lastMessageBody}}</p>
              </div>
              <div class="font-size-11" style="white-space: nowrap;">
                {{chatMap.get(key).lastMessageTime | messageDate}}
              </div>
              <div *ngIf="chatMap.get(key).unreadMessageCount>0" class="unread-message">
                <span class="badge badge-soft-danger rounded-pill">{{chatMap.get(key).unreadMessageCount}}</span>
              </div>
            </div>
          </a>
        </li>


      </ul>
    </perfect-scrollbar>

  </div>
  <!-- End chat-message-list -->
</div>
