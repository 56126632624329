import {ICEServers} from '../app/sip/lib/helper/ice-servers';

export class Properties  {
    public static async rtcConfiguration():Promise<RTCConfiguration>{

        let urls=await new ICEServers().getBestIceServers(["turn:turn-chi.allegiancemd.com", "turn:turn-ny.allegiancemd.com", "turn:turn-atl.allegiancemd.com", "turn:turn-la.allegiancemd.com", "turn:turn-miami.allegiancemd.com"]);


       return {
        iceTransportPolicy: "relay",
        iceServers: [
            {
                "urls": [
                    "stun:stun.l.google.com:19302"
                ]
            }, {
                "username": "1926000627:username",
                "credential": "gJk9jXmfivbeUYf0e22RsH0sWaQ=",
                urls: urls
            }
        ]
       };


    };
}
