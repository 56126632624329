import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {properties} from "../../properties/urlProperties";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  /**
   * Constructor
   */

  private readonly deyalaPublicHost: string;
  private readonly deyalaSecureHost: string;
  private readonly deyalaPublicPort: string;
  private readonly deyalaSecurePort: string;

  constructor() {
    const deyalaPublicURL = properties.deyalaPublicURL;
    const deyalaSecureURL = properties.deyalaSecureURL;

    this.deyalaPublicHost = new URL(deyalaPublicURL).hostname;
    this.deyalaSecureHost = new URL(deyalaSecureURL).hostname;

    this.deyalaPublicPort = new URL(deyalaPublicURL).port;
    this.deyalaSecurePort = new URL(deyalaSecureURL).port;
  }

  /**
   * Intercept
   *
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request object
    //const user = await this.keycloakService.loadUserProfile();

    const fullUrl = req.url;
    const url = new URL(fullUrl);
    const hostname = url.hostname;
    const port = url.port;

    let newReq = req.clone({headers: req.headers});

    if ((hostname == this.deyalaPublicHost && port == this.deyalaPublicPort) ||
      (hostname == this.deyalaSecureHost && port == this.deyalaSecurePort)) {
      newReq = req.clone({
        headers: req.headers
          .set("Access-Control-Allow-Origin", "*")
          .set("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept")
      });
    }

// Response
    return next.handle(newReq);
  }
}
