export class TimerFormat {
  private static zeroPad(num: number, places: number) {
    return String(num).padStart(places, '0');
  }

  public static formatTimer(num: any) {
    let timerString = "";
    let hrs = Math.floor(num / 3600);
    if (hrs > 0) {
      timerString += `${this.zeroPad(hrs, 2)}:`;
    }
    let minRemainders = num - (hrs * 3600);
    let mins = Math.floor(minRemainders / 60)

    return (timerString + `${this.zeroPad(mins, 2)}:${this.zeroPad((minRemainders % 60), 2)}`);
  }
}
