<div class="offcanvas-body profile-offcanvas p-0">
  <div class="text-center p-4 border-bottom">
    <div class="mb-4">
      <img *ngIf="chatHeaderAvatar" [src]="chatHeaderAvatar" class="rounded-circle avatar-lg img-thumbnail" alt="">
      <div *ngIf="!chatHeaderAvatar" class=" profile-user rounded-circle avatar-lg img-thumbnail">
      <span class=" avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                          {{chatHeaderName.charAt(0)}}
                        </span>
      </div>
    </div>
    <h5 class="font-size-16 mb-1 text-truncate">{{chatHeaderName}}</h5>
    <!--    user status eg : Online-->

    <!--    <p class="text-muted text-truncate mb-1">-->
    <!--      <i class="ri-record-circle-fill font-size-10 me-1"-->
    <!--         [ngClass]=" {'text-success': chatHeaderStatus === 'online','text-warning': chatHeaderStatus === 'away'}"></i>-->
    <!--      {{chatHeaderStatus}}</p>-->
  </div>
  <!-- End profile user -->

  <!-- Start user-profile-desc -->
  <perfect-scrollbar class="p-4 user-profile-desc">
    <div class="text-muted">
      <!-- Start user-profile-desc -->
      <!--      <p class="mb-4">If several languages coalesce, the grammar of the resulting language is more simple and regular-->
      <!--        than that of the individual.</p>-->
    </div>

    <div id="profile-user-accordion" class="custom-accordion">
      <ngb-accordion #acc="ngbAccordion" activeIds="about" [closeOthers]="true">
        <ngb-panel cardClass="card shadow-none border mb-2" id="about">
          <ng-template ngbPanelTitle>
            <h5 class="font-size-14 m-0">
              <i class="ri-user-2-line me-2 align-middle d-inline-block"></i>Text</h5>
          </ng-template>
          <ng-template ngbPanelContent>
            <div>
              <p class="text-muted mb-1">Name</p>
              <h5 class="font-size-14">{{chatHeaderName}}</h5>
            </div>

            <div class="mt-4">
              <p class="text-muted mb-1">Extension</p>
              <h5 class="font-size-14">{{contactSelected.extension}}</h5>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- End Attached Files card -->
    </div>
    <!-- end profile-user-accordion -->
  </perfect-scrollbar>
  <!-- end user-profile-desc -->

</div><!--end offcanvas-body-->
