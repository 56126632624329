import {Component} from '@angular/core';
import {MainMenuService} from "../../../main-menu/main-menu.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GroupService} from "../../../../services/group-service";
import {UserService} from "../../../../services/user-service";
import {GeneralService} from "../../../../services/general-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Contact} from "../../../../model/contact.model";

@Component({
  selector: 'app-contacts-side-info',
  templateUrl: './contacts-side-info.component.html',
  styleUrls: ['./contacts-side-info.component.scss']
})
export class ContactsSideInfoComponent {

  chatHeaderName: any;
  chatHeaderStatus: any;
  chatHeaderAvatar: any;
  contactSelectedId: string
  contactSelected: Contact;

  constructor(
    private mainMenuService: MainMenuService,
    private modalService: NgbModal,
    private groupService: GroupService,
    private userService: UserService,
    private generalService: GeneralService,
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.generalService.chatHeaderName$.subscribe((value) => {
      this.chatHeaderName = value;
    });
    // this.generalService.chatHeaderStatus$.subscribe((value) => {
    //   this.chatHeaderStatus = value;
    // });
    this.generalService.chatHeaderAvatar$.subscribe((value) => {
      this.chatHeaderAvatar = value;
    });
    this.generalService.contactSelectedId$.subscribe((value) => {
      if (value !== null) {
        this.contactSelectedId = value;
        this.contactSelected = this.userService.getContactByUserId(this.contactSelectedId);
      }
    });
  }

}
