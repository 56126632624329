import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";

import {KeycloakServiceInterface} from '../interface/keycloakServiceInterface';
import {UserService} from "./user-service";
import {OAuthService} from "angular-oauth2-oidc";
import {properties} from "../../properties/urlProperties";
import jwt_decode from "jwt-decode";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class KeycloakService implements KeycloakServiceInterface {
  private readonly deyalaBaseURL: string = properties.deyalaSecureURL + "/user";
  private userName: string;

  constructor(private httpClient: HttpClient,
              private userService: UserService,
              private oauthService: OAuthService,
              private router: Router) {
    if (this.oauthService.hasValidAccessToken()) {
      this.userName = this.oauthService.getIdentityClaims()['preferred_username'];
    }
  }

  async loadUserProfile(): Promise<any> {
    this.userName = this.oauthService.getIdentityClaims()['preferred_username'];
    return await this.httpClient.get(this.deyalaBaseURL + "/userProfile", {params: {"userName": this.userName}}).toPromise()
  }

  getRoles(): string[] {
    if (this.oauthService.hasValidAccessToken()) {
      const claims = jwt_decode(this.oauthService.getAccessToken());
      return claims["realm_access"]['roles'];
    }
    return null;
  }

  isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  async logout() {
    await this.oauthService.revokeTokenAndLogout();
  }
}
