<div xmlns="http://www.w3.org/1999/html">
  <div class="px-4 pt-4">
    <h4 class="mb-4">Add New User</h4>
  </div>

  <!-- User Info -->
  <div class="card shadow-none border my-2 mx-4 bg-white newUserComponent">
    <div>
      <form [formGroup]="newUserForm">
        <perfect-scrollbar class="p-4 pb-0 new-user-form" data-simplebar>
          <mat-form-field class="d-block m-auto mt-1">
            <mat-label class="font-size-14">First Name:</mat-label>
            <input [(ngModel)]="firstName" [formControlName]="'firstName'" matInput
                   class="form-control bg-transparent border-light"
                   maxlength="20">
            <mat-error *ngIf="newUserForm.get('firstName').hasError('required')">
              First Name is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="d-block m-auto mt-1">
            <mat-label class="font-size-14">Last Name:</mat-label>
            <input [(ngModel)]="lastName" [formControlName]="'lastName'" matInput
                   class="form-control bg-transparent border-light" maxlength="20">
            <mat-error *ngIf="newUserForm.get('lastName').hasError('required')">
              Last Name is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="d-block m-auto mt-1">
            <mat-label class="font-size-14">Email:</mat-label>
            <input [(ngModel)]="email" [formControlName]="'email'" matInput
                   class="form-control bg-transparent border-light"
                   maxlength="30" pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}">
            <mat-error *ngIf="newUserForm.get('email').hasError('required')">
              Email is required
            </mat-error>
            <mat-error
              *ngIf="newUserForm.get('email').hasError('email') || newUserForm.get('email').hasError('pattern')">
              Invalid email format
            </mat-error>
          </mat-form-field>

          <mat-form-field class="d-block m-auto mt-1">
            <mat-label class="font-size-14">Extension:</mat-label>
            <input [(ngModel)]="extension" [formControlName]="'extension'" matInput
                   class="form-control bg-transparent border-light"
                   maxlength="20">
            <mat-error *ngIf="newUserForm.get('extension').hasError('required')">
              Extension is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="d-block m-auto mt-1">
            <mat-label class="font-size-14">Username:</mat-label>
            <input [(ngModel)]="username" [formControlName]="'username'" matInput
                   class="form-control bg-transparent border-light" maxlength="20">
            <mat-error *ngIf="newUserForm.get('username').hasError('required')">
              Username is required
            </mat-error>
          </mat-form-field>

          <mat-form-field class="d-block m-auto mt-1">
            <mat-label class="font-size-14">Password</mat-label>
            <input [(ngModel)]="password" [formControlName]="'password'" id="password" matInput type="password">
            <mat-error *ngIf="newUserForm.get('password').hasError('required')">
              Password is required
            </mat-error>
            <mat-error
              *ngIf="newUserForm.get('password').invalid && !newUserForm.get('password').hasError('required') &&
             newUserForm.get('password').touched">
              <span>Must be at least 8 characters!</span>
              <div>Must contain at least 1 in Capital Case!</div>
              <div>Must contain at least 1 Letter in Small Case!</div>
              <div>Must contain at least 1 Special Character [!@#$%^&*()]!</div>
              <div>Must have at least 1 number!</div>
            </mat-error>
          </mat-form-field>
        </perfect-scrollbar>

        <div class="mb-4">
          <!-- Submit button -->
          <button
            class="fuse-mat-button-large w-full mt-3"
            style="display: block;margin: auto"
            mat-flat-button
            [color]="'primary'"

            [disabled]="!newUserForm.valid"
            (click)="saveNewUser()">
                        <span>
                            <i class="ri-save-fill me-1 align-middle"></i> Save
                        </span>
            <mat-progress-spinner
              *ngIf="newUserForm.disabled"
              [diameter]="24"
              [mode]="'indeterminate'"></mat-progress-spinner>
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
