import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserCallManagementService} from "../user-chat/user-call-management/user-call-management.service";
import {CallHistoryModel} from "../../sip/lib/model/call-history-model";
import {TimerFormat} from "../../sip/lib/helper/timer-format";
import {GeneralService} from "../../services/general-service";

@Component({
  selector: 'minimized-call',
  templateUrl: './minimized-call.component.html',
  styleUrls: ['./minimized-call.component.scss']
})
export class MinimizedCallComponent implements OnInit {

  currentCall: CallHistoryModel = null;
  minimizeCall: boolean;
  peerUserInCallName: string = null;


  constructor(private generalService: GeneralService, private userCallManagementService: UserCallManagementService,
              private _changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.userCallManagementService.minimizeCall$.subscribe((value) => {
      this.minimizeCall = value;
      this._changeDetectorRef.detectChanges();
    });
    this.generalService.peerUserInCallName$.subscribe((value) => {
      this.peerUserInCallName = value;
    });
    this.userCallManagementService.currentCall$.subscribe((response) => {
      this.currentCall = response;
    });
  }

  public formatTimer(num: any) {
    return TimerFormat.formatTimer(num);
  }

  maximizeCall() {
    this.userCallManagementService.setMinimizeCall(false);
  }
}
