import {Component, OnInit} from '@angular/core';
import {MainMenuService} from "../../main-menu/main-menu.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Group} from "../../../model/group.model";
import {GroupService} from "../../../services/group-service";
import {User} from "../../../model/user.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GeneralService} from "../../../services/general-service";
import {UserService} from "../../../services/user-service";
import {ImageUtil} from '../../../../utils/image-util';
import {MessageService} from "../../../services/message-service";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {environment} from "../../../../environments/environment";
import {MessageContentTypeEnum} from "../../../model/message-type";
import {Message} from "../../../model/message.model";

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {


  groups: Group[] = null;
  defaultUser: User = null;
  selectedGroup: Group = null;
  isAvatarLoading: boolean = false;
  selectedGroupIndex: string | null = null;

  constructor(
    private mainMenuService: MainMenuService,
    private modalService: NgbModal,
    private groupService: GroupService,
    private userService: UserService,
    private generalService: GeneralService,
    private _snackBar: MatSnackBar,
    private imageUtil: ImageUtil,
    private messageService: MessageService
  ) {
  }

  ngOnInit(): void {

    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.groupService.isAvatarLoading$.subscribe((value) => {
      if (value != null)
        this.isAvatarLoading = value;
    });

    this.groupService.groupImages$.subscribe((response) => {
      if (response !== null && this.groups !== null) {
        let result: Map<string, string> = response

        this.groups.forEach(group => {
          if (result[group.id]) {
            group.srcImg = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(result[group.id]);

            if (this.selectedGroup && group.id === this.selectedGroup.id) {
              this.generalService.setChatHeaderAvatar(group.srcImg);
              this.selectedGroup.srcImg = group.srcImg;
              this.generalService.setGroupSelected(this.selectedGroup);
            }


          }
        })
      }
    });

    this.groupService.userGroups$.subscribe((response) => {
      if (response !== null) {
        this.groups = response.sort((val1, val2) => {
          return new Date(val2.lastMessageTime).getTime() - new Date(val1.lastMessageTime).getTime();
        });

        if (this.groups !== undefined && this.groups !== null && this.groups.length !== 0) {
          this.groups.forEach(value => {
            if (value.lastMessageBody === environment.systemMessageProperties.prefix + MessageContentTypeEnum.AudioCall) {
              value.lastMessageBody = "Voice Call";
            }
            if (value.lastMessageBody === environment.systemMessageProperties.prefix + MessageContentTypeEnum.VideoCall) {
              value.lastMessageBody = "Video Call";
            }
            if (value.lastMessageBody === environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType) {
              value.lastMessageBody = "ATTACHMENT";
            }
          })

        }
      }
    });

    this.generalService.groupSelected$.subscribe((response) => {
      this.selectedGroup = response;
      if (response !== null) {

        this.groups.map(group => group.srcImg = group.id === this.selectedGroup.id ? this.selectedGroup.srcImg : group.srcImg);

      }
    });

    this.getUserGroups();

  }

  //get groups of user
  private getUserGroups() {
    if (this.groups === null) {
      this.generalService.setTabsLoading(true);
      this.groupService.getUserGroups(this.defaultUser.id).subscribe({
        next: () => {
          this.generalService.setTabsLoading(false);
          this.getGroupsImages();
        },
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setTabsLoading(false);
        }
      });

    } else {
      this.getGroupsImages();
    }
  }

  getGroupsImages() {
    if (this.groups === null || this.groups.length === 0) {
      return;
    }
    this.groups.forEach((value: Group) => {
      if (value.groupImgId !== null && value.groupImgId.trim() !== '' && (value.srcImg === undefined || value.srcImg === null)) {
        this.groupService.getGroupImage(value.groupImgId, value.id, this.defaultUser.id).subscribe({
          next: (response) => {
            if (response !== null && response.size !== 0) {
              value.srcImg = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response.imageData);


              //If chat was selected before images was loaded
              if (this.selectedGroup && value.id === this.selectedGroup.id) {
                this.generalService.setChatHeaderAvatar(value.srcImg);
                this.generalService.setGroupSelected(value);
              }
            }
          }
        })
      }
    });
  }


  //Open add group modal
  openGroupModal(content: any) {
    //Open group creation modal
    this.modalService.open(content, {centered: true});
  }

  // Group Search
  GroupSearch() {
    var input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("searchGroup") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.querySelectorAll(".group-list");
    ul.forEach((item: any) => {
      li = item.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("h5")[0];
        txtValue = a?.innerText;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    })
  }

  showGroupChat(index: any) {
    if (this.selectedGroup !== undefined && this.selectedGroup !== null && this.groups[index].id === this.selectedGroup.id) {
      return;
    }
    this.generalService.clearChatInput();

    var removeClass = document.querySelectorAll('.group-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });
    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');

    let data: Group = this.groups[index];
    this.selectedGroupIndex = this.groups[index].id;
    this.generalService.setGroupSelected(data);
    this.generalService.setContactSelectedId(null);
    this.generalService.setChatSelectedId(null);
    this.selectedGroup = data;
    if (this.messageService.isThereAnUploadingMessageByRoomId(data.id)) {
      this.generalService.setSendingMessage(true);
    } else {
      this.generalService.setSendingMessage(false);
    }
    this.getMessageList(data);


    this.generalService.setChatHeaderName(data.groupName);
    this.generalService.setChatHeaderAvatar(data.srcImg);

    this.generalService.setItemSelected(SelectedItemEnum.GROUP);
  }

  private getMessageList(selectedGroup: Group) {

    let messages = this.messageService.getConversationMessagesFromAllConversationMessages(selectedGroup.id);

    if (messages === undefined || messages === null || messages.length === 0) {
      this.messageService.setCurrentConversationMessages(null);
      this.generalService.setMessagesLoading(true);
      this.messageService.getRoomMessagesHistory(selectedGroup.id, 0).subscribe({
        next: (response: Message[]) => {


          this.messageService.setCurrentConversationMessages(response);
          this.messageService.addToAllConversationMessages(selectedGroup.id, response);
          this.generalService.scrollToBottomAtChatConversation();
          this.generalService.setMessagesLoading(false);
          this.markAllMessagesAsRead(selectedGroup);

        },
        error: (err) => {

          if (err.status === 400) {
            this.openSnackBar(err.error, 'error');
          }
          if (err.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setMessagesLoading(false);
        }
      })
    } else {

      this.messageService.setCurrentConversationMessages(messages);
      this.generalService.scrollToBottomAtChatConversation();
      this.markAllMessagesAsRead(selectedGroup);

    }


  }

  private markAllMessagesAsRead(selectedGroup: Group) {
    this.messageService.markChatMessagesAsRead(this.defaultUser.id, selectedGroup.id).subscribe({

      next: () => {
        this.messageService.markAllMessagesAsRead(selectedGroup.id, this.defaultUser.id);
        let tempSelectedGroup = {...selectedGroup};
        tempSelectedGroup.unreadMessageCount = 0;
        this.generalService.setGroupSelected(tempSelectedGroup);
        this.groupService.updateUserGroupsOneItem(selectedGroup.id, tempSelectedGroup);
      },
      error: () => {
        console.log('Cannot mark messages as Read');
      }
    })
  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  dataURI_DataPart_ToBlob(dataURI: any): any {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array]);
  }

  fileDroppedToGroupHandler(event: any, index: number) {
    this.showGroupChat(index);
    // Emit the event to notify UserChatInputComponent
    this.generalService.uploadAttachmentMethodCalled.emit(event);
  }
}
