import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {OAuthService} from "angular-oauth2-oidc";
import {Injectable} from '@angular/core';
import {KeycloakService} from "../services/keycloak-service";


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private roles: any[];

  constructor(protected readonly router: Router,
              protected readonly oauthService: OAuthService,
              protected readonly keycloakService: KeycloakService) {
    this.loadRoles();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (!this.roles) this.loadRoles();

    const currentUrl = this.router.url;
    const currentUrlTree = this.router.parseUrl(currentUrl);
    const currentQueryParams = currentUrlTree.queryParams;

    if (this.oauthService.hasValidAccessToken()) {
      // return Promise.resolve(true);

      const requiredRoles = route.data["roles"];

      if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
        return Promise.resolve(true);
      }

      console.log("roles: ", this.roles);
      console.log("reqRoles: ", requiredRoles);
      console.log("abc: ", requiredRoles.some((role) => this.roles?.includes(role)))
      return Promise.resolve(requiredRoles.some((role) => this.roles?.includes(role)));
    }

    // return Promise.resolve(this.router.createUrlTree(['/login']));
    this.router.navigate(['login'], {queryParams: currentQueryParams})
    return Promise.reject(false);
  }

  private loadRoles() {
    this.roles = this.keycloakService.getRoles();
  }
}
