<div class="chat-welcome-section">
  <div class="row w-100 justify-content-center">
    <div class="col-xxl-5 col-md-7">
      <div class="p-4 text-center">
        <div class="avatar-xl mx-auto mb-4">
          <div class="avatar-title bg-secondary rounded-circle">
            <svg data-v-5e8ea5c2="" xmlns="http://www.w3.org/2000/svg" width="70px" height="65px" viewBox="0 0 24 24"
                 fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                 class="feather feather-message-square">
              <path data-v-5e8ea5c2="" d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z">
              </path>
            </svg>
          </div>
        </div>
        <h4 class="d-inline px-3 py-2 rounded-pill bg-secondary text-white fs-4"> Start Conversation </h4>
      </div>
    </div>
  </div>
</div>
