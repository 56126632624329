import {Component, ComponentRef, ElementRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {CallEvent} from "../../sip/lib/events/call-events";
import {CallState} from "../../sip/lib/model/call-state";
import {CallHistoryModel} from "../../sip/lib/model/call-history-model";
import {CallDirectionState} from "../../sip/lib/model/call-direction-state";
import {SIPService} from "../../sip/services/sip-service";
import {UserCallManagementComponent} from "../user-chat/user-call-management/user-call-management.component";
import {v4 as uuid} from "uuid";
import {RegistererState, Session, TransportState} from 'sip.js';
import {ManagedSession} from 'sip.js/lib/platform/web';
import {User} from "../../model/user.model";
import {UserService} from "../../services/user-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GeneralService} from "../../services/general-service";
import {ChatService} from "../../services/chat-service";
import {Properties} from "../../../properties/properties";
import {SIPOptions} from "../../sip/lib/interfaces/sip-options-interface";
import {LocalStorageService} from "../../sip/services/local-storage-service";
import {PrefrenceModel} from "../../sip/lib/model/prefrence-model";
import {MessageService} from "../../services/message-service";
import {Message} from "../../model/message.model";
import {Group} from "../../model/group.model";
import {Contact} from "../../model/contact.model";
import {GroupService} from "../../services/group-service";
import {ConnectionService, ConnectionState} from "ng-connection-service";
import {SelectedItemEnum} from "../../model/selected-item-enum";
import {Router} from "@angular/router";
import {KeycloakService} from "../../services/keycloak-service";
import {environment} from "../../../environments/environment";
import {MessageContentTypeEnum} from "../../model/message-type";
import {
  UserVideoCallManagementComponent
} from "../user-chat/user-video-call-management/user-video-call-management.component";
import {SearchModeEnum} from "../../model/search-mode-enum";

// Date Format

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

/**
 * Chat-component
 */
export class IndexComponent implements OnInit {

  @ViewChild(UserCallManagementComponent) callComponent: UserCallManagementComponent = null as any;
  @ViewChild("videoContent") videoContent: any;

  componentRef: ComponentRef<UserCallManagementComponent>;
  videoCallComponentRef: ComponentRef<UserVideoCallManagementComponent>;

  defaultUser: User = null;
  prefrenceModel: PrefrenceModel = null as any;
  contacts: Map<string, Contact> = new Map<string, Contact>();

  selectedItemType: SelectedItemEnum;
  // chatSelected:ChatItem;
  chatSelectedId: string;
  groupSelected: Group;
  contactSelectedId: string = null;


  peerExtension: string = null;
  callStatusMessage: string;
  callInProgress: boolean = false;
  callMaps: Map<string, CallHistoryModel> = new Map<string, CallHistoryModel>();
  activeCalls: Array<ManagedSession> = null as any;

  public isLoggedIn = false;
  public userProfile: any | null = null;
  isInternetConnected = true;

  @ViewChild('audioRemote') audioRemote: ElementRef = null as any;


  constructor(private sipService: SIPService, private rootViewContainer: ViewContainerRef
    , private userService: UserService, private generalService: GeneralService
    , private chatService: ChatService, private groupService: GroupService
    , private _snackBar: MatSnackBar
    , private localStorageService: LocalStorageService, private messageService: MessageService
    , private connectionService: ConnectionService
    , private router: Router
    , private keycloakService: KeycloakService) {
  }


  async ngOnInit() {
    this.generalService.peerUserInCallExtension$.subscribe((value) => {
      this.peerExtension = value;
    });
    this.generalService.setIsSipConnected(false);

    this.isLoggedIn = this.keycloakService.isLoggedIn();

    if (this.isLoggedIn) {

      this.userProfile = await this.keycloakService.loadUserProfile().catch((error) => {
        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
        this.userService.setLoadingPhoto(false);
      });
      console.log('Attributes  ', this.userProfile['attributes']);
      console.log('Attributes  ', this.userProfile);

      this.generalService.domain = this.userProfile ['attributes']['domain'][0];
      this.generalService.extension = this.userProfile ['attributes']['extension'][0];


      this.userService.getDefaultUser().subscribe({
          next: (response) => {
            if (!response.domain || !response.extension || response.extension == null) {
              this.router.navigateByUrl('/pages/error');
            } else {
              this.defaultUser = response;
              window.sessionStorage.setItem("defaultUser", JSON.stringify(response));
              this.userService.setDisplayName(this.defaultUser.displayName);
              //Register
              this.register();

              this.getUserImage();
            }


          },
          error: (error) => {
            if (error.status === 500) {
              this.openSnackBar('Server has problem, Please try in another time', 'error');
            } else if (error.status === 400) {
              this.openSnackBar(error.error, 'error');
            } else {
              this.openSnackBar('An error has occurred, Try again later.', 'error');
            }
            this.userService.setLoadingPhoto(false);
          }
        }
      );

      this.generalService.setSearchMode(window.localStorage.getItem('searchMode') as
                                        SearchModeEnum || SearchModeEnum.ONLY_LOADED_MESSAGES);


    }

    this.addSipListeners();

    this.generalService.itemSelected$.subscribe(value => {
      this.selectedItemType = value;
    });
    // this.generalService.chatSelected$.subscribe(value => {this.chatSelected=value;});
    this.generalService.chatSelectedId$.subscribe(value => {
      this.chatSelectedId = value;
    });
    this.generalService.contactSelectedId$.subscribe(value => {
      this.contactSelectedId = value;
    });
    this.generalService.groupSelected$.subscribe(value => {
      this.groupSelected = value;
    });
    this.userService.contacts$.subscribe(value => {
      if (value !== null) {
        let temp: Contact[] = value;
        temp.forEach(value1 => {
          this.contacts.set(value1.id, value1);
        });
      }
    });

    this.getContacts();

    // notify user when internet is down or access
    this.connectionService.monitor().subscribe((isConnected: ConnectionState) => {
      this.isInternetConnected = isConnected.hasInternetAccess || isConnected.hasNetworkConnection;
      // this.isConnected = isConnected.hasInternetAccess && isConnected.hasNetworkConnection;
      this.generalService.setIsInternetConnected(this.isInternetConnected);
      if (this.isInternetConnected) {
        this.openSnackBar('You restored your internet access.', 'success');
      } else {
        this.openSnackBar('No Internet Connection.', 'error');
      }
    });

    // ask to access mic when open site
    navigator.mediaDevices.getUserMedia({video: false, audio: true})
      .then(() => {
      })
      .catch((error) => {
        console.log("IndexComponent:getUserMedia: ", error.message);
        this.openSnackBar(error + ', Try again later.', 'error');
      });


    this.generalService.openCallManagementComponent.subscribe(value => {

      this.openOutGoingCall(value);

    });


  }

  private addSipListeners() {
    this.sipService.getCallEvent().addEventListener(CallEvent.incomingCall, (e) => {
      console.log("inside CallEvent.incomingCall ");
      this.callStatusMessage = 'Incoming Call ... ';


      let event: CustomEvent = e as CustomEvent;
      this.activeCalls = this.sipService.listSessions();
      let sessionId: CallState = event.detail.sessionId;
      //on incoming calls, store in the map
      let callHistoryModel: CallHistoryModel = this.getCallHistoryModel(sessionId);
      callHistoryModel.direction = CallDirectionState.Inbound;
      this.callMaps.set(event.detail.sessionId, callHistoryModel);
      //open the modal

      this.callInProgress = false;

      //Ringing Part added to UserCall Management

      this.openCallIncoming();
      //this.modalService.open(this.incomingCallModal, {centered: true});


      console.log("Leave CallEvent.incomingCall ");

    });

    this.sipService.getCallEvent().addEventListener(CallEvent.MessageReceived, async (e) => {
      console.log("inside CallEvent.MessageReceived ");
      let customEvent: CustomEvent = e as CustomEvent;
      await this.handleMessage(customEvent);
    });

    this.sipService.getCallEvent().addEventListener(CallEvent.registrationState, (e) => {
      let event: CustomEvent = e as CustomEvent;
      if (event.detail?.registererState == RegistererState.Registered) {
        this.generalService.setIsSipConnected(true);
        console.log('Success');
        this.sipService.initiateLines();
      } else {
        this.generalService.setIsSipConnected(false);
        console.log('error');
      }
    });
  }

  private getUserImage() {
    if (this.defaultUser.profileImgId !== null && this.defaultUser.profileImgId.trim() !== '') {
      this.userService.setLoadingPhoto(true);
      this.userService.getDefaultUserImage(this.defaultUser.profileImgId).subscribe({
        next: () => {
          this.userService.setLoadingPhoto(false);
        },
        error: (error) => {
          console.log('error at getting image ', error.status);
          this.userService.setLoadingPhoto(false);

        }
      });
    }
  }

  private async handleMessage(customEvent: CustomEvent): Promise<void> {
    let message = JSON.parse(customEvent.detail.message.incomingMessageRequest.message.body);
    let type: string = message.type;

    console.log(message.type);

    this.updateBehaviorsAndView(message.message)
    switch (type) {
      case MessageContentTypeEnum.Text:
        console.log("Message type TEXT");
        break;
      case MessageContentTypeEnum.Attachment:
        console.log("Message type ATTACHMENT");
        break;
      case MessageContentTypeEnum.AudioCall:
        console.log("Message type AUDIO_CALL");
        this.messageService.setCallMessage(message);
        break;
      case MessageContentTypeEnum.VideoCall:
        console.log("Message type VIDEO_CALL");
        if (message.callState === CallState.Trying) {
          this.generalService.setPeerUserInCallExtension(message.peerExtension)
          this.sipService.sendMessage(this.peerExtension,
            JSON.stringify({
              type: message.type,
              callState: CallState.Progress
            })).then(() => {
            this.generalService.setVideoCallUrl(message.videoCallUrl);
            this.messageService.setCallMessage(message.message);
            this.openVideoCallIncoming();
          });
        }
        break;
      default:
        break;
    }
  }

  openCallIncoming() {

    this.componentRef = this.rootViewContainer.createComponent(UserCallManagementComponent);

    let peerExtension = this.callMaps.values().next().value.number.toString();
    let contact: Contact = null;
    if (peerExtension !== undefined && peerExtension !== null && peerExtension.trim() !== '') {
      contact = this.userService.getContactByExtension(peerExtension);
    }

    this.generalService.setPeerUserInCallName(contact.displayName);
    this.generalService.setPeerUserInCallImgSrc(contact.profileImgSrc);
    this.componentRef.instance.visibleIncomingCall = true;
    this.componentRef.instance.activeCalls = this.activeCalls;
    this.componentRef.instance.callMaps = this.callMaps;
    this.componentRef.instance.callStatusMessage = this.callStatusMessage;
    this.componentRef.instance.callInProgress = this.callInProgress;

    this.componentRef.instance.closeModal.subscribe(() => this.componentRef.destroy());


    this.rootViewContainer.insert(this.componentRef.hostView);


  }

  openVideoCallIncoming() {
    this.videoCallComponentRef = this.rootViewContainer.createComponent(UserVideoCallManagementComponent);
    let contact: Contact = this.userService.getContactByExtension(this.peerExtension);

    this.generalService.setPeerUserInCallName(contact.displayName);
    this.generalService.setPeerUserInCallImgSrc(contact.profileImgSrc);
    this.videoCallComponentRef.instance.visibleIncomingVideoCall = true;
    this.videoCallComponentRef.instance.videoCallStatusMessage = this.callStatusMessage;

    this.videoCallComponentRef.instance.closeModal.subscribe(() => this.videoCallComponentRef.destroy());

    this.rootViewContainer.insert(this.videoCallComponentRef.hostView);
  }

  openOutGoingCall(isCallFromDialer: boolean) {
    this.componentRef = this.rootViewContainer.createComponent(UserCallManagementComponent);

    if (!isCallFromDialer) {
      this.componentRef.instance.visibleCallManagement = true;
      this.generalService.setPeerUserInfo(this.selectedItemType
        , this.chatSelectedId ? this.chatSelectedId : this.contactSelectedId);
    }

    this.componentRef.instance.activeCalls = this.activeCalls;

    this.rootViewContainer.insert(this.componentRef.hostView).detectChanges();
    this.componentRef.instance.closeModal.subscribe(() => this.componentRef.destroy());


    console.log(typeof this.rootViewContainer.get(this.rootViewContainer.length - 1));


  }

  private getCallHistoryModel(sessionId: string): CallHistoryModel {
    let session: Session = this.sipService.getSession(sessionId);
    return {
      id: uuid(),
      displayName: session.remoteIdentity.displayName,
      number: session.remoteIdentity.uri.user as string,
      date: new Date(),
      direction: CallDirectionState.Outbound,
      duration: 0
    };
  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close', {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  openSnackBarForMessage(message: Message): void {
    if (message.body === undefined || message.body === null || message.body.trim() === '') {
      return;
    }
    let displayedMessage = '';
    if (message.body === environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType) {
      displayedMessage = this.contacts.get(message.authorId).displayName + "\n " + 'ATTACHMENT'
    } else if (message.body === environment.systemMessageProperties.prefix + MessageContentTypeEnum.AudioCall
      || message.body === environment.systemMessageProperties.prefix + MessageContentTypeEnum.VideoCall) {
      return;
    } else {
      let splitMessage = {...message.body.split('\n')};
      let messageBody = splitMessage[0];
      // Truncate if the message exceeds 30 characters
      if (messageBody.length > 30) {
        messageBody = messageBody.substring(0, 30) + '...';
      }
      displayedMessage = this.contacts.get(message.authorId).displayName + "\n " + messageBody;
    }

    this._snackBar.open(displayedMessage, 'Close', {
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        panelClass: ["success" + '-snackbar'],
      }
    );
  }


  public async register() {

    const keyCloakUser = await this.keycloakService.loadUserProfile().catch((error) => {
      if (error.status === 500) {
        this.openSnackBar('Server has problem, Please try in another time', 'error');
      } else if (error.status === 400) {
        this.openSnackBar(error.error, 'error');
      } else {
        this.openSnackBar('An error has occurred, Try again later.', 'error');
      }
      this.userService.setLoadingPhoto(false);
    });
    this.prefrenceModel = this.localStorageService.getStoreSipSettings();
    this.prefrenceModel.sipSettings.name = this.defaultUser.displayName;
    this.prefrenceModel.sipSettings.password = keyCloakUser['attributes']['sipPassword'][0];
    this.prefrenceModel.sipSettings.server = this.defaultUser.server;
    this.prefrenceModel.sipSettings.domain = this.defaultUser.domain;
    this.prefrenceModel.sipSettings.username = this.defaultUser.extension;

    try {
      console.log('waiting..');
      this.localStorageService.saveStoreSipSettings(this.prefrenceModel);

      let rtcConfiguration: RTCConfiguration = await Properties.rtcConfiguration();

      let sipOptions: SIPOptions = {
        server: this.prefrenceModel.sipSettings.server,
        domain: this.prefrenceModel.sipSettings.domain,
        username: this.prefrenceModel.sipSettings.username,
        password: this.prefrenceModel.sipSettings.password,
        rtcConfiguration: rtcConfiguration,
        htmlAudioElement: this.audioRemote.nativeElement,
        inputAudioDeviceId: this.prefrenceModel.voiceSettings.output
      };
      this.sipService.getCallEvent().addEventListener(CallEvent.transportState, (e) => {
        let event: CustomEvent = e as CustomEvent;
        if (event.detail.transportState == TransportState.Connected)
          this.sipService.register();

      });
      await this.sipService.connect(sipOptions);
    } catch (e) {
      //this.infoMessages = [{ severity: 'error', summary: 'Error', detail: 'Registration Error' }];
      console.log(e.message);
    }

  }

  updateBehaviorsAndView(message: Message) {
    if (message && message.id === undefined || message.id === null)//dummy message just for notify (being added to group ,...) could be used afterwards for something else
    {
      if (message.body === environment.systemMessageProperties.prefix + environment.systemMessageProperties.refreshGroupListType) {
        this.groupService.updateGroupList(this.defaultUser.id);
      }

      if (message.body === environment.systemMessageProperties.prefix + environment.systemMessageProperties.refreshGroupMembersType) {
        if (this.groupSelected !== undefined && this.groupSelected !== null && this.groupSelected.id === message.roomId) {
          let copyGroup: Group = {...this.groupSelected};
          this.groupService.UpdateGroupMembersList(message.roomId, this.defaultUser.id, copyGroup)
        } else {
          this.groupService.removeMembersFromGroupList(message.roomId, this.defaultUser.id);
        }
      }

      if (message.body.indexOf(environment.systemMessageProperties.prefix + environment.systemMessageProperties.deleteMessageType) > -1) {
        this.handleDeleteMessageBehaviors(message)
      }

      return;

      //update member list inside one group (delete from behavior the members ,to call backend next time)

    }
    if (this.chatService.isMessageChatRelatedForMap(message, this.defaultUser.id)) //look if Chat Tab wasn't opened yet then call for chatMap and look in them , if there is Chats then look up inside them
    {
      this.handleChatBehaviors(message);
    } else if (this.groupService.isMessageGroupRelated(message, this.defaultUser.id)) {
      this.handleGroupBehaviors(message);
    } else {
      this.chatService.updateChatMap(this.defaultUser.id);
      this.groupService.updateGroupList(this.defaultUser.id);
      this.openSnackBarForMessage(message);
    }

    this.generalService.scrollToBottomAtChatConversation();

  }


  private handleChatBehaviors(message: Message) {

    //If we Got here , then for sure we are related to an existing Chat , there would be old messages or not , the chat could be selected or not
    if (this.selectedItemType === SelectedItemEnum.CHAT && message.roomId === this.chatSelectedId) {
      // if the chat is selected then for sure we have old messages

      this.messageService.addOneMessageToCurrentConversationMessages(message);
      this.chatService.addOneMessageToChatMap(message.roomId, message, true, this.defaultUser.id);
      this.messageService.markChatMessagesAsRead(this.defaultUser.id, this.chatSelectedId).subscribe({
        next: () => {
          this.messageService.markAllMessagesAsRead(this.chatSelectedId, this.defaultUser.id);
        },
        error: () => {
          console.log('Cannot mark messages as Read');
        }
      });

    } else {
      this.openSnackBarForMessage(message);
      if (message.body === environment.systemMessageProperties.prefix + MessageContentTypeEnum.AudioCall
        || message.body === environment.systemMessageProperties.prefix + MessageContentTypeEnum.VideoCall) {//if it's a call , consider the chat as selected so no unread count to be added
        this.chatService.addOneMessageToChatMap(message.roomId, message, true, this.defaultUser.id);
      } else {
        this.chatService.addOneMessageToChatMap(message.roomId, message, false, this.defaultUser.id);
      }
    }

  }

  private handleGroupBehaviors(message: Message) {
    if (this.selectedItemType === SelectedItemEnum.GROUP && message.roomId == this.groupSelected.id) {
      // update selectedChatMessages
      this.messageService.addOneMessageToCurrentConversationMessages(message);

      //set GroupUser Behavior with new messages
      this.groupService.addOneMessageToUserGroupsNew(message.roomId, message, true, this.defaultUser.id);

      this.markAllMessagesAsReadForGroup(this.groupSelected);
    } else {
      this.openSnackBarForMessage(message);

      //set GroupUser Behavior with new messages
      this.groupService.addOneMessageToUserGroupsNew(message.roomId, message, false, this.defaultUser.id);
    }
  }


  private getContacts() {
    if (this.contacts === null || this.contacts.size === 0) {
      this.userService.getContacts().subscribe({
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred in getting Contacts, Try again later.', 'error');
          }
        }
      });
    }
  }


  private markAllMessagesAsReadForGroup(selectedGroup: Group) {
    this.messageService.markChatMessagesAsRead(this.defaultUser.id, selectedGroup.id).subscribe({
      next: () => {
        this.messageService.markAllMessagesAsRead(selectedGroup.id, this.defaultUser.id);
      },
      error: () => {
        console.log('Cannot mark messages as Read');
      }
    })
  }


  private handleDeleteMessageBehaviors(message: Message) {
    /*
     "##$$_system_$$##type:deleteMessage__##$$chat$$##__545454545__##$$message$$##__4545545[__##$$newMessage$$##__46466]"
     "##$$_system_$$##type:deleteMessage__##$$group$$##__545454545__##$$message$$##__4545545[__##$$newMessage$$##__46466]"
     */
    let body: string = message.body;
    let envGroupIdPart: string = environment.systemMessageProperties.groupIdPart;
    let envChatIdPart: string = environment.systemMessageProperties.chatIdPart;
    let envMessageIdPart: string = environment.systemMessageProperties.messageIdPart;
    let envNewMessageIdPart: string = environment.systemMessageProperties.newMessageIdPart;

    if (body.indexOf(envGroupIdPart) > -1) { //If Message is in Group
      let groupId = body.split(envGroupIdPart)[1].split(envMessageIdPart)[0];
      let messageId = body.split(envGroupIdPart)[1].split(envMessageIdPart)[1];
      let newMessageId = null;

      if (messageId.indexOf(envNewMessageIdPart) > -1) {
        let tempString = messageId;
        messageId = tempString.split(envNewMessageIdPart)[0];
        newMessageId = tempString.split(envNewMessageIdPart)[1];
      }
      let messageToBeDeleted: Message = this.messageService.getMessageFromAllConversationMessages(groupId, messageId);
      let newLastMessage: Message = this.messageService.getMessageFromAllConversationMessages(groupId, newMessageId);

      if (messageToBeDeleted === null)//if we didn't load yet the messages of this conversation
      {
        this.groupService.updateGroupList(this.defaultUser.id);//then just update the list will be enough
      } else {//if we found the message means we need to delete it

        if (this.selectedItemType === SelectedItemEnum.GROUP && this.groupSelected.id === groupId) {//if the selected conversation is the one the peer deleted from
          this.messageService.removeOneMessageFromCurrentConversationMessages(messageToBeDeleted)
        }

        this.messageService.removeOneMessageFromAllConversationMessages(messageToBeDeleted);
        this.groupService.changeLastMessageIfMatch(messageToBeDeleted, newLastMessage, this.defaultUser.id);

      }

    } else { //If Message is in Chat
      let chatId = body.split(envChatIdPart)[1].split(envMessageIdPart)[0];
      let messageId = body.split(envChatIdPart)[1].split(envMessageIdPart)[1];
      let newMessageId = null;

      if (messageId.indexOf(envNewMessageIdPart) > -1) {
        let tempString = messageId;
        messageId = tempString.split(envNewMessageIdPart)[0];
        newMessageId = tempString.split(envNewMessageIdPart)[1];
      }
      let messageToBeDeleted: Message = this.messageService.getMessageFromAllConversationMessages(chatId, messageId)
      let newLastMessage: Message = this.messageService.getMessageFromAllConversationMessages(chatId, newMessageId)

      if (messageToBeDeleted === null)//if we didn't load yet the messages of this conversation
      {
        this.chatService.updateChatMap(this.defaultUser.id);//then just update the list will be enough
      } else {//if we found the message means we need to delete it
        if (this.selectedItemType === SelectedItemEnum.CHAT && this.chatSelectedId === chatId) {//if the selected conversation is the one the peer deleted from
          this.messageService.removeOneMessageFromCurrentConversationMessages(messageToBeDeleted)
        }

        this.messageService.removeOneMessageFromAllConversationMessages(messageToBeDeleted);
        this.chatService.changeLastMessageIfMatch(messageToBeDeleted, newLastMessage, this.defaultUser.id);
      }
    }

  }
}
