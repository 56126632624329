import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';

import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';

import {LightboxModule} from 'ngx-lightbox';

import {TabsModule} from './tabs/tabs.module';

import {ChatRoutingModule} from './chat-routing.module';

// Emoji Picker
import {PickerModule} from '@ctrl/ngx-emoji-mart';


import {IndexComponent} from './index/index.component';
import {MainMenuComponent} from './main-menu/main-menu.component';
import {LeftSidebarComponent} from './left-sidebar/left-sidebar.component';
import {UserChatComponent} from './user-chat/user-chat.component';
import {WelcomeSectionComponent} from './welcome-section/welcome-section.component';
import {UserChatHeadComponent} from './user-chat/user-chat-head/user-chat-head.component';
import {UserChatInputComponent} from './user-chat/user-chat-input/user-chat-input.component';
import {UserChatConversationComponent} from './user-chat/user-chat-conversation/user-chat-conversation.component';
import {UserCallManagementComponent} from './user-chat/user-call-management/user-call-management.component';
import {MinimizedCallComponent} from './minimized-call/minimized-call.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ReversePipe} from './chatCustomPipe/reverse.pipe';
import {NewUserComponent} from "./tabs/new-user/new-user.component";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {DeleteModalComponent} from './delete-modal/delete-modal.component';
import {
  UserVideoCallManagementComponent
} from './user-chat/user-video-call-management/user-video-call-management.component';
import {UserSearchMessagesComponent} from './user-chat/user-search-messages/user-search-messages.component';
import {MessageDatePipe} from "./user-chat/customPipe/message-date.pipe";

@NgModule({
  declarations: [IndexComponent, MainMenuComponent, LeftSidebarComponent, UserChatComponent, WelcomeSectionComponent, UserChatHeadComponent, UserChatInputComponent, UserChatConversationComponent, UserCallManagementComponent, MinimizedCallComponent, ReversePipe, NewUserComponent, DeleteModalComponent, UserVideoCallManagementComponent, UserSearchMessagesComponent, MessageDatePipe],
  imports: [
    PerfectScrollbarModule,
    LightboxModule,
    NgbAccordionModule,
    NgbModalModule,
    NgbCollapseModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ChatRoutingModule,
    TabsModule,
    NgbTooltipModule,
    NgbDropdownModule,
    PickerModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule
  ],
  providers: [
    DatePipe
  ],
  exports: []
})
export class ChatModule {
}
