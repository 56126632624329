import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {
  NgbAccordionModule,
  NgbCollapseModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {CarouselModule} from 'ngx-owl-carousel-o';

import {ContactsComponent} from './contacts/contacts.component';
import {SettingsComponent} from './settings/settings.component';
import {FormsModule} from '@angular/forms';
import {GroupsComponent} from './groups/groups.component';
import {ChatListComponent} from "./chat-list/chat-list.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {GroupSideInfoComponent} from './groups/group-side-info/group-side-info.component';
import {ChatSideInfoComponent} from './chat-list/chat-side-info/chat-side-info.component';
import {GroupCreationComponent} from './groups/group-creation/group-creation.component';
import {MessageDatePipe} from "./customPipe/message-date.pipe";
import {ContactsSideInfoComponent} from "./contacts/contacts-side-info/contacts-side-info.component";
import {DragDropFileUploadDirective} from "../drag-drop-file-upload.directive";
import {DialerComponent} from './dialer/dialer.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [DragDropFileUploadDirective, ContactsComponent, SettingsComponent, GroupsComponent, GroupCreationComponent, ChatListComponent, GroupSideInfoComponent, ChatSideInfoComponent, ContactsSideInfoComponent, GroupCreationComponent, MessageDatePipe, DialerComponent],
  imports: [
    CarouselModule,
    CommonModule,
    NgbDropdownModule,
    NgbAccordionModule,
    PerfectScrollbarModule,
    NgbTooltipModule,
    NgbModalModule,
    NgbCollapseModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule
  ],
  exports: [DragDropFileUploadDirective, ContactsComponent, SettingsComponent, GroupsComponent, GroupCreationComponent, ChatListComponent, GroupSideInfoComponent, ChatSideInfoComponent, ContactsSideInfoComponent, DialerComponent],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ]
})
export class TabsModule {
}
