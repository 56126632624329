<div class="p-3 p-lg-4 border-top mb-0 send-msg">
  <form (ngSubmit)="messageSave()" [formGroup]="formData" id="chatinput-form" enctype="multipart/form-data">


    <div class="row no-gutters">
      <div class="col">
        <div>
          <textarea id="textMessageInput"
                    class="textarea-like-input form-control form-control-lg bg-light border-light custom-scrollbar"
                    placeholder="Enter Message..." formControlName="message" [(ngModel)]="emoji"
                    (keydown)="handleKeyDown($event)" (keydown.enter)="$event.preventDefault();sendMessage()"
                    cdkTextareaAutosize cdkAutosizeMaxRows="2"></textarea>
        </div>
      </div>
      <div class="col-auto">
        <div class="chat-input-links ms-md-2">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <button type="button" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect"
                      data-toggle="tooltip" data-placement="top" ngbTooltip="Emoji" (click)="toggleEmojiPicker()"
                      id="emoji-btn">
                <i class="ri-emotion-happy-line"></i>
              </button>
              <emoji-mart class="emoji-mart" *ngIf="showEmojiPicker" (emojiSelect)="addEmoji($event)"
                          title="Pick your emoji…"></emoji-mart>
            </li>
            <li class="list-inline-item">
              <label id="files" class="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                <i class="ri-attachment-line"></i>
                <input #imageFileInput
                       (change)="uploadFile(imageFileInput.files,$event)"
                       [accept]="attachmentsExtensions"
                       [multiple]="true"
                       id="image-file-input"
                       type="file"
                       [disabled]="sendingMessage"
                       hidden>

              </label>
            </li>
            <li class="list-inline-item">
              <button type="submit" (click)="sendMessage()"
                      [disabled]="!isSipConnected || !isInternetConnected|| sendingMessage"
                      class="btn btn-primary font-size-16 btn-lg chat-send waves-effect waves-light">
                <i class="ri-send-plane-2-fill"></i>
              </button>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </form>
</div>
<!-- end chat input section -->

<div class="position-relative">
  <!--reply part-->
  <div _ngcontent-hes-c189="" class="replyCard">
    <div _ngcontent-hes-c189="" class="card mb-0">
      <div _ngcontent-hes-c189="" class="card-body right py-3">
        <div _ngcontent-hes-c189="" class="replymessage-block mb-0 d-flex align-items-start">
          <div _ngcontent-hes-c189="" class="flex-grow-1">
            <h5 _ngcontent-hes-c189="" class="conversation-name">You</h5>
            <p _ngcontent-hes-c189="" class="mb-0">Wow that's great</p>
          </div>
          <div _ngcontent-hes-c189="" class="flex-shrink-0">
            <button _ngcontent-hes-c189="" type="button" id="close_toggle"
                    class="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none" (click)="closeReply()">
              <i _ngcontent-xeu-c127="" class="ri-close-line float-end fs-5"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--attachment part-->
  <div _ngcontent-hes-c189="" class="attachmentCard">
    <div _ngcontent-hes-c189="" class="card mb-0">
      <div _ngcontent-hes-c189="" class="card-body py-3">
        <div _ngcontent-hes-c189="" class="attachmentmessage-block mb-0 d-flex align-items-start">

          <perfect-scrollbar>
            <div _ngcontent-hes-c189="" class="flex-grow-1" style="max-height: 300px;">
              <!-- Attachments -->
              <div *ngIf="attachmentList && attachmentList.length > 0">
                <ng-container *ngFor="let attachment of attachmentList">
                  <div class="card p-2 border mb-2">
                    <div class="d-flex align-items-center">
                      <div class="avatar-sm me-3 ms-0">
                        <div *ngIf="!sendingMessage"
                             class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                          <i class="ri-image-fill"></i>
                        </div>
                        <div *ngIf="sendingMessage"
                             class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                          <i class="ri-loader-line rotate-icon"></i>
                        </div>
                      </div>
                      <div class="flex-grow-1">
                        <div class="text-start">
                          <h5 class="font-size-14 mb-1 text-truncate" style="max-width: 200px;">{{attachment.name}}</h5>
                          <!--                      <p class="text-muted font-size-13 mb-0">{{attachment.contentType}}</p>-->
                        </div>
                      </div>

                      <div class="me-1 ms-0" *ngIf="!sendingMessage">
                        <a (click)="removeImage(attachment)"><i class="ri-delete-bin-line float-end text-muted"></i></a>
                      </div>
                      <!--                  <div class="me-1 ms-0" *ngIf="sendingMessage">-->
                      <!--                    <a (click)="stopSending(attachment)"><i class="ri-close-circle-line float-end text-muted"></i></a>-->
                      <!--                  </div>-->
                    </div>

                  </div>

                </ng-container>
              </div>


            </div>
          </perfect-scrollbar>
          <div _ngcontent-hes-c189="" class="flex-shrink-0">
            <button _ngcontent-hes-c189="" type="button" [disabled]="sendingMessage"
                    class="btn btn-sm btn-link mt-n2 me-n3 fs-18 shadow-none" (click)="closeAttachmentCard()">
              <i _ngcontent-xeu-c127="" class="ri-close-line float-end fs-5"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
