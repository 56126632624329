import {Component, NgZone} from '@angular/core';
import {NullValidationHandler, OAuthService} from "angular-oauth2-oidc";
import {environment} from "../environments/environment";
import {Params, Router} from "@angular/router";
import {App, BackButtonListenerEvent, URLOpenListenerEvent} from "@capacitor/app";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private oauthService: OAuthService,
              private router: Router,
              private zone: NgZone) {
    this.configOAuthService();
    App.addListener('backButton', (event: BackButtonListenerEvent) => {
      console.log("backButton is pressed");
      App.exitApp();
    });
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      let url = new URL(event.url);
      const queryParams: Params = {};
      url.searchParams.forEach((value, key, parent) => {
        queryParams[key] = value;
      });

      this.zone.run(() => {
        this.router.navigate(
          ['login'],
          {
            // relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
          })
          .then(navigateResult => {
            this.oauthService.loadDiscoveryDocumentAndTryLogin();
          })
          .catch(error => console.error(error));
      });
    });
  }

  private configOAuthService() {
    this.oauthService.configure({
      issuer: environment.keyCloakConfig.issuer,
      redirectUri: environment.keyCloakConfig.redirectUri,
      clientId: environment.keyCloakConfig.clientId,
      responseType: 'code',
      scope: 'openid profile email offline_access',
      revocationEndpoint: environment.keyCloakConfig.issuer + "/protocol/openid-connect/revoke",
      requireHttps: false,
      showDebugInformation: true,
      strictDiscoveryDocumentValidation: false,
      skipIssuerCheck: true,
      logoutUrl: environment.keyCloakConfig.logoutUri
    });

    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService.setStorage(localStorage);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
}
