import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {properties} from "../../properties/urlProperties";
import {KeycloakUser} from "../model/user.model";
import {Contact} from "../model/contact.model";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _userUrl: string = properties.deyalaSecureURL + '/user';
  private _displayName: BehaviorSubject<string | null> = new BehaviorSubject(null);
  private _userImage: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _contacts: BehaviorSubject<Contact[] | null> = new BehaviorSubject(null);
  private _loadingPhoto: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient
  ) {
  }


  get displayName$(): Observable<string> {
    return this._displayName.asObservable();
  }

  setDisplayName(displayName: string): void {
    this._displayName.next(displayName);
  }

  get loadingPhoto$(): Observable<boolean> {
    return this._loadingPhoto.asObservable();
  }

  setLoadingPhoto(value: boolean): void {
    this._loadingPhoto.next(value);
  }

  createNewUser(keycloakUser: KeycloakUser): Observable<any> {
    return this._httpClient.post(this._userUrl + "/createNewUser", keycloakUser);
  }


  getDefaultUser(): Observable<any> {
    //debugger;
    return this._httpClient.get(this._userUrl + '/getDefaultUser');
  }

  getContacts(): Observable<any> {
    return this._httpClient.get(this._userUrl + '/getContacts')
      .pipe(
        tap((response) => {
          this._contacts.next(response);
        })
      );
  }

  get contacts$(): Observable<any> {
    return this._contacts.asObservable();
  }

  setContacts(contacts: Contact[]): void {
    this._contacts.next(contacts);
  }


  updateDisplayName(displayName: string): Observable<void> {
    const parameters = {
      'displayName': displayName
    };
    return this._httpClient.put<void>(this._userUrl + '/updateDisplayName',
      null,
      {
        params: parameters
      }).pipe(tap(() => {
      this._displayName.next(displayName);
    }));
  }

  getDefaultUserImage(profileImgId: string): Observable<any> {
    return this._httpClient.get(this._userUrl + '/getProfileImage', {
      params: {'profileImgId': profileImgId}
    })
      .pipe(
        tap((response) => {
          this._userImage.next(response.imageData);
        })
      );
  }

  getUserImage(profileImgId: string): Observable<any> {
    return this._httpClient.get(this._userUrl + '/getProfileImage', {
      params: {'profileImgId': profileImgId}
    });
  }

  get userImage$(): Observable<any> {
    return this._userImage.asObservable();
  }

  setUserImage(profileImgId: any): void {
    this._userImage.next(profileImgId);
  }

  uploadUserImage(photo: File): Observable<any> {
    const formData = new FormData();
    formData.append('profileImage', photo);
    return this._httpClient.put(this._userUrl + '/updateProfileImage', formData, {
      responseType: 'text'
    }).pipe(
      tap(() => {
        const blob = new Blob([photo]);
        blob.arrayBuffer().then((response) => {
          this._userImage.next(response);
        });
      })
    );
  }

  deleteUserImage(): Observable<any> {
    return this._httpClient.delete(this._userUrl + '/deleteUserProfileImage').pipe(
      tap(() => {
        this._userImage.next(null);
      })
    );
  }

  deleteUser(username: string, userId: string): Observable<any> {
    const parameters = {
      'username': username,
      'userId': userId
    };
    return this._httpClient.delete(this._userUrl + '/deleteUser', {
        params: parameters
      }
    ).pipe(tap(() => {
        let contactList = this._contacts.getValue();
        contactList.filter((contact, index) => {
          if (contact.id !== userId) {
            contactList.splice(index, 1);
          }
        });
        this._contacts.next(contactList);
      })
    );
  }

  updateContactBehavior(value: Contact) {
    const index = this._contacts.getValue().findIndex(obj => {
      return obj.id === value.id;
    });
    let tempContactList: Contact[] = [...this._contacts.getValue()];
    tempContactList[index] = value;
    this.setContacts(tempContactList);


  }

  updateContactImgSrc(contactId: String, imgSrc: any) {
    const index = this._contacts.getValue().findIndex(obj => {
      return obj.id === contactId;
    });
    let tempContactList: Contact[] = [...this._contacts.getValue()];
    if (index < 0) {
      return;
    }
    tempContactList[index].profileImgSrc = imgSrc;
    this.setContacts(tempContactList);


  }


  getContactImgSrcIfExist(contactId: String) {
    const contact = this._contacts.getValue().find(obj => {
      return obj.id === contactId;
    });
    if (contact !== undefined && contact !== null && contact.profileImgSrc !== undefined && contact.profileImgSrc !== null) {
      return contact.profileImgSrc;
    }

  }

  getContactImgIdIfExist(contactId: String) {
    const contact = this._contacts.getValue().find(obj => {
      return obj.id === contactId;
    });
    if (contact !== undefined && contact !== null && contact.profileImgId !== undefined && contact.profileImgId !== null) {
      return contact.profileImgId;
    }

  }


  updateContactImgProfileAndImgSrc(contactId: String, profileImgId: string, imgSrc: any) {
    const index = this._contacts.getValue().findIndex(obj => {
      return obj.id === contactId;
    });
    let tempContactList: Contact[] = [...this._contacts.getValue()];
    tempContactList[index].profileImgSrc = imgSrc;
    tempContactList[index].profileImgId = profileImgId;
    this.setContacts(tempContactList);


  }

  updateContactName(contactId: String, newName: any) {
    const index = this._contacts.getValue().findIndex(obj => {
      return obj.id === contactId;
    });
    let tempContactList: Contact[] = [...this._contacts.getValue()];
    tempContactList[index].displayName = newName;
    this.setContacts(tempContactList);


  }

  getContactByUserId(selectedContactId: string) {
    const index = this._contacts.getValue().findIndex(obj => {
      return obj.id === selectedContactId;
    });
    if (index < 0) return null;
    else {
      return this._contacts.getValue()[index];
    }
  }

  getContactByExtension(extension: string) {
    const index = this._contacts.getValue().findIndex(obj => {
      return obj.extension === extension;
    });
    if (index < 0) return null;
    else {
      return this._contacts.getValue()[index];
    }
  }
}
