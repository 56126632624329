<!-- Start add group Modal -->

<div class="modal-content">
  <!-- Loader -->
  <div
    *ngIf="createGroupModalLoading"
    class="fixed inset-x-0 top-0 z-50">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  </div>
  <div class="modal-header">
    <h5 class="modal-title font-size-16" id="addgroup-exampleModalLabel">Create New Group</h5>
    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"
            (click)="closeModal()">
    </button>
  </div>
  <div class="modal-body p-4">
    <form>

      <div class="text-center">
        <div class="mb-4 profile-user text-center">

          <mat-progress-spinner
            *ngIf="isAvatarLoading"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>

          <div *ngIf="!isAvatarLoading">
            <img *ngIf="groupCreationImage" [src]="groupCreationImage" class="rounded-circle avatar-lg img-thumbnail"
                 alt="">

            <div *ngIf="!groupCreationImage" class="rounded-circle avatar-lg img-thumbnail">
            <span class="avatar-title rounded-circle bg-soft-primary text-primary text-center font-size-14">
              Add Group Image
            </span>
            </div>
          </div>

          <!--edit photo-->
          <input id="profile-img-file-input" class="profile-img-file-input d-none"
                 (click)="compressAndUploadFile()">
          <label *ngIf="!isAvatarLoading" for="profile-img-file-input" class="profile-photo-edit avatar-xs">
        <span class="avatar-title rounded-circle bg-light text-body">
          <i class="ri-pencil-fill"></i>
        </span>
          </label>

        </div>
      </div>

      <div class="mb-4">
        <label class="form-label" for="addgroupname-input">Group Name</label>
        <input name="groupName" type="text" [(ngModel)]="groupCreationName" class="form-control" id="addgroupname-input"
               placeholder="Enter Group Name">
      </div>
      <div class="mb-4">
        <label class="form-label">Group Members</label>
        <div class="mb-3">
          <button class="btn btn-light btn-sm" type="button" data-toggle="collapse"
                  (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
                  aria-controls="collapseExample">Select Members
          </button>
        </div>

        <div class="collapse" id="groupmembercollapse" #collapse="ngbCollapse"
             [(ngbCollapse)]="isCollapsed">
          <div class="card border">

            <div class="search-box chat-search-box border-bottom">
              <div class="input-group  input-group-lg rounded-3">
                <div class="input-group-prepend">
                  <button class="btn btn-link text-decoration-none text-muted pr-1" type="button">
                    <i class="ri-search-line search-icon font-size-18"></i>
                  </button>
                </div>
                <input type="text" class="form-control " name="contactSearchKey"
                       placeholder="Search users..."
                       id="searchUsers" [(ngModel)]="searchKey"
                       (keyup)="searchContacts()">
              </div>
            </div>

            <div class="card-body p-2">
              <perfect-scrollbar data-simplebar style="height: 150px;">

                <div *ngFor="let item of groupedContacts; let index = index">
                  <div class="p-3 font-weight-bold text-primary">
                    {{item.key}}
                  </div>
                  <ul class="list-unstyled contact-list">
                    <li *ngFor="let contactItem of item.filteredContacts; let i = index">
                      <div class="form-check">
                        <input [id]="'memberCheck' + index + i" type="checkbox"
                               class="form-check-input form-check-input"
                               (change)="checkMemberChanged($event , contactItem)" [checked]="contactItem.checked"
                        >
                        <label [for]="'memberCheck' + index + i"
                               class="form-check-label">{{contactItem.displayName}}</label>
                      </div>
                    </li>
                  </ul>
                </div>

              </perfect-scrollbar>
            </div>

          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label"
               for="addgroupdescription-input">Description</label>
        <textarea name="groupDesc" [(ngModel)]="groupCreationDescription" class="form-control"
                  id="addgroupdescription-input" rows="3"
                  placeholder="Enter description"></textarea>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-link" data-dismiss="modal"
            (click)="closeModal()">Close
    </button>
    <button [disabled]="createGroupModalLoading" type="button" class="btn btn-primary" (click)="CreateNewGroup()">Create
      Group
    </button>
  </div>
</div>

<!-- End add group Modal -->
