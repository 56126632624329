import {Component, OnDestroy, OnInit} from '@angular/core';
import {Contact} from "../../../../model/contact.model";
import {GroupCreation} from "../../../../model/group.model";
import {GeneralService} from "../../../../services/general-service";
import {GroupService} from "../../../../services/group-service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UserService} from "../../../../services/user-service";
import {User} from "../../../../model/user.model";
import {DataUrl, NgxImageCompressService, UploadResponse} from "ngx-image-compress";
import {environment} from "../../../../../environments/environment";
import {ImageUtil} from '../../../../../utils/image-util';
import {GroupMember} from "../../../../model/groupMember.model";
import {SIPService} from "../../../../sip/services/sip-service";
import {MessageContentTypeEnum} from "../../../../model/message-type";
import {Message} from "../../../../model/message.model";

@Component({
  selector: 'app-group-creation',
  templateUrl: './group-creation.component.html',
  styleUrls: ['./group-creation.component.scss']
})
export class GroupCreationComponent implements OnInit, OnDestroy {

  defaultUser: User = null;
  //TODO: Fadi remove contacts and use contactMap.
  contacts: Contact[] = null;
  contactMap: Map<string, Contact> = new Map<string, Contact>();
  createGroupModalLoading: any;
  groupCreationName: string = '';
  groupCreationImage: any;
  isCollapsed = true;
  searchKey: string = '';
  filteredContacts: Contact[] = null;
  groupedContacts: any;
  groupCreationDescription: string = '';
  groupCreationMembers: GroupMember[] = [];
  isAvatarLoading: Boolean = false;
  imageFile: File = null;

  constructor(
    private generalService: GeneralService,
    private groupService: GroupService,
    private modalService: NgbModal,
    private userService: UserService,
    private _snackBar: MatSnackBar,
    private imageCompress: NgxImageCompressService,
    private imageUtil: ImageUtil,
    private sipService: SIPService
  ) {
  }

  ngOnDestroy(): void {
    // remove any checks members in create group modal
    if (this.groupedContacts && this.groupedContacts.length > 0) {
      this.groupedContacts.forEach(contactItem => {
        if (contactItem.filteredContacts) {
          contactItem.filteredContacts.forEach(item => {
            if (item && item.hasOwnProperty('checked') && item.checked) {
              item.checked = false;
            }
          });
        }
      });
    }
  }

  ngOnInit(): void {
    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.generalService.createGroupModalLoading$.subscribe((value) => {
      this.createGroupModalLoading = value;
    });

    this.userService.contacts$.subscribe((response) => {
      if (response !== null) {
        response.forEach(value1 => {
          this.contactMap.set(value1.id, value1);
        });
        this.contacts = response.filter(con => con.id !== this.defaultUser.id);
        const grouped = this.sortAndGroupByLetters(this.contacts);
        this.groupedContacts = Object.keys(grouped).map(key => ({key, filteredContacts: grouped[key]}));
        if (this.groupedContacts && this.groupedContacts.length > 0) {
          this.groupedContacts.forEach(contactItem => {
            if (contactItem.filteredContacts) {
              contactItem.filteredContacts.forEach(item => {
                if (item && item.hasOwnProperty('checked') && item.checked) {
                  item.checked = false;
                }
              });
            }
          });
        }
      }
    });

    this.getContactsToAddNewMembers();
  }

  searchContacts(): void {
    this.filteredContacts = this.contacts.filter(contact =>
      contact.displayName.toLowerCase().includes(this.searchKey.toLowerCase())
    );
    const grouped = this.sortAndGroupByLetters(this.filteredContacts);
    this.groupedContacts = Object.keys(grouped).map(key => ({key, filteredContacts: grouped[key]}));
  }

  checkMemberChanged(event, contactItem: any) {
    contactItem.checked = event.target.checked;
    if (event.target.checked) {
      this.groupCreationMembers.push({
        userId: contactItem.id,
        userName: contactItem.displayName,
        admin: false,
        profileImgId: contactItem.profileImgId,
        profileImgSrc: null
      });
    } else {
      //Remove element from groupCreationMembers
      const index: number = this.groupCreationMembers.findIndex(member => member.userId === contactItem.id);
      if (index !== -1) {
        this.groupCreationMembers.splice(index, 1);
      }
    }
  }

  CreateNewGroup() {
    if (this.groupCreationName.trim() === "") {
      this.openSnackBar("You must add group name", "error");
      return;
    }

    if (this.groupCreationMembers.length === 0) {
      this.openSnackBar("You must select at least 1 contact.", "error");
      return;
    }

    this.generalService.setCreateGroupModalLoading(true);

    let groupCreationUser: any = this.defaultUser;
    this.getUserImage(groupCreationUser);
    this.groupCreationMembers.forEach((value) => {
      this.getUserImage(value)
    })

    let newGroup: GroupCreation = {
      groupName: this.groupCreationName,
      createdDate: new Date(),
      createdBy: this.defaultUser.id,
      description: this.groupCreationDescription,
      groupImgId: null,
      members: this.groupCreationMembers,
      imageFile: this.imageFile
    }
    this.groupService.createNewGroup(newGroup, this.groupCreationImage, groupCreationUser).subscribe(
      {
        next: async () => {
          this.generalService.setCreateGroupModalLoading(false);
          this.clearGroupCreationData();
          this.modalService.dismissAll();
          //Send message to notify all members with the new Group
          let systemMessage: Message = {
            roomId: null,
            authorId: this.defaultUser.id,
            body: environment.systemMessageProperties.prefix + environment.systemMessageProperties.refreshGroupListType,
            messageContentType: MessageContentTypeEnum.System,
          };
          for (const value of newGroup.members) {
            await this.sipService.sendMessage(this.contactMap.get(value.userId).extension,
              JSON.stringify({type: MessageContentTypeEnum.System, message: systemMessage}));
          }

        },
        error: (error) => {
          this.clearGroupCreationData();
          this.modalService.dismissAll();

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setCreateGroupModalLoading(false);
        }
      }
    );
  }

  private getUserImage(value: any) {
    if (value === null || value.profileImgId === undefined || value.profileImgId === null || value.profileImgId.trim() === '') {
      return;
    }
    if (value.profileImgSrc !== undefined && value.profileImgSrc !== null) {
      return;
    }
    this.userService.getUserImage(value.profileImgId).subscribe({
      next: (response) => {
        if (response !== null && response.size !== 0) {
          value.profileImgSrc = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response.imageData);
        }
      }
    });
  }

  private getContactsToAddNewMembers() {
    //Get contacts to add members in Group creation modal
    if (this.contacts === null) {
      this.generalService.setTabsLoading(true);
      this.userService.getContacts().subscribe({
        next: () => {
          this.generalService.setTabsLoading(false);
          this.filteredContacts = this.contacts;
        },
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setTabsLoading(false);
        }
      });
    }
  }

  private sortAndGroupByLetters(usersList: Contact[]): any {
    const sorted = usersList.sort((a, b) => a.displayName > b.displayName ? 1 : -1);

    const grouped = sorted.reduce((groups, contact) => {
      const letter = contact.displayName.charAt(0);
      groups[letter] = groups[letter] || [];
      groups[letter].push(contact);

      return groups;
    }, {});

    return grouped;
  }

  private clearGroupCreationData(): void {
    this.groupCreationName = '';
    this.groupCreationDescription = '';
    this.groupCreationMembers = [];
  }

  private openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  closeModal() {
    this.modalService.dismissAll();

  }

  compressAndUploadFile() {

    this.imageCompress
      .uploadFile()
      .then(({image, orientation, fileName}: UploadResponse) => {
        const imageBlobBeforeCompression = this.dataURItoBlob(image);
        const imageFileBeforeCompression = new File([imageBlobBeforeCompression], fileName);

        const allowedTypes = environment.imageProperties.allowedTypes;
        const fileSizeInKB = Math.round(imageFileBeforeCompression.size / 1024);
        const fileType = this.dataURItoType(image);

        // Return if the file is too large
        if (fileSizeInKB > (environment.imageProperties.maxImageSizeInMB * 1024)) {
          this.openSnackBar('size of image is too large,image should less than ' + environment.imageProperties.maxImageSizeInMB + 'MB..', 'error');
          // this.groupService.setIsAvatarLoading(false);

          return;
        }

        // Return if the file is not allowed
        if (!allowedTypes.includes(fileType)) {
          this.openSnackBar('you must upload an image..', 'error');
          // this.groupService.setIsAvatarLoading(false);


          return;
        }

        this.groupService.setIsAvatarLoading(true);

        this.imageCompress
          .compressFile(image, orientation, 50, 50)
          .then((result: DataUrl) => {

            const imageBlob = this.dataURItoBlob(result);
            this.imageFile = new File([imageBlob], fileName);

            const newSrcImg = result;

            this.groupService.setIsAvatarLoading(false);
            this.groupCreationImage = newSrcImg;

          });
      });

  }

  dataURItoBlob(dataURI): any {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array]);
  }

  dataURItoType(dataURI): any {
    //dataURI = "data:image/png;base64,iVBORw0KG......"
    const data = dataURI.split(',')[0];
    return data.split(";")[0].split(":")[1];
  }

}

