import {Component} from '@angular/core';
import {Message} from "../../../model/message.model";
import {MessageService} from "../../../services/message-service";
import {GeneralService} from "../../../services/general-service";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {Group} from "../../../model/group.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Contact} from "../../../model/contact.model";
import {UserService} from "../../../services/user-service";

@Component({
  selector: 'app-user-search-messages',
  templateUrl: './user-search-messages.component.html',
  styleUrls: ['./user-search-messages.component.scss']
})
export class UserSearchMessagesComponent {


  searchResult: Message[] = [];
  searchKeyword: string = "";
  contacts: Map<string, Contact> = new Map<string, Contact>();

  selectedItem: SelectedItemEnum;
  selectedChatId: string;
  selectedGroup: Group = null;
  progressMessage: string = "Type A keyword and hit Enter";


  constructor(private messageService: MessageService, private generalService: GeneralService, private _snackBar: MatSnackBar, private userService: UserService) {
  }


  ngOnInit(): void {
    this.generalService.itemSelected$.subscribe(value => {
      this.selectedItem = value;
    });

    this.generalService.chatSelectedId$.subscribe(value => {
      if (value !== null) {
        this.selectedChatId = value;
      }
    });
    this.userService.contacts$.subscribe((response) => {
      if (response !== null) {
        let temp: Contact[] = response;
        temp.forEach(value => {
          this.contacts.set(value.id, value);
        });
      }
    });
    this.generalService.groupSelected$.subscribe((value) => {
      this.selectedGroup = value;
    });

  }

  searchMessages() {

    var input: any
    input = document.getElementById("searchMessages") as HTMLAreaElement;


    if (input.value === undefined || input.value === null || input.value.trim() === '') {
      this.openSnackBar('Search Keyword cannot be empty.', 'error');
      return;
    }
    this.searchKeyword = input.value;

    let roomId = null;
    if (this.selectedItem === SelectedItemEnum.GROUP) {
      roomId = this.selectedGroup.id
    } else if (this.selectedItem === SelectedItemEnum.CHAT) {
      roomId = this.selectedChatId
    } else {
      roomId = null;
    }

    if (!roomId) return;

    this.generalService.setSideCanvasLoading(true);
    this.progressMessage = "Searching...";

    this.messageService.searchMessages(roomId, this.searchKeyword).subscribe({
      next: (response: Message[]) => {
        this.searchResult = response
        this.generalService.setSideCanvasLoading(false);

        if (response === null || response.length === 0)
          this.progressMessage = "No Match for the keyword \"" + this.searchKeyword + "\"";


      },
      error: (err) => {

        if (err.status === 400) {
          this.openSnackBar(err.error, 'error');
        }
        if (err.status === 500) {
          this.openSnackBar('Internal server error!', 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }

        this.generalService.setSideCanvasLoading(false);
        this.progressMessage = "Sorry ! Try Again";

      }
    });

  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }
}
