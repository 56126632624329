import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {
  private _activeTab: BehaviorSubject<string | null> = new BehaviorSubject(null);


  constructor() {
  }

  get activeTab$(): Observable<string> {
    return this._activeTab.asObservable();
  }

  setActiveTab(value: string): void {
    this._activeTab.next(value);
  }
}
