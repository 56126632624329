<!-- Start Settings content -->
<div class="settingComponent">
  <div class="px-4 pt-4">
    <h4 class="mb-0">Settings</h4>
  </div>

  <div class="text-center border-bottom p-4">
    <div class="mb-4 profile-user">

      <mat-progress-spinner
        *ngIf="loadingPhoto"
        [diameter]="24"
        [mode]="'indeterminate'"
      ></mat-progress-spinner>

      <img *ngIf="!loadingPhoto" [src]="userImageUrl" class="rounded-circle avatar-lg img-thumbnail" alt=""
           id="user_profile">

      <!--edit photo-->
      <input id="profile-img-file-input" class="profile-img-file-input d-none"
             (click)="compressAndUploadFile()">
      <label *ngIf="!loadingPhoto" for="profile-img-file-input" class="profile-photo-edit avatar-xs">
        <span class="avatar-title rounded-circle bg-light text-body">
          <i class="ri-pencil-fill"></i>
        </span>
      </label>

      <!--delete photo-->
      <label *ngIf="canDeleteImageProfile &&  !loadingPhoto" (click)="fileDelete()"
             class="profile-photo-delete avatar-xs">
        <span class="avatar-title rounded-circle bg-light text-body">
          <i class="ri-delete-bin-line"></i>
        </span>
      </label>

    </div>

    <h5 class="font-size-16 mb-1 text-truncate">{{defaultUser.displayName}}</h5>
    <div class="dropdown d-inline-block mb-1" ngbDropdown>
      <a class="text-muted dropdown-toggle pb-1 d-block" href="javascript: void(0);" role="button"
         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>Available
        <i class="mdi mdi-chevron-down"></i>
      </a>

      <div class="dropdown-menu" ngbDropdownMenu>
        <a class="dropdown-item" href="javascript:void(0);">Available</a>
        <a class="dropdown-item" href="javascript:void(0);">Busy</a>
      </div>
    </div>
  </div>
  <!-- End profile user -->

  <!-- Start User profile description -->
  <perfect-scrollbar class="p-4 user-profile-desc" data-simplebar>
    <ngb-accordion #acc="ngbAccordion" activeIds="perfonal-info" [closeOthers]="true">
      <!-- Personal Info -->
      <ngb-panel cardClass="card shadow-none border mb-2" id="perfonal-info">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">Personal Info</h5>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="float-end">
            <button type="button" class="btn btn-light btn-sm shadow-none" id="user_name_edit"
                    (click)="edit_userName()"><i class="ri-edit-fill me-1 align-middle"></i>Edit
            </button>
          </div>
          <div>
            <!--Name-->
            <p class="text-muted mb-1">Name</p>
            <h5 class="font-size-14" id="user_name">{{defaultUser.displayName}}</h5>
            <div id="edit-user-name" class="visually-hidden d-flex justify-content-between">
              <input type="text" [(ngModel)]="editedName" class="form-control bg-soft-light border-light"
                     maxlength="20"/>
              <div class="float-right">
                <button type="submit" id="receiverSave" (click)="userNameChange()"
                        class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
                  Save
                </button>
              </div>
            </div>

            <!--Extension-->
            <div class="mt-4">
              <p class="text-muted mb-1">Extension</p>
              <h5 class="font-size-14" id="user_extension">{{defaultUser.extension}}</h5>
            </div>
          </div>

          <!--          <div class="mt-4">-->
          <!--            <p class="text-muted mb-1">Email</p>-->
          <!--            <h5 class="font-size-14">abc@123.com</h5>-->
          <!--          </div>-->

          <!--          <div class="mt-4">-->
          <!--            <p class="text-muted mb-1">Time</p>-->
          <!--            <h5 class="font-size-14">11:40 AM</h5>-->
          <!--          </div>-->

          <!--          <div class="mt-4">-->
          <!--            <p class="text-muted mb-1">Location</p>-->
          <!--            <h5 class="font-size-14">California, USA</h5>-->
          <!--          </div>-->
        </ng-template>
      </ngb-panel>
      <!-- Privacy -->
      <!--      <ngb-panel cardClass="card shadow-none border mb-2" id="privacy">-->
      <!--        <ng-template ngbPanelTitle>-->
      <!--          <h5 class="font-size-14 m-0">Privacy</h5>-->
      <!--        </ng-template>-->
      <!--        <ng-template ngbPanelContent>-->
      <!--          <div class="py-3">-->
      <!--            <div class="d-flex align-items-center">-->
      <!--              <div class="flex-grow-1 overflow-hidden">-->
      <!--                <h5 class="font-size-13 mb-0 text-truncate">Profile photo</h5>-->

      <!--              </div>-->
      <!--              <div class="dropdown ms-2 me-0" ngbDropdown>-->
      <!--                <button class="btn btn-light btn-sm dropdown-toggle w-sm" type="button" data-toggle="dropdown"-->
      <!--                        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>Everyone-->
      <!--                  <i class="mdi mdi-chevron-down"></i>-->
      <!--                </button>-->
      <!--                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Everyone</a>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Selected</a>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Nobody</a>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <div class="py-3 border-top">-->
      <!--            <div class="d-flex align-items-center">-->
      <!--              <div class="flex-grow-1 overflow-hidden">-->
      <!--                <h5 class="font-size-13 mb-0 text-truncate">Last seen</h5>-->

      <!--              </div>-->
      <!--              <div class="ms-2 me-0">-->
      <!--                <div class="form-check form-switch">-->
      <!--                  <input type="checkbox" class="form-check-input" id="privacy-lastseenSwitch" checked="">-->
      <!--                  <label class="form-check-label" for="privacy-lastseenSwitch"></label>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div class="py-3 border-top">-->
      <!--            <div class="d-flex align-items-center">-->
      <!--              <div class="flex-grow-1 overflow-hidden">-->
      <!--                <h5 class="font-size-13 mb-0 text-truncate">Status</h5>-->
      <!--              </div>-->
      <!--              <div class="dropdown ms-2 me-0" ngbDropdown>-->
      <!--                <button class="btn btn-light btn-sm dropdown-toggle w-sm" type="button" data-toggle="dropdown"-->
      <!--                        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>Everyone-->
      <!--                  <i class="mdi mdi-chevron-down"></i>-->
      <!--                </button>-->
      <!--                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Everyone</a>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Selected</a>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Nobody</a>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div class="py-3 border-top">-->
      <!--            <div class="d-flex align-items-center">-->
      <!--              <div class="flex-grow-1 overflow-hidden">-->
      <!--                <h5 class="font-size-13 mb-0 text-truncate">Read receipts</h5>-->

      <!--              </div>-->
      <!--              <div class="ms-2 me-0">-->
      <!--                <div class="form-check form-switch">-->
      <!--                  <input type="checkbox" class="form-check-input" id="privacy-readreceiptSwitch" checked="">-->
      <!--                  <label class="form-check-label" for="privacy-readreceiptSwitch"></label>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->

      <!--          <div class="py-3 border-top">-->
      <!--            <div class="d-flex align-items-center">-->
      <!--              <div class="flex-grow-1 overflow-hidden">-->
      <!--                <h5 class="font-size-13 mb-0 text-truncate">Groups</h5>-->

      <!--              </div>-->
      <!--              <div class="dropdown ms-2 me-0" ngbDropdown>-->
      <!--                <button class="btn btn-light btn-sm dropdown-toggle w-sm" ngbDropdownToggle type="button"-->
      <!--                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Everyone-->
      <!--                  <i class="mdi mdi-chevron-down"></i>-->
      <!--                </button>-->
      <!--                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Everyone</a>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Selected</a>-->
      <!--                  <a class="dropdown-item" href="javascript:void(0);">Nobody</a>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </ng-template>-->
      <!--      </ngb-panel>-->
      <!-- Security -->
      <!--      <ngb-panel cardClass="card shadow-none border mb-2" id="security">-->
      <!--        <ng-template ngbPanelTitle>-->
      <!--          <h5 class="font-size-14 m-0">Security</h5>-->
      <!--        </ng-template>-->
      <!--        <ng-template ngbPanelContent>-->
      <!--          <div>-->
      <!--            <div class="d-flex align-items-center">-->
      <!--              <div class="flex-grow-1 overflow-hidden">-->
      <!--                <h5 class="font-size-13 mb-0 text-truncate">Show security notification</h5>-->
      <!--              </div>-->
      <!--              <div class="ms-2 me-0">-->
      <!--                <div class="form-check form-switch">-->
      <!--                  <input type="checkbox" class="form-check-input" id="security-notificationswitch">-->
      <!--                  <label class="form-check-label" for="security-notificationswitch"></label>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </ng-template>-->
      <!--      </ngb-panel>-->
      <!-- Help -->
      <!--      <ngb-panel cardClass="card shadow-none border mb-2" id="help">-->
      <!--        <ng-template ngbPanelTitle>-->
      <!--          <h5 class="font-size-14 m-0">Help</h5>-->
      <!--        </ng-template>-->
      <!--        <ng-template ngbPanelContent>-->
      <!--          <div>-->
      <!--            <div class="py-3">-->
      <!--              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);" class="text-body d-block">FAQs</a>-->
      <!--              </h5>-->
      <!--            </div>-->
      <!--            <div class="py-3 border-top">-->
      <!--              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);" class="text-body d-block">Contact</a>-->
      <!--              </h5>-->
      <!--            </div>-->
      <!--            <div class="py-3 border-top">-->
      <!--              <h5 class="font-size-13 mb-0"><a href="javascript:void(0);" class="text-body d-block">Terms & Privacy-->
      <!--                policy</a>-->
      <!--              </h5>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </ng-template>-->
      <!--      </ngb-panel>-->
      <!-- Audio Settings -->
      <ngb-panel cardClass="card shadow-none border mb-2" id="audioSettings">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">Audio Settings</h5>
        </ng-template>
        <ng-template ngbPanelContent>

          <!--         Input Audio (Microphones ) drop down-->
          <div class="py-3 border-top">
            <div class="d-flex align-items-center flex-wrap">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-1 text-truncate">Microphones</h5>

              </div>
              <div class="dropdown ms-2 me-0" ngbDropdown>
                <button class="btn btn-light btn-sm dropdown-toggle w-sm text-wrap" ngbDropdownToggle type="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{selectedMic?.label}} <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-start" ngbDropdownMenu>
                  <button *ngFor="let mic of audioInputs" (click)="selectMic(mic)"
                          class="dropdown-item font-size-13 text-wrap">{{mic.label}}</button>
                </div>
              </div>
            </div>
          </div>

          <!--         Output Audio (Speakers ) drop down-->
          <div class="py-3 border-top">
            <div class="d-flex align-items-center flex-wrap">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-1 text-truncate">Speakers</h5>

              </div>
              <div class="dropdown ms-2 me-0" ngbDropdown>
                <button class="btn btn-light btn-sm dropdown-toggle w-sm text-wrap" ngbDropdownToggle type="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{selectedSpeaker?.label}} <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-start" ngbDropdownMenu>
                  <button *ngFor="let speaker of audioOutputs" (click)="selectSpeaker(speaker)"
                          class="dropdown-item font-size-13 text-wrap">{{speaker.label}}</button>
                </div>
              </div>
            </div>
          </div>


          <div>
            <button [disabled]="btnRegisteredDisabled || !isSipConnected" type="submit"
                    (click)="saveVoiceSettings()"
                    class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
              Save
            </button>
          </div>

        </ng-template>


      </ngb-panel>
      <!-- Video Settings -->
      <ngb-panel cardClass="card shadow-none border mb-2" id="videoSettings">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">Video Settings</h5>
        </ng-template>
        <ng-template ngbPanelContent>

          <!--         Input Video (Cams ) drop down-->
          <div class="py-3 border-top">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">Cams</h5>

              </div>
              <div class="dropdown ms-2 me-0" ngbDropdown>
                <button class="btn btn-light btn-sm dropdown-toggle w-sm" ngbDropdownToggle type="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{selectedCam?.label}} <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                  <button *ngFor="let cam of cams" (click)="selectCam(cam)"
                          class="dropdown-item">{{cam.label}}</button>
                </div>
              </div>
            </div>
          </div>


          <div>
            <button [disabled]="btnRegisteredDisabled || !isSipConnected" type="submit"
                    (click)="saveVideoSettings()"
                    class="btn btn-primary btn-block waves-effect waves-light" style="display: block;">
              Save
            </button>
          </div>

        </ng-template>


      </ngb-panel>

      <!-- Search Settings -->
      <ngb-panel cardClass="card shadow-none border mb-2" id="searchSettings">
        <ng-template ngbPanelTitle>
          <h5 class="font-size-14 m-0">Search Settings</h5>
        </ng-template>
        <ng-template ngbPanelContent>

          <!--         Input search drop down-->
          <div class="py-3 border-top">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="font-size-13 mb-0 text-truncate">Search Mode</h5>

              </div>
              <div class="dropdown ms-2 me-0" ngbDropdown>
                <button class="btn btn-light btn-sm dropdown-toggle w-sm" ngbDropdownToggle type="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{selectedSearchMode}} <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                  <button *ngFor="let searchMode of SearchModeEnum | keyvalue"
                          (click)="changeSearchMode(searchMode.value)"
                          class="dropdown-item">{{searchMode.value}}</button>

                </div>
              </div>
            </div>
          </div>

        </ng-template>


      </ngb-panel>


    </ngb-accordion>
    <!-- end profile-setting-accordion -->
  </perfect-scrollbar>
  <!-- End User profile description -->
</div>
<!-- Start Settings content -->


<audio #audioRemote id="audioRemote"></audio>


<!-- Center Modal -->
<ng-template #deletionConfirmationMessage let-modal>

  <div class="modal-body">
    <div class="p-0 card-body">
      <div class="modal--modal-content">
        <div class="text-center p-1">

          <i class=" ri-information-fill " style="color: #EF476F;font-size: 100px"></i>

          <h5 class="text-wrap">Are you sure to delete?</h5>
          <div class="mt-3">
            <ul class="list-inline mb-1">
              <li class="list-inline-item px-2 me-2 ms-0">
                <div class="border-0 modal-footer">
                  <button (click)="modal.dismiss()" class="btn btn-secondary">Cancel</button>
                </div>
              </li>
              <li class="list-inline-item px-2 me-2 ms-0">
                <div class="border-0 modal-footer">
                  <button (click)="fileDelete()" class="btn btn-primary">Delete</button>
                </div>

              </li>
            </ul>
          </div>
        </div>
      </div>


    </div>
  </div>
</ng-template>
