import {URI, UserAgent} from "sip.js";
import {OutgoingRequestMessage} from "sip.js/lib/core";

export class OptionsKeepAlive {

  timeOut: any = null as any;


  outgoingRequestMessage: OutgoingRequestMessage = null as any;

  start(userAgent: UserAgent, requestURI: URI, fromURI: URI, toURI: URI, interval: number) {


    let outgoingRequestMessage = userAgent.userAgentCore.makeOutgoingRequestMessage("OPTIONS", requestURI, fromURI, toURI, {});
    this.timeOut = setInterval(() => {
      if (!userAgent.isConnected()) {
        return;
      }
      let optionsPingRequest = userAgent.userAgentCore.request(outgoingRequestMessage, {
        onAccept: (response) => {
        },
        onReject: (response) => {
          console.log(`${response} `);
        }
      });
    }, interval * 1000);

  }

  stop() {
    clearTimeout(this.timeOut);
  }

}
