import {Component} from '@angular/core';

@Component({
  selector: 'welcome-section',
  templateUrl: './welcome-section.component.html',
  styleUrls: ['./welcome-section.component.scss']
})
export class WelcomeSectionComponent {

}
