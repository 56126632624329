import {Component} from '@angular/core';
import {MainMenuService} from "../../../main-menu/main-menu.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {GroupService} from "../../../../services/group-service";
import {UserService} from "../../../../services/user-service";
import {GeneralService} from "../../../../services/general-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ChatItem} from "../../../../model/chatItem.model";
import {ChatService} from "../../../../services/chat-service";

@Component({
  selector: 'app-chat-side-info',
  templateUrl: './chat-side-info.component.html',
  styleUrls: ['./chat-side-info.component.scss']
})
export class ChatSideInfoComponent {

  chatHeaderName: any;
  // chatHeaderStatus: any;
  chatHeaderAvatar: any;
  chatSelectedId: string
  chatSelected: ChatItem;

  constructor(
    private mainMenuService: MainMenuService,
    private modalService: NgbModal,
    private groupService: GroupService,
    private userService: UserService,
    private chatService: ChatService,
    private generalService: GeneralService,
    private _snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {


    this.generalService.chatHeaderName$.subscribe((value) => {
      this.chatHeaderName = value;
    });
    // this.generalService.chatHeaderStatus$.subscribe((value) => {this.chatHeaderStatus = value; });
    this.generalService.chatHeaderAvatar$.subscribe((value) => {
      this.chatHeaderAvatar = value;
    });
    this.generalService.chatSelectedId$.subscribe((value) => {
      if (value !== null) {
        this.chatSelectedId = value;
        this.chatSelected = this.chatService.getChatItem(this.chatSelectedId);
      }
    });
  }

}
