<div class="p-3 p-lg-4 border-bottom">
  <div class="row align-items-center">
    <div class="col-sm-4 col-8">
      <div class="d-flex align-items-center">
        <div class="d-block d-lg-none me-2 ms-0">
          <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"
             (click)="closeUserChat()"><i class="ri-arrow-left-s-line"></i></a>
        </div>
        <div class="me-3 ms-0">
          <mat-progress-spinner
            *ngIf="isAvatarLoading"
            [diameter]="24"
            [mode]="'indeterminate'"
          ></mat-progress-spinner>
          <div *ngIf="!isAvatarLoading">
            <img *ngIf="chatHeaderAvatar" [src]="chatHeaderAvatar" class="rounded-circle avatar-xs" alt="">
            <div class="avatar-xs" *ngIf="!chatHeaderAvatar">
                    <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                      {{(chatHeaderName).charAt(0)}}
                    </span>
            </div>
          </div>
        </div>
        <div class="flex-grow-1 overflow-hidden">
          <h5 class="font-size-16 mb-0 text-truncate">
            <a href="javascript:void(0);" class="text-reset user-profile-show username"
               (click)="onChatInfoClicked(userInfo)">{{chatHeaderName}} {{isSelfChoosen ? " (YOU)" : ""}}</a>
            <!--Status-->
            <!--            <i class="ri-record-circle-fill font-size-10 d-inline-block ms-1"-->
            <!--               [ngClass]=" {'text-success': chatHeaderStatus === 'online','text-warning': chatHeaderStatus === 'away'}"></i>-->
          </h5>
        </div>
      </div>
    </div>
    <div class="col-sm-8 col-4">
      <ul class="list-inline user-chat-nav text-end mb-0">

        <!--search messages Button selectedSearchMode=ONLY_LOADED_MESSAGES-->
        <li
          *ngIf="selectedSearchMode===SearchModeEnum.ONLY_LOADED_MESSAGES&&selectedItemType!==SelectedItemEnum.CONTACT"
          class="list-inline-item">
          <div class="dropdown" ngbDropdown>
            <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <i class="ri-search-line"></i>
            </button>
            <div class="dropdown-menu p-0 dropdown-menu-right dropdown-menu-md" ngbDropdownMenu>
              <div class="search-box p-2">
                <input type="text" class="form-control bg-light border-0"
                       placeholder="Search.."
                       (keyup)="MessageSearch()" id="searchMessage">
              </div>
            </div>
          </div>
        </li>

        <!--search messages Button selectedSearchMode=ALL_HISTORY-->
        <li *ngIf="selectedSearchMode===SearchModeEnum.ALL_HISTORY&&selectedItemType!==SelectedItemEnum.CONTACT"
            class="list-inline-item">
          <button type="button" class="btn nav-btn user-profile-show" (click)="onSearchMessagesClicked(searchMessages)">
            <i class="ri-search-line"></i>
          </button>
        </li>

        <!--Call button and Call modal-->
        <li class="list-inline-item d-none d-lg-inline-block me-2 ms-0"
            *ngIf="selectedItemType!==SelectedItemEnum.GROUP">
          <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#audiocallModal"
                  (click)="call()" [disabled]="!isSipConnected || isSelfChoosen">
            <i class="ri-phone-line"></i>
          </button>
        </li>


        <!--VideoCall button and VideoCall Modal-->
        <li class="list-inline-item d-none d-lg-inline-block me-2 ms-0"
            *ngIf="selectedItemType!==SelectedItemEnum.GROUP">
          <button type="button" class="btn nav-btn" data-bs-toggle="modal" data-bs-target="#videocallModal"
                  (click)="openVideoManagement()" [disabled]="!isSipConnected || isSelfChoosen">
            <i class="ri-vidicon-line"></i>
          </button>
        </li>

        <!--chat info Button-->
        <li class="list-inline-item d-none d-lg-inline-block">
          <button type="button" class="btn nav-btn user-profile-show" (click)="onChatInfoClicked(userInfo)">
            <i class="ri-user-2-line"></i>
          </button>
        </li>

        <!--three dots -->
        <li class="list-inline-item d-inline-block d-lg-none">
          <div class="dropdown" ngbDropdown>
            <button class="btn nav-btn dropdown-toggle" ngbDropdownToggle type="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
              <i class="ri-more-fill"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a class="dropdown-item d-block d-lg-none user-profile-show" (click)="showUserProfile()"
                 href="javascript: void(0);">View Profile
                <i class="ri-user-2-line float-end text-muted"></i></a>
              <a *ngIf="isSipConnected && selectedItemType!==SelectedItemEnum.GROUP && !isSelfChoosen"
                 class="dropdown-item d-block d-lg-none" href="javascript: void(0);"
                 data-bs-toggle="modal" data-bs-target="#audiocallModal" (click)="call()">
                Call <i class="ri-phone-line float-end text-muted"></i>
              </a>
              <a *ngIf="isSipConnected && selectedItemType!==SelectedItemEnum.GROUP && !isSelfChoosen"
                 class="dropdown-item d-block d-lg-none" href="javascript: void(0);"
                 data-bs-toggle="modal" data-bs-target="#videocallModal" (click)="openVideoManagement()">
                Video Call <i class="ri-vidicon-line float-end text-muted"></i>
              </a>
              <!--              <a class="dropdown-item" href="javascript:void(0);">Archive-->
              <!--                <i class="ri-archive-line float-end text-muted"></i></a>-->
              <!--              <a class="dropdown-item" href="javascript:void(0);">Muted-->
              <!--                <i class="ri-volume-mute-line float-end text-muted"></i></a>-->
              <!--              <a class="dropdown-item" href="javascript:void(0);" (click)="deleteAllMessage()">Delete-->
              <!--                <i class="ri-delete-bin-line float-end text-muted"></i></a>-->
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>
</div>


<!--Modals-->
<!--UnComment for the first Way-->
<!--<ng-template #modalContainer></ng-template>-->


<ng-template #modaltest let-modal>
  <div class="modal-body">
    <div class="text-center p-4">
      <div class="avatar-lg mx-auto mb-4">
        <img [src]="chatHeaderAvatar" alt="" class="img-thumbnail rounded-circle">
      </div>
      <h5 class="text-truncate">{{(chatHeaderName)}}</h5>
      <p class="text-muted">Start Audio Call</p>
      <div class="mt-5">
        <ul class="list-inline mb-1">
          <li class="list-inline-item px-2 me-2 ms-0">
            <button type="button" class="btn btn-danger avatar-sm rounded-circle"
                    data-bs-dismiss="modal" (click)="modal.close('Close click')">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-close-fill"></i>
                              </span>
            </button>
          </li>
          <li class="list-inline-item px-2">
            <!--                            Start call-->
            <button type="button" class="btn btn-success avatar-sm rounded-circle">
                              <span class="avatar-title bg-transparent font-size-20">
                                <i class="ri-phone-fill"></i>
                              </span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-template>

<!-- Chat Info Sidebar -->
<ng-template #userInfo let-offcanvas>
  <div class="offcanvas-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>

  <div *ngIf="selectedItemType==='GROUP'">
    <app-group-side-info></app-group-side-info>
  </div>
  <div *ngIf="selectedItemType==='CHAT'">
    <app-chat-side-info></app-chat-side-info>
  </div>
  <div *ngIf="selectedItemType==='CONTACT'">
    <app-contacts-side-info></app-contacts-side-info>
  </div>
</ng-template>

<!-- Search Messages Sidebar -->
<ng-template #searchMessages let-offcanvas>
  <!-- Loader -->
  <div
    *ngIf="sideCanvasLoading"
    class="fixed inset-x-0 top-0 z-50">
    <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
  </div>
  <div class="offcanvas-header">
    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
  </div>
  <app-user-search-messages></app-user-search-messages>

</ng-template>

<!--&lt;!&ndash;Center Outgoing call modal&ndash;&gt;-->
<!--<ng-template #outgoingCallModal let-modal>-->
<!--  <div class="modal-body">-->
<!--    <div class="text-center p-4">-->
<!--      <div class="avatar-lg mx-auto mb-4">-->
<!--        <img src="{{userProfile}}" alt="" class="img-thumbnail rounded-circle">-->
<!--      </div>-->
<!--      <h5 class="text-truncate">{{(userName)}}</h5>-->
<!--      <p class="text-muted" >{{callStatusMessage}}</p>-->

<!--      <div class="mt-5">-->

<!--        <ul class="list-inline mb-1">-->
<!--          <li class="list-inline-item px-2 me-2 ms-0">-->
<!--            <button type="button" class="btn btn-danger avatar-sm rounded-circle"-->
<!--                    data-bs-dismiss="modal" (click)="hangup()">-->
<!--                              <span class="avatar-title bg-transparent font-size-20">-->
<!--                                <i class="ri-close-fill"></i>-->
<!--                              </span>-->
<!--            </button>-->
<!--          </li>-->

<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->

<!--&lt;!&ndash;Center Incoming call modal&ndash;&gt;-->
<!--<ng-template #incomingCallModal let-modal>-->
<!--  <div class="modal-body">-->
<!--    <div class="text-center p-4">-->
<!--      <div class="avatar-lg mx-auto mb-4">-->
<!--        <img src="{{userProfile}}" alt="" class="img-thumbnail rounded-circle">-->
<!--      </div>-->
<!--      <h5 class="text-truncate">{{(userName)}}</h5>-->
<!--      <p class="text-muted" >{{callStatusMessage}}</p>-->

<!--      <div class="mt-5">-->

<!--        <ul class="list-inline mb-1">-->

<!--          <li class="list-inline-item px-2">-->
<!--            <button *ngIf="!callInProgress"  type="button" class="btn btn-success avatar-sm rounded-circle" (click)="answerIncomingCall()">-->
<!--                              <span class="avatar-title bg-transparent font-size-20">-->
<!--                                <i class="ri-phone-fill"></i>-->
<!--                              </span>-->
<!--            </button>-->
<!--          </li>-->

<!--          <li class="list-inline-item px-2 me-2 ms-0">-->
<!--            <button type="button" class="btn btn-danger avatar-sm rounded-circle"-->
<!--                    data-bs-dismiss="modal" (click)="hangup()">-->
<!--                              <span class="avatar-title bg-transparent font-size-20">-->
<!--                                <i class="ri-close-fill"></i>-->
<!--                              </span>-->
<!--            </button>-->
<!--          </li>-->

<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</ng-template>-->


<audio #incomingRingTone id="incomingRingTone" loop></audio>

<audio #callWaiting id="call-waiting" src="assets/sounds/call-waiting.mp3"></audio>
<audio #wrongNumberTone id="wrongNumberTone" src="assets/sounds/wrongNumber.mp3"></audio>
