<div id="users-chat" class="position-relative" appDragDropFileUpload
     (fileDropped)="fileDroppedHandler($event)">
  <perfect-scrollbar class="chat-conversation p-3 p-lg-4" #scrollRef (psScrollUp)="onScrollTop($event)">

    <div class="d-flex justify-content-center">
      <mat-progress-spinner
        *ngIf="isMessagesLoading"
        [diameter]="24"
        [mode]="'indeterminate'"
      ></mat-progress-spinner>
    </div>

    <ul class="list-unstyled mb-0" id="users-conversation" #getHeight>
      <li class="chats" *ngFor="let messageItem of messageList ;let i=index ; "
          [ngClass]="{'right': messageList[messageList.length-1-i].authorId === defaultUser.id}">

        <div class="chat-day-title"
             *ngIf="!isMessagesLoading && messageList[messageList.length - 1 - i].dateTitle !== null">
          <span class="title">{{messageList[messageList.length - 1 - i].dateTitle}}</span>
        </div>

        <!--        <div id="messageItem{{i}}" class="conversation-list"  style="opacity: 50%">-->
        <div id="messageItem{{i}}" class="conversation-list"
             [ngStyle]="{'opacity': (messageList[messageList.length-1-i].uploading) ? '50%' : '100%'}"
        >
          <div class="chat-avatar" *ngIf="messageList[messageList.length-1-i].authorId !== defaultUser.id">
            <img [src]="contacts.get(messageList[messageList.length-1-i].authorId).profileImgSrc" alt=""
                 *ngIf="contacts.get(messageList[messageList.length-1-i].authorId).profileImgSrc">
            <div class="avatar-xs" *ngIf="!contacts.get(messageList[messageList.length-1-i].authorId).profileImgSrc">
                      <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                        {{contacts.get(messageList[messageList.length - 1 - i].authorId).displayName.charAt(0)}}
                      </span>
            </div>
          </div>
          <div class="chat-avatar" *ngIf="messageList[messageList.length-1-i].authorId === defaultUser.id">
            <img [src]="userImageUrl" alt="">
          </div>

          <div class="user-chat-content me-3">
            <div class="ctext-wrap">
              <div class="ctext-wrap-content"
                   [ngClass]="{'attachment-wrap-content' : (messageList[messageList.length-1-i].messageContentType===MessageContentTypeEnum.Attachment)}">
                <!-- Reply Part above the message-->
                <div class="replymessage-block mb-0 mt-2 d-flex align-items-start"
                     [ngStyle]="messageList[messageList.length - 1 - i].authorId === defaultUser.id ? {'border-left': '2px solid rgb(255, 255, 255)'} : {}"
                     *ngIf="messageList[messageList.length-1-i].messageReplyId&&messageList[messageList.length-1-i].messageReplyBody">
                  <div class="flex-grow-1">
                    <h5 class="conversation-name"
                        [ngClass]="{'text-white': messageList[messageList.length-1-i].authorId === defaultUser.id}">{{contacts.get(messageList[messageList.length - 1 - i].messageReplyAuthorId).displayName}}</h5>
                    <p class="mb-0">{{messageList[messageList.length - 1 - i].messageReplyBody}}</p>
                  </div>
                  <div class="flex-shrink-0">
                    <button type="button" class="btn btn-sm btn-link mt-n2 me-n3 font-size-18">
                    </button>
                  </div>
                </div>
                <!--Message body-->
                <p class="mb-0 messageText pt-2 pb-1 text-start"
                   style="overflow-wrap: anywhere;white-space: pre-line;"
                   [ngClass]="{'pt-3' : (messageList[messageList.length-1-i].forward)}"
                   *ngIf="messageList[messageList.length-1-i].messageContentType===MessageContentTypeEnum.Text">
                  {{messageList[messageList.length - 1 - i].body + "   " + i}}
                  <!-- <span class="animate-typing" *ngIf="data.istyping">
                    <span class="dot ms-1"></span>
                    <span class="dot ms-1"></span>
                    <span class="dot ms-1"></span>
                  </span> -->
                </p>
                <div class="pt-3"
                     *ngIf="messageList[messageList.length-1-i].messageContentType===MessageContentTypeEnum.AudioCall">
                  <div class="card py-2 px-3 mb-2 bg-white">
                    <div class="d-flex flex-row align-items-center">
                      <div
                        class="d-flex justify-content-center align-items-center rounded-circle avatar-xs bg-light me-2">
                        <i class="ri-phone-fill fs-5 text-secondary"></i>
                      </div>
                      <span class="fw-bold">Audio call</span>
                    </div>
                  </div>
                </div>
                <div class="pt-3"
                     *ngIf="messageList[messageList.length-1-i].messageContentType===MessageContentTypeEnum.VideoCall">
                  <div class="card py-2 px-3 mb-2 bg-white">
                    <div class="d-flex flex-row align-items-center">
                      <div
                        class="d-flex justify-content-center align-items-center rounded-circle avatar-xs bg-light me-2">
                        <i class="ri-vidicon-fill fs-5 text-secondary"></i>
                      </div>
                      <span class="fw-bold">Video call</span>
                    </div>
                  </div>
                </div>
                <!--Message ATTACHMENT-->
                <div class="position-absolute top-0 w-100" style="left: 4px;">
                  <div class="d-flex flex-row justify-content-between align-items-center forwardedMessage"
                       [ngClass]="{'text-white-50': messageList[messageList.length-1-i].authorId === defaultUser.id,
                       'text-black-50': messageList[messageList.length-1-i].authorId !== defaultUser.id,
                       'justify-content-between' : (messageList[messageList.length-1-i].forward),
                       'justify-content-end' : (!messageList[messageList.length-1-i].forward)}">
                    <div class="d-flex flex-row justify-content-between align-items-center fw-bolder fst-italic"
                         *ngIf="messageList[messageList.length-1-i].forward" style="font-size: 10px">
                      <i class="ri-share-forward-fill"></i>
                      <span class="ps-1">Forwarded</span>
                    </div>
                    <div class="dropdown" ngbDropdown *ngIf="messageList[messageList.length-1-i].body">
                      <a class="dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle
                         data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="toggleDropDown(i)">
                        <i class="ri-arrow-drop-down-line fs-4"
                           [ngClass]="{'text-white-50': messageList[messageList.length-1-i].authorId === defaultUser.id, 'text-black-50': messageList[messageList.length-1-i].authorId !== defaultUser.id}">
                        </i>
                      </a>
                      <div id="dropdown{{i}}" class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);"
                           (click)="replyMessage($event ,messageList[messageList.length-1-i])">Reply
                          <i class="ri-reply-line float-end text-muted"></i></a>
                        <a class="dropdown-item" href="javascript:void(0);" (click)="copyMessage($event)">Copy
                          <i class="ri-file-copy-line float-end text-muted"></i></a>
                        <!--                    <a class="dropdown-item" href="javascript:void(0);">Save-->
                        <!--                      <i class="ri-save-line float-end text-muted"></i></a>-->
                        <a class="dropdown-item" href="javascript:void(0);" *ngIf=" messageList[messageList.length-1-i].messageContentType!==MessageContentTypeEnum.AudioCall
                  && messageList[messageList.length-1-i].messageContentType!==MessageContentTypeEnum.VideoCall"
                           (click)="openForwardModal(centerDataModal,messageList.length-1-i)">Forward
                          <i class="ri-chat-forward-line float-end text-muted"></i></a>
                        <a class="dropdown-item" href="javascript:void(0);"
                           *ngIf="messageList[messageList.length-1-i].authorId === defaultUser.id"
                           (click)="deleteMessage($event,messageList.length-1-i)">Delete
                          <i class="ri-delete-bin-line float-end text-muted"></i></a>
                      </div>
                    </div>
                  </div>
                </div>


                <div class="pt-4"
                     *ngIf="messageList[messageList.length-1-i].messageContentType===MessageContentTypeEnum.Attachment">
                  <div
                    *ngFor="let attachmentItem of messageList[messageList.length-1-i].messageAttachmentsList;let x=index;">

                    <div class="card p-2 mb-2 cardAttachment" style="background-color: white">
                      <div class="d-flex align-items-center">
                        <div class="avatar-sm me-3 ms-0">
                          <div *ngIf="!messageList[messageList.length-1-i].uploading"
                               class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                            <i class="ri-file-text-fill"></i>
                          </div>
                          <div *ngIf="messageList[messageList.length-1-i].uploading"
                               class="avatar-title bg-soft-primary text-primary rounded font-size-20">
                            <i class="ri-loader-line rotate-icon"></i>
                          </div>
                        </div>
                        <div class="d-flex-body">
                          <div class="text-start">
                            <h5 class="font-size-14 mb-1 text-truncate attachmentTitle">{{attachmentItem.name}}</h5>
                            <!--                        <p class="text-muted font-size-13 mb-0">{{attachmentItem.size}}</p>-->
                          </div>
                        </div>

                        <div class="ms-4 me-1">
                          <ul class="list-inline mb-0 font-size-20">
                            <li class="list-inline-item">
                              <a href="javascript:void(0);" class="text-muted"
                                 *ngIf="!messageList[messageList.length-1-i].uploading"
                                 (click)="downloadMessageAttachment(messageList[messageList.length-1-i].id,attachmentItem)">
                                <i *ngIf="!attachmentItem.downloadInProgress" class="ri-download-2-line"></i>
                                <i *ngIf="attachmentItem.downloadInProgress" class="ri-download-cloud-2-fill"></i>
                              </a>
                              <!--                          <a *ngIf="messageList[messageList.length-1-i].uploading" href="javascript:void(0);" class="text-muted" (click)="stopSending(i,attachmentItem)">-->
                              <!--                            <i class="ri-close-circle-line float-end text-muted"></i>-->
                              <!--                          </a>-->
                            </li>
                            <!--                        <li class="list-inline-item dropdown" ngbDropdown>-->
                            <!--                          <a class="dropdown-toggle text-muted" href="javascript:void(0);" ngbDropdownToggle-->
                            <!--                             role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
                            <!--                            <i class="ri-more-fill"></i>-->
                            <!--                          </a>-->
                            <!--                          <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>-->
                            <!--                            <a class="dropdown-item" href="javascript:void(0);">Share-->
                            <!--                              <i class="ri-share-line float-end text-muted"></i></a>-->
                            <!--                            <a class="dropdown-item" href="javascript:void(0);" (click)="deleteMessage($event,messageList.length-1-i)">Delete-->
                            <!--                              <i class="ri-delete-bin-line float-end text-muted"></i></a>-->
                            <!--                          </div>-->
                            <!--                        </li>-->
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="mb-0 messageText"
                     *ngIf="messageList[messageList.length-1-i].body!==environment.systemMessageProperties.prefix+environment.systemMessageProperties.attachmentType">
                    {{messageList[messageList.length - 1 - i].body + "   " + i}}
                    <!-- <span class="animate-typing" *ngIf="data.istyping">
                      <span class="dot ms-1"></span>
                      <span class="dot ms-1"></span>
                      <span class="dot ms-1"></span>
                    </span> -->
                  </p>
                </div>

                <p class="chat-time mb-0" *ngIf="!false">
                  <i class="ri-time-line align-middle"></i>
                  <span
                    class="align-middle">{{messageList[messageList.length - 1 - i].dateTime |date :'h:mm a' }}</span>
                </p>
              </div>
            </div>

            <!--            <div class="ctext-wrap" *ngIf="true">-->
            <!--              <div class="ctext-wrap-content">-->
            <!--                <p class="mb-0">-->
            <!--                  {{messageList[messageList.length-1-i].body}}-->
            <!--                </p>-->
            <!--                <p class="chat-time mb-0"><i class="ri-time-line align-middle"></i> <span-->
            <!--                  class="align-middle">{{messageList[messageList.length-1-i].dateTime}}</span></p>-->
            <!--              </div>-->
            <!--              <div class="dropdown align-self-start" ngbDropdown>-->
            <!--                <a class="dropdown-toggle" href="javascript:void(0);" role="button" ngbDropdownToggle-->
            <!--                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
            <!--                  <i class="ri-more-2-fill"></i>-->
            <!--                </a>-->
            <!--                <div class="dropdown-menu" ngbDropdownMenu>-->
            <!--                  <a class="dropdown-item" href="javascript:void(0);" (click)="replyMessage($event)">Reply-->
            <!--                    <i class="ri-reply-line float-end text-muted"></i></a>-->
            <!--                  <a class="dropdown-item" href="javascript:void(0);" (click)="copyMessage($event)">Copy-->
            <!--                    <i class="ri-file-copy-line float-end text-muted"></i></a>-->
            <!--                  <a class="dropdown-item" href="javascript:void(0);">Save-->
            <!--                    <i class="ri-save-line float-end text-muted"></i></a>-->
            <!--                  <a class="dropdown-item" href="javascript:void(0);" (click)="openForwardModal(centerDataModal)">Forward-->
            <!--                    <i class="ri-chat-forward-line float-end text-muted"></i></a>-->
            <!--                  <a class="dropdown-item" href="javascript:void(0);" (click)="deleteMessage($event)">Delete-->
            <!--                    <i class="ri-delete-bin-line float-end text-muted"></i></a>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="conversation-name">
              <span
                *ngIf="messageList[messageList.length-1-i].authorId === defaultUser.id">{{defaultUser.displayName}}</span>
              <span
                *ngIf="messageList[messageList.length-1-i].authorId !== defaultUser.id">{{contacts.get(messageList[messageList.length - 1 - i].authorId).displayName}}</span>
            </div>

          </div>
        </div>

        <!--        <div class="chat-day-title" *ngIf="isToday(messageList[messageList.length-1-i].dateTime)">-->
        <!--          <span class="title">Today</span>-->
        <!--        </div>-->


        <!--        <div class="chat-day-title" *ngIf="getTitle(messageList[messageList.length - 1 - i],i,messageList.length - 1 - i) !== null">-->
        <!--        </div>-->
      </li>
    </ul>
  </perfect-scrollbar>
  <div class="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade" id="copyClipBoard"
       role="alert">
    Message copied
  </div>
</div>


<!--Modals-->

<!-- Center Modal -->
<ng-template #centerDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title">Forward to...</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeForwadModal()"></button>
  </div>
  <div class="modal-body">
    <div class="p-2 card-body">
      <div data-simplebar="init" style="max-height: 200px;">
        <div class="simplebar-wrapper" style="margin: 0px;">
          <div class="simplebar-height-auto-observer-wrapper">
            <div class="simplebar-height-auto-observer"></div>
          </div>
          <div class="simplebar-mask">
            <div class="simplebar-offset" style="right: 0px; bottom: 0px;">
              <div class="simplebar-content-wrapper" tabindex="0" role="region" aria-label="scrollable content"
                   style="height: auto; overflow: hidden scroll;">
                <div class="simplebar-content" style="padding: 0px;">

                  <!--private chats -->
                  <div *ngIf="chatMap&&chatMap.size>0" class="p-3 font-weight-bold text-primary">Private Chats</div>
                  <ul class="list-unstyled contact-list">
                    <li *ngFor="let chatItem of chatMap| keyvalue ">
                      <div class="form-check">
                        <input [id]="'chatCheck' + chatItem.key" type="checkbox"
                               class="form-check-input form-check-input"
                               value="{{chatItem.value.peerUserId===defaultUser.id?chatItem.value.peerUserName+' (YOU)':chatItem.value.peerUserName}}"
                               (change)="checkReceiverChanged($event,chatItem.value,SelectedItemEnum.CHAT)">
                        <label [for]="'chatCheck' + chatItem.key"
                               class="form-check-label">{{chatItem.value.peerUserId === defaultUser.id ? chatItem.value.peerUserName + ' (YOU)' : chatItem.value.peerUserName}}</label>
                      </div>
                    </li>
                  </ul>

                  <!--Groups -->
                  <div *ngIf="groupList&&groupList.length>0" class="p-3 font-weight-bold text-primary">Groups</div>
                  <ul class="list-unstyled contact-list">
                    <li *ngFor="let group of groupList; let index= index">
                      <div class="form-check">
                        <input [id]="'groupCheck' + index" type="checkbox" class="form-check-input form-check-input"
                               value="{{group.groupName}}"
                               (change)="checkReceiverChanged($event,group,SelectedItemEnum.GROUP)">
                        <label [for]="'groupCheck' + index" class="form-check-label">{{group.groupName}}</label>
                      </div>
                    </li>
                  </ul>


                  <!--Contacts -->
                  <div *ngIf="groupedContacts&&groupedContacts.length>0" class="p-3 font-weight-bold text-primary">
                    Contacts
                  </div>
                  <div *ngFor="let item of groupedContacts; let index = index">
                    <div class="p-3 font-weight-bold text-primary">{{item.key}}</div>
                    <ul class="list-unstyled contact-list">
                      <li *ngFor="let contactItem of item.contacts; let i = index">
                        <div class="form-check">
                          <input [id]="'memberCheck' + index + i" type="checkbox"
                                 class="form-check-input form-check-input"
                                 value="{{contactItem.id===defaultUser.id?contactItem.displayName+' (YOU)':contactItem.displayName}}"
                                 (change)="checkReceiverChanged($event,contactItem,SelectedItemEnum.CONTACT)">
                          <label [for]="'memberCheck' + index + i"
                                 class="form-check-label">{{contactItem.id === defaultUser.id ? contactItem.displayName + ' (YOU)' : contactItem.displayName}}</label>
                        </div>
                      </li>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div class="simplebar-placeholder" style="width: auto; height: 1666px;"></div>
        </div>
        <div class="simplebar-track simplebar-horizontal" style="visibility: hidden;">
          <div class="simplebar-scrollbar" style="width: 0px; display: none;"></div>
        </div>
        <div class="simplebar-track simplebar-vertical" style="visibility: visible;">
          <div class="simplebar-scrollbar" style="height: 25px; transform: translate3d(0px, 0px, 0px); display: block;">
          </div>
        </div>
      </div>
      <div class="border-0 modal-footer">
        <button class="btn btn-primary" (click)="forward()">Forward</button>
      </div>
    </div>
  </div>
</ng-template>
