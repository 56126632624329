import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from "@angular/common";

@Pipe({
  name: 'messageDate'
})
export class MessageDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(date: Date): string {

    const formattedDate = this.datePipe.transform(date, 'M/d/y');
    const today = this.datePipe.transform(new Date(), 'M/d/y');
    const yesterday = this.datePipe.transform(new Date(new Date().getTime() - 24 * 60 * 60 * 1000), 'M/d/y');

    if (formattedDate === today) {
      return this.datePipe.transform(date, 'h:mm a');
    } else if (formattedDate === yesterday) {
      return 'Yesterday';
    } else {
      return this.datePipe.transform(date, 'M/d/y');
    }
  }

}
