import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import {CallHistoryModel} from "../../../sip/lib/model/call-history-model";

@Injectable({
  providedIn: 'root'
})
export class UserCallManagementService {

  private _currentCall: BehaviorSubject<CallHistoryModel | null> = new BehaviorSubject(null);
  private _minimizeCall: BehaviorSubject<boolean | null> = new BehaviorSubject(null);

  constructor() {
  }


  get currentCall$(): Observable<CallHistoryModel> {
    return this._currentCall.asObservable();
  }

  setCurrentCall(currentCall: CallHistoryModel): void {
    this._currentCall.next(currentCall);
  }


  get minimizeCall$(): Observable<boolean> {
    return this._minimizeCall.asObservable();
  }

  setMinimizeCall(value: boolean): void {
    this._minimizeCall.next(value);
  }


}
