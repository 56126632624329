import {CallEvent} from 'src/app/sip/lib/events/call-events';
import {Injectable} from "@angular/core";
import {ClickEvent} from "src/app/sip/lib/events/click-event";
import {CallHistoryModel} from "src/app/sip/lib/model/call-history-model";

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private _clickEvent: ClickEvent;
  private _callEvent: CallEvent;
  private sessionToTransfer = null as any;

  constructor() {
    this._clickEvent = new ClickEvent();
    this._callEvent = new CallEvent();
  }

  public getClickEvent() {
    return this._clickEvent;
  }

  public getCallEvent() {
    return this._callEvent;
  }


  public numberHistoryClick(callHistoryModel: CallHistoryModel) {
    this._clickEvent.dispatchEvent(new CustomEvent(ClickEvent.HistoryClick, {
      detail: {callHistoryModel: callHistoryModel}
    }));
  }


  public numberTransferClick(sessionId: string) {
    this._clickEvent.dispatchEvent(new CustomEvent(ClickEvent.TransferClick, {
      detail: {sessionId: sessionId}
    }));
    this.sessionToTransfer = sessionId;
  }

  public numberUnTransferClick(sessionId: string) {
    this._clickEvent.dispatchEvent(new CustomEvent(ClickEvent.UnTransferClick, {
      detail: {sessionId: sessionId}
    }));
    this.sessionToTransfer = null;
  }

  public getTransferSessionId() {
    return this.sessionToTransfer;
  }

  public isTransferStatus(sessionId: string) {
    return this.sessionToTransfer == sessionId;
  }


  public anyPendingTransfer(): boolean {
    return (this.sessionToTransfer);
  }


}
