<!-- Start Contact content -->
<div>
  <div class="p-4">
    <div *ngIf="defaultUser.type===RoleTypeEnum.ADMIN" class="user-chat-nav float-end">
      <div ngbTooltip="New User">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0" data-toggle="modal"
                data-target="#addContact-exampleModal" (click)="openContactsModal()">
          <i class="ri-user-add-line"></i>
        </button>
      </div>
    </div>
    <h4 class="mb-4">Contacts</h4>

    <div class="search-box chat-search-box">
      <div class="input-group bg-light input-group-lg rounded-3">
        <div class="input-group-prepend">
          <button class="btn btn-link text-decoration-none text-muted pr-1" type="button">
            <i class="ri-search-line search-icon font-size-18"></i>
          </button>
        </div>
        <input type="text" class="form-control bg-light" [(ngModel)]="searchKey"
               (keyup)="searchContacts()" placeholder="Search users...">
      </div>
    </div>
    <!-- End search-box -->
  </div>
  <!-- end p-4 -->

  <!-- Start contact lists -->
  <perfect-scrollbar class="p-4 chat-message-list chat-group-list" data-simplebar>
    <div *ngFor="let item of groupedContacts | keyvalue; let index = index">
      <div class="p-3 font-weight-bold text-primary">
        {{item.key}}
      </div>
      <ul class="list-unstyled contact-list">
        <li *ngFor="let contactItem of item.value  ; let i = index  "
            appDragDropFileUpload (fileDropped)="fileDroppedToContactHandler($event,i,item.key,index)">
          <div class="d-flex align-items-center" id="contactItem{{contactItem.id}}">
            <div class="flex-grow-1" (click)="chooseContact(i,item.key,index)">
              <h5
                class="font-size-14 m-0">{{contactItem.displayName}}{{contactItem.id === defaultUser.id ? " (YOU)" : ""}}</h5>
            </div>

            <div class="dropdown" ngbDropdown>
              <a href="javascript:void(0);" class="text-muted dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                 aria-haspopup="true" aria-expanded="false">
                <i class="ri-more-2-fill"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                <!--                <a class="dropdown-item" href="javascript:void(0);">Share-->
                <!--                  <i class="ri-share-line float-end text-muted"></i></a>-->
                <!--                <a class="dropdown-item" href="javascript:void(0);">Block-->
                <!--                  <i class="ri-forbid-line float-end text-muted"></i></a>-->
                <a class="dropdown-item" href="javascript:void(0);"
                   (click)="deleteUser(contactItem.displayName ,contactItem.id)">Remove
                  <i class="ri-delete-bin-line float-end text-muted"></i></a>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- end contact list A -->

  </perfect-scrollbar>
  <!-- end contact lists -->
</div>
<!-- Start Contact content -->
