export class ICEServers {

  constructor() {

    // Creating Our XMLHttpRequest object

  }

  async getBestIceServers(turnServersArray: string[]) {

    type ICEResponse = {
      url: string;
      respTime: number;

    };

    var iceServers = [], turnSevers: ICEResponse[] = [];

    for (var x = 0; x < turnServersArray.length; x++) {
      var iceURL = turnServersArray[x];
      if (iceURL.toLowerCase().startsWith("turn")) {
        var turnURL = "https://" + turnServersArray[x].replace(/^[^:]+:/g, "").replace(/:.*$/, "").trim();

        var respTime = await this.pingURL(turnURL);
        let obj: ICEResponse = {
          url: iceURL,
          respTime: respTime
        };
        turnSevers.push(obj);
      }
    }

    return turnSevers.sort((t1, t2) => t1.respTime - t2.respTime).map(s => s.url);

  }


  private async pingURL(url: string) {
    var xhr = new XMLHttpRequest();
    return new Promise<number>((res, rej) => {


      var start_time = new Date().getTime();
      var request_time = 0;

      xhr.open("GET", url, true);

      xhr.onreadystatechange = (obj: any) => {
        request_time = new Date().getTime() - start_time;
        res(request_time);
      }
      xhr.send();
    });
  }


}
