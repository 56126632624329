import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {MainMenuService} from "./main-menu.service";
import {ImageUtil} from '../../../utils/image-util';
import {UserService} from "../../services/user-service";
import {User} from "../../model/user.model";
import {KeycloakService} from '../../services/keycloak-service';
import {RoleTypeEnum} from "../../model/user-type";

type ThemeMode = 'light' | 'dark';

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  activetab: string;
  userImageUrl: any;
  loadingPhoto: boolean;
  defaultUser: User = null;
  mode: ThemeMode = 'light';
  localStorageThemeModeAttributeName: string = 'themeMode';
  protected readonly RoleTypeEnum = RoleTypeEnum;

  constructor(
    private mainMenuService: MainMenuService,
    private userService: UserService,
    private imageUtil: ImageUtil,
    private readonly keycloakService: KeycloakService,
  ) {
  }

  ngOnInit(): void {
    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.userService.loadingPhoto$.subscribe((value) => {
      this.loadingPhoto = value;
    });

    this.getUserImage();

    this.mainMenuService.setActiveTab('Chats');

    this.mainMenuService.activeTab$.subscribe((activeTabValue) => {
      this.activetab = activeTabValue;
    });

    this.mode = window.localStorage.getItem(this.localStorageThemeModeAttributeName) as ThemeMode || 'light';
    this.changeMode(this.mode);
  }

  /**
   * Topbar Light-Dark Mode Change
   */
  changeMode(mode: ThemeMode) {
    this.mode = mode;
    document.body.setAttribute('data-layout-mode', this.mode);
    window.localStorage.setItem(this.localStorageThemeModeAttributeName, this.mode);
  }

  /**
   * Logout the user
   */
  async logout(): Promise<void> {
    await this.keycloakService.logout();
    window.sessionStorage.clear();
  }

  setActiveTab(activeTab: string): void {
    this.mainMenuService.setActiveTab(activeTab);

  }

  getUserImage(): void {
    // this.isLoading = true;
    this.userService.userImage$.subscribe((response) => {
        if (response === null || response?.size === 0) {
          this.userImageUrl = environment.imageProperties.defaultAvatarPath;

          //this.isLoading = false;

        } else {
          this.userImageUrl = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response);
          //this.isLoading = false;

        }
      }
    );
  }
}
