import {PrefrenceModel} from "../lib/model/prefrence-model";
import {CallHistoryModel} from "../lib/model/call-history-model";
import {Injectable} from "@angular/core";


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  private sipSettingsKey: string = "sipSettings";
  private callHistoryKey: string = "callHistoryKey";

  public saveCallHistory(callList: Array<CallHistoryModel>) {
    localStorage.setItem(this.callHistoryKey, JSON.stringify(callList.slice(0, 300)));
  }

  public getCallHistory(): Array<CallHistoryModel> {
    let json = localStorage.getItem(this.callHistoryKey) as string;
    if (json == null) {
      return [];
    }
    return JSON.parse(json) as Array<CallHistoryModel>;
  }

  public saveStoreSipSettings(prefrenceModel: PrefrenceModel) {
    localStorage.setItem(this.sipSettingsKey, JSON.stringify(prefrenceModel));
  }

  public getStoreSipSettings(): PrefrenceModel {
    let json = localStorage.getItem(this.sipSettingsKey) as string;
    if (json === 'null' || json === null) {
      return new PrefrenceModel();
    }
    return JSON.parse(json) as PrefrenceModel;
  }
}
