<audio #DTMF id="DTMF" src="assets/sounds/dtmf.mp3"></audio>

<div class="px-4 pt-4">
  <h4 class="mb-4">Dialer</h4>
</div>

<div class="card shadow-none border my-2 mx-4 p-2 pb-4 bg-white dialer">
  <div class="input-group mb-3 me-5 p-2">
    <input type="text" [ngClass]="{'form-control':true,'transfer':isTransferPending()}"
           placeholder="{{isTransferPending()?'Transfer to':'Phone number'}}" [(ngModel)]="phoneToDialInput"
           (keyup)="handleKeyUp($event)" list="datalistOptions">
    <!--    <datalist id="datalistOptions">-->
    <!--      <option *ngFor="let option of outgoingCallHistoryList" [value]="option"></option>-->
    <!--    </datalist>-->
    <a class="input-group-text" style="text-decoration: none; " (click)="deleteDigit()">
      <i class="ri-delete-back-2-line"></i>
    </a>
  </div>

  <div>
    <div class=" d-flex justify-content-evenly ">
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(1)">
        1
      </div>
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(2)">
        <div>2</div>
        <div>ABC</div>
      </div>
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(3)">
        <div>3</div>
        <div>DEF</div>
      </div>
    </div>

    <div class=" d-flex  justify-content-evenly mt-3">
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(4)">
        <div>4</div>
        <div>GHI</div>
      </div>
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(5)">
        <div>5</div>
        <div>JKL</div>
      </div>
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(6)">
        <div>6</div>
        <div>MNO</div>
      </div>
    </div>

    <div class=" d-flex justify-content-evenly mt-3 ">
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(7)">
        <div>7</div>
        <div>PQRS</div>
      </div>
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(8)">
        <div>8</div>
        <div>TUV</div>
      </div>
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(9)">
        <div>9</div>
        <div>WXYZ</div>
      </div>
    </div>

    <div class=" d-flex   justify-content-evenly mt-3 ">
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler('*')">
        <div>*</div>
      </div>
      <div class="rounded-circle digit d-flex flex-column  align-items-center justify-content-center"
           (click)="digitHandler(0)">
        <div>0</div>
      </div>
      <div class="rounded-circle digit d-flex flex-column align-items-center justify-content-center"
           (click)="digitHandler('#')">
        <div>#</div>
      </div>
    </div>
    <div class=" d-flex   justify-content-evenly mt-3 ">
      <button class=" d-flex   justify-content-evenly mt-3 rounded-circle call-icn " (click)="call()"
              [disabled]="!isCallButtonEnabled()"
              [ngStyle]="{'background-color': enableCallButton ? '#10b6a7':'transparent', 'border': enableCallButton ? 0:'2px solid #ccc'}">
        <i class="ri-phone-line font-size-24" [ngStyle]="{'color': enableCallButton ?'#FFF': 'black'}"></i>
      </button>
    </div>

  </div>
</div>
