import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SIPService} from "../../../sip/services/sip-service";
import {LocalStorageService} from "../../../sip/services/local-storage-service";
import {EventService} from "../../../sip/services/event-service";
import {MainMenuService} from "../../main-menu/main-menu.service";
import {Router} from "@angular/router";
import {NgbModal, NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder} from "@angular/forms";
import {CallHistoryModel} from "../../../sip/lib/model/call-history-model";
import {ManagedSession} from 'sip.js/lib/platform/web';
import {TimerFormat} from "../../../sip/lib/helper/timer-format";
import {GeneralService} from "../../../services/general-service";
import {GroupService} from "../../../services/group-service";
import {Message} from "../../../model/message.model";
import {User} from "../../../model/user.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageService} from "../../../services/message-service";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {ChatService} from "../../../services/chat-service";
import {UserVideoCallManagementComponent} from "../user-video-call-management/user-video-call-management.component";
import {UserService} from "../../../services/user-service";
import {SearchModeEnum} from "../../../model/search-mode-enum";


@Component({
  selector: 'user-chat-head',
  templateUrl: './user-chat-head.component.html',
  styleUrls: ['./user-chat-head.component.scss']
})
export class UserChatHeadComponent implements OnInit {


  videoCallComponentRef: ComponentRef<UserVideoCallManagementComponent>;
  // UnComment for the first way
  // @ViewChild('modalContainer', {read: ViewContainerRef}) modalContainer: ViewContainerRef;


  @ViewChild('incomingCallModal') incomingCallModal: any;

  chatHeaderName: any;
  // chatHeaderStatus: any;
  chatHeaderAvatar: any;
  isSipConnected: boolean = false;
  SelectedItemEnum = SelectedItemEnum;


  activeCalls: Array<ManagedSession> = null as any;
  callMaps: Map<string, CallHistoryModel> = new Map<string, CallHistoryModel>();
  confMap: Map<string, boolean> = new Map<string, boolean>();

  callStatusMessage: String;
  callInProgress: boolean = false;
  sideCanvasLoading: boolean = false;


  selectedItemType: SelectedItemEnum;

  isAvatarLoading: boolean = false;
  message: Message = null;
  defaultUser: User = null;
  selectedId: string = null;
  isSelfChoosen: boolean = null;
  selectedSearchMode: SearchModeEnum;
  SearchModeEnum = SearchModeEnum;

  @ViewChild('incomingRingTone') incomingRingTone: ElementRef = null as any;
  @ViewChild('callWaiting') callWaiting: ElementRef = null as any;
  @ViewChild('wrongNumberTone') wrongNumberTone: ElementRef = null as any;


  constructor(private sipService: SIPService, private localStorageService: LocalStorageService, private eventService: EventService,
              private mainMenuService: MainMenuService, private generalService: GeneralService, private groupService: GroupService,
              private userService: UserService,
              private router: Router, private modalService: NgbModal, private offcanvasService: NgbOffcanvas, private _changeDetectorRef: ChangeDetectorRef,
              private rootViewContainer: ViewContainerRef, private messageService: MessageService, private chatService: ChatService,
              public formBuilder: FormBuilder, private _snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.generalService.isSipConnected$.subscribe((val) => {
      this.isSipConnected = val;
    });

    this.groupService.isAvatarLoading$.subscribe((value) => {
      if (value != null)
        this.isAvatarLoading = value;
    });

    this.generalService.itemSelected$.subscribe((value) => {
      this.selectedItemType = value;
      if (value === SelectedItemEnum.CHAT) {
        this.generalService.chatSelectedId$.subscribe((value) => {
          this.selectedId = value;
          if (!value) return;
          let userId: string = this.chatService.getPeerUserIdByChatId(value);
          this.isSelfChoosen = !userId || this.defaultUser.id === userId;
        });
      } else if (value === SelectedItemEnum.CONTACT) {
        this.generalService.contactSelectedId$.subscribe((value) => {
          this.selectedId = value;
          if (!value) return;
          this.isSelfChoosen = this.defaultUser.id === value;
        });
      }
    });

    this.generalService.chatHeaderName$.subscribe((value) => {
      this.chatHeaderName = value;
    });
    // this.generalService.chatHeaderStatus$.subscribe((value) => {
    //   this.chatHeaderStatus = value;
    // });
    this.generalService.chatHeaderAvatar$.subscribe((value) => {
      this.chatHeaderAvatar = value;
    });


    this.generalService.sideCanvasLoading$.subscribe((value) => {
      this.sideCanvasLoading = value;
      this._changeDetectorRef.detectChanges();
    });

    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.generalService.searchMode$.subscribe((value: SearchModeEnum): void => {
      this.selectedSearchMode = value;
    });
  }

  call() {
    this.generalService.openCallManagementComponent.emit(false);
  }

  openVideoManagement() {
    this.videoCallComponentRef = this.rootViewContainer.createComponent(UserVideoCallManagementComponent);

    this.videoCallComponentRef.instance.visibleVideoCallManagement = true;

    if (this.selectedId) {
      this.generalService.setPeerUserInfo(this.selectedItemType, this.selectedId);
    }

    this.rootViewContainer.insert(this.videoCallComponentRef.hostView).detectChanges();
    this.videoCallComponentRef.instance.closeModal.subscribe(() => this.videoCallComponentRef.destroy());

    console.log(typeof this.rootViewContainer.get(this.rootViewContainer.length - 1));
  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  // Message Search
  MessageSearch() {
    let input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("searchMessage") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.getElementById("users-conversation");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("p")[0];
      txtValue = a?.innerText;
      if (txtValue?.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }


  /**
   * Show user profile
   */
  // tslint:disable-next-line: typedef
  showUserProfile() {
    document.getElementById('profile-detail').style.display = 'block';
  }

  // Delete All Message
  deleteAllMessage() {
    let allMsgDelete: any = document.getElementById('users-conversation')?.querySelectorAll('.chats');
    allMsgDelete.forEach((item: any) => {
      item.remove();
    })
  }

  /**
   * Close user chat
   */
  // tslint:disable-next-line: typedef
  closeUserChat() {
    document.getElementById('chat-group').classList.remove('user-chat-show');
    document.querySelector('.chat-welcome-section').classList.remove('d-none');
    document.querySelector('.user-chat').classList.add('d-none');
    this.generalService.setItemSelected(null);
    this.generalService.setGroupSelected(null);
    this.generalService.setChatSelectedId(null);
    this.generalService.setContactSelectedId(null);
    this.generalService.setChatHeaderName('');
    this.generalService.setChatHeaderAvatar(null);
    // this.generalService.setChatHeaderStatus(null);
    this.messageService.setCurrentConversationMessages(null);
  }


  // Filter Offcanvas Set
  onChatInfoClicked(content: TemplateRef<any>) {
    this.offcanvasService.open(content, {position: 'end'});
  }

  // Filter Offcanvas Set
  onSearchMessagesClicked(content: TemplateRef<any>) {
    this.offcanvasService.open(content, {position: 'end'});
  }


  public formatTimer(num: any) {
    return TimerFormat.formatTimer(num);
  }

}
