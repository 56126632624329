import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MainMenuService} from "../main-menu/main-menu.service";
import {Chats} from "../index/chat.model";
import {GeneralService} from "../../services/general-service";

@Component({
  selector: 'left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss']
})
export class LeftSidebarComponent implements OnInit {

  activetab: string;
  chat: Chats[];

  userName: string;
  // userStatus: string ;
  userProfile: string;
  message: any;
  tabsLoading: boolean;


  constructor(
    private mainMenuService: MainMenuService,
    private generalService: GeneralService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {

    this.generalService.setChat();

    // this.generalService.setChatHeaderStatus('online');
    this.generalService.setChatHeaderAvatar('assets/images/users/avatar-4.jpg');


    this.mainMenuService.activeTab$.subscribe((activeTabValue) => {
      this.activetab = activeTabValue;
    });
    this.generalService.tabsLoading$.subscribe((value) => {
      this.tabsLoading = value;
      this._changeDetectorRef.detectChanges();
    });


    this.onListScroll();

  }

  onListScroll() {
    //TODO: Review Scroll List if facing any scroll issue
    // if (this.scrollRef !== undefined) {
    //   setTimeout(() => {
    //     this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
    //   }, 500);
    // }
  }


}
