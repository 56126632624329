export class CallEvent extends EventTarget {

  constructor() {
    super()
  }

  public static registrationState: string = 'registrationState';
  public static transportState: string = 'transportState';
  public static sessionState: string = 'sessionState';
  public static incomingCall: string = 'incomingCall';
  public static outgoingCall: string = 'outgoingCall';
  public static rejectedCall: string = 'rejectedCall';
  public static hangupCall: string = 'hangupCall';
  public static callAnswered: string = 'callAnswered';
  public static notificationParking: string = 'notificationParking';
  public static NotificationMessageSummary: string = "NotificationMessageSummary";
  public static MessageReceived: string = "MessageReceived";


}
