import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../services/user-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CustomPasswordValidator} from "./customPasswordValidator";
import {GeneralService} from "../../../services/general-service";
import {KeycloakUser} from "../../../model/user.model";

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})

export class NewUserComponent implements OnInit {
  firstName: string;
  lastName: string;
  email: string;
  extension: string;
  username: string;
  password: string;
  newUserForm: FormGroup;

  constructor(private _userService: UserService, private _snackBar: MatSnackBar, private _formBuilder: FormBuilder,
              private generalService: GeneralService) {
  }

  async ngOnInit() {
    this.newUserForm = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('[a-zA-Z0-9.-_]+@[a-zA-Z.-]+\\.[a-zA-Z]{2,}')]],
      extension: ['', [Validators.required]],
      username: ['', [Validators.required]],
      password: ['', Validators.compose([
        // 1. Password Field is Required
        Validators.required,
        // 2. check whether the entered password has a number
        CustomPasswordValidator.patternValidator(/\d/, {hasNumber: true}),
        // 3. check whether the entered password has upper case letter
        CustomPasswordValidator.patternValidator(/[A-Z]/, {hasCapitalCase: true}),
        // 4. check whether the entered password has a lower-case letter
        CustomPasswordValidator.patternValidator(/[a-z]/, {hasSmallCase: true}),
        // 5. check whether the entered password has a special character
        CustomPasswordValidator.patternValidator(/[!@#$%^&*()+]/, {hasSpecialCharacters: true}),
        // 6. Has a minimum length of 8 characters
        Validators.minLength(8)])
      ],
    });

  }

  saveNewUser(): void {
    this.generalService.setTabsLoading(true);

    const keycloakUser: KeycloakUser = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      extension: this.extension,
      username: this.username,
      password: this.password
    };

    this._userService.createNewUser(keycloakUser).subscribe({
      next: () => {
        this._userService.getContacts().subscribe({
          next: () => {
            this.generalService.setTabsLoading(false);
            this.newUserForm.reset();
            Object.keys(this.newUserForm.controls).forEach(controlName => {
              this.newUserForm.get(controlName).setErrors(null);
            });
          },
          error: (error) => {
            if (error.status === 400) {
              this.openSnackBar(error.error, 'error');
            }
            if (error.status === 500) {
              this.openSnackBar('Internal server error!', 'error');
            } else {
              this.openSnackBar('An error has occurred, Try again later.', 'error');
            }
            this.generalService.setTabsLoading(false);
          }
        });
        this.generalService.setTabsLoading(false);
        this.openSnackBar('Saved successfully', 'success');
      }, error: (error) => {
        this.generalService.setTabsLoading(false);
        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400 || error.status === 409) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }

      }
    });

  }


  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }
}
