<div class="offcanvas-body profile-offcanvas p-0">


  <div class="px-4 pt-4">
    <h4 class="mb-4">Search Messages</h4>
    <div class="search-box chat-search-box">
      <div class="input-group mb-3 rounded-3">
                <span class="input-group-text text-muted bg-light pe-1 ps-3" id="basic-addon1">
                  <i class="ri-search-line search-icon font-size-18"></i>
                </span>
        <input type="text" class="form-control bg-light"
               placeholder="Type a Keyword"
               aria-label="Type a Keyword" aria-describedby="basic-addon1"
               (keydown.enter)="$event.preventDefault();searchMessages()"
               id="searchMessages" autocomplete="off">
      </div>
    </div>
  </div> <!-- .p-4 -->


  <!-- Start result-message-list -->
  <div class="px-2" *ngIf="searchResult&&searchResult.length>0">
    <h5 class="mb-3 px-3 font-size-16">Results for "{{searchKeyword}}"</h5>

    <perfect-scrollbar id="scrollView" class="search-message-list">
      <ul class="list-unstyled chat-list chat-user-list">
        <li *ngFor="let messageItem of searchResult  ; let i = index"
            style="background-color: var(--bs-light-bg-subtle);">
          <a href="javascript:void(0);" id="messageItem{{messageItem.id}}"
             style="border-top: 1px solid var(--bs-dark-bg-subtle);">
            <div class="d-flex">
              <div class="flex-grow-1 overflow-hidden">
                <h5 class="text-truncate font-size-15 mb-1">
                  <span>{{contacts.get(messageItem.authorId).displayName}}</span>
                </h5>
                <p class="chat-user-message text-truncate mb-0">{{messageItem.body}}</p>
              </div>
              <div class="font-size-11" style="white-space: nowrap;">
                {{messageItem.dateTime| messageDate}}
              </div>
            </div>
          </a>
        </li>
      </ul>
    </perfect-scrollbar>

  </div>

  <div class="px-2"
       *ngIf="(searchResult===null || (searchResult&&searchResult.length===0)) ">
    <h5 class="mb-3 px-3 font-size-16">{{progressMessage}}</h5>
  </div>

</div><!--end offcanvas-body-->
