import {LocalStorageService} from 'src/app/sip/services/local-storage-service';
import {EventService} from 'src/app/sip/services/event-service';
import {LineModel, LineState} from '../lib/model/line-model';
import {ManagedSession, SessionDescriptionHandlerOptions, SessionManager} from "sip.js/lib/platform/web";

import {
  Inviter,
  InviterInviteOptions,
  Notification,
  RegistererState,
  Session,
  SessionInfoOptions,
  SessionState,
  Subscriber,
  TransportState,
  URI,
  UserAgent,
  UserAgentOptions,
  Web,
} from "sip.js";
import {Injectable} from "@angular/core";
import {
  IncomingRequestMessage,
  OutgoingSubscribeRequest,
  OutgoingSubscribeRequestDelegate,
  Subscription,
  SubscriptionState
} from "sip.js/lib/core";
import {SIPOptions} from '../lib/interfaces/sip-options-interface';
import {OptionsKeepAlive} from '../lib/helper/options-keep-alive';
import {IncomingCallModel} from "src/app/sip/lib/model/incoming-call-model";
import {CallDirectionState} from "src/app/sip/lib/model/call-direction-state";
import {CallState} from "src/app/sip/lib/model/call-state";
import {CallEvent} from "src/app/sip/lib/events/call-events";
import * as xml2js from 'xml2js';


@Injectable({
  providedIn: 'root'
})
export class SIPService {
  // private userAgent: UserAgent = null as any;
  //private registerer: Registerer = null as any;

  private sipOptions: SIPOptions = null as any;

  // Number of times to attempt reconnection before giving up
  private reconnectionAttempts = 3;
  // Number of seconds to wait between reconnection attempts
  private reconnectionDelay = 4;

  // Used to guard against overlapping reconnection attempts
  private attemptingReconnection = false;
  // If false, reconnection attempts will be discontinued or otherwise prevented
  private shouldBeConnected = true;

  //private handler1: Function = null as any;
  private optionsKeepAlive: OptionsKeepAlive = null as any;

  private sessionMaps: Map<String, any> = null as any;

  private publicIPV4: string = null as any;
  private managedSession: SessionManager;

  //phone lines
  public lines: Array<LineModel> = [];


  private _callEvent: CallEvent;


  constructor(private eventService: EventService, private localStorageService: LocalStorageService) {
    this.optionsKeepAlive = new OptionsKeepAlive();
    this.managedSession = null as any;
    this._callEvent = eventService.getCallEvent();
  }

  public async connect(sipOptions: SIPOptions) {
    this.sipOptions = sipOptions;

    let callEvent = this._callEvent;

    let uri = UserAgent.makeURI(`sip:${this.sipOptions.username}@${this.sipOptions.domain}`);

    if (!uri) {
      throw new Error("Failed to create URI");
    }

    let authorizationUsername = uri.user as string;


    let transportOptions = {
      server: this.sipOptions.server,
      traceSip: false
    };

    let optionsKeepAlive = this.optionsKeepAlive;
    let attemptReconnection = this.attemptReconnection;
    let oninvite = this.oninvite;
    let voicemailMWI = this.voicemailMWI;
    let obj = this;

    const userAgentOptions: UserAgentOptions = {
      contactName: this.sipOptions.username,
      uri,
      authorizationPassword: this.sipOptions.password,
      authorizationUsername: authorizationUsername,
      transportOptions,
      hackIpInContact: this.sipOptions.domain,
      sessionDescriptionHandlerFactoryOptions: {
        //not sure?
        //constraints: {
        //     audio: "5555555",
        //     video: this.config.video
        // },
        iceGatheringTimeout: 500,
        peerConnectionConfiguration: this.sipOptions.rtcConfiguration?.bundlePolicy
      },
      logLevel: "error"
    };

    let onServerConnect = this.onServerConnect;
    this.managedSession = new SessionManager(this.sipOptions.server, {
      delegate: {
        onCallAnswered(session) {

          callEvent.dispatchEvent(new CustomEvent(CallEvent.callAnswered, {
            detail: {
              sessionId: session.id
            }
          }));

        },
        onCallReceived(session) {
          oninvite.call(obj, session);
        }, onCallCreated(session) {
        }, onCallHangup(session) {

          callEvent.dispatchEvent(new CustomEvent(CallEvent.hangupCall, {
            detail: {
              sessionId: session.id
            }
          }));

        },
        onMessageReceived(msg) {

          callEvent.dispatchEvent(new CustomEvent(CallEvent.MessageReceived, {
            detail: {
              message: msg
            }
          }));

          console.log("=================******message*********===============================");
          console.log(msg.request);
          console.log("=================******message-END*********===============================");

        },
        onNotificationReceived(notification) {
          voicemailMWI.call(obj, notification);
        },
        onServerDisconnect(error) {
          optionsKeepAlive.stop();
          if (error) {
            attemptReconnection.call(obj);
          }
        }, onServerConnect() {
          onServerConnect.call(obj);
        }, onUnregistered() {
          callEvent.dispatchEvent(new CustomEvent(CallEvent.registrationState, {
            detail: {registererState: RegistererState.Unregistered}
          }));
        }, onRegistered() {
          callEvent.dispatchEvent(new CustomEvent(CallEvent.registrationState, {
            detail: {registererState: RegistererState.Registered}
          }));
        }
      },
      userAgentOptions: userAgentOptions
    });

    await this.managedSession.connect();
  }


  public async register() {

    await this.managedSession.register();
  }

  public initiateLines() {
    if (!this.localStorageService.getStoreSipSettings().sipSettings.enableLines) {
      return;
    }
    if (this.lines.length == 0) {
      for (let x = 1; x <= 6; x++) {
        let lineModel: LineModel = {
          name: x + "",
          state: LineState.Pending,
          value: `park+*${5900 + x}`,
          number: x
        };
        this.lines.push(lineModel);
      }
    }

    this.lines.forEach(line => {
      this.subscribeToLine(line.value);
    });

    this.getCallEvent().addEventListener(CallEvent.notificationParking, (e) => {
      let event: CustomEvent = e as CustomEvent;
      let notification: IncomingRequestMessage = event.detail.notification;
      this.parseParkNotification(notification);
    });
  }


  private parseParkNotification(msg: IncomingRequestMessage) {

    let parser = new xml2js.Parser(
      {
        trim: true,
        explicitArray: true
      });
    parser.parseString(msg.body, (err, result) => {
      let root = result["dialog-info"].dialog[0];
      let state = root.state[0];
      let id = root["$"]["id"];
      let lineState: LineState = LineState.Pending;
      switch (state) {
        case "confirmed":
          lineState = LineState.Busy;
          break;
        case "terminated":
          lineState = LineState.Available;
          break;
      }

      this.lines.forEach(line => {
        if (line.value.substring(5) == id) {
          line.state = lineState;
        }
      });

    });
  }

  public getCallEvent() {
    return this._callEvent;
  }

  public unregister() {
    this.managedSession.unregister();
  }

  private attemptReconnection = (reconnectionAttempt = 1): void => {
    // If not intentionally connected, don't reconnect.
    if (!this.shouldBeConnected) {
      return;
    }

    // Reconnection attempt already in progress
    if (this.attemptingReconnection) {
      return;
    }

    // Reconnection maximum attempts reached
    if (reconnectionAttempt > this.reconnectionAttempts) {
      return;
    }

    // We're attempting a reconnection
    this.attemptingReconnection = true;

    setTimeout(() => {
      // If not intentionally connected, don't reconnect.
      if (!this.shouldBeConnected) {
        this.attemptingReconnection = false;
        return;
      }
      // Attempt reconnect
      this.managedSession.connect()
        .then(() => {
          // Reconnect attempt succeeded
          this.attemptingReconnection = false;
        })
        .catch((error: Error) => {
          // Reconnect attempt failed
          this.attemptingReconnection = false;
          this.attemptReconnection(++reconnectionAttempt);
        });
    }, reconnectionAttempt === 1 ? 0 : this.reconnectionDelay * 1000);
  }


  public async callOnlyIfNoActive(number: string): Promise<string | undefined> {
    let activeList = this.listActiveCalls();
    if (activeList.length <= 0) {
      return await this.call(number);
    }
    return undefined;
  }

  private async call(targetNumber: string): Promise<string> {
    let prefix = targetNumber.startsWith("+") ? "+" : undefined;
    let park = false;

    if (targetNumber.startsWith("park+*")) {
      targetNumber = targetNumber.substring(6);
      park = true;
    }
    let target = targetNumber
      .replace(/A|B|C/gi, "2")
      .replace(/D|E|F/gi, "3")
      .replace(/G|H|I/gi, "4")
      .replace(/J|K|L/gi, "5")
      .replace(/M|N|O/gi, "6")
      .replace(/P|Q|R|S/gi, "7")
      .replace(/T|U|V/gi, "8")
      .replace(/W|X|Y|Z/gi, "9")
      .replace(/\D/gi, "");
    if (park) {
      target = `park+*${target}`;
    }
    if (prefix) {
      target = `+${target}`;
    }

    let callEvent = this._callEvent;
    // Handle incoming INVITE request.
    let constrainsDefault: MediaStreamConstraints = {
      audio: true,
      video: false
    };

    let sessionDescriptionHandlerOptions: SessionDescriptionHandlerOptions = {
      constraints: constrainsDefault
    };

    let inviterInviteOptions: InviterInviteOptions = {
      sessionDescriptionHandlerOptions: sessionDescriptionHandlerOptions,
      requestDelegate: {
        onAccept(response) {
          callEvent.dispatchEvent(new CustomEvent(CallEvent.outgoingCall, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Accept,
              sessionState: inviter.state,
              statusCode: response.message.statusCode
            }
          }));

          callEvent.dispatchEvent(new CustomEvent(CallEvent.sessionState, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Accept,
              sessionState: inviter.state,
              callDirectionState: CallDirectionState.Outbound,
              statusCode: response.message.statusCode
            }
          }));
        }, onProgress(response) {

          callEvent.dispatchEvent(new CustomEvent(CallEvent.outgoingCall, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Progress,
              sessionState: inviter.state,
              statusCode: response.message.statusCode
            }
          }));

          callEvent.dispatchEvent(new CustomEvent(CallEvent.sessionState, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Progress,
              sessionState: inviter.state,
              callDirectionState: CallDirectionState.Outbound,
              statusCode: response.message.statusCode
            }
          }));
        }, onRedirect(response) {

          callEvent.dispatchEvent(new CustomEvent(CallEvent.outgoingCall, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Redirect,
              sessionState: inviter.state,
              statusCode: response.message.statusCode
            }
          }));

          callEvent.dispatchEvent(new CustomEvent(CallEvent.sessionState, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Redirect,
              sessionState: inviter.state,
              callDirectionState: CallDirectionState.Outbound,
              statusCode: response.message.statusCode
            }
          }));
        }, onReject(response) {

          callEvent.dispatchEvent(new CustomEvent(CallEvent.outgoingCall, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Reject,
              sessionState: inviter.state,
              callDirectionState: CallDirectionState.Outbound,
              statusCode: response.message.statusCode
            }
          }));

          callEvent.dispatchEvent(new CustomEvent(CallEvent.rejectedCall, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Reject,
              sessionState: inviter.state,
              callDirectionState: CallDirectionState.Outbound,
              statusCode: response.message.statusCode
            }
          }));
        }, onTrying(response) {

          callEvent.dispatchEvent(new CustomEvent(CallEvent.outgoingCall, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Trying,
              sessionState: inviter.state,
              callDirectionState: CallDirectionState.Outbound,
              statusCode: response.message.statusCode
            }
          }));

          callEvent.dispatchEvent(new CustomEvent(CallEvent.sessionState, {
            detail: {
              sessionId: inviter.id,
              callState: CallState.Trying,
              sessionState: inviter.state,
              callDirectionState: CallDirectionState.Outbound,
              statusCode: response.message.statusCode
            }
          }));
        }
      }
    };

    let inviter: Inviter = await this.managedSession.call(`sip:${target}@${this.sipOptions.domain}`, undefined, inviterInviteOptions);
    this.sessionManagement(inviter, CallDirectionState.Outbound);
    this.addToSessionMap(inviter);
    inviter.invite();
    return inviter.id;
  }


  public async sendMessage(destination: string, message: string) {
    await this.managedSession.message(`sip:${destination}@${this.sipOptions.domain}`, message);
  }


  public sendDTMFTone(digit: string, sessionId: string) {
    let session: Session = this.getSession(sessionId);
    let options: SessionInfoOptions = {
      requestOptions: {
        body: {
          contentDisposition: "render",
          contentType: "application/dtmf-relay",
          content: `Signal=${digit}\r\nDuration=250`
        }
      }
    }
    session.info(options);
  }

  public hangup(sessionId: string) {
    let session: Session = this.getSession(sessionId);

    this.managedSession.hangup(session);
  }

  public mute(sessionId: string) {
    let session: Session = this.getSession(sessionId);
    if (this.managedSession.isMuted(session)) {
      this.managedSession.unmute(this.getSession(sessionId));
    } else {
      this.managedSession.mute(this.getSession(sessionId));
    }
  }

  public accept(sessionId: string) {
    this.managedSession.answer(this.getSession(sessionId));
  }


  public async holdConf(confSessionIdList: string[]) {
    console.log(`**********************conference size ${confSessionIdList.length} **************************************`);
    let sessions = confSessionIdList.map(sessionId => this.getSession(sessionId));

    let isHeld = sessions.length > 0 && this.managedSession.isHeld(sessions[0]);

    sessions.forEach(async (session) => {
      console.log(`**********************conference held ${isHeld} **************************************`);
      if (isHeld) {
        await this.managedSession.unhold(session);
      } else if (!this.managedSession.isHeld(session)) {
        await this.managedSession.hold(session);
        this.cleanupMedia();
      }
    });

    if (isHeld) {
      this.conference(sessions);
    }

  }

  public hold(sessionId: string) {
    let session: Session = this.getSession(sessionId);

    if (this.managedSession.isHeld(session)) {
      this.managedSession.unhold(session);
      this.assignStream(session);
    } else {
      this.managedSession.hold(session);
      this.cleanupMedia();
    }
  }

  public async transfer(sessionId: string, target: string) {

    let session: Session = this.getSession(sessionId);
    await this.managedSession.transfer(session, `sip:${target}@${this.sipOptions.domain}`);
  }

  public transferToAnotherCall(sessionId: string, targetSessionId: string) {
    let session: Session = this.getSession(sessionId);
    let targetSession: Session = this.getSession(targetSessionId);
    session.refer(targetSession);
  }

  public subscribeToLine1(ext: string) {
    let expires = 340;
    let reqURI = UserAgent.makeURI(`sip:${this.sipOptions.username}@${this.sipOptions.domain}`);
    let toURI = UserAgent.makeURI(`sip:${ext}@${this.sipOptions.domain}`);
    let fromURI = UserAgent.makeURI(`sip:${this.sipOptions.username}@${this.sipOptions.domain}`);
    let outgoingRequestMessage = this.managedSession.userAgent.userAgentCore.makeOutgoingRequestMessage("SUBSCRIBE", toURI!,
      fromURI!, toURI!, {});
    outgoingRequestMessage.setHeader("Event", "dialog");
    outgoingRequestMessage.setHeader("Expires", `${expires}`);
    outgoingRequestMessage.setHeader("Accept", "application/dialog-info+xml,multipart/related,application/rlmi+xml");
    outgoingRequestMessage.setHeader("Allow", "INVITE, ACK, OPTIONS, CANCEL, BYE, SUBSCRIBE, NOTIFY, INFO, REFER, UPDATE, MESSAGE");
    outgoingRequestMessage.setHeader("Contact", this.managedSession.userAgent.contact.toString());
    //outgoingRequestMessage.setHeader("Supported", "replaces, path, timer");
    let callEvent = this._callEvent;
    let outgoingSubscribeRequestDelegate: OutgoingSubscribeRequestDelegate = {
      onNotify(request) {
        request.request.accept();
        console.log("##################onNotify###########################");
        console.log(request);
        request.subscription!.autoRefresh = false;

        setInterval(() => {
          request.subscription!.autoRefresh = false;
          console.log("=========+++++++++==============" + request.subscription?.subscriptionState);
          console.log("*****************autoRefresh***********************************" + request.subscription?.subscriptionState);
          request.subscription?.refresh();
        }, (expires * 1000) - 40000);


        callEvent.dispatchEvent(new CustomEvent(CallEvent.notificationParking, {
          detail: {notification: request.request.message}
        }));

        console.log("=========+++++++++==============" + request.subscription?.subscriptionState);
        if (request.subscription != undefined && request.subscription?.subscriptionState !== SubscriptionState.Terminated) {

          let subscription: Subscription = request.subscription;
          subscription.autoRefresh = true;
          subscription.delegate = {
            onNotify(notification) {
              notification.accept();
              //notification.request.getHeader("Subscription-State");
              callEvent.dispatchEvent(new CustomEvent(CallEvent.notificationParking, {
                detail: {notification: notification.message}
              }));
              console.log("onNotify function");
              console.log(notification);


            },
          }
        }
        console.log("##################onNotify###########################");
      },

    };
    let outgoingSubscribeRequest: OutgoingSubscribeRequest = this.managedSession.userAgent.userAgentCore.subscribe(outgoingRequestMessage, outgoingSubscribeRequestDelegate);

    // let subscribeUserAgentClient:SubscribeUserAgentClient=new SubscribeUserAgentClient(this.managedSession.userAgent.userAgentCore,
    //     outgoingRequestMessage);

  }


  public async subscribeToLine(ext: string) {
    let expires = 240;
    let callEvent = this._callEvent;
    let targetURI = new URI("sip", ext, this.sipOptions.domain);
    let subscriber: Subscriber = new Subscriber(this.managedSession.userAgent, targetURI, "dialog",
      {
        extraHeaders: ['Accept: application/dialog-info+xml,multipart/related,application/rlmi+xml'],
        expires: expires
      });

    subscriber.delegate = {
      onNotify: (notification) => {
        notification.accept();
        //notification.request.getHeader("Subscription-State");
        callEvent.dispatchEvent(new CustomEvent(CallEvent.notificationParking, {
          detail: {notification: notification.request}
        }));
      }
    };

    subscriber.stateChange.addListener((newState) => {
      if (newState !== "Subscribed") {
        this.lines.filter(l => l.value == ext).forEach(l => {
          l.state = LineState.Pending;
        });
      }

    });

    await subscriber.subscribe();

  }

  private oninvite(session: Session): void {
    this.sessionManagement(session, CallDirectionState.Inbound);
    this.addToSessionMap(session);
    let incomingCallModel: IncomingCallModel = new IncomingCallModel();
    incomingCallModel.callerId = session.remoteIdentity.uri.user as string;
    incomingCallModel.callerName = session.remoteIdentity.displayName;

    this._callEvent.dispatchEvent(new CustomEvent(CallEvent.incomingCall, {
      detail: {
        sessionId: session.id,
        incomingCallModel: incomingCallModel
      }
    }));
  }

  private sessionManagement(session: Session, callDirectionState: CallDirectionState) {

    session.stateChange?.addListener((state: SessionState) => {

      this._callEvent.dispatchEvent(new CustomEvent(CallEvent.sessionState, {
        detail: {
          sessionId: session.id,
          callState: CallState.Redirect,
          sessionState: session.state,
          callDirectionState: CallDirectionState.Outbound
        }
      }));

      console.log(`Session state changed to ${state}`);
      switch (state) {
        case SessionState.Initial:

          console.log("==========================Initial============================");
          break;
        case SessionState.Establishing:
          console.log("==========================Establishing============================");
          break;
        case SessionState.Established:
          this.assignStream(session);
          console.log("==========================Established============================");
          break;
        case SessionState.Terminating:
        // fall through
        case SessionState.Terminated:
          this.cleanupMedia();
          this.removeFromSessionMap(session);
          break;
        default:
          console.log("==========================Terminating============================");
          throw new Error("Unknown session state.");
      }
    });
  }

  private onServerConnect() {
    this._callEvent.dispatchEvent(new CustomEvent(CallEvent.transportState, {
      detail: {transportState: TransportState.Connected}
    }));

    let uri = this.managedSession.userAgent.configuration.uri;
    this.optionsKeepAlive.start(this.managedSession.userAgent, UserAgent.makeURI(`sip:${this.sipOptions.domain}`)!, uri, uri, 40);
  }

  public async assignStream(session: Session) {


    let sessionDescriptionHandler = session.sessionDescriptionHandler;
    if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
      throw new Error("Invalid session description handler.");
    }
    let stream = sessionDescriptionHandler.remoteMediaStream;

    if ((this.sipOptions.inputAudioDeviceId !== null) && this.sipOptions.inputAudioDeviceId !== "default") {
      try {

        const audioStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: {
              exact: this.sipOptions.inputAudioDeviceId
            }
          }
        });
        if (audioStream !== undefined)
          await sessionDescriptionHandler.peerConnection?.getSenders().find(s => s.track?.kind == "audioinput")?.replaceTrack(audioStream.getAudioTracks()[0]);
      } catch (e) {
        console.log(e);
      }
    }

    let htmlAudioElement = this.sipOptions.htmlAudioElement;
    // Set element source.
    htmlAudioElement.autoplay = true; // Safari does not allow calling .play() from a non user action
    htmlAudioElement.srcObject = stream;

    // Load and start playback of media.
    htmlAudioElement.play().catch((error: Error) => {
      console.error("Failed to play media");
      console.error(error);
    });

    // If a track is added, load and restart playback of media.
    stream.onaddtrack = (): void => {
      htmlAudioElement.load(); // Safari does not work otheriwse
      htmlAudioElement.play().catch((error: Error) => {
        console.error("Failed to play remote media on add track");
        console.error(error);
      });
    };

    // If a track is removed, load and restart playback of media.
    stream.onremovetrack = (): void => {
      htmlAudioElement.load(); // Safari does not work otheriwse
      htmlAudioElement.play().catch((error: Error) => {
        console.error("Failed to play remote media on remove track");
        console.error(error);
      });
    };
  }


  public cleanupMedia() {
    this.sipOptions.htmlAudioElement.srcObject = null;
    this.sipOptions.htmlAudioElement?.pause();
  }

  private addToSessionMap(session: any) {
    if (this.sessionMaps == null) {
      this.sessionMaps = new Map<String, Session>();
    }
    this.sessionMaps.set(session.id, session);
  }


  public getSession(sessionId: string): any {
    return this.sessionMaps?.get(sessionId) as any;
  }


  private removeFromSessionMap(session: any) {
    if (this.sessionMaps == null) {
      return;
    }
    this.sessionMaps.delete(session.id);
  }

  public listSessions(): Array<ManagedSession> {
    return this.managedSession.managedSessions;
  }

  public listActiveCalls(): Array<ManagedSession> {
    return this.listSessions().filter(ms => !ms.held);
  }


  public conference(sessions: Session[]) {

    this.cleanupMedia();
    sessions.forEach((session) => {
      this.managedSession.unhold(session);
      this.managedSession.unmute(session);
    });

    //take all received tracks from the sessions you want to merge
    let receivedTracks: MediaStreamTrack[] = [];
    sessions.forEach(function (session) {
      if (session !== null && session !== undefined) {
        let sessionDescriptionHandler = session.sessionDescriptionHandler;
        if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
          throw new Error("Invalid session description handler.");
        }

        sessionDescriptionHandler.peerConnection?.getReceivers().forEach(function (receiver) {
          receivedTracks.push(receiver.track);
        });
      }
    });

    //use the Web Audio API to mix the received tracks
    var context = new AudioContext();
    var allReceivedMediaStreams = new MediaStream();

    sessions.forEach(function (session) {
      if (session !== null && session !== undefined) {
        let sessionDescriptionHandler = session.sessionDescriptionHandler;
        if (!sessionDescriptionHandler || !(sessionDescriptionHandler instanceof Web.SessionDescriptionHandler)) {
          throw new Error("Invalid session description handler.");
        }

        var mixedOutput = context.createMediaStreamDestination();

        sessionDescriptionHandler?.peerConnection?.getReceivers().forEach(function (receiver) {
          receivedTracks.forEach(function (track) {
            allReceivedMediaStreams.addTrack(receiver.track);
            if (receiver.track.id !== track.id) {
              var sourceStream = context.createMediaStreamSource(new MediaStream([track]));
              sourceStream.connect(mixedOutput);
            }
          });
        });
        //mixing your voice with all the received audio
        sessionDescriptionHandler?.peerConnection?.getSenders().forEach(function (sender) {
          if (sender.track == undefined) {
            throw new Error("Invalid session description handler.");
          }
          let track: MediaStreamTrack = sender.track;
          var sourceStream = context.createMediaStreamSource(new MediaStream([track]));
          sourceStream.connect(mixedOutput);
        });
        sessionDescriptionHandler?.peerConnection?.getSenders()[0].replaceTrack(mixedOutput.stream.getTracks()[0]);
      }
    });

    let remoteAudio = this.sipOptions.htmlAudioElement;

    remoteAudio.srcObject = allReceivedMediaStreams;
    var promiseRemote = remoteAudio.play();
    if (promiseRemote !== undefined) {
      promiseRemote.then(() => {
        console.log("playing all received streams to you");
      }).catch((error: any) => {
        console.log(error);
      });
    }
  }

  public voicemailMWI(notification: Notification) {
    if (notification.request.getHeader("Event") !== "message-summary") {
      return;
    }

    let body = notification.request.body;
    let map: Map<string, string> = new Map();
    body.split(/\n|\r\n/).filter(s => !s.includes("Message-Account")).forEach(s => {

      let preIndex = s.indexOf(":");
      let key = s.substring(0, preIndex);
      let value = s.substring(preIndex + 1);
      map.set(key, value.replace(/\([^\)]+\)/, ""));
    });
    if (map.has('Voice-Message')) {
      let arr = map.get('Voice-Message')?.split(/\//);
      if (arr?.length === 2) {
        this._callEvent.dispatchEvent(new CustomEvent(CallEvent.NotificationMessageSummary, {
          detail: {
            newMessages: arr[0],
            readMessages: arr[1]
          }
        }));
      }
    }
  }

}
