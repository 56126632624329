<div class="modal-body">
  <div class="p-0 card-body">
    <div class="modal--modal-content">
      <div class="text-center p-1">
        <i class=" ri-information-fill " style="color: #EF476F;font-size: 100px"></i>
        <h5 class="text-wrap">{{question}}</h5>
        <div class="mt-3">
          <ul class="list-inline mb-1">
            <li class="list-inline-item px-2 me-2 ms-0">
              <div class="border-0 modal-footer">
                <button (click)="activeModal.dismiss()" class="btn btn-secondary">Cancel</button>
              </div>
            </li>
            <li class="list-inline-item px-2 me-2 ms-0">
              <div class="border-0 modal-footer">
                <button (click)="confirmDelete()" class="btn btn-primary">{{button}}</button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
