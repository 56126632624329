<div class="chat-leftsidebar me-lg-1">
  <div class="tab-content">

    <!-- Loader -->
    <div
      *ngIf="tabsLoading"
      class="fixed inset-x-0 top-0 z-50">
      <mat-progress-bar [mode]="'indeterminate'"></mat-progress-bar>
    </div>

    <!-- Start chats tab-pane -->
    <div id="pills-chat" role="tabpanel" aria-labelledby="pills-chat-tab" *ngIf="activetab === 'Chats'">
      <!-- Start chats content -->
      <app-chat-list></app-chat-list>
      <!-- Start chats content -->
    </div>
    <!-- End chats tab-pane -->

    <!-- Start groups tab-pane -->
    <div id="pills-groups" role="tabpanel" aria-labelledby="pills-groups-tab" *ngIf="activetab === 'Groups'">
      <!-- Start Groups content -->
      <app-groups></app-groups>
      <!-- End Groups content -->
    </div>
    <!-- End groups tab-pane -->

    <!-- Start contacts tab-pane -->
    <div id="pills-contacts" role="tabpanel" aria-labelledby="pills-contacts-tab" *ngIf="activetab === 'Contacts'">
      <app-contacts></app-contacts>
    </div>
    <!-- End contacts tab-pane -->

    <!-- Start dialer tab-pane -->
    <div id="dialer" role="tabpanel" aria-labelledby="pills-dialer-tab" *ngIf="activetab === 'Dialer'">
      <app-dialer></app-dialer>
    </div>
    <!-- End dialer tab-pane -->

    <!-- Start settings tab-pane -->
    <div id="pills-setting" role="tabpanel" aria-labelledby="pills-setting-tab" *ngIf="activetab === 'Settings'">
      <app-settings></app-settings>
    </div>
    <!-- End settings tab-pane -->

    <!-- Start add-new-user tab-pane -->
    <div id="addNewUser" role="tabpanel" aria-labelledby="pills-newUser-tab" *ngIf="activetab === 'NewUser'">
      <app-new-user></app-new-user>
    </div>
    <!-- End add-new-user tab-pane -->
  </div>

</div>
