import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SIPService} from "../../../sip/services/sip-service";
import {PrefrenceModel} from "../../../sip/lib/model/prefrence-model";
import {Properties} from "../../../../properties/properties";
import {RegistererState, TransportState} from 'sip.js';
import {SIPOptions} from "../../../sip/lib/interfaces/sip-options-interface";
import {CallEvent} from "../../../sip/lib/events/call-events";
import {LocalStorageService} from "../../../sip/services/local-storage-service";
import {User} from "../../../model/user.model";
import {UserService} from "../../../services/user-service";
import {ImageUtil} from '../../../../utils/image-util';
import {environment} from "../../../../environments/environment";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DataUrl, NgxImageCompressService, UploadResponse} from "ngx-image-compress";
import {GeneralService} from "../../../services/general-service";
import {ModalService} from "../../../services/modal.service";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {ChatService} from "../../../services/chat-service";
import {KeycloakService} from "../../../services/keycloak-service";
import {GroupService} from "../../../services/group-service";
import {SearchModeEnum} from "../../../model/search-mode-enum";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
/**
 * Tabs-settings component
 */
export class SettingsComponent implements OnInit {

  @ViewChild('audioRemote') audioRemote: ElementRef = null as any;

  defaultUser: User = null;
  userImageUrl: any;
  canDeleteImageProfile: boolean = false;

  loadingPhoto: boolean = false;
  isSipConnected: boolean = false;


  editedName: any;
  extension: any;
  password: any;
  domain: any;
  server: any;
  btnRegisteredDisabled: boolean = false;

  audioOutputs: MediaDeviceInfo[] = [];
  audioInputs: MediaDeviceInfo[] = [];
  cams: MediaDeviceInfo[] = [];

  prefrenceModel: PrefrenceModel = null as any;
  // infoMessages: Message[] = [];
  registered: boolean = false;
  userStatus: string = "available";//available or dnd
  selectedMic: MediaDeviceInfo;
  selectedSpeaker: MediaDeviceInfo;
  selectedCam: MediaDeviceInfo;
  selectedItem: SelectedItemEnum;
  selectedChatId: string;
  selectedContactId: string;

  selectedSearchMode: SearchModeEnum;
  SelectedItemEnum = SelectedItemEnum;
  SearchModeEnum = SearchModeEnum;
  console = console;

  constructor(private localStorageService: LocalStorageService, private sipService: SIPService, private keycloakService: KeycloakService
    , private userService: UserService, private imageUtil: ImageUtil, private modalService: NgbModal, private _snackBar: MatSnackBar
    , private imageCompress: NgxImageCompressService, private generalService: GeneralService, private chatService: ChatService, private groupService: GroupService,
              private deleteModalService: ModalService
  ) {
  }

  async ngOnInit() {
    this.userService.loadingPhoto$.subscribe((value) => {
      this.loadingPhoto = value;
    });

    this.generalService.isSipConnected$.subscribe((val) => {
      this.isSipConnected = val;
    });

    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.editedName = this.defaultUser.displayName

    const keyCloakUser = await this.keycloakService.loadUserProfile().catch((error) => {
      if (error.status === 500) {
        this.openSnackBar('Server has problem, Please try in another time', 'error');
      } else if (error.status === 400) {
        this.openSnackBar(error.error, 'error');
      } else {
        this.openSnackBar('An error has occurred, Try again later.', 'error');
      }
      this.userService.setLoadingPhoto(false);
    });
    await this.loadAudioAndVideo();
    this.prefrenceModel = this.localStorageService.getStoreSipSettings();
    this.prefrenceModel.sipSettings.name = this.defaultUser.displayName;
    this.prefrenceModel.sipSettings.password = keyCloakUser['attributes']['sipPassword'][0];
    this.prefrenceModel.sipSettings.server = this.defaultUser.server;
    this.prefrenceModel.sipSettings.domain = this.defaultUser.domain;
    this.prefrenceModel.sipSettings.username = this.defaultUser.extension;

    //this.prefrenceModel = new PrefrenceModel();

    console.log('audioInputs   ', this.audioInputs);
    this.eventSetup();

    console.log('audioOutputs  ', this.audioOutputs);
    this.getUserImage();

    this.generalService.itemSelected$.subscribe(value => {
      this.selectedItem = value;
    });
    this.generalService.chatSelectedId$.subscribe(value => {
      if (value !== null) {
        this.selectedChatId = value;
      }
    });
    this.generalService.contactSelectedId$.subscribe(value => {
      if (value !== null) {
        this.selectedContactId = value;
      }
    });
    this.generalService.searchMode$.subscribe(value => {
      this.selectedSearchMode = value;
    })
  }


  compressAndUploadFile() {

    let selectedChatPeerUserId = this.chatService.getPeerUserIdByChatId(this.selectedChatId);

    this.imageCompress
      .uploadFile()
      .then(({image, orientation, fileName}: UploadResponse) => {
        const imageBlobBeforeCompression = this.dataURItoBlob(image);
        const imageFileBeforeCompression = new File([imageBlobBeforeCompression], fileName);

        const allowedTypes = environment.imageProperties.allowedTypes;
        const fileSizeInKB = Math.round(imageFileBeforeCompression.size / 1024);
        const fileType = this.dataURItoType(image);

        // Return if the file is too large
        if (fileSizeInKB > (environment.imageProperties.maxImageSizeInMB * 1024)) {
          this.openSnackBar('size of image is too large,image should less than ' + environment.imageProperties.maxImageSizeInMB + 'MB..', 'error');
          this.userService.setLoadingPhoto(false);

          return;
        }
        // Return if the file is not allowed
        if (!allowedTypes.includes(fileType)) {
          this.openSnackBar('you must upload an image..', 'error');
          this.userService.setLoadingPhoto(false);

          return;
        }

        this.userService.setLoadingPhoto(true);


        this.imageCompress
          .compressFile(image, orientation, 50, 50)
          .then((result: DataUrl) => {

            const imageBlob = this.dataURItoBlob(result);
            const imageFile = new File([imageBlob], fileName);

            // Upload the avatar
            this.userService.uploadUserImage(imageFile).subscribe(
              {
                next: (response) => {
                  this.userService.setLoadingPhoto(false);
                  let tempSrcImg = result;

                  this.userService.updateContactImgProfileAndImgSrc(this.defaultUser.id, response.toString(), tempSrcImg);
                  this.chatService.updateChatMapPeerSrcImg(this.defaultUser.id, response.toString(), tempSrcImg);
                  this.groupService.updateUserGroupMemberSrcImg(this.defaultUser.id, response.toString(), tempSrcImg);

                  if (this.selectedItem === SelectedItemEnum.CONTACT || this.selectedItem === SelectedItemEnum.CHAT) {
                    if ((this.selectedChatId !== undefined && this.selectedChatId !== null && selectedChatPeerUserId === this.defaultUser.id) || (this.selectedContactId !== undefined && this.selectedContactId !== null && this.selectedContactId === this.defaultUser.id)) {
                      this.generalService.setChatHeaderAvatar(tempSrcImg);
                    }
                  }


                },
                error: (error) => {
                  this.userService.setLoadingPhoto(false);

                  if (error.status === 400) {
                    this.openSnackBar(error.error, 'error');
                  } else if (error.status === 413) {
                    this.openSnackBar('Image size is too big!', 'error');
                  } else {
                    this.openSnackBar('An error has occurred, Try again later.', 'error');
                  }
                }
              }
            );


          });
      });
  }

  dataURItoBlob(dataURI): any {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array]);
  }

  dataURItoType(dataURI): any {
    //dataURI = "data:image/png;base64,iVBORw0KG......"
    const data = dataURI.split(',')[0];
    return data.split(";")[0].split(":")[1];
  }


  // User Profile Image Delete
  fileDelete() {
    let selectedChatPeerUserId = this.chatService.getPeerUserIdByChatId(this.selectedChatId);


    const deleteFile = () => {
      this.userService.setLoadingPhoto(true);
      // this.modalService.dismissAll();

      this.userService.deleteUserImage().subscribe({
        next: () => {
          this.userService.setLoadingPhoto(false);

          this.userService.updateContactImgProfileAndImgSrc(this.defaultUser.id, "", null);
          this.chatService.updateChatMapPeerSrcImg(this.defaultUser.id, "", null);
          this.groupService.updateUserGroupMemberSrcImg(this.defaultUser.id, "", null);


          if (this.selectedItem === SelectedItemEnum.CONTACT || this.selectedItem === SelectedItemEnum.CHAT) {
            if ((this.selectedChatId !== undefined && this.selectedChatId !== null && selectedChatPeerUserId === this.defaultUser.id) || (this.selectedContactId !== undefined && this.selectedContactId !== null && this.selectedContactId === this.defaultUser.id)) {
              this.generalService.setChatHeaderAvatar(null);
            }
          }


        },
        error: (error) => {
          this.userService.setLoadingPhoto(false);

          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          //this.isLoading=false
        }
      });
    }
    this.deleteModalService.openDeletionConfirmationModal(deleteFile, "Are you sure to delete?", "Delete");

  }


  // User Name Update
  edit_userName() {
    this.editedName = this.defaultUser.displayName
    document.getElementById("user_name").classList.toggle("visually-hidden");
    document.getElementById("user_name_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-user-name").classList.toggle("visually-hidden");
  }

  // User Name Update
  userNameChange() {
    let selectedChatPeerUserId = this.chatService.getPeerUserIdByChatId(this.selectedChatId);
    //this.isLoading = true;
    this.userService.updateDisplayName(this.editedName).subscribe({
      next: () => {
        this.defaultUser.displayName = this.editedName;
        window.sessionStorage.setItem("defaultUser", JSON.stringify(this.defaultUser));

        this.userService.updateContactName(this.defaultUser.id, this.editedName);
        this.chatService.updateChatMapPeerName(this.defaultUser.id, this.editedName);
        this.groupService.updateUserGroupsMemberName(this.defaultUser.id, this.editedName);


        if (this.selectedItem === SelectedItemEnum.CONTACT || this.selectedItem === SelectedItemEnum.CHAT) {
          if ((this.selectedChatId !== undefined && this.selectedChatId !== null && selectedChatPeerUserId === this.defaultUser.id) || (this.selectedContactId !== undefined && this.selectedContactId !== null && this.selectedContactId === this.defaultUser.id)) {
            this.generalService.setChatHeaderName(this.editedName);
          }
        }
      },
      error: (error) => {
        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
        //this.isLoading=false

      }
    });
    document.getElementById("user_name").classList.toggle("visually-hidden");
    document.getElementById("edit-user-name").classList.toggle("visually-hidden");
    document.getElementById("user_name_edit").classList.toggle("visually-hidden");

  }

  private async loadAudioAndVideo() {


    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
      return;
    }
    this.audioInputs = [];
    this.audioOutputs = [];
    this.cams = [];

    let audioInputs = this.audioInputs;
    let audioOutputs = this.audioOutputs;
    let cams = this.cams;

    await navigator.mediaDevices.enumerateDevices()
      .then(function (devices) {

        devices.forEach(function (device) {
          if (device.kind === "audioinput") {
            audioInputs.push(device);
          } else if (device.kind === "audiooutput") {
            audioOutputs.push(device);
          } else if (device.kind === "videoinput") {
            cams.push(device);
          }
          console.log(device.kind + ": " + device.label +
            " id = " + device.deviceId + "\n");
        });

      })
      .catch(function (err) {
        console.log(err.name + ": " + err.message);
      });


    this.selectedSpeaker = audioOutputs[0];
    this.selectedMic = audioInputs[0];
    this.selectedCam = cams[0];
  }


  public async register() {
    try {

      this.btnRegisteredDisabled = true;
      //this.infoMessages = [{ severity: 'info', summary: 'Wait', detail: 'Waiting for server response' }];
      console.log('waiting..');
      this.localStorageService.saveStoreSipSettings(this.prefrenceModel);

      let rtcConfiguration: RTCConfiguration = await Properties.rtcConfiguration();

      let sipOptions: SIPOptions = {
        server: this.prefrenceModel.sipSettings.server,
        domain: this.prefrenceModel.sipSettings.domain,
        username: this.prefrenceModel.sipSettings.username,
        password: this.prefrenceModel.sipSettings.password,
        rtcConfiguration: rtcConfiguration,
        htmlAudioElement: this.audioRemote.nativeElement,
        inputAudioDeviceId: this.prefrenceModel.voiceSettings.output
      };
      this.sipService.getCallEvent().addEventListener(CallEvent.transportState, (e) => {
        let event: CustomEvent = e as CustomEvent;
        if (event.detail.transportState == TransportState.Connected)
          this.sipService.register();

      });
      await this.sipService.connect(sipOptions);
    } catch (e) {
      //this.infoMessages = [{ severity: 'error', summary: 'Error', detail: 'Registration Error' }];
      console.log(e.message);
      this.btnRegisteredDisabled = false;
    }

  }

  private eventSetup() {
    this.sipService.getCallEvent().addEventListener(CallEvent.registrationState, (e) => {
      let event: CustomEvent = e as CustomEvent;
      if (event.detail?.registererState == RegistererState.Registered) {
        this.registered = true;
        //this.infoMessages = [{ severity: 'success', summary: 'Success', detail: 'Successful Registration' }];
        console.log('Success');
        setTimeout(() => {
          this.btnRegisteredDisabled = false;
        }, 2000);
        this.sipService.initiateLines();
      } else {
        this.registered = false;
        //this.infoMessages = [{ severity: 'error', summary: 'Error', detail: 'Registration Error' }];
        console.log('error');
        this.btnRegisteredDisabled = false;
      }
    });
  }

  selectMic(mic: MediaDeviceInfo) {
    this.selectedMic = mic;
    this.prefrenceModel.voiceSettings.input = this.selectedMic.deviceId;
  }

  selectSpeaker(speaker: MediaDeviceInfo) {
    this.selectedSpeaker = speaker;
    this.prefrenceModel.voiceSettings.output = this.selectedSpeaker.deviceId;
  }

  selectCam(cam: MediaDeviceInfo) {
    this.selectedCam = cam;
    this.prefrenceModel.videoSettings.cam = this.selectedCam.deviceId;
  }

  saveVoiceSettings() {
    this.localStorageService.saveStoreSipSettings(this.prefrenceModel);
    this.audioRemote.nativeElement.setSinkId(this.prefrenceModel.voiceSettings.output);
    //this.infoMessages = [{ severity: 'success', summary: 'Success', detail: 'Saved Voice Settings' }];
    console.log(this.prefrenceModel);
    console.log('Success Voice Settings ');

    if (this.isSipConnected)
      this.sipService.unregister();

    this.register();

  }

  saveVideoSettings() {
    this.localStorageService.saveStoreSipSettings(this.prefrenceModel);
    //this.infoMessages = [{ severity: 'success', summary: 'Success', detail: 'Saved Voice Settings' }];
    console.log(this.prefrenceModel);
    console.log('Success Video Settings ')

    if (this.isSipConnected)
      this.sipService.unregister();

    this.register();
  }

  getUserImage(): void {
    // this.isLoading = true;
    this.userService.userImage$.subscribe((response) => {
        if (response === null || response?.size === 0) {
          this.userImageUrl = environment.imageProperties.defaultAvatarPath;
          this.canDeleteImageProfile = false;

          //this.isLoading = false;

        } else {
          this.userImageUrl = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response);
          this.canDeleteImageProfile = true;
          //this.isLoading = false;

        }
      }
    );

  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  public changeSearchMode(value: SearchModeEnum): void {
    this.generalService.setSearchMode(value);
    window.localStorage.setItem('searchMode', value);
  }
}
