import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {SIPService} from "../../../sip/services/sip-service";
import {LocalStorageService} from "../../../sip/services/local-storage-service";
import {EventService} from "../../../sip/services/event-service";
import {MainMenuService} from "../../main-menu/main-menu.service";
import {Router} from "@angular/router";
import {NgbModal, NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder} from "@angular/forms";
import {CallEvent} from "../../../sip/lib/events/call-events";
import {GeneralService} from "../../../services/general-service";
import {MessageContentTypeEnum} from "../../../model/message-type";
import {Message} from "../../../model/message.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "../../../model/user.model";
import {ChatItem} from "../../../model/chatItem.model";
import {MessageService} from "../../../services/message-service";
import {CallStatusEnum} from "../../../model/call-status-enum";
import {ChatService} from "../../../services/chat-service";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {UserService} from "../../../services/user-service";
import {Contact} from "../../../model/contact.model";
import {environment} from "../../../../environments/environment";
import {CallState} from "../../../sip/lib/model/call-state";


@Component({
  selector: 'user-video-call-management',
  templateUrl: './user-video-call-management.component.html',
  styleUrls: ['./user-video-call-management.component.scss']
})
export class UserVideoCallManagementComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('outgoingRingTone') outgoingRingTone: ElementRef = null as any;
  @ViewChild('hangupTone') hangupTone: ElementRef = null as any;
  @ViewChild('dialingTone') dialingTone: ElementRef = null as any;
  @ViewChild('callWaiting') callWaiting: ElementRef = null as any;
  @ViewChild('wrongNumberTone') wrongNumberTone: ElementRef = null as any;
  @ViewChild('incomingRingTone') incomingRingTone: ElementRef = null as any;

  //TODO:Fadi check for the below to be used from service
  videoCallStatusMessage: String;
  visibleIncomingVideoCall: boolean;
  visibleReachingOrInProgressVideoCall: boolean;
  visibleVideoCallManagement: boolean;
  message: Message = null;
  defaultUser: User = null;
  selectedId: string = null;
  selectedItemType: SelectedItemEnum;
  peerUserInVideoCallName: string = null;
  peerUserInVideoCallImgSrc: any = null;
  peerExtension: any = null;
  videoCallUrl: string = null;


  constructor(private sipService: SIPService, private localStorageService: LocalStorageService, private eventService: EventService,
              private mainMenuService: MainMenuService, private generalService: GeneralService, private messageService: MessageService,
              private router: Router, private modalService: NgbModal, private offCanvasService: NgbOffcanvas,
              public formBuilder: FormBuilder, private _snackBar: MatSnackBar, private chatService: ChatService, private userService: UserService) {
  }

  ngOnInit(): void {
    this.generalService.itemSelected$.subscribe({
      next: (value) => {
        this.selectedItemType = value;
        if (value === SelectedItemEnum.CHAT) {
          this.generalService.chatSelectedId$.subscribe({
            next: (value) => {
              this.selectedId = value;
            }
          });
        } else if (value === SelectedItemEnum.CONTACT) {
          this.generalService.contactSelectedId$.subscribe({
            next: (value) => {
              this.selectedId = value;
            }
          });
        }
      }
    });

    this.messageService.callMessage$.subscribe({
      next: (responseMessage: Message) => {
        this.message = responseMessage;
      }
    });

    this.generalService.videoCallUrl$.subscribe({
      next: (value) => {
        this.videoCallUrl = value;
      }
    });

    this.generalService.peerUserInCallName$.subscribe({
      next: (value) => {
        if (value != null) {
          this.peerUserInVideoCallName = value;
        }
      }
    });
    this.generalService.peerUserInCallImgSrc$.subscribe({
      next: (value) => {
        this.peerUserInVideoCallImgSrc = value;
      }
    });
    this.generalService.peerUserInCallExtension$.subscribe({
      next: (value) => {
        this.peerExtension = value;
      }
    });

    console.log('visibleIncomingVideoCall ', this.visibleIncomingVideoCall);
    console.log('visibleReachingOrInProgressVideoCall ', this.visibleReachingOrInProgressVideoCall);
    console.log('visibleVideoCallManagement ', this.visibleVideoCallManagement);

    this.createAndAddListeners();

    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));
  }

  createAndAddListeners() {
    this.sipService.getCallEvent().addEventListener(CallEvent.MessageReceived, (evt) => {
      let customEvent: CustomEvent = evt as CustomEvent;
      let message = JSON.parse(customEvent.detail.message.incomingMessageRequest.message.body);
      let callState = message.callState;
      if (message.type === MessageContentTypeEnum.VideoCall) {
        switch (callState) {
          case CallState.Progress:
            //pause all the rings and show ringing voice
            this.pauseRings();
            this.outgoingRingTone.nativeElement.play();
            this.videoCallStatusMessage = 'Ringing ... ';
            break;
          case CallState.Accept:
            //pause all the rings and show ringing voice
            this.close();
            window.open(this.videoCallUrl, "_blank");
            break;
          case CallState.Reject:
            //pause all the rings and show ringing voice
            this.close();
            break;
          default:
            break;
        }

      }
    });
  }

  ngAfterViewInit() {
    if (this.visibleIncomingVideoCall) {
      this.incomingRingTone.nativeElement.play();
    }
  }

  public hangup() {
    console.log('hangup method');
    if (this.message && this.message.id !== null) {
      switch (this.message.callStatus) {
        case CallStatusEnum.ACCEPTED:
          this.updateCallStatus(CallStatusEnum.COMPLETED);
          break;
        case CallStatusEnum.TRYING:
          if (this.message.authorId === this.defaultUser.id) {
            this.updateCallStatus(CallStatusEnum.CANCELED);
          } else {
            this.updateCallStatus(CallStatusEnum.REJECTED);
          }
          break;
      }
    }
    this.sipService.sendMessage(this.peerExtension,
      JSON.stringify({
        type: this.message.messageContentType,
        callState: CallState.Reject
      })).then(() => {
      this.hangupTone.nativeElement.play();
      this.close();
    });
  }

  public answerIncomingCall() {
    this.updateCallStatus(CallStatusEnum.ACCEPTED);
    console.log(this.peerExtension)
    this.sipService.sendMessage(this.peerExtension,
      JSON.stringify({
        type: this.message.messageContentType,
        callState: CallState.Accept
      })).then(() => {
      this.close();
      window.open(this.videoCallUrl, "_blank");
    });
  }

  private pauseRings() {
    this.dialingTone.nativeElement.pause();
    this.outgoingRingTone.nativeElement.pause();
    this.incomingRingTone.nativeElement.pause();
  }

  openVideoManagement() {
    if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.CHAT) {
      let selectedChatItem = {...this.chatService.getChatItem(this.selectedId)};
      this.handleCallAndSendMessageInPrivateChat(selectedChatItem);
    } else if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.CONTACT) {
      let selectedContact = {...this.userService.getContactByUserId(this.selectedId)};
      this.handleCallAndSendMessageToNewContact(selectedContact);
    }

    this.visibleVideoCallManagement = false;
    this.visibleReachingOrInProgressVideoCall = true;

    //trying the call, show trying to dial
    this.dialingTone.nativeElement.play();
    this.videoCallStatusMessage = 'Calling ... ';
  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  close() {
    this.pauseRings();
    this.hideAll();
    this.closeModal.emit();
  }

  ngOnDestroy(): void {
    this.sipService.getCallEvent().removeAllListeners('outgoingCall');
    this.sipService.getCallEvent().removeAllListeners('callAnswered');
    this.sipService.getCallEvent().removeAllListeners('hangupCall');
    this.sipService.getCallEvent().removeAllListeners('rejectedCall');

    console.log('Good Bye',
      'outgoingCall ' + this.sipService.getCallEvent().eventListeners('outgoingCall').length,
      'callAnswered ' + this.sipService.getCallEvent().eventListeners('callAnswered').length,
      'hangupCall ' + this.sipService.getCallEvent().eventListeners('hangupCall').length,
      'rejectedCall ' + this.sipService.getCallEvent().eventListeners('rejectedCall').length,
    );


  }

  hideAll() {
    this.visibleVideoCallManagement = false;
    this.visibleReachingOrInProgressVideoCall = false;
    this.visibleIncomingVideoCall = false;
  }

  private handleCallAndSendMessageInPrivateChat(selectedChatItem: ChatItem) {
    this.message = {
      authorId: this.defaultUser.id,
      forward: false,
      roomId: selectedChatItem.chatId,
      messageContentType: MessageContentTypeEnum.VideoCall,
      body: environment.systemMessageProperties.prefix + MessageContentTypeEnum.VideoCall
    }

    this.messageService.createMessage(this.message).subscribe(
      {
        next: (responseMessage: Message) => {
          this.message = responseMessage;
          this.messageService.generateVideoCallUrl(this.message).subscribe({
            next: (videoCallUrl: string) => {
              this.generalService.setVideoCallUrl(videoCallUrl);
              this.sipService.sendMessage(selectedChatItem.peerUserExtension,
                JSON.stringify({
                  type: this.message.messageContentType,
                  message: this.message,
                  videoCallUrl: videoCallUrl,
                  peerExtension: this.defaultUser.extension,
                  callState: CallState.Trying
                })).then(() => {
                this.openSnackBar('Success.', 'success');
                this.updateViewsAndBehaviorsForPrivateChat(this.message, selectedChatItem);
              });
            },
            error: (error) => {
              if (error.status === 500) {
                this.openSnackBar('Server has problem, Please try in another time', 'error');
              } else if (error.status === 400) {
                this.openSnackBar(error.error, 'error');
              } else {
                this.openSnackBar('An error has occurred, Try again later.', 'error');
              }
            }
          });
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });

  }

  private handleCallAndSendMessageToNewContact(selectedContact: Contact) {
    this.message = {
      authorId: this.defaultUser.id,
      forward: false,
      roomId: null,
      messageContentType: MessageContentTypeEnum.VideoCall,
      unreadIDs: [selectedContact.id],
      body: environment.systemMessageProperties.prefix + MessageContentTypeEnum.VideoCall
    }

    this.messageService.createMessage(this.message).subscribe(
      {
        next: (responseMessage: Message) => {
          this.message = responseMessage;
          this.messageService.generateVideoCallUrl(this.message).subscribe({
            next: (videoCallUrl: string) => {
              this.generalService.setVideoCallUrl(videoCallUrl);
              this.sipService.sendMessage(selectedContact.extension,
                JSON.stringify({
                  type: this.message.messageContentType,
                  message: this.message,
                  videoCallUrl: videoCallUrl,
                  peerExtension: this.defaultUser.extension,
                  callState: CallState.Trying
                })).then(() => {
                this.openSnackBar('Success.', 'success');
                this.message.dateTime = responseMessage.dateTime;
                this.message.id = responseMessage.id;
                this.message.roomId = responseMessage.roomId;
                this.message.active = responseMessage.active;
                this.message.unreadIDs = responseMessage.unreadIDs;
                this.updateViewsAndBehaviorsForNewContact(this.message, selectedContact);
              });
            },
            error: (error) => {
              if (error.status === 500) {
                this.openSnackBar('Server has problem, Please try in another time', 'error');
              } else if (error.status === 400) {
                this.openSnackBar(error.error, 'error');
              } else {
                this.openSnackBar('An error has occurred, Try again later.', 'error');
              }
            }
          });
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });

  }

  private updateViewsAndBehaviorsForNewContact(message: Message, selectedContact: Contact) {
    let tempChatItem: ChatItem = {
      chatId: message.roomId,
      lastMessageBody: message.body,
      lastMessageId: message.id,
      lastMessageTime: message.dateTime,
      peerUserExtension: selectedContact.extension,
      peerUserId: selectedContact.id,
      peerUserName: selectedContact.displayName,
      peerUserProfileImgId: selectedContact.profileImgId,
      peerUserProfileImgSrc: selectedContact.profileImgSrc,
      reachEndOfMessages: false,
      unreadMessageCount: 0,
    };
    //update selectedChatMessages
    if (message.unreadIDs[0] === this.selectedId) {
      this.messageService.setCurrentConversationMessages([message]);
    }
    this.messageService.addOneMessageToAllConversationMessages(message.roomId, message)

    //update selectedChatItem Behavior with new messages
    this.generalService.setChatSelectedId(message.roomId);
    this.generalService.setContactSelectedId(null);
    this.generalService.setItemSelected(SelectedItemEnum.CHAT);


    //set ChatList Behavior with new messages
    this.chatService.addOneItemToChatMap(tempChatItem);

    this.generalService.scrollToBottomAtChatConversation();

  }

  private updateViewsAndBehaviorsForPrivateChat(message: Message, selectedChatItem: ChatItem) {

    selectedChatItem.lastMessageId = message.id;
    selectedChatItem.lastMessageBody = message.body;
    selectedChatItem.lastMessageTime = message.dateTime;
    //update selectedChatMessages
    if (this.selectedId === message.roomId) {
      this.messageService.addOneMessageToCurrentConversationMessages(message);
    }
    this.chatService.handleAComingMessageForMap(selectedChatItem.chatId, message, true);

    this.generalService.scrollToBottomAtChatConversation();
  }

  private updateCallStatus(callStatus: CallStatusEnum) {
    this.messageService.updateCallStatus(this.message.id, this.defaultUser.id, callStatus).subscribe(
      {
        next: () => {
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      }
    );
  }
}
