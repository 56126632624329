// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keyCloakConfig: {
    issuer: 'https://auth.allegiancemd.com/realms/Deyala-Realm',
    // issuer: 'http://10.0.2.2:8080/realms/Deyala-Realm',
    // logoutUri:'deyala-mobile://dashboard',
    logoutUri:'https://deyala.officeblaze.com/login',
    redirectUri: 'https://deyala.officeblaze.com/login',
    // redirectUri: 'deyala-mobile://dashboard',
    realm: 'Deyala-Realm',
    clientId: 'Deyala-Keycloak-Client',
    loadUserProfileAtStartUp: false
  },
  imageProperties: {
    maxImageSizeInMB: 5,
    maxImageSizeInBytes: 5242880,
    allowedTypes: ['image/jpeg', 'image/png', 'image/jpg'],
    defaultAvatarPath: "assets/images/users/avatar-0.jpg"
  },
  systemMessageProperties: {
    prefix: "##$$_system_$$##",

    attachmentType: "type:attachmentWithNoCaption",
    refreshGroupListType:"type:refreshGroupList",
    deleteMessageType: "type:deleteMessage",
    refreshGroupMembersType:"type:refreshGroupMembersList",

    chatIdPart:"__##$$chat$$##__",//Id of Chat (roomID) to be added __##$$chat$$##__54825
    messageIdPart:"__##$$message$$##__",//Id of message to be added __##$$message$$##__75747
    newMessageIdPart:"__##$$newMessage$$##__",//Id of message to be added __##$$message$$##__75747
    groupIdPart:"__##$$group$$##__",//Id of group (roomID) to be added __##$$group$$##__47268
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
