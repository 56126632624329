import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SIPService} from "../../../sip/services/sip-service";
import {LocalStorageService} from "../../../sip/services/local-storage-service";
import {EventService} from "../../../sip/services/event-service";
import {MainMenuService} from "../../main-menu/main-menu.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {User} from "../../../model/user.model";
import {ImageUtil} from '../../../../utils/image-util';
import {UserService} from "../../../services/user-service";
import {environment} from "../../../../environments/environment";
import {GeneralService} from "../../../services/general-service";
import {Message, MessageAttachments} from "../../../model/message.model";
import {MessageContentTypeEnum} from "../../../model/message-type";
import {ChatItem} from "../../../model/chatItem.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageService} from "../../../services/message-service";
import {Subject} from "rxjs";
import {ChatService} from "../../../services/chat-service";
import {Group} from "../../../model/group.model";
import {GroupService} from "../../../services/group-service";
import {Contact} from "../../../model/contact.model";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {v4 as uuid} from 'uuid';
import {MessagingService} from "../../../services/messaging-service";

@Component({
  selector: 'user-chat-input',
  templateUrl: './user-chat-input.component.html',
  styleUrls: ['./user-chat-input.component.scss']
})
export class UserChatInputComponent implements OnInit {

  emoji = '';

  message: Message = null;
  selectedChatId: string = null;
  selectedGroup: Group = null;
  selectedContactId: string = null;
  formData: FormGroup;
  replyMessage: Message;
  attachmentList: MessageAttachments[] = null;
  attachmentChanged: Subject<MessageAttachments> = new Subject<MessageAttachments>();
  attachmentsExtensions: string[] = ['.txt', '.png', '.pdf', '.docx', '.jpg', '.jpeg'];

  selectedItemType: SelectedItemEnum;

  defaultUser: User = null;
  userImageUrl: any;
  isSipConnected: boolean = false;
  isInternetConnected: boolean = true;
  sendingMessage: boolean = false;
  selectedFile: any = null;
  @ViewChild('imageFileInput') imageFileInput: ElementRef;

  contacts: Map<string, Contact> = new Map<string, Contact>();

  constructor(private sipService: SIPService, private localStorageService: LocalStorageService, private eventService: EventService, private groupService: GroupService,
              private mainMenuService: MainMenuService, private generalService: GeneralService, private messageService: MessageService, private chatService: ChatService,
              private userService: UserService, private imageUtil: ImageUtil, private _snackBar: MatSnackBar, private messagingService: MessagingService,
              public formBuilder: FormBuilder) {
  }


  ngOnInit(): void {

    this.generalService.uploadAttachmentMethodCalled.subscribe((fileList, event) => {
      this.uploadFile(fileList, event);
    });


    this.generalService.isSipConnected$.subscribe((val) => {
      this.isSipConnected = val;
    });
    this.generalService.clearChatInput$.subscribe(() => {
      this.clearChatInput();
      this.closeAttachmentCard();
    });


    this.generalService.replyMessage$.subscribe((value) => {
      this.replyMessage = value;
    });

    this.generalService.chatSelectedId$.subscribe((value) => {
      this.selectedChatId = value;
    });
    this.generalService.groupSelected$.subscribe((value) => {
      this.selectedGroup = value;
    });
    this.generalService.contactSelectedId$.subscribe((value) => {
      this.selectedContactId = value;
    });
    this.generalService.itemSelected$.subscribe((value) => {
      this.selectedItemType = value;
    });
    this.userService.contacts$.subscribe(value => {
      if (value !== null) {
        let temp: Contact[] = value;
        temp.forEach(value1 => {
          this.contacts.set(value1.id, value1);
        });
      }
    });


    this.generalService.sendingMessage$.subscribe((val) => {
      this.sendingMessage = val;
    });
    this.generalService.closeAttachmentCard$.subscribe(() => {
      this.closeAttachmentCard();
    })

    // Validation
    this.formData = this.formBuilder.group({
      message: ['', [Validators.required]],
    });

    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));
    this.generalService.isInternetConnected$.subscribe({
      next: (response) => {
        this.isInternetConnected = response;
      }
    })
  }

  /**
   * Save the message in chat
   */
  messageSave() {
    var groupMsg = document.querySelector('.pills-groups-tab.active');
    const message = this.formData.get('message')!.value;
    if ((message === null || message.trim() === '') && (this.img === null || this.img === '')) {
      return;
    }
    if (!groupMsg) {
      let element: Element = document.querySelector('.chat-user-list li.active .chat-user-message');
      if (element != null) {
        element.innerHTML = message ? message : this.img;
      }
    }

    this.generalService.scrollToBottomAtChatConversation();


    // Set Form Data Reset

    this.clearChatInput();

  }


  /**
   * Save the message in chat
   */
  async sendMessage() {

    if (!this.isSipConnected || !this.isInternetConnected || this.sendingMessage) {
      return
    }
    // this.generalService.setSendingMessage(true);
    if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.GROUP) {
      let copyOfSelectedGroup = {...this.selectedGroup};

      this.message = {
        authorId: this.defaultUser.id,
        body: this.formData.get('message')!.value,
        forward: false,
        roomId: copyOfSelectedGroup.id,
        messageContentType: this.attachmentList && this.attachmentList.length > 0 ? MessageContentTypeEnum.Attachment : MessageContentTypeEnum.Text,
        messageAttachmentsList: this.attachmentList,
        localUUId: uuid(),
        dateTime: new Date(),
        messageReplyId: this.replyMessage === null || this.replyMessage === undefined ? null : this.replyMessage.id,
        messageReplyBody: this.replyMessage === null || this.replyMessage === undefined ? null : this.getReplyBody(),
        messageReplyAuthorId: this.replyMessage === null || this.replyMessage === undefined ? null : this.replyMessage.authorId
      }

      let copyOfMessage = {...this.message};

      this.messagingService.sendMessageInGroup(copyOfSelectedGroup, copyOfMessage, this.attachmentList);
      //TODO: Fadi delete after test

      // this.sendMessageInGroup(copyOfSelectedGroup,copyOfMessage,this.attachmentList);

    } else if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.CHAT) {
      let copyOfSelectedChatItem: ChatItem = {...this.chatService.getChatItem(this.selectedChatId)};


      this.message = {
        authorId: this.defaultUser.id,
        body: this.formData.get('message')!.value,
        forward: false,
        roomId: copyOfSelectedChatItem.chatId,
        messageContentType: this.attachmentList && this.attachmentList.length > 0 ? MessageContentTypeEnum.Attachment : MessageContentTypeEnum.Text,
        messageAttachmentsList: this.attachmentList,
        localUUId: uuid(),
        dateTime: new Date(),
        messageReplyId: this.replyMessage === null || this.replyMessage === undefined ? null : this.replyMessage.id,
        messageReplyBody: this.replyMessage === null || this.replyMessage === undefined ? null : this.getReplyBody(),
        messageReplyAuthorId: this.replyMessage === null || this.replyMessage === undefined ? null : this.replyMessage.authorId
      }

      let copyOfMessage = {...this.message};

      this.messagingService.sendMessageInPrivateChat(copyOfSelectedChatItem, copyOfMessage, this.attachmentList);

    } else if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.CONTACT) {
      let copyOfSelectedContact: Contact = {...this.userService.getContactByUserId(this.selectedContactId)};

      this.message = {
        authorId: this.defaultUser.id,
        body: this.formData.get('message')!.value,
        forward: false,
        roomId: null,
        messageContentType: this.attachmentList && this.attachmentList.length > 0 ? MessageContentTypeEnum.Attachment : MessageContentTypeEnum.Text,
        messageAttachmentsList: this.attachmentList,
        unreadIDs: [copyOfSelectedContact.id],
        localUUId: uuid(),
        dateTime: new Date()
      }
      let copyOfMessage = {...this.message};

      this.messagingService.sendMessageToNewContact(copyOfSelectedContact, copyOfMessage, this.attachmentList);
    }

    this.clearChatInput();


    this.emoji = "";
  }

  private getReplyBody() {
    let replyBody = '';
    if (this.replyMessage.messageContentType === MessageContentTypeEnum.VideoCall) {
      replyBody = 'Video Call'
    } else if (this.replyMessage.messageContentType === MessageContentTypeEnum.AudioCall) {
      replyBody = 'Voice Call'
    } else if (this.replyMessage.messageContentType === MessageContentTypeEnum.Attachment
      && this.replyMessage.body === environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType) {
      replyBody = 'ATTACHMENT'
    } else if (this.replyMessage.messageContentType === MessageContentTypeEnum.Attachment
      && this.replyMessage.body !== environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType) {
      replyBody = this.replyMessage.body
    } else {
      replyBody = this.replyMessage.body;
    }

    return replyBody;
  }


// File Upload
  imageURL: string | undefined;
  img: any;

  fileChange(event: any) {
    let fileList: any = (event.target as HTMLInputElement);
    let file: File = fileList.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      this.imageURL = reader.result as string;
      this.img = this.imageURL;
    }
    reader.readAsDataURL(file)
  }

  uploadFile(fileList: FileList, event: any): void {
    this.checkPositionAttachment();
    document.querySelector('.attachmentCard')?.classList.add('show');
    // Return if canceled
    if (!fileList.length) {
      return;
    }

    for (let file = 0; file < fileList.length; file++) {
      const fileData = fileList[file];
      let fileArrayNumber;
      fileData.arrayBuffer().then((buff) => {
        fileArrayNumber = new Uint8Array(buff);
        const attachment: MessageAttachments = {
          name: fileData.name,
          contentType: fileData.type,
          attachment: Array.from(fileArrayNumber),
          downloadInProgress: false
        };
        if (this.attachmentList === null) {
          this.attachmentList = [attachment];
        } else {
          this.attachmentList.push(attachment);
        }
      }).catch((err) => {
        this.openSnackBar(err.message, 'error');
      });
    }
    if (event !== undefined && event !== null) event.target.value = null;
  }

  private checkPositionAttachment() {
    const attachmentCard = document.querySelector('.attachmentCard') as HTMLElement | null;
    if (document.querySelector('.replyCard')?.classList.contains('show')) {
      const box1Height = document.querySelector('.replyCard')?.clientHeight;
      attachmentCard.style.bottom = `${box1Height - 1}px`;
    } else {
      attachmentCard.style.bottom = `0px`;
    }
  }

  removeImage(attchment: MessageAttachments): void {
    const index = this.attachmentList.indexOf(attchment, 0);
    this.attachmentList.splice(index, 1);

    // Update the image
    this.attachmentChanged.next(attchment);
    if (this.attachmentList.length == 0) {
      this.imageFileInput.nativeElement.value = '';
      this.closeAttachmentCard();
    }
  }


  closeReply() {
    this.generalService.setReplyMessage(null);
    document.querySelector('.replyCard')?.classList.remove('show');
    this.checkPositionAttachment();
  }

  closeAttachmentCard() {
    this.attachmentList = null;
    document.querySelector('.attachmentCard')?.classList.remove('show');

  }


  // Emoji Picker
  showEmojiPicker = false;
  sets: any = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set: any = 'twitter';

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }


  addEmoji(event: any) {
    const {emoji} = this;
    const text = `${emoji}${event.emoji.native}`;
    this.emoji = text;
    this.showEmojiPicker = false;
  }

  onFocus() {
    this.showEmojiPicker = false;
  }

  onBlur() {
  }

  handleKeyDown(event) {
    if ((event.altKey || event.shiftKey || event.ctrlKey) && event.key === 'Enter') {
      const textarea = event.target as HTMLTextAreaElement;
      const {selectionStart, selectionEnd, value} = textarea;

      // Insert a line break at the current caret position
      const newValue = value.substring(0, selectionStart) + '\n' + value.substring(selectionEnd);
      this.emoji = newValue;
      textarea.value = newValue;

      // Prevent the default behavior of the key combination
      event.preventDefault();
      textarea.scrollTop = textarea.scrollHeight;
    }
  }

  /**
   * Returns form
   */
  get form() {
    return this.formData.controls;
  }


  clearChatInput() {
    this.formData = this.formBuilder.group({
      message: null,
    });
    // this.generalService.setReplyMessage(null);
    this.emoji = '';
    this.img = '';
    document.querySelector('.replyCard')?.classList.remove('show');

  }

  getUserImage(): void {
    // this.isLoading = true;
    this.userService.userImage$.subscribe((response) => {
        if (response === null || response.size === 0) {
          this.userImageUrl = environment.imageProperties.defaultAvatarPath;

          //this.isLoading = false;

        } else {
          this.userImageUrl = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response);
          //this.isLoading = false;

        }
      }
    );

  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }


  // stopSending(attachment: MessageAttachments) {
  //   console.log("attachment:  ",attachment)
  //   const index = this.attachmentList.indexOf(attachment, 0);
  //   if (index != -1){
  //     this.attachmentList.splice(index, 1);
  //   }
  // }

}
