export class LineModel {
  public state: LineState = null as any;
  public name: string = null as any;
  public value: string = null as any;
  public number: number = null as any;
}

export enum LineState {
  Pending = "Pending",
  Busy = "Busy",
  Available = "Available"
}
