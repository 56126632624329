import {Injectable} from "@angular/core";
import {Message, MessageAttachments} from "../model/message.model";
import {MessageService} from "./message-service";
import {ChatService} from "./chat-service";
import {GeneralService} from "./general-service";
import {ChatItem} from "../model/chatItem.model";
import {Contact} from "../model/contact.model";
import {SelectedItemEnum} from "../model/selected-item-enum";
import {Group} from "../model/group.model";
import {GroupService} from "./group-service";
import {User} from "../model/user.model";
import {environment} from "../../environments/environment";
import {v4 as uuid} from "uuid";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SIPService} from "../sip/services/sip-service";
import {UserService} from "./user-service";

@Injectable({
  providedIn: 'root'
})
export class MessagingService {


  selectedChatId: string = null;
  selectedGroup: Group = null;
  selectedContactId: string = null;
  selectedItemType: SelectedItemEnum;
  defaultUser: User = null;
  contacts: Map<string, Contact> = new Map<string, Contact>();


  constructor(
    private messageService: MessageService,
    private chatService: ChatService,
    private generalService: GeneralService,
    private groupService: GroupService,
    private _snackBar: MatSnackBar,
    private sipService: SIPService,
    private userService: UserService,
  ) {

    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));
    this.generalService.chatSelectedId$.subscribe((value) => {
      this.selectedChatId = value;
    });
    this.generalService.groupSelected$.subscribe((value) => {
      this.selectedGroup = value;
    });
    this.generalService.contactSelectedId$.subscribe((value) => {
      this.selectedContactId = value;
    });
    this.generalService.itemSelected$.subscribe((value) => {
      this.selectedItemType = value;
    });
    this.userService.contacts$.subscribe(value => {
      if (value !== null) {
        let temp: Contact[] = value;
        temp.forEach(value1 => {
          this.contacts.set(value1.id, value1);
        });
      }
    });
  }


  public sendAForwardedMessageInPrivateChat(copyOfSelectedChatItem: ChatItem, oldCopyOfMessage: Message, attachmentList: MessageAttachments[]) {

    let copyOfMessage = {...oldCopyOfMessage};
    let messages = this.messageService.getConversationMessagesFromAllConversationMessages(copyOfSelectedChatItem.chatId);
    if (messages === undefined || messages === null || messages.length === 0) {
      this.messageService.getRoomMessagesHistory(copyOfSelectedChatItem.chatId, 0).subscribe({
        next: (response: Message[]) => {
          this.messageService.addToAllConversationMessages(copyOfSelectedChatItem.chatId, response);
          this.sendMessageInPrivateChat(copyOfSelectedChatItem, copyOfMessage, attachmentList);
        },
        error: (err) => {
          if (err.status === 400) {
            this.openSnackBar(err.error, 'error');
          }
          if (err.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });
    } else {
      this.sendMessageInPrivateChat(copyOfSelectedChatItem, copyOfMessage, attachmentList);
    }
  }

  public sendAForwardedMessageInGroup(copyOfSelectedGroup: Group, oldCopyOfMessage: Message, attachmentList: MessageAttachments[]) {

    let copyOfMessage = {...oldCopyOfMessage};
    let messages = this.messageService.getConversationMessagesFromAllConversationMessages(copyOfSelectedGroup.id);
    if (messages === undefined || messages === null || messages.length === 0) {
      this.messageService.getRoomMessagesHistory(copyOfSelectedGroup.id, 0).subscribe({
        next: (response: Message[]) => {
          this.messageService.addToAllConversationMessages(copyOfSelectedGroup.id, response);
          this.sendMessageInGroup(copyOfSelectedGroup, copyOfMessage, attachmentList);
        },
        error: (err) => {

          if (err.status === 400) {
            this.openSnackBar(err.error, 'error');
          }
          if (err.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      })
    } else {
      this.sendMessageInGroup(copyOfSelectedGroup, copyOfMessage, attachmentList);
    }

  }

  public sendMessageInPrivateChat(copyOfSelectedChatItem: ChatItem, copyOfMessage: Message, attachmentList: MessageAttachments[]) {

    if (attachmentList && attachmentList.length > 0) {
      if (!copyOfMessage.body || copyOfMessage.body.trim() === "") {
        copyOfMessage.body = environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType;
      }
    } else {
      if ((!copyOfMessage.body || copyOfMessage.body.trim() === "")) {
        this.generalService.setSendingMessage(false);
        return;
      }
    }

    if (this.selectedChatId === copyOfMessage.roomId) {
      this.messageService.uploadOneMessageToCurrentConversationMessages(copyOfMessage);
    }
    this.messageService.uploadOneMessageToAllConversationMessages(copyOfMessage.roomId, copyOfMessage);
    this.generalService.clearChatInput();
    this.generalService.scrollToBottomAtChatConversation();
    this.generalService.setSendingMessage(true);

    this.messageService.createMessage(copyOfMessage).subscribe({
      next: async (responseMessage: Message) => {
        await this.sipService.sendMessage(copyOfSelectedChatItem.peerUserExtension,
          JSON.stringify({type: responseMessage.messageContentType, message: responseMessage}));
        document.getElementById('textMessageInput').style.visibility = "visible";
        copyOfMessage.dateTime = responseMessage.dateTime;
        copyOfMessage.id = responseMessage.id;
        copyOfMessage.active = responseMessage.active;
        copyOfMessage.unreadIDs = responseMessage.unreadIDs;
        if (copyOfMessage.messageAttachmentsList) {
          copyOfMessage.messageAttachmentsList.forEach((value) => {
            value.contentS3Id = copyOfMessage.id + '_' + value.name;
          });
        }
        this.generalService.closeAttachmentCard();
        this.generalService.setReplyMessage(null);
        this.updateViewsAndBehaviorsForChatItem(copyOfMessage, copyOfSelectedChatItem);
        if (this.selectedChatId === copyOfMessage.roomId) {
          this.generalService.setSendingMessage(false);
        }
      },
      error: (error) => {
        if (this.selectedChatId === copyOfMessage.roomId) {
          this.messageService.removeOneFakeMessageFromCurrentConversationMessages(copyOfMessage);
          this.generalService.setSendingMessage(false);
        }
        this.messageService.removeOneFakeMessageFromAllConversationMessages(copyOfMessage.roomId, copyOfMessage);
        this.generalService.scrollToBottomAtChatConversation();

        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
      }
    });
  }

  public sendMessageInGroup(copyOfSelectedGroup: Group, copyOfMessage: Message, attachmentList: MessageAttachments[]) {

    if (attachmentList && attachmentList.length > 0) {
      if (!copyOfMessage.body || copyOfMessage.body.trim() === "") {
        copyOfMessage.body = environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType;
      }
    } else {
      if (!copyOfMessage.body || copyOfMessage.body.trim() === "") {
        this.generalService.setSendingMessage(false);
        return;
      }
    }

    if (this.selectedGroup && this.selectedGroup.id === copyOfMessage.roomId) {
      this.messageService.uploadOneMessageToCurrentConversationMessages(copyOfMessage);
    }
    this.messageService.uploadOneMessageToAllConversationMessages(copyOfMessage.roomId, copyOfMessage);
    this.generalService.clearChatInput();
    this.generalService.scrollToBottomAtChatConversation();
    this.generalService.setSendingMessage(true);

    this.messageService.createMessage(copyOfMessage).subscribe({
      next: async (responseMessage: Message) => {
        for (const value of responseMessage.unreadIDs) {
          await this.sipService.sendMessage(this.contacts.get(value).extension,
            JSON.stringify({type: responseMessage.messageContentType, message: responseMessage}));
        }

        document.getElementById('textMessageInput').style.visibility = "visible";

        copyOfMessage.dateTime = responseMessage.dateTime;
        copyOfMessage.id = responseMessage.id;
        copyOfMessage.active = responseMessage.active;
        copyOfMessage.unreadIDs = responseMessage.unreadIDs;
        if (copyOfMessage.messageAttachmentsList) {
          copyOfMessage.messageAttachmentsList.forEach((value) => {
            value.contentS3Id = copyOfMessage.id + '_' + value.name;
          });
        }
        this.generalService.closeAttachmentCard();
        this.generalService.setReplyMessage(null);
        this.updateViewsAndBehaviorsForGroup(copyOfMessage, copyOfSelectedGroup);
        if (this.selectedGroup.id === copyOfMessage.roomId) {
          this.generalService.setSendingMessage(false);
        }
      },
      error: (error) => {

        if (this.selectedGroup && this.selectedGroup.id === copyOfMessage.roomId) {
          this.messageService.removeOneFakeMessageFromCurrentConversationMessages(copyOfMessage);
          this.generalService.setSendingMessage(false);
        }
        this.messageService.removeOneFakeMessageFromAllConversationMessages(copyOfMessage.roomId, copyOfMessage);
        this.generalService.scrollToBottomAtChatConversation();

        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
      }
    });
  }

  public sendMessageToNewContact(copyOfSelectedContact: Contact, oldCopyOfMessage: Message, attachmentList: MessageAttachments[]) {

    let copyOfMessage = {...oldCopyOfMessage};

    let copyOfMessageToBeSent = {...copyOfMessage}; //this copy to be with roomId = null , to get from Database a new Room


    if (attachmentList && attachmentList.length > 0) {
      if (!copyOfMessage.body || copyOfMessage.body.trim() === "") {
        copyOfMessage.body = environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType;
        copyOfMessageToBeSent.body = environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType;
      }
    } else {
      if ((!copyOfMessage.body || copyOfMessage.body.trim() === "")) {
        this.generalService.setSendingMessage(false);
        return;
      }
    }

    let tempChatItem: ChatItem = {
      chatId: uuid(), //it's a fake Id (temperory Id) t be related with the new  message
      lastMessageBody: copyOfMessage.body,
      lastMessageId: copyOfMessage.id,
      lastMessageTime: copyOfMessage.dateTime,
      peerUserExtension: copyOfSelectedContact.extension,
      peerUserId: copyOfSelectedContact.id,
      peerUserName: copyOfSelectedContact.displayName,
      peerUserProfileImgId: copyOfSelectedContact.profileImgId,
      peerUserProfileImgSrc: copyOfSelectedContact.profileImgSrc,
      reachEndOfMessages: false,
      unreadMessageCount: 0
    };

    copyOfMessage.roomId = tempChatItem.chatId; //set the fake room Id to the message to be saved in conversation


    let fakeRoomId = tempChatItem.chatId;
    let newRoomId = null;
    //the below method adding a fake Or Real ChatItem , if found oldUUId = null , then add it normally(as fake) , else delete the fake one and add the new Item
    this.chatService.uploadOneItemToChatMap(tempChatItem, null);

    //the below two lines add a temperory message in a fake room Id to be deleted upon (success and failure)
    if (
      (this.selectedChatId && (this.selectedChatId === fakeRoomId || this.selectedChatId === newRoomId))
      ||
      (this.selectedContactId && this.selectedContactId === copyOfMessage.unreadIDs[0])
    ) {
      this.messageService.uploadOneMessageToCurrentConversationMessages(copyOfMessage);
    }
    this.messageService.uploadOneMessageToAllConversationMessages(copyOfMessage.roomId, copyOfMessage);

    this.generalService.clearChatInput();
    this.generalService.scrollToBottomAtChatConversation();
    this.generalService.setSendingMessage(true);


    this.messageService.createMessage(copyOfMessageToBeSent).subscribe({
      next: async (responseMessage: Message) => {
        if (copyOfSelectedContact.extension !== this.defaultUser.extension) {
          await this.sipService.sendMessage(copyOfSelectedContact.extension,
            JSON.stringify({type: responseMessage.messageContentType, message: responseMessage}));
          document.getElementById('textMessageInput').style.visibility = "visible";
        }

        newRoomId = responseMessage.roomId;
        //remove the fake message from current conversation
        if (
          (this.selectedChatId && (this.selectedChatId === fakeRoomId || this.selectedChatId === newRoomId))
          ||
          (this.selectedContactId && this.selectedContactId === copyOfMessage.unreadIDs[0])
        ) {
          this.messageService.removeOneFakeMessageFromCurrentConversationMessages(copyOfMessage);
        }
        //remove fake message from messageMap
        this.messageService.removeOneFakeMessageFromAllConversationMessages(fakeRoomId, copyOfMessage);

        //and then add them with the right IDs
        copyOfMessage.dateTime = responseMessage.dateTime;
        copyOfMessage.id = responseMessage.id;
        copyOfMessage.roomId = responseMessage.roomId;
        copyOfMessage.active = responseMessage.active;
        copyOfMessage.unreadIDs = responseMessage.unreadIDs;
        copyOfMessage.uploading = false;
        if (copyOfMessage.messageAttachmentsList) {
          copyOfMessage.messageAttachmentsList.forEach((value) => {
            value.contentS3Id = copyOfMessage.id + '_' + value.name;
          });
        }
        this.generalService.closeAttachmentCard();
        this.generalService.setReplyMessage(null);
        if (this.selectedChatId === fakeRoomId)//if the selected ChatID is the old then set the the new ChatID
        {
          this.generalService.setChatSelectedId(copyOfMessage.roomId);
        }
        this.updateViewsAndBehaviorsForNewContact(copyOfMessage, copyOfSelectedContact, tempChatItem.chatId);
      },
      error: (error) => {
        if (
          (this.selectedChatId && (this.selectedChatId === fakeRoomId || this.selectedChatId === newRoomId))
          ||
          (this.selectedContactId && this.selectedContactId === copyOfMessage.unreadIDs[0])
        ) {
          this.messageService.removeOneFakeMessageFromCurrentConversationMessages(copyOfMessage);
        }
        this.messageService.removeOneFakeMessageFromAllConversationMessages(fakeRoomId, copyOfMessage);
        this.chatService.removeOneItemFromChatMap(tempChatItem);

        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
        this.generalService.setSendingMessage(false);
      }
    });
  }


  private updateViewsAndBehaviorsForChatItem(message: Message, copyofSelectedChatItem: ChatItem) {

    copyofSelectedChatItem.lastMessageId = message.id;
    copyofSelectedChatItem.lastMessageBody = message.body;
    copyofSelectedChatItem.lastMessageTime = message.dateTime;
    //update selectedChatMessages
    if (this.selectedChatId === message.roomId) {
      this.messageService.uploadOneMessageToCurrentConversationMessages(message);
      this.generalService.scrollToBottomAtChatConversation();

    }

    this.messageService.uploadOneMessageToAllConversationMessages(message.roomId, message);


    //set ChatList Behavior  with Last Message Body Date and Time
    this.chatService.updateChatMapOneItem(message.roomId, copyofSelectedChatItem);

    this.generalService.scrollToBottomAtChatConversation();

  }

  private updateViewsAndBehaviorsForNewContact(message: Message, copyOfSelectedContact: Contact, oldUUID: string) {

    let tempChatItem: ChatItem = {
      chatId: message.roomId,
      lastMessageBody: message.body,
      lastMessageId: message.id,
      lastMessageTime: message.dateTime,
      peerUserExtension: copyOfSelectedContact.extension,
      peerUserId: copyOfSelectedContact.id,
      peerUserName: copyOfSelectedContact.displayName,
      peerUserProfileImgId: copyOfSelectedContact.profileImgId,
      peerUserProfileImgSrc: copyOfSelectedContact.profileImgSrc,
      reachEndOfMessages: false,
      unreadMessageCount: 0,
    };

    //update selectedChatMessages
    if (
      (this.selectedChatId && (this.selectedChatId === oldUUID || this.selectedChatId === message.roomId))
      ||
      (this.selectedContactId && this.selectedContactId === message.unreadIDs[0])
      ||
      (this.selectedContactId && this.selectedContactId === this.defaultUser.id)
    ) {
      this.messageService.addOneMessageToCurrentConversationMessages(message);
      this.generalService.setSendingMessage(false);

    }
    this.messageService.addOneMessageToAllConversationMessages(message.roomId, message);

    //update selectedChatItem Behavior with new messages
    this.generalService.setChatSelectedId(message.roomId);
    this.generalService.setContactSelectedId(null);
    this.generalService.setItemSelected(SelectedItemEnum.CHAT);


    //the below method adding a fake Or Real ChatItem , if found oldUUId = null , then add it normally(as fake) , else delete the fake one and add the new Item
    this.chatService.uploadOneItemToChatMap(tempChatItem, oldUUID);

    this.generalService.scrollToBottomAtChatConversation();

  }

  private updateViewsAndBehaviorsForGroup(message: Message, copyOfSelectedGroup: Group) {

    copyOfSelectedGroup.lastMessageId = message.id;
    copyOfSelectedGroup.lastMessageBody = message.body;
    copyOfSelectedGroup.lastMessageTime = message.dateTime;
    //update selectedChatMessages
    if (this.selectedGroup && this.selectedGroup.id === message.roomId) {
      this.messageService.uploadOneMessageToCurrentConversationMessages(message);
      //update selectedChatItem Behavior with new messages
      this.generalService.setGroupSelected(copyOfSelectedGroup);
    }
    this.messageService.uploadOneMessageToAllConversationMessages(message.roomId, message);


    //set ChatList Behavior with new messages
    this.groupService.updateUserGroupsOneItem(message.roomId, copyOfSelectedGroup);

    this.generalService.scrollToBottomAtChatConversation();

  }


  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }


}
