export class ClickEvent extends EventTarget {

  constructor() {
    super()
  }

  public static HistoryClick: string = "HistoryClick";
  public static TransferClick: string = "TransferClick";
  public static UnTransferClick: string = "UnTransferClick";

}
