import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {properties} from "../../properties/urlProperties";
import {Group, GroupCreation} from "../model/group.model";
import {GroupMember} from "../model/groupMember.model";
import {Message} from "../model/message.model";
import {MessageService} from "./message-service";
import {GeneralService} from "./general-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ImageUtil} from '../../utils/image-util';
import {environment} from "../../environments/environment";
import {MessageContentTypeEnum} from "../model/message-type";
import {Contact} from "../model/contact.model";
import {SIPService} from "../sip/services/sip-service";
import {NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class GroupService {


  private _groupUrl: string = properties.deyalaSecureURL + '/group';

  private _userGroups: BehaviorSubject<Group[] | null> = new BehaviorSubject(null);
  private _selectedGroupMembers: BehaviorSubject<GroupMember[] | null> = new BehaviorSubject(null);
  private _groupImages: BehaviorSubject<any | null> = new BehaviorSubject(null);
  private _isAvatarLoading: BehaviorSubject<boolean | null> = new BehaviorSubject(null);


  constructor(private _httpClient: HttpClient, private messageService: MessageService
    , private generalService: GeneralService, private _snackBar: MatSnackBar
    , private imageUtil: ImageUtil, private sipService: SIPService, private offcanvasService: NgbOffcanvas) {
  }


  get userGroups$(): Observable<Group[]> {
    return this._userGroups.asObservable();
  }


  get groupImages$(): Observable<any> {
    return this._groupImages.asObservable();
  }

  get selectedGroupMembers$(): Observable<GroupMember[]> {
    return this._selectedGroupMembers.asObservable();
  }

  setSelectedGroupMembers(groupMembers: GroupMember[]): void {
    this._selectedGroupMembers.next(groupMembers);
  }

  setUserGroups(userGroups: Group[]): void {
    this._userGroups.next(userGroups);
  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  setUserGroupList(groupId: string, groupMembers: GroupMember[]) {
    const index = this._userGroups.getValue() !== null ? this._userGroups.getValue().findIndex(obj => {
      return obj.id === groupId;
    }) : -1;
    if (index >= 0) {
      let tempGroupList: Group[] = [...this._userGroups.getValue()];
      tempGroupList[index].members = groupMembers;
      this.setUserGroups(tempGroupList);
    }
  }

  getUserGroups(userId: string): Observable<any> {
    return this._httpClient.get(this._groupUrl + '/getUserGroups', {
      params: {'userId': userId}
    })
      .pipe(
        tap((response) => {
          this._userGroups.next(response);
        })
      );
  }

  getUserGroupsWithoutSettingBehavior(userId: string): Observable<any> {
    return this._httpClient.get(this._groupUrl + '/getUserGroups', {
      params: {'userId': userId}
    })
      .pipe(
        tap(() => {
        })
      );
  }

  updateGroupList(userId: string): void {
    this.getUserGroupsWithoutSettingBehavior(userId).subscribe({
      next: (response: Group[]) => {

        let tempWantedResult: Group[] = [];

        if (this.generalService.getSelectedGroupData) {
          let selecGroupIndex = response.findIndex(val => {
            return val.id === this.generalService.getSelectedGroupData.id
          });
          if (selecGroupIndex < 0) {
            this.showWelcomeSection();
          }
        }


        for (let group of response) {
          let index = this._userGroups.getValue() !== null ? this._userGroups.getValue().findIndex(obj => {
            return obj.id === group.id;
          }) : -1;

          if (index >= 0) {
            this._userGroups.getValue()[index].lastMessageId = group.lastMessageId;
            this._userGroups.getValue()[index].lastMessageBody = group.lastMessageBody;
            this._userGroups.getValue()[index].lastMessageTime = group.lastMessageTime;
            this._userGroups.getValue()[index].unreadMessageCount = group.unreadMessageCount;
            tempWantedResult.push(this._userGroups.getValue()[index])
          } else {
            tempWantedResult.push(group);

            //to get photo if exist
            if (group.groupImgId !== undefined && group.groupImgId !== null && group.groupImgId.trim() !== '') {

              group.srcImg = this.getGroupImgSrcIfExist(group.id);
              if (group.srcImg === undefined || group.srcImg === null) {

                this.getGroupImage(group.groupImgId, group.id, userId).subscribe({
                  next: value => {
                    if (value !== null && value.size !== 0) {
                      let imgSrc = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(value.imageData);
                      group.srcImg = imgSrc;
                    }

                  }
                });
              }
            }

          }
        }
        this._userGroups.next(tempWantedResult);
      },
      error: () => {
        console.log('Tried to update Group List for an incoming new message Or Message Creation');
      }
    });
  }

  UpdateGroupMembersList(groupId: string, userId: string, group: Group): void {
    this.getGroupMembersWithoutChangingBehaviours(groupId, userId).subscribe({
      next: (response) => {
        let tempWantedResult: GroupMember[] = [];
        let admin = null;
        for (let member of response) {
          let index = this._selectedGroupMembers.getValue() !== null ? this._selectedGroupMembers.getValue().findIndex(obj => {
            return obj.userId === member.userId
          }) : -1
          if (index >= 0) {
            this._selectedGroupMembers.getValue()[index].admin = member.admin
            this._selectedGroupMembers.getValue()[index].userName = member.userName
            if (this._selectedGroupMembers.getValue()[index].profileImgId !== member.profileImgId) {
              this._selectedGroupMembers.getValue()[index].profileImgId = member.profileImgId
              this._selectedGroupMembers.getValue()[index].profileImgSrc = null
            }
            tempWantedResult.push(this._selectedGroupMembers.getValue()[index]);
          } else {
            tempWantedResult.push(member);
          }
          if (member.userId === userId) {
            admin = member.admin
          }
        }
        this.setUserGroupList(groupId, tempWantedResult);
        const index = this._userGroups.getValue() !== null ? this._userGroups.getValue().findIndex(obj => {
          return obj.id === groupId;
        }) : -1;
        if (index >= 0) {
          if (admin !== null) {
            this._userGroups.getValue()[index].admin = admin;
          }
          let selectedGroup: Group = this.generalService.getSelectedGroupData;
          if (selectedGroup !== undefined && selectedGroup !== null && selectedGroup.id === group.id) {
            this.generalService.setGroupSelected(this._userGroups.getValue()[index]);
            if (this._selectedGroupMembers.getValue() !== undefined && this._selectedGroupMembers.getValue() !== null && this._selectedGroupMembers.getValue() === group.members) {
              this.setSelectedGroupMembers(tempWantedResult);
            }
          }
        }
      }
    })
  }

  removeMembersFromGroupList(groupId: string, userId: string) {
    // extra check to make sure that I am not selecting the group I am updating
    let selectedGroup: Group = this.generalService.getSelectedGroupData;
    if (selectedGroup !== undefined && selectedGroup !== null && selectedGroup.id === groupId) {
      let copyGroup: Group = {...selectedGroup};
      this.UpdateGroupMembersList(groupId, userId, copyGroup);
    } else {
      this.setUserGroupList(groupId, null);
    }
  }

  getGroupMembers(groupId: string, userId: string): Observable<any> {
    const params = {
      'groupId': groupId,
      'userId': userId
    };
    return this._httpClient.get(this._groupUrl + '/getGroupMembers', {
      params: params
    })
      .pipe(
        tap((response) => {
          console.log("response:  ", response)
          this.setUserGroupList(groupId, response);
        })
      );
  }

  getGroupMembersWithoutChangingBehaviours(groupId: string, userId: string): Observable<any> {
    const params = {
      'groupId': groupId,
      'userId': userId
    };
    return this._httpClient.get(this._groupUrl + '/getGroupMembers', {
      params: params
    })
      .pipe(
        tap(() => {
        })
      );
  }

  getGroupImage(groupImgId: string, groupId: string, userId: string): Observable<any> {
    const params = {
      'groupImgId': groupImgId,
      'groupId': groupId,
      'userId': userId
    };
    return this._httpClient.get(this._groupUrl + '/getGroupImage', {
      params: params
    });
  }

  createNewGroup(newGroup: GroupCreation, groupCreationImage: any, defaultUser: any) {
    let membersIds: string[] = newGroup.members.map(member => member.userId);
    const formData = new FormData();
    formData.append('groupName', newGroup.groupName);
    if (newGroup.groupImgId !== null) {
      formData.append('groupImgId', newGroup.groupImgId);
    }
    formData.append('createdBy', newGroup.createdBy);
    formData.append('createdDate', newGroup.createdDate.toString());
    formData.append('description', newGroup.description);
    formData.append('membersIds', membersIds.toString());
    if (newGroup.imageFile !== null) {
      formData.append('imageFile', newGroup.imageFile);
    }

    return this._httpClient.put(this._groupUrl + '/createGroup',
      formData,
      {
        responseType: 'text'
      }
    ).pipe(tap((groupId) => {
      let newMembers: GroupMember[] = [];
      newMembers.push({
        userId: defaultUser.id,
        userName: defaultUser.displayName,
        admin: true,
        profileImgId: defaultUser.profileImgId,
        profileImgSrc: defaultUser.profileImgSrc
      })
      newMembers = newMembers.concat(newGroup.members);

      let createdGroup: Group = {
        id: groupId,
        groupName: newGroup.groupName,
        createdDate: newGroup.createdDate,
        createdBy: newGroup.createdBy,
        unreadMessageCount: 0,
        admin: true,
        description: newGroup.description,
        groupImgId: newGroup.groupImgId,
        srcImg: groupCreationImage,
        lastMessageBody: null,
        lastMessageTime: null,
        lastMessageId: null,
        members: newMembers,
        messages: null,
        reachEndOfMessages: false
      }
      this._userGroups.next(this._userGroups.getValue().concat(createdGroup));
    }));

  }

  uploadGroupImage(photo: File, userId: string, groupId: string, oldGroupImgId: string): Observable<any> {
    const formData = new FormData();
    formData.append('groupImage', photo);
    formData.append('userId', userId);
    formData.append('groupId', groupId);
    formData.append("oldGroupImgId", oldGroupImgId)
    return this._httpClient.put(this._groupUrl + '/updateGroupImage', formData).pipe(
      tap(() => {
        // const blob = new Blob([photo]);
        // const srcImg = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        // this.generalService.setChatHeaderAvatar(srcImg);
      })
    );
  }


  get isAvatarLoading$(): Observable<boolean> {
    return this._isAvatarLoading.asObservable();
  }

  setIsAvatarLoading(value: boolean): void {
    this._isAvatarLoading.next(value);
  }

  deleteGroupImage(groupId: string, userId: string): Observable<any> {
    return this._httpClient.delete(this._groupUrl + '/deleteGroupImage', {
      params: {'groupId': groupId, 'userId': userId}
    });
  }

  updateGroupName(groupId: string, userId: string, groupName: string): Observable<void> {
    const parameters = {
      'groupId': groupId,
      'userId': userId,
      'groupName': groupName
    };
    return this._httpClient.put<void>(this._groupUrl + '/updateGroupName',
      null,
      {
        params: parameters
      });
  }

  updateGroupDescription(groupId: string, userId: string, groupDescription: string): Observable<void> {
    const parameters = {
      'groupId': groupId,
      'userId': userId,
      'groupDescription': groupDescription
    };
    return this._httpClient.put<void>(this._groupUrl + '/updateGroupDescription',
      null,
      {
        params: parameters
      });
  }

  addGroupMembers(groupId: string, userId: string, members: GroupMember[]) {
    let membersIds: string[] = members.map(member => member.userId);
    const parameters = {
      'groupId': groupId,
      'userId': userId,
      'membersIds': membersIds
    };
    return this._httpClient.put<void>(this._groupUrl + '/addGroupMember',
      null,
      {
        params: parameters
      }).pipe(tap(() => {
      this.setSelectedGroupMembers(this._selectedGroupMembers.getValue().concat(members));
      this.setUserGroupList(groupId, this._selectedGroupMembers.getValue());
    }));
  }

  deleteGroupMembers(groupId: string, userId: string, memberId: string) {
    const parameters = {
      'groupId': groupId,
      'userId': userId,
      'memberId': memberId
    };
    return this._httpClient.put<void>(this._groupUrl + '/deleteGroupMember',
      null,
      {
        params: parameters
      }).pipe(tap(() => {
      let tempGroupMemberList: GroupMember[] = [...this._selectedGroupMembers.getValue()];
      const indexToRemove = tempGroupMemberList.findIndex(member => member.userId === memberId);
      if (indexToRemove !== -1) {
        tempGroupMemberList.splice(indexToRemove, 1);
      }
      this.setSelectedGroupMembers(tempGroupMemberList);
      this.setUserGroupList(groupId, tempGroupMemberList);
    }));
  }


  updateUserGroupsOneItem(groupId: string, group: Group) {
    const index = this._userGroups.getValue().findIndex(obj => {
      return obj.id === groupId;
    });
    let tempGroupList: Group[] = [...this._userGroups.getValue()];
    tempGroupList[index] = group;
    this.setUserGroups(tempGroupList);
  }


  // addOneMessageToUserGroups(roomId: string, message: Message, groupSelected: boolean, userId: string) {
  //
  //   if (this._userGroups.getValue() === undefined || this._userGroups.getValue() === null) {
  //     //If Group tab wasn't open yet and we received a group related message.
  //     this.getUserGroups(userId).subscribe({
  //       next: () => {
  //         this.handleAComingMessage(roomId, message, groupSelected);
  //       }
  //     });
  //
  //   } else {
  //     this.handleAComingMessage(roomId, message, groupSelected);
  //   }
  //
  // }

  // private handleAComingMessage(roomId: string, message: Message, groupSelected: boolean) {
  //   const index = this._userGroups.getValue().findIndex(obj => {
  //     return obj.id === roomId;
  //   });
  //   let tempGroupList: Group[] = this.deepCopyUserGroupList();
  //
  //   if (tempGroupList[index].messages === undefined || tempGroupList[index].messages === null || tempGroupList[index].messages.length === 0) {
  //     this.messageService.getRoomMessagesHistory(roomId, 1).subscribe({
  //       next: (response) => {
  //         tempGroupList[index].messages = response;
  //         tempGroupList[index].messages.unshift(message);
  //         tempGroupList[index].lastMessageId = message.id;
  //         tempGroupList[index].lastMessageBody = message.body;
  //         tempGroupList[index].lastMessageTime = message.dateTime;
  //         if (!groupSelected) {
  //           tempGroupList[index].unreadMessageCount += 1;
  //         }
  //         this.setUserGroups(tempGroupList);
  //
  //       },
  //       error: () => {
  //         return;
  //       }
  //     });
  //   } else {
  //     tempGroupList[index].messages.unshift(message);
  //     tempGroupList[index].lastMessageId = message.id;
  //     tempGroupList[index].lastMessageBody = message.body;
  //     tempGroupList[index].lastMessageTime = message.dateTime;
  //     if (!groupSelected) {
  //       tempGroupList[index].unreadMessageCount += 1;
  //     }
  //     this.setUserGroups(tempGroupList);
  //
  //
  //   }
  //
  // }

  removeAdminPermission(groupId: string, userId: string, adminId: string) {
    const parameters = {
      'groupId': groupId,
      'userId': userId,
      'adminId': adminId
    };

    return this._httpClient.put(this._groupUrl + '/removeAdminPermission',
      null,
      {
        params: parameters
      }).pipe(tap(() => {
      let tempGroupMemberList: GroupMember[] = [...this._selectedGroupMembers.getValue()];
      const memberIndex = tempGroupMemberList.findIndex(member => member.userId === adminId);
      if (memberIndex !== -1) {
        tempGroupMemberList[memberIndex].admin = false;
      }
      this.setSelectedGroupMembers(tempGroupMemberList);
      this.setUserGroupList(groupId, tempGroupMemberList);
    }));
  }

  promoteToAdmin(groupId: string, userId: string, memberId: string) {
    const paramaters = {
      'groupId': groupId,
      'userId': userId,
      'memberId': memberId
    };

    return this._httpClient.put(this._groupUrl + '/promoteToAdmin',
      null,
      {
        params: paramaters
      }).pipe(tap(() => {
      let tempGroupMemberList: GroupMember[] = [...this._selectedGroupMembers.getValue()];
      const memberIndex = tempGroupMemberList.findIndex(member => member.userId === memberId);
      if (memberIndex !== -1) {
        tempGroupMemberList[memberIndex].admin = true;
      }
      this.setSelectedGroupMembers(tempGroupMemberList);
      this.setUserGroupList(groupId, tempGroupMemberList);
    }))
  }

  leaveGroup(groupId: string, userId: string) {
    const parameters = {
      'groupId': groupId,
      'userId': userId
    };

    return this._httpClient.put(this._groupUrl + '/leaveGroup',
      null,
      {
        params: parameters
      }).pipe(tap(() => {
      let tempGroups: Group[] = [...this._userGroups.getValue()];
      const groupIndex = tempGroups.findIndex(group => group.id == groupId)
      if (groupIndex !== -1)
        tempGroups.splice(groupIndex, 1);
      this.setUserGroups(tempGroups);
    }));
  }

  deleteGroup(groupId: string, userId: string) {
    const parameters = {
      'groupId': groupId,
      'userId': userId
    };
    return this._httpClient.put(this._groupUrl + '/deleteGroup',
      null,
      {
        params: parameters
      }).pipe(tap(() => {
      let tempGroups: Group[] = [...this._userGroups.getValue()];
      const groupIndex = tempGroups.findIndex(group => group.id == groupId)
      if (groupIndex !== -1)
        tempGroups.splice(groupIndex, 1);
      this.setUserGroups(tempGroups);
    }));
  }


  private deepCopyUserGroupList() {

    let copyGroupList: Group[] = [];

    if (this._userGroups.getValue() === undefined || this._userGroups.getValue() === null) {
      return null;
    }

    for (let item of this._userGroups.getValue()) {

      let copyGroup: Group = {
        id: item.id,
        groupName: item.groupName,
        groupImgId: item.groupImgId,
        srcImg: item.srcImg,
        unreadMessageCount: item.unreadMessageCount,
        createdDate: item.createdDate,
        createdBy: item.createdBy,
        admin: item.admin,
        description: item.description,
        lastMessageBody: item.lastMessageBody,
        lastMessageTime: item.lastMessageTime,
        lastMessageId: item.lastMessageId,
        members: item.members,
        messages: [],
        reachEndOfMessages: item.reachEndOfMessages
      };

      if (item.messages !== undefined && item.messages !== null) {
        item.messages.forEach(value => {
          copyGroup.messages.push({...value});
        });
      }

      copyGroupList.push(copyGroup);
    }

    return copyGroupList;
  }


  isMessageGroupRelated(message: Message, userId) {
    //return whether this message from a Group or not

    if (this._userGroups.getValue() === undefined || this._userGroups.getValue() === null) {
      //If Group tab wasn't open yet and we received a group related message.
      this.getUserGroups(userId).subscribe({
        next: () => {
          const index = this._userGroups.getValue().findIndex(obj => {
            return obj.id === message.roomId;
          });
          return index >= 0;

        }
      });

    } else {
      const index = this._userGroups.getValue().findIndex(obj => {
        return obj.id === message.roomId;
      });
      return index >= 0;
    }

  }


  getGroupImgSrcIfExist(groupId: String) {
    if (this._userGroups.getValue()) {
      const group = this._userGroups.getValue().find(obj => {
        return obj.id === groupId;
      });
      if (group !== undefined && group !== null && group.srcImg !== undefined && group.srcImg !== null) {
        return group.srcImg;
      }
    }
    return null;
  }


  updateGroupImgSrc(groupId: String, imgSrc: any) {
    const index = this._userGroups.getValue().findIndex(obj => {
      return obj.id === groupId;
    });
    let tempGroupList: Group[] = [...this._userGroups.getValue()];
    tempGroupList[index].srcImg = imgSrc;
    this.setUserGroups(tempGroupList);


  }

  updateGroupAdminPermission(groupId: String, admin: boolean) {
    const index = this._userGroups.getValue().findIndex(obj => {
      return obj.id === groupId;
    });
    let tempGroupList: Group[] = [...this._userGroups.getValue()];
    tempGroupList[index].admin = admin;
    this.setUserGroups(tempGroupList);


  }

  /********************New Code Structure*********************/


  addOneMessageToUserGroupsNew(roomId: string, message: Message, groupSelected: boolean, userId: string) {

    if (this._userGroups.getValue() === undefined || this._userGroups.getValue() === null) {
      //If Group tab wasn't open yet and we received a group related message.
      this.getUserGroups(userId).subscribe({
        next: () => {
          this.handleAComingMessageNew(roomId, message, groupSelected);
        }
      });

    } else {
      this.handleAComingMessageNew(roomId, message, groupSelected);
    }

  }


  private handleAComingMessageNew(roomId: string, message: Message, groupSelected: boolean) {
    const index = this._userGroups.getValue().findIndex(obj => {
      return obj.id === roomId;
    });
    let tempGroupList: Group[] = this.deepCopyUserGroupList();

    let messages: Message[] = this.messageService.getConversationMessagesFromAllConversationMessages(roomId);

    if (messages === undefined || messages === null || messages.length === 0) {
      this.messageService.getRoomMessagesHistory(roomId, 1).subscribe({
        next: (response) => {
          this.messageService.addToAllConversationMessages(roomId, response);
          this.messageService.addOneMessageToAllConversationMessages(roomId, message);
          tempGroupList[index].lastMessageId = message.id;
          tempGroupList[index].lastMessageBody = message.body;
          tempGroupList[index].lastMessageTime = message.dateTime;
          if (!groupSelected) {
            tempGroupList[index].unreadMessageCount += 1;
          }
          this.setUserGroups(tempGroupList);

        },
        error: () => {
          return;
        }
      });
    } else {
      this.messageService.addOneMessageToAllConversationMessages(roomId, message);
      tempGroupList[index].lastMessageId = message.id;
      tempGroupList[index].lastMessageBody = message.body;
      tempGroupList[index].lastMessageTime = message.dateTime;
      if (!groupSelected) {
        tempGroupList[index].unreadMessageCount += 1;
      }
      this.setUserGroups(tempGroupList);


    }

  }

  updateUserGroupMemberSrcImg(userId: string, newProfileImg: string, newSrcImg: any) {
    let tempGroupList: Group[] = this.deepCopyUserGroupList();
    tempGroupList?.forEach(value => {
      value.members?.forEach(member => {
        if (member.userId === userId) {
          member.profileImgId = newProfileImg;
          member.profileImgSrc = newSrcImg;
        }
      });
    });
    this.setUserGroups(tempGroupList);

  }

  updateUserGroupsMemberName(userId: string, newName: string) {
    let tempGroupList: Group[] = this.deepCopyUserGroupList();
    tempGroupList?.forEach(value => {
      value.members?.forEach(member => {
        if (member.userId === userId) {
          member.userName = newName;
        }
      });
    });
    this.setUserGroups(tempGroupList);
  }


  changeLastMessageIfMatch(messageToDelete: Message, newLastMessage: Message, currentUserId: string) {
    if (!messageToDelete) return;
    let tempGroupList: Group[] = this.deepCopyUserGroupList();
    const index = this._userGroups.getValue().findIndex(obj => {
      return obj.id === messageToDelete.roomId;
    });

    if (messageToDelete.unreadIDs.includes(currentUserId) && tempGroupList[index].unreadMessageCount > 0) {
      tempGroupList[index].unreadMessageCount -= 1;
    }

    if (tempGroupList[index].lastMessageId === messageToDelete.id) {

      tempGroupList[index].lastMessageId = (!newLastMessage) ? null : newLastMessage.id;
      tempGroupList[index].lastMessageBody = (!newLastMessage) ? null : newLastMessage.body;
      tempGroupList[index].lastMessageTime = (!newLastMessage) ? null : newLastMessage.dateTime;

    }
    this.setUserGroups(tempGroupList);

    return index;


  }

  async notifyAllMembers(contactMap: Map<string, Contact>, index: number, messageToDelete: Message, newMessage: Message, authorId: string) {
    let systemMessage: Message = {
      roomId: null,
      authorId: authorId,
      body:
        environment.systemMessageProperties.prefix
        + environment.systemMessageProperties.deleteMessageType
        + environment.systemMessageProperties.groupIdPart + messageToDelete.roomId
        + environment.systemMessageProperties.messageIdPart + messageToDelete.id
        + ((newMessage) ? environment.systemMessageProperties.newMessageIdPart + newMessage.id : ""),
      messageContentType: MessageContentTypeEnum.System,
    };

    let tempGroupList: Group[] = this.deepCopyUserGroupList();
    // debugger;
    let memberList: GroupMember[] = tempGroupList[index].members;

    if (memberList !== undefined && memberList !== null && memberList.length !== 0) {
      for (const value of memberList) {
        await this.sipService.sendMessage(contactMap.get(value.userId).extension,
          JSON.stringify({type: MessageContentTypeEnum.System, message: systemMessage}));
      }
    } else {

      this.getGroupMembers(messageToDelete.roomId, messageToDelete.authorId).subscribe({
        next: async () => {
          tempGroupList = this.deepCopyUserGroupList();
          memberList = tempGroupList[index].members;
          for (const value of memberList) {
            await this.sipService.sendMessage(contactMap.get(value.userId).extension,
              JSON.stringify({type: MessageContentTypeEnum.System, message: systemMessage}));
          }
        },
        error: () => {
          console.log('error in retrieving members');
        }
      });

    }


  }


  private showWelcomeSection() {
    this.offcanvasService.dismiss();
    document.querySelector('.user-chat').classList.remove('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.remove('d-none');
    document.querySelector('.user-chat').classList.add('d-none');
    this.generalService.setGroupSelected(null);
    this.generalService.setItemSelected(null);
    this.generalService.setChatHeaderAvatar(null);
    this.generalService.setChatHeaderAvatar(null);
    // this.generalService.setChatHeaderStatus(null);
    this.messageService.setCurrentConversationMessages(null);
  }

}
