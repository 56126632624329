export class PrefrenceModel {

  sipSettings: SIPSettings = {
    server: null as any,
    domain: null as any,
    username: null as any,
    password: null as any,
    name: null as any,
    enableLines: true
  };

  public voiceSettings: VoiceSettings = {
    input: null as any,
    output: null as any
  };

  public videoSettings: VideoSettings = {
    cam: null as any
  };

}

interface SIPSettings {
  server: string;
  domain: string;
  username: string;
  password: string;
  name: string;
  enableLines: Boolean;
}

interface VoiceSettings {
  input: string;
  output: string;
}

interface VideoSettings {
  cam: string;
};
