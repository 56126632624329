import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {DeleteModalComponent} from "../chat/delete-modal/delete-modal.component";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: NgbModal) {
  }

  openDeletionConfirmationModal(deleteAction: () => void, question, buttonText) {
    const modalRef = this.modalService.open(DeleteModalComponent, {centered: true});
    modalRef.componentInstance.deleteAction = deleteAction;
    modalRef.componentInstance.question = question;
    modalRef.componentInstance.button = buttonText;
  }


}
