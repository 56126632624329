import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {MainMenuService} from "../../main-menu/main-menu.service";
import {GeneralService} from "../../../services/general-service";
import {ChatItem} from "../../../model/chatItem.model";
import {ChatService} from "../../../services/chat-service";
import {UserService} from "../../../services/user-service";
import {User} from "../../../model/user.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MessageService} from "../../../services/message-service";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {Message} from "../../../model/message.model";
import {environment} from "../../../../environments/environment";
import {MessageContentTypeEnum} from "../../../model/message-type";
import {ImageUtil} from "../../../../utils/image-util";


@Component({
  selector: 'app-chat-list',
  templateUrl: './chat-list.component.html',
  styleUrls: ['./chat-list.component.scss']
})
export class ChatListComponent implements OnInit {

  lastSelectedChatId: string;
  defaultUser: User = null;

  chatMap: Map<string, ChatItem> = new Map<string, ChatItem>();
  chatMapKeys: Array<string> = new Array<string>();
  allConversationMessages: Map<string, Message[]> = new Map<string, Message[]>();


  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private mainMenuService: MainMenuService,
    private generalService: GeneralService,
    private chatService: ChatService,
    private userService: UserService,
    private messageService: MessageService,
    private _snackBar: MatSnackBar,
    private imageUtil: ImageUtil
  ) {
  }

  ngOnInit() {
    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));


    this.chatService.chatMap$.subscribe((response) => {
      if (response !== null) {
        this.chatMap = new Map([...Object.entries(response)].sort(this.sortByDateTimeMap));

        if (this.chatMap === undefined || this.chatMap === null || this.chatMap.size === 0) {
          this.chatMap = new Map([...response].sort(this.sortByDateTimeMap));
        }

        if (this.chatMap !== undefined && this.chatMap !== null && this.chatMap.size !== 0) {
          this.chatMap.forEach(value => {
            if (value.lastMessageBody === environment.systemMessageProperties.prefix + MessageContentTypeEnum.AudioCall) {
              value.lastMessageBody = "Voice Call";
            }
            if (value.lastMessageBody === environment.systemMessageProperties.prefix + MessageContentTypeEnum.VideoCall) {
              value.lastMessageBody = "Video Call";
            }
            if (value.lastMessageBody === environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType) {
              value.lastMessageBody = "ATTACHMENT";
            }
          })

        }

        //*** IMPORTANT ****: the below solution is to avoid development error ExpressionChangedAfterItHasBeenCheckedError  other wise use chatMap.keys() at HTML directly
        this.chatMapKeys = Array.from(this.chatMap.keys());

      }
    });

    this.messageService.allConversationMessages$.subscribe((response) => {
      if (response !== null) {
        this.allConversationMessages = new Map(response);
      }
    })


    // this.getChatList();

    this.getChatMap();


  }


  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

// Contact Search
  contactSearch() {
    var input: any, filter: any, ul: any, li: any, a: any | undefined, i: any, txtValue: any;
    input = document.getElementById("searchContact") as HTMLAreaElement;
    filter = input.value.toUpperCase();
    ul = document.querySelectorAll(".chat-user-list");
    ul.forEach((item: any) => {
      li = item.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("h5")[0];
        txtValue = a?.innerText;
        if (txtValue?.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    })
  }


  showChat(index: any) {

    if (this.chatMapKeys[index] !== this.lastSelectedChatId) {
      this.generalService.clearChatInput();
    }
    this.lastSelectedChatId = this.chatMapKeys[index];
    var removeClass = document.querySelectorAll('.chat-user-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });

    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');
    // event.target.closest('li').classList.add('active');
    const chatItemElement = document.getElementById(`chatItem${this.lastSelectedChatId}`);
    chatItemElement.closest('li').classList.add('active');

    let data: ChatItem = this.chatMap.get(this.lastSelectedChatId);

    this.generalService.setGroupSelected(null);
    this.generalService.setContactSelectedId(null);
    this.generalService.setChatSelectedId(data.chatId);

    if (this.messageService.isThereAnUploadingMessageByRoomId(data.chatId)) {
      this.generalService.setSendingMessage(true);
    } else {
      this.generalService.setSendingMessage(false);
    }

    this.getMessageList(data.chatId);

    this.generalService.setChatHeaderName(data.peerUserName);
    // this.generalService.setChatHeaderStatus('online');
    this.generalService.setChatHeaderAvatar(data.peerUserProfileImgSrc);
    this.generalService.setItemSelected(SelectedItemEnum.CHAT);

  }

  private getChatMap() {
    if (this.chatMap.size === 0) {
      this.generalService.setTabsLoading(true);
      this.chatService.getChatMap(this.defaultUser.id).subscribe({
        next: () => {
          this.generalService.setTabsLoading(false);
          this.displayImagesForInitialChatItems();
        },
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setTabsLoading(false);
        }
      });
    } else {
      this.displayImagesForInitialChatItems();
    }
  }

  private displayImagesForInitialChatItems() {
    this.chatMapKeys.slice(0, 15).forEach(value => {
      this.getChatItemDisplayedImage(this.chatMap.get(value));
    })
  }

  private getChatItemDisplayedImage(value: ChatItem) {
    value.peerUserProfileImgSrc = this.userService.getContactImgSrcIfExist(value.peerUserId);

    if (value.peerUserProfileImgSrc !== undefined && value.peerUserProfileImgSrc !== null)
      return;

    if (value.peerUserProfileImgId !== undefined && value.peerUserProfileImgId !== null && value.peerUserProfileImgId.trim() !== '') {

      this.userService.getUserImage(value.peerUserProfileImgId).subscribe({
        next: (response) => {
          if (response !== null && response.size !== 0) {
            value.peerUserProfileImgSrc = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response.imageData);
            this.userService.updateContactImgSrc(value.peerUserId, value.peerUserProfileImgSrc);


            //If chat was selected before images was loaded
            if (this.lastSelectedChatId && value.chatId === this.lastSelectedChatId) {
              this.generalService.setChatHeaderAvatar(value.peerUserProfileImgSrc);
            }

          }
        }
      })
    }
  }

  onScrollGetDisplayedItems() {
    let flag = 0;
    for (let i = 0; i < this.chatMapKeys.length; i++) {
      if (flag === 2) {
        break;
      }
      const chatItemElement = document.getElementById(`chatItem${i}`);
      let visible = this.visibleY(chatItemElement);
      if (visible && flag === 0) {
        flag = 1;
      }
      if (!visible && flag === 1) {
        flag = 2;
      }
      if (chatItemElement && visible) {
        this.getChatItemDisplayedImage(this.chatMap.get(this.chatMapKeys[i]));
      }
    }
  }

  private getMessageList(selectedChatId: string) {

    let messages = this.allConversationMessages.get(selectedChatId);
    if (messages === undefined || messages === null || messages.length === 0) {
      this.messageService.setCurrentConversationMessages(null);
      this.generalService.setMessagesLoading(true);
      this.messageService.getRoomMessagesHistory(selectedChatId, 0).subscribe({
        next: (response: Message[]) => {
          this.messageService.setCurrentConversationMessages(response);
          this.messageService.addToAllConversationMessages(selectedChatId, response);
          this.generalService.scrollToBottomAtChatConversation();
          this.generalService.setMessagesLoading(false);
          this.markAllMessagesAsRead(selectedChatId);

        },
        error: (err) => {

          if (err.status === 400) {
            this.openSnackBar(err.error, 'error');
          }
          if (err.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setMessagesLoading(false);
        }
      })
    } else {

      this.messageService.setCurrentConversationMessages(messages);
      this.generalService.scrollToBottomAtChatConversation();
      this.markAllMessagesAsRead(selectedChatId);

    }


  }

  private markAllMessagesAsRead(selectedChatItemId: string) {
    this.messageService.markChatMessagesAsRead(this.defaultUser.id, selectedChatItemId).subscribe({

      next: () => {
        this.messageService.markAllMessagesAsRead(selectedChatItemId, this.defaultUser.id);
        this.chatService.updateChatMapOneItemUnreadMessageCount(selectedChatItemId, 0);
      },
      error: () => {
        console.log('Cannot mark messages as Read');
      }
    })
  }

  visibleY(el) {
    let rect = el.getBoundingClientRect();
    let top = rect.top;
    let height = rect.height;
    el = el.parentNode;
    do {
      rect = el.getBoundingClientRect();
      if (top > rect.bottom) return false;
      // Check if the element is out of view due to a container scrolling
      if ((top + height) <= rect.top) return false
      el = el.parentNode;
    } while (el != document.getElementById("scrollView"));
    // Check it's within the document viewport
    return top <= document.documentElement.clientHeight;
  };

  sortByDateTimeMap = (a: [string, ChatItem], b: [string, ChatItem]) => {
    return (new Date(a[1].lastMessageTime).getTime() < new Date(b[1].lastMessageTime).getTime()) ? 1 : -1;
  }

  fileDroppedToChatHandler(event: any, index: any) {
    this.showChat(index);
    // Emit the event to notify UserChatInputComponent
    this.generalService.uploadAttachmentMethodCalled.emit(event);
  }
}

