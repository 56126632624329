import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {properties} from "../../properties/urlProperties";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {ChatItem} from "../model/chatItem.model";
import {Message} from "../model/message.model";
import {MessageService} from "./message-service";
import {UserService} from "./user-service";
import {ImageUtil} from '../../utils/image-util';
import {environment} from "../../environments/environment";
import {MessageContentTypeEnum} from "../model/message-type";
import {SIPService} from "../sip/services/sip-service";

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  private _chatUrl: string = properties.deyalaSecureURL + '/chat';
  // private _chatList: BehaviorSubject<ChatItem[] | null> = new BehaviorSubject(null);
  private _chatMap: BehaviorSubject<Map<string, ChatItem> | null> = new BehaviorSubject<Map<string, ChatItem> | null>(null);


  constructor(
    private _httpClient: HttpClient, private messageService: MessageService, private userService: UserService,
    private imageUtil: ImageUtil,
    private sipService: SIPService
  ) {
  }

  // getChatList(userId:string): Observable<any> {
  //   return this._httpClient.get(this._chatUrl + '/getChatList', {
  //     params:{'userId':userId}
  //   })
  //     .pipe(
  //       tap((response) => {
  //         this._chatList.next(response);
  //
  //       })
  //     );
  // }

  getChatListWithoutSettingBehavior(userId: string): Observable<any> {
    return this._httpClient.get(this._chatUrl + '/getChatList', {
      params: {'userId': userId}
    })
      .pipe(
        tap(() => {
        })
      );
  }

  //
  // updateChatList(userId:string): void {
  //   this.getChatListWithoutSettingBehavior(userId).subscribe({
  //     next: (response) => {
  //
  //       let tempWantedResult: ChatItem[] = [];
  //       for (let chatItem of response) {
  //         let index =this._chatList.getValue()!==null?this._chatList.getValue().findIndex(obj => {return obj.chatId === chatItem.chatId;}):-1;
  //
  //
  //         if (index >= 0) {
  //           tempWantedResult.push(this._chatList.getValue()[index])
  //         } else {
  //           tempWantedResult.push(chatItem)
  //
  //           if(chatItem.peerUserProfileImgId!==undefined && chatItem.peerUserProfileImgId!==null && chatItem.peerUserProfileImgId.trim()!==''){
  //
  //             chatItem.peerUserProfileImgSrc =  this.userService.getContactImgSrcIfExist(chatItem.peerUserId);
  //              if(chatItem.peerUserProfileImgSrc===undefined||chatItem.peerUserProfileImgSrc===null){
  //
  //                this.userService.getUserImage(chatItem.peerUserProfileImgId).subscribe({
  //                  next:value => {
  //                    if (value !== null && value.size !== 0) {
  //                      const blob = new Blob([value]);
  //                     let imgSrc = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
  //                      chatItem.peerUserProfileImgSrc = imgSrc;
  //                      this.userService.updateContactImgSrc(chatItem.peerUserId,imgSrc);
  //                  }
  //
  //                  }
  //                });
  //              }
  //           }
  //         }
  //       }
  //       this._chatList.next(tempWantedResult);
  //     },
  //     error: () => {
  //       console.log('Error on Trying to update chat List for an incoming new message');
  //     }
  //   });
  // }
  //
  //
  // get chatList$(): Observable<ChatItem[]> {
  //   return this._chatList.asObservable();
  // }
  //
  // setChatList(value:ChatItem[]):void {
  //   this._chatList.next(value);
  // }
  //
  // updateChatListOneItem(chatId: string, chatItem:ChatItem) {
  //   const index = this._chatList.getValue().findIndex(obj => {return obj.chatId === chatId;});
  //   let tempChatList:ChatItem[] =this.deepCopyChatList();
  //   tempChatList[index]={...chatItem};
  //   this.setChatList(tempChatList);
  // }

  // addOneItemToChatList(chatItem:ChatItem) {
  //   let tempChatList:ChatItem[] =this.deepCopyChatList();
  //   tempChatList.unshift(chatItem);
  //   this.setChatList(tempChatList);
  // }

  // addOneMessageToChatList(chatId: string, message: Message ,chatSelected:boolean,userId:string) {
  //
  //   if(this._chatList.getValue()===undefined || this._chatList.getValue()===null){
  //     //If Chat tab wasn't open yet and we received a chat related message.
  //     this.getChatList(userId).subscribe({
  //       next:()=>{
  //         this.handleAComingMessage(chatId,message,chatSelected);
  //       }
  //     })
  //
  //   }
  //   else {
  //     this.handleAComingMessage(chatId,message,chatSelected);
  //   }
  //
  // }

  // private handleAComingMessage(chatId: string, message: Message, chatSelected: boolean)
  // {
  //   const index = this._chatList.getValue().findIndex(obj => {
  //     return obj.chatId === chatId;
  //   });
  //   let tempChatList:ChatItem[] =this.deepCopyChatList();
  //
  //   if(tempChatList[index].messages===undefined|| tempChatList[index].messages===null || tempChatList[index].messages.length===0)
  //   {
  //     this.messageService.getRoomMessagesHistory(chatId,1).subscribe({
  //       next:(response)=>{
  //         tempChatList[index].messages=response;
  //         tempChatList[index].messages.unshift(message);
  //         tempChatList[index].lastMessageId=message.id;
  //         tempChatList[index].lastMessageBody=message.body;
  //         tempChatList[index].lastMessageTime=message.dateTime;
  //         if(!chatSelected)
  //         {tempChatList[index].unreadMessageCount +=1;}
  //         this.setChatList(tempChatList);
  //
  //       },
  //       error:()=>{
  //         return;
  //       }
  //     });
  //   }
  //   else {
  //     tempChatList[index].messages.unshift(message);
  //     tempChatList[index].lastMessageId=message.id;
  //     tempChatList[index].lastMessageBody=message.body;
  //     tempChatList[index].lastMessageTime=message.dateTime;
  //     if(!chatSelected)
  //     {tempChatList[index].unreadMessageCount +=1;}
  //     this.setChatList(tempChatList);
  //
  //
  //   }
  // }

  // isMessageChatRelated(message : Message,userId:string){
  //   //return whether this message from a chat or not
  //
  //   if (this._chatList.getValue()===undefined||this._chatList.getValue()===null){
  //     //If Chat tab wasn't open yet and we received a Chat related message.
  //     this.getChatList(userId).subscribe({
  //       next:()=>{
  //         const index = this._chatList.getValue().findIndex(obj => {return obj.chatId === message.roomId;});
  //         return index >= 0;
  //       }
  //     })
  //     }
  //   else {
  //     const index = this._chatList.getValue().findIndex(obj => {return obj.chatId === message.roomId;});
  //     return index >= 0;
  //   }
  //
  // }
  //
  // getChatItemByUserId(id: string) {
  //   const index = this._chatList.getValue().findIndex(obj => {
  //     return obj.peerUserId === id;
  //   });
  //   if (index >= 0) {
  //     return this._chatList.getValue()[index];
  //   } else {
  //     return null;
  //   }
  //
  // }
  //
  // private deepCopyChatList() {
  //
  //   let temp:ChatItem[]=[];
  //   for (let item of this._chatList.getValue()){
  //
  //     let copyChatItem:ChatItem={
  //       chatId: item.chatId,
  //       lastMessageBody: item.lastMessageBody,
  //       lastMessageId: item.lastMessageId,
  //       lastMessageTime: item.lastMessageTime,
  //       messages: [],
  //       peerUserExtension: item.peerUserExtension,
  //       peerUserId: item.peerUserId,
  //       peerUserName: item.peerUserName,
  //       peerUserProfileImgId: item.peerUserProfileImgId,
  //       peerUserProfileImgSrc: item.peerUserProfileImgSrc,
  //       reachEndOfMessages: item.reachEndOfMessages,
  //       unreadMessageCount: item.unreadMessageCount,
  //     };
  //
  //     if(item.messages!==undefined&&item.messages!==null){
  //     item.messages.forEach(value => {
  //       copyChatItem.messages.push({...value});
  //     });}
  //
  //     temp.push(copyChatItem);
  //   }
  //
  //   return temp;
  // }
  //
  // updateChatListPeerName(userId: string, newName:string) {
  //   const index = this._chatList.getValue().findIndex(obj => {return obj.peerUserId === userId;});
  //   if(index<0) return;
  //   this._chatList.getValue()[index].peerUserName=newName;
  //   this.setChatList(this._chatList.getValue());
  // }
  //
  // updateChatListPeerSrcImg(userId: string, newProfileImg:string ,newSrcImg:any) {
  //   const index = this._chatList.getValue().findIndex(obj => {return obj.peerUserId === userId;});
  //   if(index<0) return;
  //   this._chatList.getValue()[index].peerUserProfileImgSrc=newSrcImg;
  //   this._chatList.getValue()[index].peerUserProfileImgId=newProfileImg;
  //   this.setChatList(this._chatList.getValue());
  // }


  get chatMap$(): Observable<Map<string, ChatItem>> {
    return this._chatMap.asObservable();
  }

  setChatMap(value: Map<string, ChatItem>): void {
    this._chatMap.next(value);
  }

  getChatMap(userId: string): Observable<any> {
    return this._httpClient.get(this._chatUrl + '/getChatMap', {
      params: {'userId': userId}
    })
      .pipe(
        tap((response) => {
          this._chatMap.next(response);

        })
      );
  }

  getChatItem(chatId: string): ChatItem {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (tempChatMap !== null) {
      return tempChatMap.get(chatId);
    }
    return null;
  }

  getPeerUserIdByChatId(chatId: string): string {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (tempChatMap !== null && tempChatMap.get(chatId)) {
      return tempChatMap.get(chatId).peerUserId;
    }
    return null;
  }

  getPeerUserExtensionByChatId(chatId: string): string {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (tempChatMap !== null) {
      return tempChatMap.get(chatId).peerUserExtension;
    }
    return null;
  }


  updateChatMapOneItem(chatId: string, chatItem: ChatItem) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    tempChatMap.set(chatId, chatItem);
    this.setChatMap(tempChatMap);
  }

  updateChatMapOneItemUnreadMessageCount(chatId: string, unreadMessageCount: number) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    tempChatMap.get(chatId).unreadMessageCount = unreadMessageCount;
    this.setChatMap(tempChatMap);
  }


  updateChatMapOneItemLastMessage(chatId: string, message: Message, isChatSelected: boolean) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    tempChatMap.get(chatId).lastMessageId = message.id;
    tempChatMap.get(chatId).lastMessageBody = message.body;
    tempChatMap.get(chatId).lastMessageTime = message.dateTime;
    if (!isChatSelected) {
      tempChatMap.get(chatId).unreadMessageCount += 1;
    }
    this.setChatMap(tempChatMap);
  }


  getChatMapWithoutSettingBehavior(userId: string): Observable<Map<string, ChatItem>> {
    return this._httpClient.get<Map<string, ChatItem>>(this._chatUrl + '/getChatMap', {
      params: {'userId': userId}
    })
      .pipe(
        tap(() => {
        })
      );
  }


  updateChatMap(userId: string): void {
    this.getChatMapWithoutSettingBehavior(userId).subscribe({
      next: (response) => {
        let responseMap: Map<string, ChatItem> = new Map([...Object.entries(response)]);

        let tempWantedResult: Map<string, ChatItem> = new Map<string, ChatItem>();

        responseMap.forEach((value, key) => {

          let tempChatItem: ChatItem = this._chatMap.getValue() ? this._chatMap.getValue()[key] : null;

          if (tempChatItem !== undefined && tempChatItem !== null) {
            tempChatItem.lastMessageId = value.lastMessageId;
            tempChatItem.lastMessageBody = value.lastMessageBody;
            tempChatItem.lastMessageTime = value.lastMessageTime;
            tempChatItem.unreadMessageCount = value.unreadMessageCount;
            tempWantedResult.set(key, tempChatItem)
          } else {
            tempWantedResult.set(key, value);

            if (value.peerUserProfileImgId !== undefined && value.peerUserProfileImgId !== null && value.peerUserProfileImgId.trim() !== '') {

              value.peerUserProfileImgSrc = this.userService.getContactImgSrcIfExist(value.peerUserId);
              if (value.peerUserProfileImgSrc === undefined || value.peerUserProfileImgSrc === null) {
                this.userService.getUserImage(value.peerUserProfileImgId).subscribe({
                  next: (response) => {
                    if (response !== null && response.size !== 0) {
                      let imgSrc = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response.imageData);
                      value.peerUserProfileImgSrc = imgSrc;
                      this.userService.updateContactImgSrc(value.peerUserId, imgSrc);
                    }
                  }
                });
              }
            }

          }
        });

        this.setChatMap(tempWantedResult);
      },
      error: () => {
        console.log('Error on Trying to update chat List for an incoming new message');
      }
    });
  }


  addOneMessageToChatMap(chatId: string, message: Message, isChatSelected: boolean, userId: string) {

    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();

    if (tempChatMap === undefined || tempChatMap === null) {
      //If Chat tab wasn't open yet and we received a chat related message.
      this.getChatMap(userId).subscribe({
        next: () => {
          this.handleAComingMessageForMap(chatId, message, isChatSelected);
        }
      })

    } else {
      this.handleAComingMessageForMap(chatId, message, isChatSelected);
    }

  }

  handleAComingMessageForMap(chatId: string, message: Message, isChatSelected: boolean) {

    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();

    let messages: Message[] = this.messageService.getConversationMessagesFromAllConversationMessages(chatId);

    if (messages === undefined || messages === null || messages.length === 0) {
      this.messageService.getRoomMessagesHistory(chatId, 1).subscribe({
        next: (response: Message[]) => {
          this.messageService.addToAllConversationMessages(chatId, response);
          this.messageService.addOneMessageToAllConversationMessages(chatId, message);
          tempChatMap.get(chatId).lastMessageId = message.id;
          tempChatMap.get(chatId).lastMessageBody = message.body;
          tempChatMap.get(chatId).lastMessageTime = message.dateTime;

          if (!isChatSelected) {
            tempChatMap.get(chatId).unreadMessageCount += 1;
          }

          this.setChatMap(tempChatMap);

        },
        error: () => {
          return;
        }
      });
    } else {
      this.messageService.addOneMessageToAllConversationMessages(chatId, message);
      tempChatMap.get(chatId).lastMessageId = message.id;
      tempChatMap.get(chatId).lastMessageBody = message.body;
      tempChatMap.get(chatId).lastMessageTime = message.dateTime;

      if (!isChatSelected) {
        tempChatMap.get(chatId).unreadMessageCount += 1;
      }
      this.setChatMap(tempChatMap);


    }


  }

  isMessageChatRelatedForMap(message: Message, userId: string): boolean {
    //return whether this message from a chat or not
    let tempChatMapFirst: Map<string, ChatItem> = this.getCopyOfChatMap();

    if (tempChatMapFirst === undefined || tempChatMapFirst === null) {
      //If Chat tab wasn't open yet and we received a Chat related message.
      // this.getChatMap(userId).subscribe({
      //   next:()=>{
      //     let tempChatMap: Map<string, ChatItem> =this.getCopyOfChatMap();
      //     if(tempChatMap){
      //     const chatItem = tempChatMap.get(message.roomId);
      //     return (chatItem!==undefined && chatItem !== null);
      //     }
      //     else{
      //     return false;
      //     }
      //
      //   }
      // });
      return false;
    } else {
      const chatItem = tempChatMapFirst.get(message.roomId);
      return (chatItem !== undefined && chatItem !== null);
    }

  }


  getChatItemIdByUserIdForMap(userId: string) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (!tempChatMap || tempChatMap.size === 0) return null;

    let valueRes = null;
    tempChatMap.forEach((value) => {
      if (valueRes) return;
      if (value.peerUserId === userId) valueRes = value.chatId;
    });

    return valueRes;

  }


  getChatItemByExtension(extension: string): ChatItem {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (!tempChatMap || tempChatMap.size === 0) return null;

    let valueRes = null;
    tempChatMap.forEach((value) => {
      if (valueRes) return;
      if (value.peerUserExtension === extension) valueRes = value;
    });

    return valueRes;

  }

  addOneItemToChatMap(chatItem: ChatItem) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (tempChatMap !== undefined && tempChatMap !== null && tempChatMap.size !== 0) {
      tempChatMap = new Map([[chatItem.chatId, chatItem], ...tempChatMap]);
    } else {
      tempChatMap = new Map([[chatItem.chatId, chatItem]]);
    }
    this.setChatMap(tempChatMap);
  }


  uploadOneItemToChatMap(chatItem: ChatItem, oldUUID: string) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (tempChatMap !== undefined && tempChatMap !== null && tempChatMap.size !== 0) {
      if (!oldUUID) {
        chatItem.isChatItemIsTemp = true;
        tempChatMap = new Map([[chatItem.chatId, chatItem], ...tempChatMap]);
      } else {
        chatItem.isChatItemIsTemp = false;
        tempChatMap.delete(oldUUID);
        tempChatMap = new Map([[chatItem.chatId, chatItem], ...tempChatMap]);
      }
    } else {
      chatItem.isChatItemIsTemp = true;
      tempChatMap = new Map([[chatItem.chatId, chatItem]]);
    }
    this.setChatMap(tempChatMap);
  }

  removeOneItemFromChatMap(chatItem: ChatItem) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (tempChatMap !== undefined && tempChatMap !== null && tempChatMap.size !== 0) {
      tempChatMap.delete(chatItem.chatId);
    }
    this.setChatMap(tempChatMap);
  }

  updateChatMapPeerSrcImg(userId: string, newProfileImg: string, newSrcImg: any) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (tempChatMap !== undefined && tempChatMap !== null && tempChatMap.size !== 0) {

      tempChatMap.forEach((value) => {
        if (value.peerUserId === userId) {
          value.peerUserProfileImgSrc = newSrcImg;
          value.peerUserProfileImgId = newProfileImg;
          this.setChatMap(tempChatMap);
          return;
        }
      });
    }
  }

  updateChatMapPeerName(userId: string, newName: string) {
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    if (tempChatMap !== undefined && tempChatMap !== null && tempChatMap.size !== 0) {

      tempChatMap.forEach((value) => {
        if (value.peerUserId === userId) {
          value.peerUserName = newName;
          this.setChatMap(tempChatMap);
          return;
        }
      });
    }
  }


  private getCopyOfChatMap() {
    if (this._chatMap.getValue() === undefined || this._chatMap.getValue() === null) return null;
    let tempChatMap: Map<string, ChatItem> = new Map([...Object.entries(this._chatMap.getValue())]);

    if (tempChatMap === undefined || tempChatMap === null || tempChatMap.size === 0) {
      tempChatMap = new Map([...this._chatMap.getValue()]);
    }
    return tempChatMap;
  }


  changeLastMessageIfMatch(messageToDelete: Message, newLastMessage: Message, currentUserId) {
    if (!messageToDelete) return;
    let tempChatMap: Map<string, ChatItem> = this.getCopyOfChatMap();
    let tempChatItem = tempChatMap.get(messageToDelete.roomId);

    if (messageToDelete.unreadIDs.includes(currentUserId) && tempChatItem.unreadMessageCount > 0) {
      tempChatItem.unreadMessageCount -= 1;
    }
    if (tempChatItem.lastMessageId === messageToDelete.id) {

      tempChatItem.lastMessageId = (!newLastMessage) ? null : newLastMessage.id;
      tempChatItem.lastMessageBody = (!newLastMessage) ? null : newLastMessage.body;
      tempChatItem.lastMessageTime = (!newLastMessage) ? null : newLastMessage.dateTime;

      tempChatMap.set(messageToDelete.roomId, tempChatItem);
    }
    this.setChatMap(tempChatMap);
    return tempChatItem.peerUserExtension;

  }

  async notifyPeerUser(peerUserExtension: string, messageToDelete: Message, newMessage: Message, authorId: string) {
    let systemMessage: Message = {
      roomId: null,
      authorId: authorId,
      body:
        environment.systemMessageProperties.prefix
        + environment.systemMessageProperties.deleteMessageType
        + environment.systemMessageProperties.chatIdPart + messageToDelete.roomId
        + environment.systemMessageProperties.messageIdPart + messageToDelete.id
        + ((newMessage) ? environment.systemMessageProperties.newMessageIdPart + newMessage.id : ""),
      messageContentType: MessageContentTypeEnum.System,
    };

    await this.sipService.sendMessage(peerUserExtension,
      JSON.stringify({type: MessageContentTypeEnum.System, message: systemMessage}));
  }
}
