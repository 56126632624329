import {Injectable} from "@angular/core";
import {Contact} from "../../model/contact.model";

class BookElement {
  private readonly _contact: Contact = null;
  private readonly _contactNames: string[] = [];

  constructor(contact: Contact) {
    this._contact = contact
    this._contactNames = contact.displayName.split(' ').filter(name => name.length > 0);
  }

  get contactNames(): string[] {
    return this._contactNames;
  }

  get contact(): Contact {
    return this._contact;
  }
}

@Injectable()
export class ContactsBook {

  private readonly dictionary: BookElement[] = [];

  constructor(contacts: Contact[]) {
    if (!contacts) return;
    // const sortedContacts: Contacts[] = contacts.sort(
    //   (a, b) =>
    //     a.displayName.localeCompare(b.displayName, undefined, {sensitivity: "base"}))
    this.dictionary = contacts.map((contact) => new BookElement(contact));
  }

  search(query: string): Contact[] {
    // if query is null, undefined or empty string return empty list
    if (!query) return [];

    const queryWords: string[] = query.split(' ').filter(word => word.length > 0);

    return this.dictionary
      .filter((BookElement) => this.checkMatch(BookElement, queryWords))
      .map((BookElement) => BookElement.contact);

  }


  private checkMatch(BookElement: BookElement, queryWords: string[]): boolean {
    if (!queryWords || !BookElement) return false;
    for (const currentQueryWord of queryWords) {
      let found: boolean = false;

      for (const name of BookElement.contactNames) {
        // why not using name.startWith() ?
        // because localeCompare function can be diacritic/case in/sensitive
        if (name.substring(0, currentQueryWord.length)
          .localeCompare(currentQueryWord, 'en', {
            sensitivity: 'base',
            usage: "search",
            ignorePunctuation: true
          }) === 0) {
          found = true;
          break;
        }
      }

      if (!found) return false;
    }

    return true;
  }
}
