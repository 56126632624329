<!-- Start Groups content -->
<div>
  <div class="p-4">
    <div class="user-chat-nav float-end">
      <div ngbTooltip="Create group">
        <!-- Button trigger modal -->
        <button type="button" class="btn btn-link text-decoration-none text-muted font-size-18 py-0"
                data-toggle="modal" data-target="#addgroup-exampleModal" (click)="openGroupModal(createGroupContent)">
          <i class="ri-group-line me-1"></i>
        </button>
      </div>
    </div>
    <h4 class="mb-4">Groups</h4>

    <ng-template #createGroupContent let-modal>
      <app-group-creation></app-group-creation>
    </ng-template>

    <div class="search-box chat-search-box">
      <div class="input-group rounded-3">
        <button class="input-group-text text-muted bg-light pe-1 ps-3" type="button">
          <i class="ri-search-line search-icon font-size-18"></i>
        </button>
        <input type="text" class="form-control bg-light"
               placeholder="Search groups..." (keyup)="GroupSearch()"
               id="searchGroup" autocomplete="off">
      </div>
    </div>
    <!-- end search-box -->
  </div>

  <!-- Start chat-group-list -->
  <perfect-scrollbar class="p-4 chat-message-list chat-group-list" data-simplebar>
    <ul class="list-unstyled chat-list group-list">

      <!--        TODO:implement the below when message is ready-->
      <li *ngFor="let item of groups ; let i = index "
          appDragDropFileUpload (fileDropped)="fileDroppedToGroupHandler($event,i)"
          (click)="showGroupChat(i)" [ngClass]="{ active: item.id === selectedGroupIndex }">
        <!--      <li *ngFor="let item of groups" >-->
        <a href="javascript:void(0);" id="groupItem{{item.id}}">
          <div class="d-flex">
            <div class="chat-user-img me-3 ms-0">
              <mat-progress-spinner
                *ngIf="isAvatarLoading && selectedGroup?.id===item.id"
                [diameter]="24"
                [mode]="'indeterminate'"
              ></mat-progress-spinner>
              <div class="avatar-xs" *ngIf="!(isAvatarLoading && selectedGroup?.id===item.id)">
                        <span *ngIf="!item.srcImg" class="avatar-title rounded-circle bg-soft-primary text-primary">
                          {{(item.groupName).charAt(0)}}
                        </span>
                <img *ngIf="item.srcImg" [src]="item.srcImg" alt="user-img"
                     class="img-fluid w-100 h-100 rounded-circle">

              </div>
            </div>
            <div class="flex-grow-1 overflow-hidden">
              <h5 class="text-truncate font-size-14 mb-1">#{{item.groupName}}</h5>
              <p *ngIf="false" class="chat-user-message text-truncate mb-0">typing<span
                class="animate-typing">
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                            <span class="dot ms-1"></span>
                          </span></p>
              <p class="chat-user-message text-truncate mb-0">{{item.lastMessageBody}}</p>
            </div>
            <div *ngIf="item.lastMessageTime" class="font-size-11">
              {{ item.lastMessageTime | messageDate }}
            </div>
            <div *ngIf="item.unreadMessageCount>0" class="unread-message">
              <span class="badge badge-soft-danger rounded-pill">{{item.unreadMessageCount}}</span>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </perfect-scrollbar>
  <!-- End chat-group-list -->
</div>
<!-- End Groups content -->
