import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Contact} from '../../../model/contact.model';
import {UserService} from "../../../services/user-service";
import {User} from "../../../model/user.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GeneralService} from "../../../services/general-service";
import {ContactsBook} from "../../../core/helpers/contacts-book";
import {ChatService} from "../../../services/chat-service";
import {MessageService} from "../../../services/message-service";
import {MainMenuService} from "../../main-menu/main-menu.service";
import {ImageUtil} from '../../../../utils/image-util';
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {RoleTypeEnum} from "../../../model/user-type";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
/**
 * Tab-contacts component
 */
export class ContactsComponent implements OnInit {

  contacts: Contact[] = null;
  filteredContacts: Contact[] = null;
  groupedContacts: any;
  defaultUser: User;
  choosenContact: Contact = null;
  searchKey: string = '';
  private ContactsBook: ContactsBook;
  contactSelectedId: string = null;
  protected readonly RoleTypeEnum = RoleTypeEnum;

  constructor(private modalService: NgbModal
    , private userService: UserService
    , private generalService: GeneralService
    , private _snackBar: MatSnackBar
    , private chatService: ChatService
    , private messageService: MessageService
    , private imageUtil: ImageUtil
    , private mainMenuService: MainMenuService
  ) {
  }

  ngOnInit(): void {
    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.userService.contacts$.subscribe((response) => {
      if (response === null) return;

      this.contacts = response.sort((a: Contact, b: Contact) =>
        a.displayName.localeCompare(b.displayName, undefined, {sensitivity: "base", ignorePunctuation: true}));
      this.ContactsBook = new ContactsBook(this.contacts);

      this.updateGroupedContactsWith(this.contacts);
    });
    this.generalService.contactSelectedId$.subscribe((response) => {
      this.contactSelectedId = response;
    });
    this.getContacts();

  }


  private getContacts() {
    if (this.contacts === null) {
      this.generalService.setTabsLoading(true);
      this.userService.getContacts().subscribe({
        next: () => {
          this.filteredContacts = this.contacts;
          this.generalService.setTabsLoading(false);
        },
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setTabsLoading(false);
        }
      });
    }
  }

  /**
   * Contacts modal open
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openContactsModal() {
    this.mainMenuService.setActiveTab('NewUser');

  }


  searchContacts(): void {
    if (!this.searchKey) {
      this.updateGroupedContactsWith(this.contacts);
      return;
    }
    this.filteredContacts = this.ContactsBook.search(this.searchKey); // the result is sorted already
    this.updateGroupedContactsWith(this.filteredContacts);
  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  deleteUser(username: string, userId: string): void {
    this.userService.deleteUser(username, userId).subscribe({
      next: () => {
        this.openSnackBar(username + ' deleted successfully.', 'success');
      }, error: (error) => {
        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
      }
    });
  }

  private updateGroupedContactsWith(contacts: Contact[]) {
    const grouped = contacts.reduce((groups, contact) => {
      // using normalize('NFD').replace(/[\u0300-\u036f]/g, "") to remove diacritics like á => a
      // convert to uppercase to group both lowercase and uppercase letters to the same group
      const letter: string = contact.displayName.charAt(0).normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase();
      groups[letter] = groups[letter] || [];
      groups[letter].push(contact);

      return groups;
    }, {});

    // contacts list
    this.groupedContacts = grouped;
  }

  chooseContact(i: number, key: any, groupedContactsIndex) {
    if (this.groupedContacts[key][i] !== undefined && this.groupedContacts[key][i] !== null
      && this.groupedContacts[key][i].id === this.contactSelectedId) {
      return;
    }
    this.generalService.clearChatInput();

    var removeClass = document.querySelectorAll('.contact-list li');
    removeClass.forEach((element: any) => {
      element.classList.remove('active');
    });
    this.choosenContact = this.groupedContacts[key][i];
    this.getUserImage(this.choosenContact);

    document.querySelector('.user-chat').classList.add('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.add('d-none');
    document.querySelector('.user-chat').classList.remove('d-none');
    // event.target.closest('li').classList.add('active');
    const contactItemElement = document.getElementById(`contactItem${this.choosenContact.id}`);
    contactItemElement.closest('li').classList.add('active');

    let selectedChatItemId: string = this.chatService.getChatItemIdByUserIdForMap(this.choosenContact.id);

    this.generalService.setChatSelectedId(selectedChatItemId);//Could be null
    this.generalService.setGroupSelected(null);
    this.generalService.setItemSelected(selectedChatItemId ? SelectedItemEnum.CHAT : SelectedItemEnum.CONTACT);
    this.generalService.setContactSelectedId(selectedChatItemId ? null : this.choosenContact.id);

    this.generalService.setChatHeaderName(this.choosenContact.displayName);
    // this.generalService.setChatHeaderStatus('online');
    this.generalService.setChatHeaderAvatar(this.choosenContact.profileImgSrc);

    let tempChatItemId: string = this.chatService.getChatItemIdByUserIdForMap(this.choosenContact.id);

    if (tempChatItemId) {
      if (this.messageService.isThereAnUploadingMessageByRoomId(tempChatItemId)) {
        this.generalService.setSendingMessage(true);
      } else {
        this.generalService.setSendingMessage(false);
      }

    } else {
      this.generalService.setSendingMessage(false);
    }

    this.getMessageList(selectedChatItemId);

  }

  private getUserImage(value: Contact) {
    if (value === null || value.profileImgId === undefined || value.profileImgId === null || value.profileImgId.trim() === '') {
      return;
    }
    if (value.profileImgSrc !== undefined && value.profileImgSrc !== null) {
      return;
    }
    this.userService.getUserImage(value.profileImgId).subscribe({
      next: (response) => {
        if (response !== null && response.size !== 0) {
          const imgSrc = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response.imageData);
          value.profileImgSrc = imgSrc;
          this.userService.updateContactBehavior(value);
          //If chat was selected before images was loaded
          if (this.choosenContact && this.choosenContact.id === value.id) {
            this.generalService.setChatHeaderAvatar(imgSrc);
          }

        }
      }
    });
  }

  private getMessageList(selectedChatId: string) {

    if (selectedChatId === null) {
      this.messageService.setCurrentConversationMessages(null);
      return;
    }

    let messages = this.messageService.getConversationMessagesFromAllConversationMessages(selectedChatId);

    if (messages === undefined || messages === null || messages.length === 0) {
      this.messageService.setCurrentConversationMessages(null);
      this.generalService.setMessagesLoading(true);
      this.messageService.getRoomMessagesHistory(selectedChatId, 0).subscribe({
        next: (response) => {
          this.messageService.setCurrentConversationMessages(response);
          this.messageService.addToAllConversationMessages(selectedChatId, response);
          this.generalService.scrollToBottomAtChatConversation();
          this.generalService.setMessagesLoading(false);
          this.markAllMessagesAsRead(selectedChatId);

        },
        error: (err) => {

          if (err.status === 400) {
            this.openSnackBar(err.error, 'error');
          }
          if (err.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setMessagesLoading(false);
        }
      })
    } else {

      this.messageService.setCurrentConversationMessages(messages);
      this.generalService.scrollToBottomAtChatConversation();
      this.markAllMessagesAsRead(selectedChatId);

    }


  }

  private markAllMessagesAsRead(selectedChatId: string) {
    this.messageService.markChatMessagesAsRead(this.defaultUser.id, selectedChatId).subscribe({

      next: () => {
        this.messageService.markAllMessagesAsRead(selectedChatId, this.defaultUser.id);
        this.chatService.updateChatMapOneItemUnreadMessageCount(selectedChatId, 0);
      },
      error: () => {
        console.log('Cannot mark messages as Read');
      }
    })
  }

  fileDroppedToContactHandler(event: any, index: number, key: unknown, groupedContactsIndex) {
    this.chooseContact(index, key, groupedContactsIndex);
    // Emit the event to notify UserChatInputComponent
    this.generalService.uploadAttachmentMethodCalled.emit(event);
  }
}
