import {Component, OnInit, ViewChild} from '@angular/core';
import {SIPService} from "../../sip/services/sip-service";
import {LocalStorageService} from "../../sip/services/local-storage-service";
import {EventService} from "../../sip/services/event-service";
import {MainMenuService} from "../main-menu/main-menu.service";
import {Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder} from "@angular/forms"
import {Chats} from "../index/chat.model";
import {GeneralService} from "../../services/general-service";


@Component({
  selector: 'user-chat',
  templateUrl: './user-chat.component.html',
  styleUrls: ['./user-chat.component.scss']
})
export class UserChatComponent implements OnInit {


  chat: Chats[];
  @ViewChild('scrollRef') scrollRef: any;
  public isCollapsed = true;


  images: { src: string; thumb: string; caption: string }[] = [];


  constructor(private sipService: SIPService, private localStorageService: LocalStorageService, private eventService: EventService,
              private mainMenuService: MainMenuService, private generalService: GeneralService,
              private router: Router, private modalService: NgbModal,
              public formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.generalService.setChat();

    this.generalService.chat$.subscribe((response) => {
      this.chat = response;
    });
    this.onListScroll();
  }


  onListScroll() {
    //TODO: Review Scroll List if facing any scroll issue
    // if (this.scrollRef !== undefined) {
    //   setTimeout(() => {
    //     this.scrollRef.SimpleBar.getScrollElement().scrollTop = this.scrollRef.SimpleBar.getScrollElement().scrollHeight;
    //   }, 500);
    // }
  }


  /**
   * Open add group modal
   * @param content content
   */
  // tslint:disable-next-line: typedef
  openGroupModal(content: any) {
    this.modalService.open(content, {centered: true});
  }


}
