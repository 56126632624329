import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImageUtil {

    public toBase64(buffer: number[]): any {
        let binary = '';
        const bytes = new Uint8Array( buffer );
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }
}
