const chat = [
  {
    id: 1,
    name: 'Patrick Hendricks',
    profilePicture: 'assets/images/users/avatar-2.jpg',
    status: 'online',
    lastMessage: 'Hey! there I\'m available',
    time: '05 min',
    isActive: true,
    messages: [
      {
        id: 1,
        message: 'Good Morning',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00'
      },
      {
        id: 2,
        message: 'Good morning, How are you? What about our next meeting?',
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:02',
        align: 'right'
      },
      {
        isToday: true
      },
      {
        id: 3,
        message: 'Yeah everything is fine',
        message2: '& Next meeting tomorrow 10.00AM',
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:05',
      },
      {
        id: 4,
        message: 'Wow that\'s great',
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:06',
        align: 'right'
      },
      {
        id: 5,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '1',
            src: 'assets/images/small/img-1.jpg',
            caption: 'Image 4 caption here',
            thumb: 'assets/images/small/img-1.jpg'
          },
        ]
      },
      {
        id: 6,
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:16',
        align: 'right',
        isfile: true,
        fileContent: 'admin_v1.0.zip',
        fileSize: '12.5 MB'
      },
      {
        id: 1,
        message: '👍',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00',
        replayName: "You",
        replaymsg: `Good Morning`,
        align: 'right',
      },
    ]
  },
  {
    id: 2,
    name: 'Mark Messer',
    profilePicture: 'assets/images/users/avatar-3.jpg',
    status: 'away',
    unRead: '02',
    lastMessage: 'Images',
    time: '12 min',
    messages: [
      {
        id: 1,
        message: 'Hello',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00',
        align: 'right'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '4',
            src: 'assets/images/small/img-4.jpg',
            caption: 'Image 4 caption here',
            thumb: 'assets/images/small/img-4.jpg'
          },
          {
            id: '5',
            src: 'assets/images/small/img-5.jpg',
            caption: 'Image 5 caption here',
            thumb: 'assets/images/small/img-5.jpg'
          },
        ],
        imageNo: ['4', '5']
      },
    ]
  },
  {
    id: 3,
    name: 'General',
    lastMessage: 'This theme is awesome!',
    time: '20 min',
    messages: [
      {
        id: 1,
        message: 'Hello send project images',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '4',
            src: 'assets/images/small/img-4.jpg',
            caption: 'Image 4 caption here',
            thumb: 'assets/images/small/img-4.jpg'
          },
          {
            id: '6',
            src: 'assets/images/small/img-6.jpg',
            caption: 'Image 6 caption here',
            thumb: 'assets/images/small/img-6.jpg'
          },
        ]
      },
      {
        id: 3,
        message: 'Good Afternoon everyone !',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '2:05',
        align: 'right'
      },
      {
        id: 34,
        message: 'This theme is Awesome!',
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '2:06'
      },
    ]
  },
  {
    id: 4,
    name: 'Doris Brown',
    profilePicture: 'assets/images/users/avatar-4.jpg',
    status: 'online',
    lastMessage: 'Nice to meet you',
    time: '10:12 AM',
    messages: [
      {
        id: 1,
        message: 'Good Morning',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00'
      },
      {
        id: 2,
        message: 'Good morning, How are you? What about our next meeting?',
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:02',
        align: 'right'
      },
      {
        isToday: true
      },
      {
        id: 3,
        message: 'Yeah everything is fine',
        message2: '& Next meeting tomorrow 10.00AM',
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:05',
      },
      {
        id: 4,
        message: 'Wow that\'s great',
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:06',
        align: 'right'
      },
      {
        id: 5,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '1',
            src: 'assets/images/small/img-1.jpg',
            caption: 'Image 1 caption here',
            thumb: 'assets/images/small/img-1.jpg'
          },
          {
            id: '2',
            src: 'assets/images/small/img-2.jpg',
            caption: 'Image 2 caption here',
            thumb: 'assets/images/small/img-2.jpg'
          }
        ]
      },
      {
        id: 6,
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:16',
        align: 'right',
        isfile: true,
        fileContent: 'admin_v1.0.zip',
        fileSize: '12.5 MB'
      }
    ]
  },
  {
    id: 5,
    name: 'Designer',
    unRead: '01',
    lastMessage: 'Next meeting tomorrow 10:00AM',
    time: '12:01 PM',
    messages: [
      {
        id: 1,
        message: 'Hello send project images',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00'
      },
      {
        isToday: true
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '2',
            src: 'assets/images/small/img-2.jpg',
            caption: 'Image 2 caption here',
            thumb: 'assets/images/small/img-2.jpg'
          },
        ]
      },
      {
        id: 3,
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:16',
        align: 'right',
        isfile: true,
        fileContent: 'Minible-Vertical.zip',
        fileSize: '12.5 MB'
      },
      {
        id: 4,
        message: '@Doris Brown please review this code, and give me feedback asap',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '2:05',
        align: 'right'
      },
      {
        id: 5,
        message: 'Good Afternoon everyone !',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '2:05'
      },
    ]
  },
  {
    id: 6,
    name: 'Steve Walker',
    profilePicture: 'assets/images/users/avatar-6.jpg',
    status: 'away',
    lastMessage: 'Admin-A.zip',
    time: '03:20 PM',
    messages: [
      {
        id: 1,
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:16',
        isfile: true,
        fileContent: 'Minible-Vertical.zip',
        fileSize: '12.5 MB',
      },
      {
        id: 2,
        message: 'Okay 👍, let me check it and get back to you soon',
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '1:16',
        align: 'right'
      },
    ]
  },
  {
    id: 7,
    name: 'Albert Rodarte',
    status: 'online',
    isTyping: true,
    time: '04:56 PM',
    messages: [
      {
        id: 1,
        message: 'hi',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '1:05'
      },
      {
        id: 2,
        message: 'Hello, how can i help you',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '1:05',
        align: 'right'
      },
    ]
  },
  {
    id: 8,
    name: 'Mirta George',
    status: 'online',
    lastMessage: 'Yeah everything is fine',
    time: '12/07',
    messages: [
      {
        id: 1,
        message: 'hi...Good Morning!',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '09:05'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '4',
            src: 'assets/images/small/img-4.jpg',
            caption: 'Image 4 caption here',
            thumb: 'assets/images/small/img-4.jpg'
          },
          {
            id: '2',
            src: 'assets/images/small/img-2.jpg',
            caption: 'Image 2 caption here',
            thumb: 'assets/images/small/img-2.jpg'
          },
        ]
      },
      {
        id: 3,
        message: 'please, save this pictures to your file and give it to me after you have done with editing!',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:31'
      },
      {
        id: 4,
        message: 'Yeah, Everything is fine👍',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '02:50',
        align: 'right'
      },
    ]
  },
  {
    id: 9,
    name: 'Paul Haynes',
    profilePicture: 'assets/images/users/avatar-7.jpg',
    status: 'away',
    lastMessage: 'Good morning',
    time: '12/07',
    messages: [
      {
        id: 1,
        message: 'hi...Good Morning!',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '09:05'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '4',
            src: 'assets/images/small/img-2.jpg',
            caption: 'Image 4 caption here',
            thumb: 'assets/images/small/img-4.jpg'
          },
          {
            id: '1',
            src: 'assets/images/small/img-2.jpg',
            caption: 'Image 2 caption here',
            thumb: 'assets/images/small/img-2.jpg'
          }
        ],
        align: 'right'
      },
      {
        id: 3,
        message: 'please, save this pictures to your file and give it to me after you have done with editing!',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:31',
        align: 'right'
      },
      {
        id: 4,
        message: 'Good Morning😄',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '02:50',
      },
    ]
  },
  {
    id: 10,
    name: 'Jonathan Miller',
    status: 'online',
    lastMessage: 'Hi, How are you?',
    time: '12/07',
    messages: [
      {
        id: 1,
        message: 'hello Admin',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '08:00'
      },
      {
        id: 2,
        message: 'Good morning',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '08:00',
        align: 'right'
      },
      {
        id: 3,
        message: 'is everything is fine ?',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '08:00'
      },
      {
        id: 4,
        message: 'i can help you😊',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '08:00'
      },
      {
        id: 5,
        message: 'Hi, How are You?',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '08:00'
      },
    ]
  },
  {
    id: 11,
    name: 'Ossie Wilson',
    profilePicture: 'assets/images/users/avatar-8.jpg',
    status: 'away',
    lastMessage: 'I\'ve finished it! See you so',
    time: '11/07',
    messages: [
      {
        id: 1,
        message: 'hi',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '1:05',
        align: 'right'
      },
      {
        id: 2,
        message: 'Did you finished it?',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '12:00',
        align: 'right'
      },
      {
        id: 3,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '3',
            src: 'assets/images/small/img-3.jpg',
            caption: 'Image 3 caption here',
            thumb: 'assets/images/small/img-3.jpg'
          }
        ]
      }
    ]
  },
  {
    id: 12,
    name: 'Sara Muller',
    status: 'online',
    lastMessage: 'Wow that\'s great',
    time: '11/07',
    messages: [
      {
        id: 1,
        message: 'hi yana',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '12:00',
        align: 'right'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '3',
            src: 'assets/images/small/img-3.jpg',
            caption: 'Image 3 caption here',
            thumb: 'assets/images/small/img-3.jpg'
          },
        ]
      },
      {
        id: 3,
        message: 'Wow that\'s great',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '12:00',
        align: 'right'
      }
    ]
  },
]

const groups = [
  {
    id: 1, name: 'General',
    messages: [
      {
        id: 1,
        message: 'hi yana',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '12:00',
        align: 'right'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '3',
            src: 'assets/images/small/img-3.jpg',
            caption: 'Image 3 caption here',
            thumb: 'assets/images/small/img-3.jpg'
          },
        ]
      },
      {
        id: 3,
        message: 'Wow that\'s great',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '12:00',
        align: 'right'
      }
    ]
  },
  {
    id: 2, name: 'Reporting',
    unread: '+23',
    messages: [
      {
        id: 1,
        message: 'Hello send project images',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00'
      },
      {
        isToday: true
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '2',
            src: 'assets/images/small/img-2.jpg',
            caption: 'Image 2 caption here',
            thumb: 'assets/images/small/img-2.jpg'
          },
        ]
      },
      {
        id: 3,
        name: 'Patricia Smith',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:16',
        align: 'right',
        isfile: true,
        fileContent: 'Minible-Vertical.zip',
        fileSize: '12.5 MB'
      },
      {
        id: 4,
        message: '@Doris Brown please review this code, and give me feedback asap',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '2:05',
        align: 'right'
      },
      {
        id: 5,
        message: 'Good Afternoon everyone !',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '2:05'
      },
    ]
  },
  {
    id: 3, name: 'Designers',
    messages: [
      {
        id: 1,
        message: 'hi',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '1:05'
      },
      {
        id: 2,
        message: 'Hello, how can i help you',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '1:05',
        align: 'right'
      },
    ]
  },
  {
    id: 4, name: 'Developers',
    unread: 'New',
    messages: [
      {
        id: 1,
        message: 'hi...Good Morning!',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '09:05'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '4',
            src: 'assets/images/small/img-4.jpg',
            caption: 'Image 4 caption here',
            thumb: 'assets/images/small/img-4.jpg'
          },
          {
            id: '2',
            src: 'assets/images/small/img-2.jpg',
            caption: 'Image 2 caption here',
            thumb: 'assets/images/small/img-2.jpg'
          }
        ],
        align: 'right'
      },
      {
        id: 3,
        message: 'please, save this pictures to your file and give it to me after you have done with editing!',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:31',
        align: 'right'
      },
      {
        id: 4,
        message: 'Good Morning😄',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '02:50',
      },
    ]
  },
  {
    id: 5, name: 'Project-alpha',
    messages: [
      {
        id: 1,
        message: 'Hello send project images',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '4',
            src: 'assets/images/small/img-4.jpg',
            caption: 'Image 4 caption here',
            thumb: 'assets/images/small/img-4.jpg'
          },
          {
            id: '6',
            src: 'assets/images/small/img-6.jpg',
            caption: 'Image 6 caption here',
            thumb: 'assets/images/small/img-6.jpg'
          }
        ]
      },
      {
        id: 3,
        message: 'Good Afternoon everyone !',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '2:05',
        align: 'right'
      },
      {
        id: 34,
        message: 'This theme is Awesome!',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '2:06'
      },
    ]
  },
  {
    id: 6, name: 'Snacks',
    messages: [
      {
        id: 1,
        message: 'Dorsin Brown',
        name: 'Dorsin Brown',
        profile: 'assets/images/users/avatar-4.jpg',
        time: '10:00',
        align: 'right'
      },
      {
        id: 2,
        name: 'Doris Brown',
        profile: 'assets/images/users/avatar-1.jpg',
        time: '10:09',
        isimage: true,
        imageContent: [
          {
            id: '4',
            src: 'assets/images/small/img-4.jpg',
            caption: 'Image 4 caption here',
            thumb: 'assets/images/small/img-4.jpg'
          },
          {
            id: '5',
            src: 'assets/images/small/img-5.jpg',
            caption: 'Image 5 caption here',
            thumb: 'assets/images/small/img-5.jpg'
          }
        ]
      },
    ]
  }
];


export {chat, groups};
