import {Injectable} from '@angular/core';
import {Device} from "@capacitor/device";
import {Directory, Filesystem} from "@capacitor/filesystem";
import {HttpClient} from "@angular/common/http";
import {HttpOptions, HttpParams} from "@capacitor/core/types/core-plugins";


@Injectable({
    providedIn: 'root'
})
export class DownLoadFileUtil {
    constructor(private _httpClient: HttpClient) {
    }

    public async downloadAndSaveToStorage(httpOptions: HttpOptions, attachment) {
        const {platform} = await Device.getInfo();
        if (platform === "web") await this.webDownloadFile(httpOptions, attachment);
        else await this.mobileDownloadFile(httpOptions, attachment);
    }

    private async webDownloadFile(httpOptions: HttpOptions, attachment) {
      await this._httpClient
        .get(httpOptions.url, {...httpOptions, responseType: "arraybuffer"})
        .subscribe({
          next: (response) => {
            const blob = new Blob([response]);
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.setAttribute('target', '_blank');
            link.setAttribute('href', url.toString());
            link.setAttribute('download', attachment.name);
            document.body.appendChild(link);
            link.click();
            link.remove();
            attachment.downloadInProgress = false;
          },
          error: (error) => {
            throw error;
          }
        })
    }

    private async mobileDownloadFile(httpOptions: HttpOptions, attachment) {
        // Filesystem.downloadFile function does not see params attribute
        // that is why we are going to convert the params object to string and concatenate it
        // with url
        const url = `${httpOptions.url}?${this.getURLParamsAsString(httpOptions.params)}`;
        delete httpOptions.url;
        delete httpOptions.params;

        const {path} = await Filesystem.downloadFile({
            path: `${attachment.name}`,
            directory: Directory.Documents,
            ...httpOptions,
            url: url,
        });
      attachment.downloadInProgress = false;
        console.log("file was successfully saved at path: ", path);
    }

    private getURLParamsAsString(params: HttpParams): string {
        const result: string[] = [];
        for (const key in params) {
            if (!params.hasOwnProperty(key)) continue;
            const value = params[key];
            if (typeof value === 'string') {
                const x: string = "dfs";
                const finalValue = this.replaceSpaces(value);
                result.push(`${key}=${finalValue}`)
            }//
            else if (Array.isArray(value)) {
                const finalValue = value.map(this.replaceSpaces).join(',')
                result.push(`${key}=${finalValue}`)
            } //
            else throw TypeError("value is not string nor array of strings");
        }
        return result.join('&');
    }

    private replaceSpaces(str: string) {
        return str.replace(/ /g, '%20');
    }
}
