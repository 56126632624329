<div>
  <div class="d-lg-flex">
    <!-- start chat conversation section -->
    <div class="w-100 position-relative">

      <!-- user Chat head-->
      <user-chat-head></user-chat-head>

      <!-- start chat conversation -->
      <user-chat-conversation></user-chat-conversation>

      <!-- chat input section -->
      <user-chat-input></user-chat-input>


    </div>
    <!-- end chat conversation section -->

    <!-- <app-profile-detail></app-profile-detail> -->
  </div>
</div>




