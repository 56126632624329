<minimized-call></minimized-call>

<div class="layout-wrapper d-lg-flex">

  <!--  side Menu -->
  <main-menu *ngIf="defaultUser!==null"></main-menu>

  <!-- start chat-leftsidebar (details)-->
  <left-sidebar></left-sidebar>

  <!--      notification-->
  <!--  <div class="p-1 p-lg-1 border-bottom">-->
  <!--    <div class="row align-items-start">-->
  <!--      <div>-->
  <!--        <ul class="list-inline user-chat-nav mb-0">-->
  <!--          <li class="list-inline-item">-->
  <!--            <div>test-->
  <!--            </div>-->
  <!--          </li>-->
  <!--          <li class="list-inline-item">-->
  <!--            <div>test22-->
  <!--            </div>-->
  <!--          </li>-->


  <!--        </ul>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--      end notification-->

  <!--  Chat welcome section-->
  <welcome-section class="chat-welcome-section"></welcome-section>

  <!-- Start User chat (Right) -->
  <user-chat *ngIf="defaultUser!==null" class="user-chat w-100 d-none" id="chat-group"></user-chat>

  <audio #audioRemote id="audioRemote"></audio>

</div>


