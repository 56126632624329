import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SIPService} from "../../../sip/services/sip-service";
import {LocalStorageService} from "../../../sip/services/local-storage-service";
import {EventService} from "../../../sip/services/event-service";
import {MainMenuService} from "../../main-menu/main-menu.service";
import {Router} from "@angular/router";
import {NgbModal, NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder} from "@angular/forms";
import {DatePipe} from "@angular/common";
import {Lightbox} from "ngx-lightbox";
import {User} from "../../../model/user.model";
import {UserService} from "../../../services/user-service";
import {PerfectScrollbarComponent} from "ngx-perfect-scrollbar";
import {ImageUtil} from '../../../../utils/image-util';
import {Contact} from "../../../model/contact.model";
import {environment} from "../../../../environments/environment";
import {MatSnackBar} from "@angular/material/snack-bar";
import {GeneralService} from "../../../services/general-service";
import {ChatItem} from "../../../model/chatItem.model";
import {ChatService} from "../../../services/chat-service";
import {MessageService} from "../../../services/message-service";
import {Message, MessageAttachments} from "../../../model/message.model";
import {Group} from "../../../model/group.model";
import {GroupService} from "../../../services/group-service";
import {SelectedItemEnum} from "../../../model/selected-item-enum";
import {MessageContentTypeEnum} from "../../../model/message-type";
import {MessagingService} from "../../../services/messaging-service";
import {v4 as uuid} from "uuid";
import {DownLoadFileUtil} from "../../../../utils/download-file-util";

@Component({
  selector: 'user-chat-conversation',
  templateUrl: './user-chat-conversation.component.html',
  styleUrls: ['./user-chat-conversation.component.scss']
})
export class UserChatConversationComponent implements OnInit, OnDestroy {


  /**
   * Open lightbox
   */
  openImage(index: number, i: number): void {
    // open lightbox
    this.lightbox.open(this.message[index].imageContent, i, {
      showZoom: true
    });

  }

  environment = environment;
  MessageContentTypeEnum = MessageContentTypeEnum;
  SelectedItemEnum = SelectedItemEnum;
  defaultUser: User = null;
  userImageUrl: any;
  messageList: Message[] = null;
  isMessagesLoading: boolean = false;
  endMessagesLoading: boolean = false;
  // senderName:any;
  // senderProfile:any;

  // selectedChatItem:ChatItem=null;
  selectedChatId: string = null;
  selectedGroup: Group = null;
  selectedItemType: SelectedItemEnum = null;

  contacts: Map<string, Contact> = new Map<string, Contact>();
  chatMap: Map<string, ChatItem> = new Map<string, ChatItem>();
  groupList: Group[] = null;
  groupedContacts: any;

  chatHeaderName: any;
  chatHeaderAvatar: any;
  message: any;
  today: Date = new Date();

  forwardChatList: ChatItem[] = [];
  forwardContactList: Contact[] = [];
  forwardGroupList: Group[] = [];
  forwardedMessageIndex: number;
  isDropdownOpen = false;

  @ViewChild('scrollRef') scrollRef: PerfectScrollbarComponent;
  @ViewChild('getHeight') getHeight: ElementRef = null as any;


  constructor(private sipService: SIPService, private localStorageService: LocalStorageService, private eventService: EventService, private userService: UserService,
              private mainMenuService: MainMenuService, private generalService: GeneralService,
              private chatService: ChatService, private groupService: GroupService, private messageService: MessageService,
              private router: Router, private modalService: NgbModal, private offcanvasService: NgbOffcanvas,
              public formBuilder: FormBuilder, private datePipe: DatePipe, private lightbox: Lightbox,
              private _changeDetectorRef: ChangeDetectorRef, private imageUtil: ImageUtil, private _snackBar: MatSnackBar, private messagingService: MessagingService, private downloadFileUtil: DownLoadFileUtil) {
  }


  ngOnInit(): void {

    this.generalService.scrollToBottomAtChatConversation$.subscribe(() => {
      this._changeDetectorRef.detectChanges();
      this.scrollToBottom(this.getHeight.nativeElement.scrollHeight, 1);
    });

    this.generalService.chatHeaderName$.subscribe((value) => {
      this.chatHeaderName = value;
    });

    this.generalService.chatHeaderAvatar$.subscribe((value) => {
      this.chatHeaderAvatar = value;
    });

    this.generalService.messagesLoading$.subscribe((value) => {
      this.isMessagesLoading = value;
    });


    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));
    this.userService.displayName$.subscribe((response) => {
      this.defaultUser.displayName = response;
    });

    this.getUserImage();

    this.userService.contacts$.subscribe((response) => {

      if (response !== null) {
        let temp: Contact[] = response;
        let tempForForwardList: Contact[] = [];

        temp.forEach(value1 => {
          let existedChatItem: string = this.chatService.getChatItemIdByUserIdForMap(value1.id);
          if (!existedChatItem) {
            tempForForwardList.push(value1);
          }
          this.contacts.set(value1.id, value1);

        });
        const sorted = tempForForwardList.sort((a, b) => a.displayName > b.displayName ? 1 : -1);

        const grouped = sorted.reduce((groups, contact) => {
          const letter = contact.displayName.charAt(0);
          groups[letter] = groups[letter] || [];
          groups[letter].push(contact);

          return groups;
        }, {});

        // contacts list
        this.groupedContacts = Object.keys(grouped).map(key => ({key, contacts: grouped[key]}));


      }

    });
    this.chatService.chatMap$.subscribe((response) => {
      if (response !== null) {
        this.chatMap = new Map([...Object.entries(response)]);

        if (this.chatMap === undefined || this.chatMap === null || this.chatMap.size === 0) {
          this.chatMap = new Map([...response]);
        }

      }
    });
    this.groupService.userGroups$.subscribe((response) => {
      if (response !== null) {
        this.groupList = response;

      }
    });

    // this.generalService.chatSelected$.subscribe((value)=>{this.selectedChatItem=value;});
    this.generalService.chatSelectedId$.subscribe((value) => {
      this.selectedChatId = value;
    });
    this.generalService.groupSelected$.subscribe((value) => {
      this.selectedGroup = value;
    });
    this.generalService.itemSelected$.subscribe((value) => {
      this.selectedItemType = value;
    });

    this.messageService.currentConversationMessages$.subscribe(value => {
      this.lastDisplayedTitle = null;
      if (value !== null) {
        for (let i = value.length - 1; i >= 0; i--) {
          value[i].dateTitle = this.getTitle_2(value[i].dateTime);
        }
      }
      this.messageList = value;
    });

    document.addEventListener('click', this.closeDropdownOnOutsideClick);
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.closeDropdownOnOutsideClick);
  }

  /**
   * Open center modal
   * @param centerDataModal center modal data
   */
  centerModal(centerDataModal: any) {
    this.modalService.open(centerDataModal, {centered: true});
  }

  async openForwardModal(centerDataModal: any, index: number) {
    await this.getChatMap();
    await this.getContacts();
    await this.getGroupList();
    this.modalService.open(centerDataModal, {centered: true});
    this.forwardedMessageIndex = index;
  }

  // Copy Message
  copyMessage(event: any) {
    navigator.clipboard.writeText(event.target.closest('.chats').querySelector('.messageText').innerHTML);
    document.getElementById('copyClipBoard')?.classList.add('show');
    setTimeout(() => {
      document.getElementById('copyClipBoard')?.classList.remove('show');
    }, 1000);
  }


// Replay Message
  replyMessage(event: any, message: Message) {
    this.generalService.setReplyMessage(message);
    this.checkPositionReply();
    document.querySelector('.replyCard')?.classList.add('show');
    var copyText;
    if (message.body === environment.systemMessageProperties.prefix + MessageContentTypeEnum.AudioCall) {
      copyText = "Voice Call";
    } else if (message.body === environment.systemMessageProperties.prefix + MessageContentTypeEnum.VideoCall) {
      copyText = "Video Call";
    } else if (message.body === environment.systemMessageProperties.prefix + environment.systemMessageProperties.attachmentType) {
      copyText = "ATTACHMENT";
    } else
      copyText = message.body;
    (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .mb-0") as HTMLAreaElement).innerHTML = copyText;
    var msgOwnerName: any = event.target.closest(".chats").classList.contains("right") == true ? 'You' : document.querySelector('.username')?.innerHTML;
    (document.querySelector(".replyCard .replymessage-block .flex-grow-1 .conversation-name") as HTMLAreaElement).innerHTML = msgOwnerName;
  }

  private checkPositionReply() {
    const attachmentCard = document.querySelector('.attachmentCard') as HTMLElement | null;
    if (attachmentCard.classList.contains('show')) {
      const box1Height = document.querySelector('.replyCard')?.clientHeight;
      attachmentCard.style.bottom = `${box1Height - 1}px`;
    }
  }

  // Delete Message
  deleteMessage(event: any, messageIndex: number) {
    let messageToDelete = {...this.messageList[messageIndex]};
    let newLastMessage = {...this.messageList[1]};

    this.messageService.deleteMessage(messageToDelete.id, this.defaultUser.id, newLastMessage?.body, newLastMessage?.id, newLastMessage?.dateTime ? new Date(newLastMessage?.dateTime)?.toString() : null).subscribe({
      next: async () => {

        this.messageService.removeOneMessageFromAllConversationMessages(messageToDelete);
        this.messageService.removeOneMessageFromCurrentConversationMessages(messageToDelete);
        if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.CHAT) {
          let peerUserExtension = this.chatService.changeLastMessageIfMatch(messageToDelete, newLastMessage, this.defaultUser.id);
          await this.chatService.notifyPeerUser(peerUserExtension, messageToDelete, newLastMessage, this.defaultUser.id);
        } else if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.GROUP) {
          let index: number = this.groupService.changeLastMessageIfMatch(messageToDelete, newLastMessage, this.defaultUser.id);
          await this.groupService.notifyAllMembers(this.contacts, index, messageToDelete, newLastMessage, this.defaultUser.id);
        }
      },
      error: (error) => {
        if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        }
        if (error.status === 500) {
          this.openSnackBar('Internal server error!', 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
      }
    });
  }

  scrollToBottom(offset: number, speed: number) {
    if (this.scrollRef !== undefined) {
      this.scrollRef.directiveRef.scrollToBottom(-offset, speed);
    }
  }

  getUserImage(): void {
    // this.isLoading = true;
    this.userService.userImage$.subscribe((response) => {
        if (response === null || response?.size === 0) {
          this.userImageUrl = environment.imageProperties.defaultAvatarPath;

          //this.isLoading = false;

        } else {
          this.userImageUrl = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response);
          //this.isLoading = false;

        }
      }
    );

  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  isToday(date: Date) {

    let val = new Date(date).toDateString() === new Date().toDateString();
    return val

  }

  getTitle(messageItem: Message, index: number, revIndex: number) {

    console.log(messageItem);
    console.log(index);
    console.log(revIndex);
    return null
  }

  lastDisplayedTitle: string | null = null;

  getTitle_2(date: Date): string | null {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    let title: string;

    if (new Date(date).toDateString() === today.toDateString()) {
      title = 'Today';
    } else if (new Date(date).toDateString() === yesterday.toDateString()) {
      title = 'Yesterday';
    } else {
      title = new Date(date).toLocaleDateString();
    }

    if (title === this.lastDisplayedTitle) {
      return null;
    }

    this.lastDisplayedTitle = title;
    return title;
  }


  // downloadMessageAttachment(messageId: string, attachmentItem: MessageAttachments): void {
  //   attachmentItem.downloadInProgress=true;
  //   this.messageService.downloadMessageAttachment(messageId, this.defaultUser.id, attachmentItem.contentS3Id).subscribe({
  //     next: (response) => {
  //       if (response !== null && response.size !== 0) {
  //         const blob = new Blob([response]);
  //         // const url = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
  //         const url = URL.createObjectURL(blob);
  //
  //         const link = document.createElement('a');
  //         link.setAttribute('target', '_blank');
  //         link.setAttribute('href', url.toString());
  //         link.setAttribute('download', attachmentItem.name);
  //         document.body.appendChild(link);
  //         link.click();
  //         link.remove();
  //
  //         attachmentItem.downloadInProgress=false;
  //       }
  //     }, error: (error) => {
  //       if (error.status === 400) {
  //         this.openSnackBar(error.error, 'error');
  //       }
  //       if (error.status === 500) {
  //         this.openSnackBar('Internal server error!', 'error');
  //       } else {
  //         this.openSnackBar('An error has occurred, Try again later.', 'error');
  //       }
  //
  //       attachmentItem.downloadInProgress=false;
  //
  //     }
  //   });
  // }

  //Omar and Hesham
  async downloadMessageAttachment(messageId: string, attachmentItem: MessageAttachments): Promise<void> {
    attachmentItem.downloadInProgress = true;
    const request = this.messageService
      .getMessageAttachmentRequest(messageId, this.defaultUser.id, attachmentItem.contentS3Id);
    try {
      await this.downloadFileUtil
        .downloadAndSaveToStorage({...request}, attachmentItem);
    } catch (error) {
      if (error.status === 400) {
        this.openSnackBar(error.error, 'error');
      }
      if (error.status === 500) {
        this.openSnackBar('Internal server error!', 'error');
      } else {
        this.openSnackBar('An error has occurred, Try again later.', 'error');
      }
    }
    // attachmentItem.downloadInProgress = false;

  }

  onScrollTop(event: any) {
    if (event.target.scrollTop !== 0)
      return;


    if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.CHAT) {
      this.callBackendForMoreMessagesForChat();
    } else if (this.selectedItemType !== null && this.selectedItemType === SelectedItemEnum.GROUP) {
      this.callBackendForMoreMessagesForGroup();
    }

  }

  private callBackendForMoreMessagesForChat() {
    if (this.messageList === null) return;
    let selectedChatItem: ChatItem = this.chatService.getChatItem(this.selectedChatId);
    if (selectedChatItem === null) {
      return;
    }


    if (this.isMessagesLoading || selectedChatItem.reachEndOfMessages) return;
    this.generalService.setMessagesLoading(true);

    this.messageService.getRoomMessagesHistory(this.selectedChatId, this.messageList.length).subscribe(
      {
        next: (val: Message[]) => {

          if (val.length !== 0) {


            let tempMessages: Message[] = [];
            this.messageList.forEach(value => {
              tempMessages.push({...value});
            });
            tempMessages = tempMessages = tempMessages.concat(val);

            //update messages
            this.messageService.setCurrentConversationMessages(tempMessages);
            this.messageService.addToAllConversationMessages(this.selectedChatId, tempMessages);

            this.generalService.setMessagesLoading(false);
            //After messages loading and detect changes in UI, get lastTopElement to scroll to it
            this._changeDetectorRef.detectChanges();
            const lastTopElement = document.getElementById(`messageItem${val.length}`);
            this.scrollRef.directiveRef.scrollToElement(lastTopElement);

          } else {
            selectedChatItem.reachEndOfMessages = true;
            this.chatService.updateChatMapOneItem(this.selectedChatId, selectedChatItem);
            this.generalService.setMessagesLoading(false);
          }

        },
        error: (err) => {

          if (err.status === 400) {
            this.openSnackBar(err.error, 'error');
          }
          if (err.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }


          this.generalService.setMessagesLoading(false);

        }
      });
  }

  private callBackendForMoreMessagesForGroup() {
    if (this.selectedGroup === null || this.messageList === null) return;

    if (this.isMessagesLoading || this.selectedGroup.reachEndOfMessages) return;
    this.generalService.setMessagesLoading(true);

    this.messageService.getRoomMessagesHistory(this.selectedGroup.id, this.messageList.length).subscribe(
      {
        next: (val: Message[]) => {

          if (val.length !== 0) {


            let tempMessages: Message[] = [];
            this.messageList.forEach(value => {
              tempMessages.push({...value});
            });
            tempMessages = tempMessages = tempMessages.concat(val);

            //update selectedChatMessages
            this.messageService.setCurrentConversationMessages(tempMessages);
            this.messageService.addToAllConversationMessages(this.selectedGroup.id, tempMessages);

            this.generalService.setMessagesLoading(false);
            //After messages loading and detect changes in UI, get lastTopElement to scroll to it
            this._changeDetectorRef.detectChanges();
            const lastTopElement = document.getElementById(`messageItem${val.length}`);
            this.scrollRef.directiveRef.scrollToElement(lastTopElement);

          } else {
            this.selectedGroup.reachEndOfMessages = true;
            this.generalService.setGroupSelected(this.selectedGroup);
            this.groupService.updateUserGroupsOneItem(this.selectedGroup.id, this.selectedGroup);
            this.generalService.setMessagesLoading(false);
          }


        },
        error: (err) => {

          if (err.status === 400) {
            this.openSnackBar(err.error, 'error');
          }
          if (err.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }


          this.generalService.setMessagesLoading(false);

        }
      });
  }


  fileDroppedHandler(fileList: FileList) {
    // Emit the event to notify UserChatInputComponent
    this.generalService.uploadAttachmentMethodCalled.emit(fileList);
  }

  // stopSending(i: number,attachment: MessageAttachments) {
  //   const index = this.messageList[this.messageList.length-1-i].messageAttachmentsList.indexOf(attachment, 0);
  //   if (index != -1){
  //     this.messageList[this.messageList.length-1-i].messageAttachmentsList.splice(index, 1);
  //   }
  //   this.messageService.abortUpload(attachment.name).subscribe({
  //     next: async () => {
  //     },
  //     error: (error) => {
  //       if (error.status === 500) {
  //         this.openSnackBar('Server has problem, Please try in another time', 'error');
  //       } else if (error.status === 400) {
  //         this.openSnackBar(error.error, 'error');
  //       } else {
  //         this.openSnackBar('An error has occurred, Try again later.', 'error');
  //       }
  //     }
  //   });
  // }

  private getChatMap() {
    if (this.chatMap.size === 0) {
      this.chatService.getChatMap(this.defaultUser.id).subscribe({
        next: () => {
        },
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          this.generalService.setTabsLoading(false);
        }
      });
    }
  }

  private getGroupList() {
    if (this.groupList === null) {
      this.groupService.getUserGroups(this.defaultUser.id).subscribe({
        next: () => {
        },
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });

    }
  }

  private getContacts() {


    if (this.contacts === null || this.contacts.size === 0) {
      this.userService.getContacts().subscribe({
        next: () => {

        },
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });
    } else {
      let tempForForwardList: Contact[] = [];

      this.contacts.forEach(value => {
        let existedChatItem: string = this.chatService.getChatItemIdByUserIdForMap(value.id);
        if (!existedChatItem) {
          tempForForwardList.push(value);
        }

      });
      const sorted = tempForForwardList.sort((a, b) => a.displayName > b.displayName ? 1 : -1);

      const grouped = sorted.reduce((groups, contact) => {
        const letter = contact.displayName.charAt(0);
        groups[letter] = groups[letter] || [];
        groups[letter].push(contact);

        return groups;
      }, {});

      // contacts list
      this.groupedContacts = Object.keys(grouped).map(key => ({key, contacts: grouped[key]}));
    }

  }

  checkReceiverChanged(event, receiver: any, receiverType: SelectedItemEnum) {
    receiver.checked = event.target.checked;
    //Adding chatItem
    if (receiverType === SelectedItemEnum.CHAT) {
      if (event.target.checked) {
        this.forwardChatList.push(receiver)
      } else {
        const index: number = this.forwardChatList.findIndex(value => value.chatId === receiver.chatId);
        if (index !== -1) {
          this.forwardChatList.splice(index, 1);
        }
      }
    }
    //Adding Group
    if (receiverType === SelectedItemEnum.GROUP) {
      if (event.target.checked) {
        this.forwardGroupList.push(receiver)
      } else {
        const index: number = this.forwardGroupList.findIndex(value => value.id === receiver.id);
        if (index !== -1) {
          this.forwardGroupList.splice(index, 1);
        }
      }
    }

    //Adding A Contact
    if (receiverType === SelectedItemEnum.CONTACT) {
      if (event.target.checked) {
        this.forwardContactList.push(receiver)
      } else {
        const index: number = this.forwardContactList.findIndex(value => value.id === receiver.id);
        if (index !== -1) {
          this.forwardContactList.splice(index, 1);
        }
      }
    }


  }

  forward() {
    if (this.forwardChatList.length === 0 && this.forwardGroupList.length === 0 && this.forwardContactList.length === 0) {
      this.openSnackBar('You must select at Least one', "error");
      return;
    }

    let copyOfMessageChat: Message = {...this.messageList[this.forwardedMessageIndex]};
    let copyOfMessageGroup: Message = {...this.messageList[this.forwardedMessageIndex]};
    let copyOfMessageNewContact: Message = {...this.messageList[this.forwardedMessageIndex]};

    for (let i = 0; i < this.forwardContactList.length; i++) {
      copyOfMessageNewContact.forward = true;
      copyOfMessageNewContact.id = null;
      copyOfMessageNewContact.dateTime = new Date();
      copyOfMessageNewContact.authorId = this.defaultUser.id;
      copyOfMessageNewContact.roomId = null;
      copyOfMessageNewContact.unreadIDs = [this.forwardContactList[i].id];
      copyOfMessageNewContact.localUUId = uuid();
      copyOfMessageNewContact.messageReplyBody = null;
      copyOfMessageNewContact.messageReplyAuthorId = null;
      copyOfMessageNewContact.messageReplyId = null;
      let copyOfAttachmentListspec: MessageAttachments[] = {...this.messageList[this.forwardedMessageIndex].messageAttachmentsList};

      this.messagingService.sendMessageToNewContact(this.forwardContactList[i], copyOfMessageNewContact, copyOfAttachmentListspec);

    }


    for (let i = 0; i < this.forwardChatList.length; i++) {
      copyOfMessageChat.forward = true;
      copyOfMessageChat.id = null;
      copyOfMessageChat.dateTime = new Date();
      copyOfMessageChat.authorId = this.defaultUser.id;
      copyOfMessageChat.roomId = this.forwardChatList[i].chatId;
      copyOfMessageChat.localUUId = uuid();
      copyOfMessageChat.messageReplyBody = null;
      copyOfMessageChat.messageReplyAuthorId = null;
      copyOfMessageChat.messageReplyId = null;
      let copyOfAttachmentListspec: MessageAttachments[] = {...this.messageList[this.forwardedMessageIndex].messageAttachmentsList};

      this.messagingService.sendAForwardedMessageInPrivateChat(this.forwardChatList[i], copyOfMessageChat, copyOfAttachmentListspec);

    }


    for (let i = 0; i < this.forwardGroupList.length; i++) {
      copyOfMessageGroup.forward = true;
      copyOfMessageGroup.id = null;
      copyOfMessageGroup.dateTime = new Date();
      copyOfMessageGroup.authorId = this.defaultUser.id;
      copyOfMessageGroup.roomId = this.forwardGroupList[i].id;
      copyOfMessageGroup.localUUId = uuid();
      copyOfMessageGroup.messageReplyBody = null;
      copyOfMessageGroup.messageReplyAuthorId = null;
      copyOfMessageGroup.messageReplyId = null;
      let copyOfAttachmentListspec: MessageAttachments[] = {...this.messageList[this.forwardedMessageIndex].messageAttachmentsList};

      this.messagingService.sendAForwardedMessageInGroup(this.forwardGroupList[i], copyOfMessageGroup, copyOfAttachmentListspec);

    }


    this.closeForwadModal();
  }

  closeForwadModal() {
    this.forwardChatList = [];
    this.forwardGroupList = [];
    this.forwardContactList = [];
    this.forwardedMessageIndex = null;
    this.modalService.dismissAll();

  }

  toggleDropDown(i: number) {
    const dropdown = document.getElementById("dropdown" + i);
    const items = document.querySelectorAll('[id*="dropdown"]');
    items.forEach((item) => {
      if (item !== dropdown)
        item.classList.remove("show")
    })
    if (dropdown.classList.contains("show")) {
      dropdown.classList.remove("show");
      this.isDropdownOpen = true;
    } else {
      dropdown.classList.add("show");
      this.isDropdownOpen = false;
    }
  }

  closeDropdownOnOutsideClick() {
    if (this.isDropdownOpen) {
      const items = document.querySelectorAll('[id*="dropdown"]');
      items.forEach((item) => {
        item.classList.remove("show")
      })
    }
  }
}
