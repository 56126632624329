import {Component, OnDestroy, OnInit} from '@angular/core';
import {MainMenuService} from "../../../main-menu/main-menu.service";
import {NgbModal, NgbOffcanvas} from "@ng-bootstrap/ng-bootstrap";
import {GroupService} from "../../../../services/group-service";
import {UserService} from "../../../../services/user-service";
import {GeneralService} from "../../../../services/general-service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Group} from "../../../../model/group.model";
import {DataUrl, NgxImageCompressService, UploadResponse} from "ngx-image-compress";
import {environment} from "../../../../../environments/environment";
import {User} from "../../../../model/user.model";
import {ImageUtil} from '../../../../../utils/image-util';
import {GroupMember} from "../../../../model/groupMember.model";
import {Contact} from "../../../../model/contact.model";
import {ModalService} from "../../../../services/modal.service";
import {MessageService} from "../../../../services/message-service";
import {SIPService} from "../../../../sip/services/sip-service";
import {MessageContentTypeEnum} from "../../../../model/message-type";
import {Message} from "../../../../model/message.model";

@Component({
  selector: 'app-group-side-info',
  templateUrl: './group-side-info.component.html',
  styleUrls: ['./group-side-info.component.scss']
})
export class GroupSideInfoComponent implements OnInit, OnDestroy {

  isAvatarLoading: Boolean = false;
  isAdmin: Boolean = false;
  selectedGroup: Group = null;
  selectedGroupMembers: GroupMember[] = null;
  defaultUser: User = null;
  editedGroupName: string;
  editedGroupDescription: string;
  contacts: Contact[] = null;
  groupedContacts: any;
  newParticipants: GroupMember[] = [];
  filteredContacts: Contact[] = [];
  contactMap: Map<string, Contact> = new Map<string, Contact>();


  constructor(
    private mainMenuService: MainMenuService,
    private modalService: NgbModal,
    private groupService: GroupService,
    private userService: UserService,
    private generalService: GeneralService,
    private _snackBar: MatSnackBar,
    private imageCompress: NgxImageCompressService,
    private imageUtil: ImageUtil,
    private deleteModalService: ModalService,
    private messageService: MessageService,
    private offcanvasService: NgbOffcanvas,
    private sipService: SIPService
  ) {
  }

  ngOnDestroy(): void {
    // remove any checks members in add participant modal
    // TODO check if converting filteredContacts to behaviour will help
    if (this.groupedContacts && this.groupedContacts.length > 0) {
      this.groupedContacts.forEach(contactItem => {
        if (contactItem.filteredContacts) {
          contactItem.filteredContacts.forEach(item => {
            if (item && item.hasOwnProperty('checked') && item.checked) {
              item.checked = false;
            }
          });
        }
      });
    }
  }

  ngOnInit(): void {

    this.generalService.groupSelected$.subscribe((value) => {
      if (value !== null) {
        this.selectedGroup = value;
        this.isAdmin = this.selectedGroup.admin;
      }
    });

    this.defaultUser = JSON.parse(window.sessionStorage.getItem('defaultUser'));

    this.groupService.isAvatarLoading$.subscribe((value) => {
      if (value != null)
        this.isAvatarLoading = value;
    });

    this.userService.contacts$.subscribe((response) => {
      if (response !== null) {
        this.contacts = response;
        this.contacts.forEach(value => {
          this.contactMap.set(value.id, value);
        });
      }
    });

    this.groupService.selectedGroupMembers$.subscribe((value) => {
      if (value !== undefined && value !== null) {
        this.selectedGroupMembers = value;
        let defaultUser;
        const adminTrue = [];
        const otherUsers = [];

        this.selectedGroupMembers.forEach((groupMember) => {
          if (groupMember.userId !== this.defaultUser.id) {
            if (groupMember.admin) {
              adminTrue.push(groupMember);
            } else {
              otherUsers.push(groupMember);
            }
          } else {
            defaultUser = groupMember;
            this.groupService.updateGroupAdminPermission(this.selectedGroup.id, groupMember.admin);
            this.isAdmin = groupMember.admin;
          }
        });

        this.selectedGroupMembers = [defaultUser, ...adminTrue, ...otherUsers]
        if (this.isAdmin) {
          this.filteredContactMethod();
        }
      }
    })


    if (this.isAdmin) {
      this.getContactsToAddNewMembers();
    } else {
      this.getGroupMembers();
    }

  }

  filteredContactMethod() {
    if (this.selectedGroupMembers !== null && this.contacts !== null) {
      this.filteredContacts = this.contacts.filter(contact => {
        return !this.selectedGroupMembers.some(groupMember => groupMember.userId === contact.id);
      });
      const grouped = this.sortAndGroupByLetters(this.filteredContacts);
      this.groupedContacts = Object.keys(grouped).map(key => ({key, filteredContacts: grouped[key]}));
      if (this.groupedContacts && this.groupedContacts.length > 0) {
        this.groupedContacts.forEach(contactItem => {
          if (contactItem.filteredContacts) {
            contactItem.filteredContacts.forEach(item => {
              if (item && item.hasOwnProperty('checked') && item.checked) {
                item.checked = false;
              }
            });
          }
        });
      }
    }
  }

  compressAndUploadFile() {

    if (!this.isAdmin)
      return;

    this.imageCompress
      .uploadFile()
      .then(({image, orientation, fileName}: UploadResponse) => {
        const imageBlobBeforeCompression = this.dataURItoBlob(image);
        const imageFileBeforeCompression = new File([imageBlobBeforeCompression], fileName);

        const allowedTypes = environment.imageProperties.allowedTypes;
        const fileSizeInKB = Math.round(imageFileBeforeCompression.size / 1024);
        const fileType = this.dataURItoType(image);

        // Return if the file is too large
        if (fileSizeInKB > (environment.imageProperties.maxImageSizeInMB * 1024)) {
          this.openSnackBar('size of image is too large,image should less than ' + environment.imageProperties.maxImageSizeInMB + 'MB..', 'error');
          this.groupService.setIsAvatarLoading(false);

          return;
        }
        // Return if the file is not allowed
        if (!allowedTypes.includes(fileType)) {
          this.openSnackBar('you must upload an image..', 'error');
          this.groupService.setIsAvatarLoading(false);


          return;
        }

        this.groupService.setIsAvatarLoading(true);


        this.imageCompress
          .compressFile(image, orientation, 50, 50)
          .then((result: DataUrl) => {

            const imageBlob = this.dataURItoBlob(result);
            const imageFile = new File([imageBlob], fileName);

            // Upload the avatar
            this.groupService.uploadGroupImage(imageFile, this.defaultUser.id, this.selectedGroup.id, this.selectedGroup.groupImgId).subscribe(
              {
                next: () => {
                  const newSrcImg = result;

                  this.groupService.setIsAvatarLoading(false);
                  this.selectedGroup.srcImg = newSrcImg;
                  this.selectedGroup.groupImgId = fileName;
                  this.generalService.setGroupSelected(this.selectedGroup);
                  this.generalService.setChatHeaderAvatar(newSrcImg);


                },
                error: (error) => {
                  this.groupService.setIsAvatarLoading(false);

                  if (error.status === 400) {
                    this.openSnackBar(error.error, 'error');
                  } else if (error.status === 413) {
                    this.openSnackBar('Image size is too big!', 'error');
                  } else {
                    this.openSnackBar('An error has occurred, Try again later.', 'error');
                  }
                }
              }
            );


          });
      });

  }

  fileDelete() {

    if (!this.isAdmin)
      return;
    const deleteFile = () => {
      this.groupService.setIsAvatarLoading(true);

      this.groupService.deleteGroupImage(this.selectedGroup.id, this.defaultUser.id).subscribe({
        next: () => {
          this.selectedGroup.srcImg = null;

          this.generalService.setChatHeaderAvatar(null);
          this.generalService.setGroupSelected(this.selectedGroup);

          this.groupService.setIsAvatarLoading(false);

        },
        error: (error) => {
          this.groupService.setIsAvatarLoading(false);

          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
          //this.isLoading=false
        }
      });
    }

    this.deleteModalService.openDeletionConfirmationModal(deleteFile, "Are you sure to delete?", "Delete");

  }

  openSnackBar(message: string, type: 'success' | 'error'): void {
    this._snackBar.open(message, 'Close'
      , {
        duration: 5000,
        horizontalPosition: 'left',
        verticalPosition: 'top',
        panelClass: [type + '-snackbar']
      }
    );
  }

  dataURItoBlob(dataURI): any {
    const byteString = window.atob(dataURI.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([int8Array]);
  }

  dataURItoType(dataURI): any {
    //dataURI = "data:image/png;base64,iVBORw0KG......"
    const data = dataURI.split(',')[0];
    return data.split(";")[0].split(":")[1];
  }

  private getGroupMembers() {
    if (this.selectedGroup.members === undefined || this.selectedGroup.members === null || this.selectedGroup.members.length === 0) {
      this.groupService.getGroupMembers(this.selectedGroup.id, this.defaultUser.id).subscribe({
        next: (response) => {
          this.groupService.setSelectedGroupMembers(response);
          if (this.contacts !== null && this.isAdmin)
            this.filteredContactMethod();
          this.displayProfilePicsForGroupMembers();
        },
        error: (error) => {
          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      })
    } else {
      this.groupService.setSelectedGroupMembers(this.selectedGroup.members);
    }
  }

  edit_GroupName() {

    if (!this.isAdmin)
      return;

    this.editedGroupName = this.selectedGroup.groupName
    document.getElementById("group_name").classList.toggle("visually-hidden");
    document.getElementById("group_name_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-group-name").classList.toggle("visually-hidden");

  }

  changeGroupName() {

    if (!this.isAdmin)
      return;

    // this.isLoading = true;
    this.groupService.updateGroupName(this.selectedGroup.id, this.defaultUser.id, this.editedGroupName).subscribe({
      next: () => {
        this.selectedGroup.groupName = this.editedGroupName;
        this.generalService.setChatHeaderName(this.editedGroupName);
        this.generalService.setGroupSelected(this.selectedGroup);
        //this.isLoading = false;
      },
      error: (error) => {
        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
        //this.isLoading=false

      }
    });

    document.getElementById("group_name").classList.toggle("visually-hidden");
    document.getElementById("edit-group-name").classList.toggle("visually-hidden");
    document.getElementById("group_name_edit").classList.toggle("visually-hidden");

  }

  edit_GroupDescription() {

    if (!this.isAdmin)
      return;

    this.editedGroupDescription = this.selectedGroup.description;
    document.getElementById("group_description").classList.toggle("visually-hidden");
    document.getElementById("group_description_edit").classList.toggle("visually-hidden");
    document.getElementById("edit-group-description").classList.toggle("visually-hidden");

  }

  changeGroupDescription() {

    if (!this.isAdmin)
      return;

    this.editedGroupDescription = this.editedGroupDescription.trim() === '' ? null : this.editedGroupDescription;

    // this.isLoading = true;
    this.groupService.updateGroupDescription(this.selectedGroup.id, this.defaultUser.id, this.editedGroupDescription).subscribe({
      next: () => {
        this.selectedGroup.description = this.editedGroupDescription;
        this.generalService.setGroupSelected(this.selectedGroup);
        //this.isLoading = false;
      },
      error: (error) => {
        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
        //this.isLoading=false

      }
    });

    document.getElementById("group_description").classList.toggle("visually-hidden");
    document.getElementById("edit-group-description").classList.toggle("visually-hidden");
    document.getElementById("group_description_edit").classList.toggle("visually-hidden");

  }

  private sortAndGroupByLetters(usersList: Contact[]): any {
    const sorted = usersList.sort((a, b) => a.displayName > b.displayName ? 1 : -1);

    const grouped = sorted.reduce((groups, contact) => {
      const letter = contact.displayName.charAt(0);
      groups[letter] = groups[letter] || [];
      groups[letter].push(contact);

      return groups;
    }, {});

    return grouped;
  }

  private getContactsToAddNewMembers() {
    //Get contacts to add members in Group
    if (this.contacts === null) {
      this.userService.getContacts().subscribe({
        next: () => {
          // this.filteredContactMethod();
          //Get group members after getting contacts list then remove them from filtered contacts
          this.getGroupMembers();
        },
        error: (error) => {

          if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          }
          if (error.status === 500) {
            this.openSnackBar('Internal server error!', 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });
    } else {
      //Contacts list exists so get group members then remove them from filtered contacts
      this.getGroupMembers();
    }
  }


  openAddParticipantsModal(centerDataModal: any) {
    if (this.contacts === null) {
      this.getContactsToAddNewMembers();
    }
    this.modalService.open(centerDataModal, {centered: true});
  }

  checkMemberChanged(event, contactItem: any) {
    contactItem.checked = event.target.checked;
    if (event.target.checked)
      this.newParticipants.push({
        userId: contactItem.id,
        userName: contactItem.displayName,
        admin: false,
        profileImgId: contactItem.profileImgId,
        profileImgSrc: contactItem.profileImgSrc
      });
    else {
      //Remove element from groupAddingMembers
      const index: number = this.newParticipants.findIndex(member => member.userId === contactItem.id);
      if (index !== -1) {
        this.newParticipants.splice(index, 1);
      }
    }
  }

  addParticipants() {
    if (!this.isAdmin)
      return;

    if (this.newParticipants.length === 0) {
      this.openSnackBar("You must select at least 1 contact.", "error");
      return;
    }

    this.newParticipants.forEach((value) => {
      this.getUserImage(value, -1);
    })

    this.groupService.addGroupMembers(this.selectedGroup.id, this.defaultUser.id, this.newParticipants).subscribe({
      next: async () => {
        await this.sendSystemMessageToRefreshGroupMemberList();
        await this.sendSystemMessageToRefreshGroupListForNewParticipants();
        this.filteredContactMethod();
        this.newParticipants = [];
        this.modalService.dismissAll();
      },
      error: (error) => {
        if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        }
        if (error.status === 500) {
          this.openSnackBar('Internal server error!', 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
      }
    });
  }

  private async sendSystemMessageToRefreshGroupMemberList() {
    let copyGroup = {...this.selectedGroup}
    let systemMessage: Message = {
      roomId: copyGroup.id,
      authorId: this.defaultUser.id,
      body: environment.systemMessageProperties.prefix + environment.systemMessageProperties.refreshGroupMembersType,
      messageContentType: MessageContentTypeEnum.System,
    };
    if (copyGroup.members !== undefined && copyGroup.members !== null && copyGroup.members.length !== 0) {
      for (const value of copyGroup.members) {
        await this.sipService.sendMessage(this.contactMap.get(value.userId).extension,
          JSON.stringify({type: MessageContentTypeEnum.System, message: systemMessage}));
      }
    }
  }

  private getUserImage(value: GroupMember, index: number) {
    if (value.profileImgSrc !== undefined && value.profileImgSrc !== null) {
      return;
    }
    if (value.profileImgId === undefined || value.profileImgId === null || value.profileImgId.trim() === '') {
      return;
    }
    if (index >= 0) { // if the member is not a new member
      value.profileImgSrc = this.userService.getContactImgSrcIfExist(value.userId)
      if (value.profileImgSrc !== undefined && value.profileImgSrc !== null) {
        return;
      }
    }
    this.userService.getUserImage(value.profileImgId).subscribe({
      next: (response) => {
        if (response !== null && response.size !== 0) {
          const imgSrc = 'data:' + 'image/jpeg' + ';base64,' + this.imageUtil.toBase64(response.imageData);
          value.profileImgSrc = imgSrc;
          if (index >= 0) {
            let member = this.selectedGroup.members.find(groupMember => groupMember.userId === value.userId);
            member.profileImgSrc = imgSrc;
          }
          this.userService.updateContactImgSrc(value.userId, imgSrc);
        }
      }
    });
  }

  private displayProfilePicsForGroupMembers() {
    this.selectedGroupMembers.slice(0, 6).forEach((value, index) => {
      this.getUserImage(value, index);
    })
  }

  onScrollGetDisplayedItems() {
    let flag = 0;
    for (let i = 0; i < this.selectedGroupMembers.length; i++) {
      if (flag === 2) {
        break;
      }
      const groupMember = document.getElementById(`groupMember${i}`);
      let visible = this.visibleY(groupMember);
      if (visible && flag === 0) {
        flag = 1;
      }
      if (!visible && flag === 1) {
        flag = 2;
      }
      if (groupMember && visible) {
        this.getUserImage(this.selectedGroupMembers[i], i);
      }
    }
  }

  visibleY(el) {
    let rect = el.getBoundingClientRect();
    let top = rect.top;
    let height = rect.height;
    el = el.parentNode;
    do {
      rect = el.getBoundingClientRect();
      if (top > rect.bottom) return false;
      // Check if the element is out of view due to a container scrolling
      if ((top + height) <= rect.top) return false
      el = el.parentNode;
    } while (el != document.getElementById("scrollView"));
    // Check it's within the document viewport
    return top <= document.documentElement.clientHeight;
  };


  deleteGroupMember(memberIdToDelete) {
    if (!this.isAdmin || memberIdToDelete === undefined || memberIdToDelete === null || memberIdToDelete === '')
      return;

    const deleteMember = () => {
      this.groupService.deleteGroupMembers(this.selectedGroup.id, this.defaultUser.id, memberIdToDelete).subscribe({
        next: async () => {
          await this.sendSystemMessageToRefreshGroupMemberList();
          await this.sendSystemMessageToRefreshGroupListForOneMember(memberIdToDelete);
          this.filteredContactMethod();
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      });
    }
    this.deleteModalService.openDeletionConfirmationModal(deleteMember, "Are you sure you want to remove this member?", "Remove");
  }

  removeAdminPermission(adminId: string, content) {
    if (!this.isAdmin || adminId === undefined || adminId === null || adminId === '')
      return;

    let adminCount = 0;
    for (let member of this.selectedGroupMembers) {
      if (member.admin)
        adminCount++;
      else
        break;
    }

    if (adminCount == 1) {
      this.modalService.open(content, {centered: true});
      return;
    }

    const removePermission = () => {
      this.groupService.removeAdminPermission(this.selectedGroup.id, this.defaultUser.id, adminId).subscribe({
        next: async () => {
          await this.sendSystemMessageToRefreshGroupMemberList();
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      })
    }
    this.deleteModalService.openDeletionConfirmationModal(removePermission, "Are you sure you want to remove admin permission?", "Remove");
  }

  promoteToAdmin(memberId: string) {
    if (!this.isAdmin || memberId === undefined || memberId === null || memberId === '')
      return;

    this.groupService.promoteToAdmin(this.selectedGroup.id, this.defaultUser.id, memberId).subscribe({
      next: async () => {
        await this.sendSystemMessageToRefreshGroupMemberList();
      },
      error: (error) => {
        if (error.status === 500) {
          this.openSnackBar('Server has problem, Please try in another time', 'error');
        } else if (error.status === 400) {
          this.openSnackBar(error.error, 'error');
        } else {
          this.openSnackBar('An error has occurred, Try again later.', 'error');
        }
      }
    })
  }

  leaveGroup(content) {
    if (this.isAdmin) {
      let adminCount = 0;
      for (let member of this.selectedGroupMembers) {
        if (member.admin)
          adminCount++;
        else
          break;
      }

      if (adminCount == 1) {
        this.modalService.open(content, {centered: true});
        return;
      }
    }

    const userLeave = () => {
      this.groupService.leaveGroup(this.selectedGroup.id, this.defaultUser.id).subscribe({
        next: async () => {
          await this.sendSystemMessageToRefreshGroupMemberList();
          this.showWelcomeSection();
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      })
    }
    this.deleteModalService.openDeletionConfirmationModal(userLeave, "Are you sure you want to leave?", "Leave");
  }

  deleteGroupByAdmin() {
    const deleteGroup = () => {
      this.groupService.deleteGroup(this.selectedGroup.id, this.defaultUser.id).subscribe({
        next: async () => {
          //Send message to notify all members with the delete Group
          let copyGroup = {...this.selectedGroup}
          let systemMessage: Message = {
            roomId: copyGroup.id,
            authorId: this.defaultUser.id,
            body: environment.systemMessageProperties.prefix + environment.systemMessageProperties.refreshGroupListType,
            messageContentType: MessageContentTypeEnum.System,
          };
          if (copyGroup.members !== undefined && copyGroup.members !== null && copyGroup.members.length !== 0) {
            for (const value of copyGroup.members) {
              await this.sipService.sendMessage(this.contactMap.get(value.userId).extension,
                JSON.stringify({type: MessageContentTypeEnum.System, message: systemMessage}));
            }
          }
          this.showWelcomeSection();
        },
        error: (error) => {
          if (error.status === 500) {
            this.openSnackBar('Server has problem, Please try in another time', 'error');
          } else if (error.status === 400) {
            this.openSnackBar(error.error, 'error');
          } else {
            this.openSnackBar('An error has occurred, Try again later.', 'error');
          }
        }
      })
    }
    this.deleteModalService.openDeletionConfirmationModal(deleteGroup, "Are you sure to delete?", "Delete");
  }

  private showWelcomeSection() {
    this.offcanvasService.dismiss();
    document.querySelector('.user-chat').classList.remove('user-chat-show')
    document.querySelector('.chat-welcome-section').classList.remove('d-none');
    document.querySelector('.user-chat').classList.add('d-none');
    this.generalService.setGroupSelected(null);
    this.generalService.setItemSelected(null);
    this.generalService.setChatHeaderAvatar(null);
    this.generalService.setChatHeaderAvatar(null);
    // this.generalService.setChatHeaderStatus(null);
    this.messageService.setCurrentConversationMessages(null);
  }

  private async sendSystemMessageToRefreshGroupListForNewParticipants() {
    if (this.newParticipants !== undefined && this.newParticipants !== null && this.newParticipants.length !== 0) {
      for (const value of this.newParticipants) {

        this.sendSystemMessageToRefreshGroupListForOneMember(value.userId);

      }

    } else {
      return;
    }
  }


  private async sendSystemMessageToRefreshGroupListForOneMember(memberIdToDelete: string) {

    let systemMessage: Message = {
      roomId: this.selectedGroup.id,
      authorId: this.defaultUser.id,
      body: environment.systemMessageProperties.prefix + environment.systemMessageProperties.refreshGroupListType,
      messageContentType: MessageContentTypeEnum.System,
    };

    let contact: Contact = this.contactMap.get(memberIdToDelete);
    if (contact !== undefined && contact !== null && contact.extension !== undefined && contact.extension !== null) {
      await this.sipService.sendMessage(contact.extension,
        JSON.stringify({type: MessageContentTypeEnum.System, message: systemMessage}));

    }

  }
}
